.page-link {
    color: $pagination-color;
    background-color: $pagination-bg;
    border-color: $pagination-border-color;

    border-radius: 0;

    &:hover {
        color: $pagination-hover-color;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }

    &.active {
      z-index: 3;
    }
    &:focus {
      box-shadow: none;//0 0 0 2px rgba(68, 145, 192, 0.2)
    }

}


.page-item {
  &.active .page-link {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }
  &.disabled .page-link {
    color: $pagination-disabled-color;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}



.pagination-compact .page-link {
    margin-left: 0.425rem;
    border-width: 0;
}


.pagination .btn.page-link {  
  border-radius: 0;
}