/*!
 * Ace Admin Template v2.1
 * Copyright 2013-2020
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */
html {
  --navbar-height: 4.5rem;
  --navbar-sm-height: 4rem;
  --navbar-mobile-height: 4rem;
  --sidebar-width: 16rem;
  --sidebar-collapsed-width: 5rem;
  --sidebar-horizontal-height: 4.25rem;
  --sidebar-horizontal-collapsed-height: 4rem;
  direction: ltr;
  text-align: left;
}

html, body {
  height: 100%;
  font-family: "Open Sans", Arial, sans-serif;
}

body {
  overflow-x: hidden;
  background-color: #e4e6e9;
  color: #484b51;
}

.body-container {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-container.container {
  padding: 0;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
}

.body-container:not(.container) {
  max-width: 100vw;
}

.main-container {
  flex: 1 0 auto;
  display: flex;
  position: relative;
  max-width: 100vw;
}

.main-content {
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1220px) {
  .container {
    max-width: calc(100vw - 4rem);
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1408px;
  }
}

@media (min-width: 1600px) {
  .container.container-plus {
    max-width: 1536px;
  }
}

@media (max-width: 1199.98px) {
  body {
    background-color: #fff;
  }
  .container {
    max-width: none;
  }
  .body-container {
    max-width: 100vw;
    overflow: hidden;
  }
}

.page-content {
  flex: 1 0 auto;
  padding: 1rem 1rem 0.625rem;
}

@media (min-width: 768px) {
  .page-content {
    padding: 1.25rem 1.25rem 0.625rem;
  }
}

@media (min-width: 1200px) {
  .page-content {
    padding: 1.25rem 1.5rem 0.625rem;
  }
}

.page-header {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  border-bottom: 1px dotted #e2e2e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header .page-tools {
  align-self: flex-end;
}

.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}

.page-title .page-info {
  margin: 0 0.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  display: block;
}

@media (min-width: 768px) {
  .page-header .page-info {
    display: inline-block;
  }
}

.text-primary {
  color: #2d7bd0 !important;
}

a.text-primary[href]:hover, a.text-primary[href]:focus {
  color: #286fbb !important;
}

.text-primary-m1 {
  color: #4087d4 !important;
}

a.text-primary-m1:hover, a.text-primary-m1:focus {
  color: #2e7acc !important;
}

.text-primary-m2 {
  color: #5393d8 !important;
}

a.text-primary-m2:hover, a.text-primary-m2:focus {
  color: #3e86d3 !important;
}

.text-primary-m3 {
  color: #669fdd !important;
}

a.text-primary-m3:hover, a.text-primary-m3:focus {
  color: #5192d8 !important;
}

.text-primary-d1 {
  color: #397cc4 !important;
}

a.text-primary-d1:hover, a.text-primary-d1:focus {
  color: #3370b0 !important;
}

.text-primary-d2 {
  color: #296fbb !important;
}

a.text-primary-d2:hover, a.text-primary-d2:focus {
  color: #2463a6 !important;
}

.text-primary-d3 {
  color: #2462a6 !important;
}

a.text-primary-d3:hover, a.text-primary-d3:focus {
  color: #1f5691 !important;
}

.text-primary-l1 {
  color: #81b0e3 !important;
}

a.text-primary-l1:hover, a.text-primary-l1:focus {
  color: #6ca3de !important;
}

.text-primary-l2 {
  color: #94bce7 !important;
}

a.text-primary-l2:hover, a.text-primary-l2:focus {
  color: #7fafe2 !important;
}

.text-primary-l3 {
  color: #a7c8eb !important;
}

a.text-primary-l3:hover, a.text-primary-l3:focus {
  color: #92bbe6 !important;
}

.text-primary-l4 {
  color: #bad3ef !important;
}

a.text-primary-l4:hover, a.text-primary-l4:focus {
  color: #a5c6ea !important;
}

.text-primary-l5 {
  color: #cddff4 !important;
}

a.text-primary-l5:hover, a.text-primary-l5:focus {
  color: #b8d2ef !important;
}

.text-blue {
  color: #478fcc !important;
}

a.text-blue[href]:hover, a.text-blue[href]:focus {
  color: #3683c3 !important;
}

.text-blue-m1 {
  color: #5899d1 !important;
}

a.text-blue-m1:hover, a.text-blue-m1:focus {
  color: #448dcb !important;
}

.text-blue-m2 {
  color: #68a3d5 !important;
}

a.text-blue-m2:hover, a.text-blue-m2:focus {
  color: #5497cf !important;
}

.text-blue-m3 {
  color: #79adda !important;
}

a.text-blue-m3:hover, a.text-blue-m3:focus {
  color: #65a1d4 !important;
}

.text-blue-d1 {
  color: #508dc1 !important;
}

a.text-blue-d1:hover, a.text-blue-d1:focus {
  color: #4180b7 !important;
}

.text-blue-d2 {
  color: #4081b8 !important;
}

a.text-blue-d2:hover, a.text-blue-d2:focus {
  color: #3974a5 !important;
}

.text-blue-d3 {
  color: #3972a3 !important;
}

a.text-blue-d3:hover, a.text-blue-d3:focus {
  color: #326590 !important;
}

.text-blue-l1 {
  color: #91bce0 !important;
}

a.text-blue-l1:hover, a.text-blue-l1:focus {
  color: #7db0da !important;
}

.text-blue-l2 {
  color: #a1c6e5 !important;
}

a.text-blue-l2:hover, a.text-blue-l2:focus {
  color: #8dbadf !important;
}

.text-blue-l3 {
  color: #b2d0ea !important;
}

a.text-blue-l3:hover, a.text-blue-l3:focus {
  color: #9ec4e5 !important;
}

.text-blue-l4 {
  color: #c2daee !important;
}

a.text-blue-l4:hover, a.text-blue-l4:focus {
  color: #aecee8 !important;
}

.text-blue-l5 {
  color: #d3e4f3 !important;
}

a.text-blue-l5:hover, a.text-blue-l5:focus {
  color: #bfd8ee !important;
}

.text-blue2 {
  color: #3299ed !important;
}

a.text-blue2[href]:hover, a.text-blue2[href]:focus {
  color: #1b8deb !important;
}

.text-blue2-m1 {
  color: #44a2ef !important;
}

a.text-blue2-m1:hover, a.text-blue2-m1:focus {
  color: #2d96ed !important;
}

.text-blue2-m2 {
  color: #57abf0 !important;
}

a.text-blue2-m2:hover, a.text-blue2-m2:focus {
  color: #409fee !important;
}

.text-blue2-m3 {
  color: #69b5f2 !important;
}

a.text-blue2-m3:hover, a.text-blue2-m3:focus {
  color: #52a9f0 !important;
}

.text-blue2-d1 {
  color: #3e95dd !important;
}

a.text-blue2-d1:hover, a.text-blue2-d1:focus {
  color: #2889d9 !important;
}

.text-blue2-d2 {
  color: #2d8ad5 !important;
}

a.text-blue2-d2:hover, a.text-blue2-d2:focus {
  color: #277dc2 !important;
}

.text-blue2-d3 {
  color: #287abe !important;
}

a.text-blue2-d3:hover, a.text-blue2-d3:focus {
  color: #246ca9 !important;
}

.text-blue2-l1 {
  color: #84c2f4 !important;
}

a.text-blue2-l1:hover, a.text-blue2-l1:focus {
  color: #6db6f2 !important;
}

.text-blue2-l2 {
  color: #96cbf6 !important;
}

a.text-blue2-l2:hover, a.text-blue2-l2:focus {
  color: #7fbff4 !important;
}

.text-blue2-l3 {
  color: #a9d4f7 !important;
}

a.text-blue2-l3:hover, a.text-blue2-l3:focus {
  color: #92c8f5 !important;
}

.text-blue2-l4 {
  color: #bbddf9 !important;
}

a.text-blue2-l4:hover, a.text-blue2-l4:focus {
  color: #a4d1f7 !important;
}

.text-blue2-l5 {
  color: #cee7fb !important;
}

a.text-blue2-l5:hover, a.text-blue2-l5:focus {
  color: #b6dbf9 !important;
}

.text-info {
  color: #2daccb !important;
}

a.text-info[href]:hover, a.text-info[href]:focus {
  color: #289ab6 !important;
}

.text-info-m1 {
  color: #40b3d0 !important;
}

a.text-info-m1:hover, a.text-info-m1:focus {
  color: #31a8c6 !important;
}

.text-info-m2 {
  color: #53bbd4 !important;
}

a.text-info-m2:hover, a.text-info-m2:focus {
  color: #3fb3cf !important;
}

.text-info-m3 {
  color: #66c2d9 !important;
}

a.text-info-m3:hover, a.text-info-m3:focus {
  color: #52bad4 !important;
}

.text-info-d1 {
  color: #39a5c0 !important;
}

a.text-info-d1:hover, a.text-info-d1:focus {
  color: #3394ac !important;
}

.text-info-d2 {
  color: #299bb7 !important;
}

a.text-info-d2:hover, a.text-info-d2:focus {
  color: #2489a2 !important;
}

.text-info-d3 {
  color: #248aa2 !important;
}

a.text-info-d3:hover, a.text-info-d3:focus {
  color: #1f788d !important;
}

.text-info-l1 {
  color: #81cde0 !important;
}

a.text-info-l1:hover, a.text-info-l1:focus {
  color: #6dc5db !important;
}

.text-info-l2 {
  color: #94d5e4 !important;
}

a.text-info-l2:hover, a.text-info-l2:focus {
  color: #80cddf !important;
}

.text-info-l3 {
  color: #a7dce9 !important;
}

a.text-info-l3:hover, a.text-info-l3:focus {
  color: #93d4e4 !important;
}

.text-info-l4 {
  color: #bae4ee !important;
}

a.text-info-l4:hover, a.text-info-l4:focus {
  color: #a6dce9 !important;
}

.text-info-l5 {
  color: #cdebf3 !important;
}

a.text-info-l5:hover, a.text-info-l5:focus {
  color: #b8e3ee !important;
}

.text-secondary {
  color: #6f829d !important;
}

a.text-secondary[href]:hover, a.text-secondary[href]:focus {
  color: #627590 !important;
}

.text-secondary-m1 {
  color: #7c8da6 !important;
}

a.text-secondary-m1:hover, a.text-secondary-m1:focus {
  color: #6d809c !important;
}

.text-secondary-m2 {
  color: #8999af !important;
}

a.text-secondary-m2:hover, a.text-secondary-m2:focus {
  color: #7a8ca5 !important;
}

.text-secondary-m3 {
  color: #96a4b7 !important;
}

a.text-secondary-m3:hover, a.text-secondary-m3:focus {
  color: #8797ad !important;
}

.text-secondary-d1 {
  color: #728299 !important;
}

a.text-secondary-d1:hover, a.text-secondary-d1:focus {
  color: #65758c !important;
}

.text-secondary-d2 {
  color: #64758d !important;
}

a.text-secondary-d2:hover, a.text-secondary-d2:focus {
  color: #59697e !important;
}

.text-secondary-d3 {
  color: #59687e !important;
}

a.text-secondary-d3:hover, a.text-secondary-d3:focus {
  color: #4e5c6f !important;
}

.text-secondary-l1 {
  color: #a9b4c4 !important;
}

a.text-secondary-l1:hover, a.text-secondary-l1:focus {
  color: #9aa7ba !important;
}

.text-secondary-l2 {
  color: #b6bfcd !important;
}

a.text-secondary-l2:hover, a.text-secondary-l2:focus {
  color: #a7b2c3 !important;
}

.text-secondary-l3 {
  color: #c3cbd6 !important;
}

a.text-secondary-l3:hover, a.text-secondary-l3:focus {
  color: #b4becc !important;
}

.text-secondary-l4 {
  color: #cfd6df !important;
}

a.text-secondary-l4:hover, a.text-secondary-l4:focus {
  color: #c0c9d5 !important;
}

.text-secondary-l5 {
  color: #dce1e7 !important;
}

a.text-secondary-l5:hover, a.text-secondary-l5:focus {
  color: #cdd4dd !important;
}

.text-default {
  color: #6b97af !important;
}

a.text-default[href]:hover, a.text-default[href]:focus {
  color: #5a8ba6 !important;
}

.text-default-m1 {
  color: #78a0b6 !important;
}

a.text-default-m1:hover, a.text-default-m1:focus {
  color: #6794ad !important;
}

.text-default-m2 {
  color: #86aabd !important;
}

a.text-default-m2:hover, a.text-default-m2:focus {
  color: #769eb4 !important;
}

.text-default-m3 {
  color: #93b3c5 !important;
}

a.text-default-m3:hover, a.text-default-m3:focus {
  color: #82a7bc !important;
}

.text-default-d1 {
  color: #6e94a8 !important;
}

a.text-default-d1:hover, a.text-default-d1:focus {
  color: #5f889e !important;
}

.text-default-d2 {
  color: #60889e !important;
}

a.text-default-d2:hover, a.text-default-d2:focus {
  color: #567a8e !important;
}

.text-default-d3 {
  color: #56798c !important;
}

a.text-default-d3:hover, a.text-default-d3:focus {
  color: #4c6b7c !important;
}

.text-default-l1 {
  color: #a6c1cf !important;
}

a.text-default-l1:hover, a.text-default-l1:focus {
  color: #95b5c6 !important;
}

.text-default-l2 {
  color: #b4cad6 !important;
}

a.text-default-l2:hover, a.text-default-l2:focus {
  color: #a4becd !important;
}

.text-default-l3 {
  color: #c1d3dd !important;
}

a.text-default-l3:hover, a.text-default-l3:focus {
  color: #b1c7d4 !important;
}

.text-default-l4 {
  color: #cedde5 !important;
}

a.text-default-l4:hover, a.text-default-l4:focus {
  color: #bdd1dc !important;
}

.text-default-l5 {
  color: #dbe6ec !important;
}

a.text-default-l5:hover, a.text-default-l5:focus {
  color: #cadae3 !important;
}

.text-success {
  color: #6bae47 !important;
}

a.text-success[href]:hover, a.text-success[href]:focus {
  color: #609c40 !important;
}

.text-success-m1 {
  color: #78b558 !important;
}

a.text-success-m1:hover, a.text-success-m1:focus {
  color: #6ba94b !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

a.text-success-m2:hover, a.text-success-m2:focus {
  color: #78b556 !important;
}

.text-success-m3 {
  color: #93c479 !important;
}

a.text-success-m3:hover, a.text-success-m3:focus {
  color: #85bc67 !important;
}

.text-success-d1 {
  color: #6ea750 !important;
}

a.text-success-d1:hover, a.text-success-d1:focus {
  color: #639648 !important;
}

.text-success-d2 {
  color: #609d40 !important;
}

a.text-success-d2:hover, a.text-success-d2:focus {
  color: #558b39 !important;
}

.text-success-d3 {
  color: #568b39 !important;
}

a.text-success-d3:hover, a.text-success-d3:focus {
  color: #4b7932 !important;
}

.text-success-l1 {
  color: #a6ce91 !important;
}

a.text-success-l1:hover, a.text-success-l1:focus {
  color: #98c67f !important;
}

.text-success-l2 {
  color: #b4d6a1 !important;
}

a.text-success-l2:hover, a.text-success-l2:focus {
  color: #a6ce8f !important;
}

.text-success-l3 {
  color: #c1ddb2 !important;
}

a.text-success-l3:hover, a.text-success-l3:focus {
  color: #b3d5a0 !important;
}

.text-success-l4 {
  color: #cee4c2 !important;
}

a.text-success-l4:hover, a.text-success-l4:focus {
  color: #c0dcb0 !important;
}

.text-success-l5 {
  color: #dbecd3 !important;
}

a.text-success-l5:hover, a.text-success-l5:focus {
  color: #cce4c1 !important;
}

.text-danger {
  color: #da3737 !important;
}

a.text-danger[href]:hover, a.text-danger[href]:focus {
  color: #d12727 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}

a.text-danger-m1:hover, a.text-danger-m1:focus {
  color: #d93434 !important;
}

.text-danger-m2 {
  color: #e15b5b !important;
}

a.text-danger-m2:hover, a.text-danger-m2:focus {
  color: #dd4545 !important;
}

.text-danger-m3 {
  color: #e46d6d !important;
}

a.text-danger-m3:hover, a.text-danger-m3:focus {
  color: #e05757 !important;
}

.text-danger-d1 {
  color: #cd4242 !important;
}

a.text-danger-d1:hover, a.text-danger-d1:focus {
  color: #c23333 !important;
}

.text-danger-d2 {
  color: #c43232 !important;
}

a.text-danger-d2:hover, a.text-danger-d2:focus {
  color: #b02d2d !important;
}

.text-danger-d3 {
  color: #ae2c2c !important;
}

a.text-danger-d3:hover, a.text-danger-d3:focus {
  color: #9a2727 !important;
}

.text-danger-l1 {
  color: #e98787 !important;
}

a.text-danger-l1:hover, a.text-danger-l1:focus {
  color: #e57171 !important;
}

.text-danger-l2 {
  color: #ec9999 !important;
}

a.text-danger-l2:hover, a.text-danger-l2:focus {
  color: #e88484 !important;
}

.text-danger-l3 {
  color: #efabab !important;
}

a.text-danger-l3:hover, a.text-danger-l3:focus {
  color: #eb9696 !important;
}

.text-danger-l4 {
  color: #f3bdbd !important;
}

a.text-danger-l4:hover, a.text-danger-l4:focus {
  color: #efa7a7 !important;
}

.text-danger-l5 {
  color: #f6cfcf !important;
}

a.text-danger-l5:hover, a.text-danger-l5:focus {
  color: #f2baba !important;
}

.text-warning {
  color: #feb902 !important;
}

a.text-warning[href]:hover, a.text-warning[href]:focus {
  color: #e6a701 !important;
}

.text-warning-m1 {
  color: #febf19 !important;
}

a.text-warning-m1:hover, a.text-warning-m1:focus {
  color: #fcb701 !important;
}

.text-warning-m2 {
  color: #fec630 !important;
}

a.text-warning-m2:hover, a.text-warning-m2:focus {
  color: #febf17 !important;
}

.text-warning-m3 {
  color: #fecc46 !important;
}

a.text-warning-m3:hover, a.text-warning-m3:focus {
  color: #fec52d !important;
}

.text-warning-d1 {
  color: #ebb015 !important;
}

a.text-warning-d1:hover, a.text-warning-d1:focus {
  color: #d49f12 !important;
}

.text-warning-d2 {
  color: #e5a702 !important;
}

a.text-warning-d2:hover, a.text-warning-d2:focus {
  color: #cc9502 !important;
}

.text-warning-d3 {
  color: #cb9402 !important;
}

a.text-warning-d3:hover, a.text-warning-d3:focus {
  color: #b28202 !important;
}

.text-warning-l1 {
  color: #fed567 !important;
}

a.text-warning-l1:hover, a.text-warning-l1:focus {
  color: #fece4e !important;
}

.text-warning-l2 {
  color: #fedb7e !important;
}

a.text-warning-l2:hover, a.text-warning-l2:focus {
  color: #fed465 !important;
}

.text-warning-l3 {
  color: #ffe295 !important;
}

a.text-warning-l3:hover, a.text-warning-l3:focus {
  color: #ffdb7c !important;
}

.text-warning-l4 {
  color: #ffe8ac !important;
}

a.text-warning-l4:hover, a.text-warning-l4:focus {
  color: #ffe193 !important;
}

.text-warning-l5 {
  color: #ffeec2 !important;
}

a.text-warning-l5:hover, a.text-warning-l5:focus {
  color: #ffe7a9 !important;
}

.text-orange {
  color: #ff9716 !important;
}

a.text-orange[href]:hover, a.text-orange[href]:focus {
  color: #fc8b00 !important;
}

.text-orange-m1 {
  color: #ffa02b !important;
}

a.text-orange-m1:hover, a.text-orange-m1:focus {
  color: #ff9512 !important;
}

.text-orange-m2 {
  color: #ffaa40 !important;
}

a.text-orange-m2:hover, a.text-orange-m2:focus {
  color: #ff9f27 !important;
}

.text-orange-m3 {
  color: #ffb355 !important;
}

a.text-orange-m3:hover, a.text-orange-m3:focus {
  color: #ffa83c !important;
}

.text-orange-d1 {
  color: #ec9426 !important;
}

a.text-orange-d1:hover, a.text-orange-d1:focus {
  color: #e48814 !important;
}

.text-orange-d2 {
  color: #e68814 !important;
}

a.text-orange-d2:hover, a.text-orange-d2:focus {
  color: #cf7a12 !important;
}

.text-orange-d3 {
  color: #cc7912 !important;
}

a.text-orange-d3:hover, a.text-orange-d3:focus {
  color: #b56b10 !important;
}

.text-orange-l1 {
  color: #ffc173 !important;
}

a.text-orange-l1:hover, a.text-orange-l1:focus {
  color: #ffb65a !important;
}

.text-orange-l2 {
  color: #ffca88 !important;
}

a.text-orange-l2:hover, a.text-orange-l2:focus {
  color: #ffbf6f !important;
}

.text-orange-l3 {
  color: #ffd39d !important;
}

a.text-orange-l3:hover, a.text-orange-l3:focus {
  color: #ffc884 !important;
}

.text-orange-l4 {
  color: #ffddb2 !important;
}

a.text-orange-l4:hover, a.text-orange-l4:focus {
  color: #ffd299 !important;
}

.text-orange-l5 {
  color: #ffe6c7 !important;
}

a.text-orange-l5:hover, a.text-orange-l5:focus {
  color: #ffdbae !important;
}

.text-brown {
  color: #c36446 !important;
}

a.text-brown[href]:hover, a.text-brown[href]:focus {
  color: #b5583b !important;
}

.text-brown-m1 {
  color: #c87257 !important;
}

a.text-brown-m1:hover, a.text-brown-m1:focus {
  color: #c26244 !important;
}

.text-brown-m2 {
  color: #ce8067 !important;
}

a.text-brown-m2:hover, a.text-brown-m2:focus {
  color: #c87054 !important;
}

.text-brown-m3 {
  color: #d38e78 !important;
}

a.text-brown-m3:hover, a.text-brown-m3:focus {
  color: #cd7e65 !important;
}

.text-brown-d1 {
  color: #b9684f !important;
}

a.text-brown-d1:hover, a.text-brown-d1:focus {
  color: #ab5c44 !important;
}

.text-brown-d2 {
  color: #b05a3f !important;
}

a.text-brown-d2:hover, a.text-brown-d2:focus {
  color: #9d5038 !important;
}

.text-brown-d3 {
  color: #9c5038 !important;
}

a.text-brown-d3:hover, a.text-brown-d3:focus {
  color: #894631 !important;
}

.text-brown-l1 {
  color: #dba290 !important;
}

a.text-brown-l1:hover, a.text-brown-l1:focus {
  color: #d5927d !important;
}

.text-brown-l2 {
  color: #e0b0a1 !important;
}

a.text-brown-l2:hover, a.text-brown-l2:focus {
  color: #daa08e !important;
}

.text-brown-l3 {
  color: #e6beb1 !important;
}

a.text-brown-l3:hover, a.text-brown-l3:focus {
  color: #e0ae9e !important;
}

.text-brown-l4 {
  color: #ebccc2 !important;
}

a.text-brown-l4:hover, a.text-brown-l4:focus {
  color: #e5bcaf !important;
}

.text-brown-l5 {
  color: #f1dad3 !important;
}

a.text-brown-l5:hover, a.text-brown-l5:focus {
  color: #ebcac0 !important;
}

.text-yellow {
  color: #f3d61f !important;
}

a.text-yellow[href]:hover, a.text-yellow[href]:focus {
  color: #eccd0d !important;
}

.text-yellow-m1 {
  color: #f4da33 !important;
}

a.text-yellow-m1:hover, a.text-yellow-m1:focus {
  color: #f3d61b !important;
}

.text-yellow-m2 {
  color: #f5dd47 !important;
}

a.text-yellow-m2:hover, a.text-yellow-m2:focus {
  color: #f4d92f !important;
}

.text-yellow-m3 {
  color: #f6e15b !important;
}

a.text-yellow-m3:hover, a.text-yellow-m3:focus {
  color: #f5dd43 !important;
}

.text-yellow-d1 {
  color: #e2c92e !important;
}

a.text-yellow-d1:hover, a.text-yellow-d1:focus {
  color: #d8bf1e !important;
}

.text-yellow-d2 {
  color: #dbc11c !important;
}

a.text-yellow-d2:hover, a.text-yellow-d2:focus {
  color: #c4ad19 !important;
}

.text-yellow-d3 {
  color: #c2ab19 !important;
}

a.text-yellow-d3:hover, a.text-yellow-d3:focus {
  color: #ab9716 !important;
}

.text-yellow-l1 {
  color: #f8e679 !important;
}

a.text-yellow-l1:hover, a.text-yellow-l1:focus {
  color: #f7e161 !important;
}

.text-yellow-l2 {
  color: #f9ea8d !important;
}

a.text-yellow-l2:hover, a.text-yellow-l2:focus {
  color: #f8e675 !important;
}

.text-yellow-l3 {
  color: #faeea1 !important;
}

a.text-yellow-l3:hover, a.text-yellow-l3:focus {
  color: #f9ea89 !important;
}

.text-yellow-l4 {
  color: #fbf1b5 !important;
}

a.text-yellow-l4:hover, a.text-yellow-l4:focus {
  color: #faec9d !important;
}

.text-yellow-l5 {
  color: #fcf5c9 !important;
}

a.text-yellow-l5:hover, a.text-yellow-l5:focus {
  color: #fbf1b1 !important;
}

.text-purple {
  color: #8e58b8 !important;
}

a.text-purple[href]:hover, a.text-purple[href]:focus {
  color: #814aad !important;
}

.text-purple-m1 {
  color: #9867be !important;
}

a.text-purple-m1:hover, a.text-purple-m1:focus {
  color: #8c55b6 !important;
}

.text-purple-m2 {
  color: #a276c5 !important;
}

a.text-purple-m2:hover, a.text-purple-m2:focus {
  color: #9664bd !important;
}

.text-purple-m3 {
  color: #ad85cb !important;
}

a.text-purple-m3:hover, a.text-purple-m3:focus {
  color: #a173c3 !important;
}

.text-purple-d1 {
  color: #8c5eb0 !important;
}

a.text-purple-d1:hover, a.text-purple-d1:focus {
  color: #7f50a4 !important;
}

.text-purple-d2 {
  color: #804fa6 !important;
}

a.text-purple-d2:hover, a.text-purple-d2:focus {
  color: #734795 !important;
}

.text-purple-d3 {
  color: #724693 !important;
}

a.text-purple-d3:hover, a.text-purple-d3:focus {
  color: #653e82 !important;
}

.text-purple-l1 {
  color: #bb9bd4 !important;
}

a.text-purple-l1:hover, a.text-purple-l1:focus {
  color: #af89cc !important;
}

.text-purple-l2 {
  color: #c5aadb !important;
}

a.text-purple-l2:hover, a.text-purple-l2:focus {
  color: #b998d3 !important;
}

.text-purple-l3 {
  color: #d0b9e1 !important;
}

a.text-purple-l3:hover, a.text-purple-l3:focus {
  color: #c4a7d9 !important;
}

.text-purple-l4 {
  color: #dac8e8 !important;
}

a.text-purple-l4:hover, a.text-purple-l4:focus {
  color: #ceb6e0 !important;
}

.text-purple-l5 {
  color: #e4d7ee !important;
}

a.text-purple-l5:hover, a.text-purple-l5:focus {
  color: #d8c5e6 !important;
}

.text-pink {
  color: #d3407f !important;
}

a.text-pink[href]:hover, a.text-pink[href]:focus {
  color: #cb2f72 !important;
}

.text-pink-m1 {
  color: #d7518b !important;
}

a.text-pink-m1:hover, a.text-pink-m1:focus {
  color: #d23c7d !important;
}

.text-pink-m2 {
  color: #db6296 !important;
}

a.text-pink-m2:hover, a.text-pink-m2:focus {
  color: #d64d88 !important;
}

.text-pink-m3 {
  color: #df74a2 !important;
}

a.text-pink-m3:hover, a.text-pink-m3:focus {
  color: #da5f94 !important;
}

.text-pink-d1 {
  color: #c74a7f !important;
}

a.text-pink-d1:hover, a.text-pink-d1:focus {
  color: #bd3a72 !important;
}

.text-pink-d2 {
  color: #be3a72 !important;
}

a.text-pink-d2:hover, a.text-pink-d2:focus {
  color: #aa3466 !important;
}

.text-pink-d3 {
  color: #a93366 !important;
}

a.text-pink-d3:hover, a.text-pink-d3:focus {
  color: #952d5a !important;
}

.text-pink-l1 {
  color: #e58cb2 !important;
}

a.text-pink-l1:hover, a.text-pink-l1:focus {
  color: #e077a4 !important;
}

.text-pink-l2 {
  color: #e99ebe !important;
}

a.text-pink-l2:hover, a.text-pink-l2:focus {
  color: #e489b0 !important;
}

.text-pink-l3 {
  color: #edafc9 !important;
}

a.text-pink-l3:hover, a.text-pink-l3:focus {
  color: #e89abb !important;
}

.text-pink-l4 {
  color: #f0c0d5 !important;
}

a.text-pink-l4:hover, a.text-pink-l4:focus {
  color: #ebabc7 !important;
}

.text-pink-l5 {
  color: #f4d1e0 !important;
}

a.text-pink-l5:hover, a.text-pink-l5:focus {
  color: #efbcd2 !important;
}

.text-grey {
  color: #6e7074 !important;
}

a.text-grey[href]:hover, a.text-grey[href]:focus {
  color: #626367 !important;
}

.text-grey-m1 {
  color: #7b7d81 !important;
}

a.text-grey-m1:hover, a.text-grey-m1:focus {
  color: #6f7074 !important;
}

.text-grey-m2 {
  color: #888a8d !important;
}

a.text-grey-m2:hover, a.text-grey-m2:focus {
  color: #7b7d80 !important;
}

.text-grey-m3 {
  color: #95979a !important;
}

a.text-grey-m3:hover, a.text-grey-m3:focus {
  color: #888a8e !important;
}

.text-grey-d1 {
  color: #717276 !important;
}

a.text-grey-d1:hover, a.text-grey-d1:focus {
  color: #656569 !important;
}

.text-grey-d2 {
  color: #636568 !important;
}

a.text-grey-d2:hover, a.text-grey-d2:focus {
  color: #57585b !important;
}

.text-grey-d3 {
  color: #585a5d !important;
}

a.text-grey-d3:hover, a.text-grey-d3:focus {
  color: #4c4d50 !important;
}

.text-grey-l1 {
  color: #a8a9ac !important;
}

a.text-grey-l1:hover, a.text-grey-l1:focus {
  color: #9b9ca0 !important;
}

.text-grey-l2 {
  color: #b5b6b8 !important;
}

a.text-grey-l2:hover, a.text-grey-l2:focus {
  color: #a8a9ac !important;
}

.text-grey-l3 {
  color: #c2c3c5 !important;
}

a.text-grey-l3:hover, a.text-grey-l3:focus {
  color: #b5b6b9 !important;
}

.text-grey-l4 {
  color: #cfd0d1 !important;
}

a.text-grey-l4:hover, a.text-grey-l4:focus {
  color: #c2c3c5 !important;
}

.text-grey-l5 {
  color: #dcddde !important;
}

a.text-grey-l5:hover, a.text-grey-l5:focus {
  color: #cfd0d2 !important;
}

.text-dark {
  color: #323337 !important;
}

a.text-dark[href]:hover, a.text-dark[href]:focus {
  color: #26272a !important;
}

.text-dark-m1 {
  color: #444549 !important;
}

a.text-dark-m1:hover, a.text-dark-m1:focus {
  color: #38393c !important;
}

.text-dark-m2 {
  color: #57585b !important;
}

a.text-dark-m2:hover, a.text-dark-m2:focus {
  color: #4b4b4e !important;
}

.text-dark-m3 {
  color: #696a6d !important;
}

a.text-dark-m3:hover, a.text-dark-m3:focus {
  color: #5c5d60 !important;
}

.text-dark-d1 {
  color: #3e3f42 !important;
}

a.text-dark-d1:hover, a.text-dark-d1:focus {
  color: #323235 !important;
}

.text-dark-d2 {
  color: #2d2e32 !important;
}

a.text-dark-d2:hover, a.text-dark-d2:focus {
  color: #212225 !important;
}

.text-dark-d3 {
  color: #28292c !important;
}

a.text-dark-d3:hover, a.text-dark-d3:focus {
  color: #1c1d1f !important;
}

.text-dark-l1 {
  color: #848587 !important;
}

a.text-dark-l1:hover, a.text-dark-l1:focus {
  color: #77787a !important;
}

.text-dark-l2 {
  color: #969799 !important;
}

a.text-dark-l2:hover, a.text-dark-l2:focus {
  color: #898a8c !important;
}

.text-dark-l3 {
  color: #a9a9ab !important;
}

a.text-dark-l3:hover, a.text-dark-l3:focus {
  color: #9c9c9e !important;
}

.text-dark-l4 {
  color: #bbbcbd !important;
}

a.text-dark-l4:hover, a.text-dark-l4:focus {
  color: #aeafb0 !important;
}

.text-dark-l5 {
  color: #cececf !important;
}

a.text-dark-l5:hover, a.text-dark-l5:focus {
  color: #c1c1c2 !important;
}

.text-white-tp1 {
  color: rgba(255, 255, 255, 0.93) !important;
}

.text-white-tp2 {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-white-tp3 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-white-tp4 {
  color: rgba(255, 255, 255, 0.73) !important;
}

.text-dark-tp1 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.text-dark-tp2 {
  color: rgba(0, 0, 0, 0.79) !important;
}

.text-dark-tp3 {
  color: rgba(0, 0, 0, 0.68) !important;
}

.text-dark-tp4 {
  color: rgba(0, 0, 0, 0.57) !important;
}

.text-dark-tp5 {
  color: rgba(0, 0, 0, 0.46) !important;
}

.text-body {
  color: #484b51 !important;
}

.opacity-1 {
  opacity: 0.91;
}

.opacity-2 {
  opacity: 0.82;
}

.opacity-3 {
  opacity: 0.73;
}

.opacity-4 {
  opacity: 0.64;
}

a {
  color: #2d7bd0;
}

a:hover {
  color: #286fbb;
}

.text-40 {
  font-size: 40% !important;
}

.text-50 {
  font-size: 50% !important;
}

.text-60 {
  font-size: 60% !important;
}

.text-70 {
  font-size: 70% !important;
}

.text-80 {
  font-size: 80% !important;
}

.text-90 {
  font-size: 90% !important;
}

.text-100 {
  font-size: 100% !important;
}

.text-110 {
  font-size: 110% !important;
}

.text-120 {
  font-size: 120% !important;
}

.text-130 {
  font-size: 130% !important;
}

.text-140 {
  font-size: 140% !important;
}

.text-150 {
  font-size: 150% !important;
}

.text-160 {
  font-size: 160% !important;
}

.text-170 {
  font-size: 170% !important;
}

.text-180 {
  font-size: 180% !important;
}

.text-190 {
  font-size: 190% !important;
}

.text-200 {
  font-size: 200% !important;
}

.text-95 {
  font-size: 95% !important;
}

.text-85 {
  font-size: 85% !important;
}

.text-75 {
  font-size: 75% !important;
}

.text-105 {
  font-size: 105% !important;
}

.text-115 {
  font-size: 115% !important;
}

.text-125 {
  font-size: 125% !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-700, .font-bold {
  font-weight: 700 !important;
}

.text-600, .font-bolder {
  font-weight: 600 !important;
}

.text-500, .font-medium {
  font-weight: 500 !important;
}

.text-400, .font-normal {
  font-weight: 400 !important;
}

.text-300, .font-light, .font-lighter {
  font-weight: 300 !important;
}

.no-underline {
  text-decoration: none !important;
}

.line-through {
  text-decoration: line-through !important;
}

.line-height-n {
  line-height: normal !important;
}

.letter-spacing {
  letter-spacing: 0.5px !important;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 1.5px !important;
}

.letter-spacing-3 {
  letter-spacing: 2px !important;
}

.letter-spacing-4 {
  letter-spacing: 2.5px !important;
}

.bgc-default {
  background-color: #79a9c5 !important;
}

.brc-default {
  border-color: #79a9c5 !important;
}

.bgc-default-tp1 {
  background-color: rgba(121, 169, 197, 0.92) !important;
}

.brc-default-tp1 {
  border-color: rgba(121, 169, 197, 0.92) !important;
}

.bgc-default-tp2 {
  background-color: rgba(121, 169, 197, 0.84) !important;
}

.brc-default-tp2 {
  border-color: rgba(121, 169, 197, 0.84) !important;
}

.bgc-default-tp3 {
  background-color: rgba(121, 169, 197, 0.76) !important;
}

.brc-default-tp3 {
  border-color: rgba(121, 169, 197, 0.76) !important;
}

.bgc-default-tp4 {
  background-color: rgba(121, 169, 197, 0.68) !important;
}

.brc-default-tp4 {
  border-color: rgba(121, 169, 197, 0.68) !important;
}

.bgc-default-d1 {
  background-color: #6da1c0 !important;
}

.bgc-default-d2 {
  background-color: #6099ba !important;
}

.bgc-default-m1 {
  background-color: #8db6ce !important;
}

.brc-default-m1, .brc-h-default-m1:hover {
  border-color: #8db6ce !important;
}

.bgc-default-m2 {
  background-color: #a1c3d6 !important;
}

.brc-default-m2, .brc-h-default-m2:hover {
  border-color: #a1c3d6 !important;
}

.bgc-default-m3 {
  background-color: #b5d0df !important;
}

.brc-default-m3, .brc-h-default-m3:hover {
  border-color: #b5d0df !important;
}

.bgc-default-m4 {
  background-color: #c9dce8 !important;
}

.brc-default-m4, .brc-h-default-m4:hover {
  border-color: #c9dce8 !important;
}

.bgc-default-l1, .bgc-h-default-l1:hover {
  background-color: #dce9f0 !important;
}

.brc-default-l1 {
  border-color: #dce9f0 !important;
}

.bgc-default-l2, .bgc-h-default-l2:hover {
  background-color: #e6eff4 !important;
}

.brc-default-l2 {
  border-color: #e6eff4 !important;
}

.bgc-default-l3, .bgc-h-default-l3:hover {
  background-color: #eef4f8 !important;
}

.brc-default-l3 {
  border-color: #eef4f8 !important;
}

.bgc-default-l4, .bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}

.brc-default-l4 {
  border-color: #f3f8fa !important;
}

.bgc-default-l5, .bgc-h-default-l5:hover {
  background-color: #f9fbfc !important;
}

.brc-default-l5 {
  border-color: #f9fbfc !important;
}

.bgc-primary {
  background-color: #5899ca !important;
}

.brc-primary {
  border-color: #5899ca !important;
}

.bgc-primary-tp1 {
  background-color: rgba(88, 153, 202, 0.92) !important;
}

.brc-primary-tp1 {
  border-color: rgba(88, 153, 202, 0.92) !important;
}

.bgc-primary-tp2 {
  background-color: rgba(88, 153, 202, 0.84) !important;
}

.brc-primary-tp2 {
  border-color: rgba(88, 153, 202, 0.84) !important;
}

.bgc-primary-tp3 {
  background-color: rgba(88, 153, 202, 0.76) !important;
}

.brc-primary-tp3 {
  border-color: rgba(88, 153, 202, 0.76) !important;
}

.bgc-primary-tp4 {
  background-color: rgba(88, 153, 202, 0.68) !important;
}

.brc-primary-tp4 {
  border-color: rgba(88, 153, 202, 0.68) !important;
}

.bgc-primary-d1 {
  background-color: #4a91c6 !important;
}

.bgc-primary-d2 {
  background-color: #3d88c1 !important;
}

.bgc-primary-m1 {
  background-color: #71a8d2 !important;
}

.brc-primary-m1, .brc-h-primary-m1:hover {
  border-color: #71a8d2 !important;
}

.bgc-primary-m2 {
  background-color: #8bb8da !important;
}

.brc-primary-m2, .brc-h-primary-m2:hover {
  border-color: #8bb8da !important;
}

.bgc-primary-m3 {
  background-color: #a4c7e2 !important;
}

.brc-primary-m3, .brc-h-primary-m3:hover {
  border-color: #a4c7e2 !important;
}

.bgc-primary-m4 {
  background-color: #bdd7ea !important;
}

.brc-primary-m4, .brc-h-primary-m4:hover {
  border-color: #bdd7ea !important;
}

.bgc-primary-l1, .bgc-h-primary-l1:hover {
  background-color: #d5e5f2 !important;
}

.brc-primary-l1 {
  border-color: #d5e5f2 !important;
}

.bgc-primary-l2, .bgc-h-primary-l2:hover {
  background-color: #e2edf6 !important;
}

.brc-primary-l2 {
  border-color: #e2edf6 !important;
}

.bgc-primary-l3, .bgc-h-primary-l3:hover {
  background-color: #ebf3f9 !important;
}

.brc-primary-l3 {
  border-color: #ebf3f9 !important;
}

.bgc-primary-l4, .bgc-h-primary-l4:hover {
  background-color: #f2f7fb !important;
}

.brc-primary-l4 {
  border-color: #f2f7fb !important;
}

.bgc-primary-l5, .bgc-h-primary-l5:hover {
  background-color: #f9fcfd !important;
}

.brc-primary-l5 {
  border-color: #f9fcfd !important;
}

.bgc-secondary {
  background-color: #91a3ac !important;
}

.brc-secondary {
  border-color: #91a3ac !important;
}

.bgc-secondary-tp1 {
  background-color: rgba(145, 163, 172, 0.92) !important;
}

.brc-secondary-tp1 {
  border-color: rgba(145, 163, 172, 0.92) !important;
}

.bgc-secondary-tp2 {
  background-color: rgba(145, 163, 172, 0.84) !important;
}

.brc-secondary-tp2 {
  border-color: rgba(145, 163, 172, 0.84) !important;
}

.bgc-secondary-tp3 {
  background-color: rgba(145, 163, 172, 0.76) !important;
}

.brc-secondary-tp3 {
  border-color: rgba(145, 163, 172, 0.76) !important;
}

.bgc-secondary-tp4 {
  background-color: rgba(145, 163, 172, 0.68) !important;
}

.brc-secondary-tp4 {
  border-color: rgba(145, 163, 172, 0.68) !important;
}

.bgc-secondary-d1 {
  background-color: #879aa4 !important;
}

.bgc-secondary-d2 {
  background-color: #7d929d !important;
}

.bgc-secondary-m1 {
  background-color: #a1b1b8 !important;
}

.brc-secondary-m1, .brc-h-secondary-m1:hover {
  border-color: #a1b1b8 !important;
}

.bgc-secondary-m2 {
  background-color: #b2bec5 !important;
}

.brc-secondary-m2, .brc-h-secondary-m2:hover {
  border-color: #b2bec5 !important;
}

.bgc-secondary-m3 {
  background-color: #c2ccd1 !important;
}

.brc-secondary-m3, .brc-h-secondary-m3:hover {
  border-color: #c2ccd1 !important;
}

.bgc-secondary-m4 {
  background-color: #d2dadd !important;
}

.brc-secondary-m4, .brc-h-secondary-m4:hover {
  border-color: #d2dadd !important;
}

.bgc-secondary-l1, .bgc-h-secondary-l1:hover {
  background-color: #e2e7e9 !important;
}

.brc-secondary-l1 {
  border-color: #e2e7e9 !important;
}

.bgc-secondary-l2, .bgc-h-secondary-l2:hover {
  background-color: #eaedef !important;
}

.brc-secondary-l2 {
  border-color: #eaedef !important;
}

.bgc-secondary-l3, .bgc-h-secondary-l3:hover {
  background-color: #f0f3f4 !important;
}

.brc-secondary-l3 {
  border-color: #f0f3f4 !important;
}

.bgc-secondary-l4, .bgc-h-secondary-l4:hover {
  background-color: #f5f7f7 !important;
}

.brc-secondary-l4 {
  border-color: #f5f7f7 !important;
}

.bgc-secondary-l5, .bgc-h-secondary-l5:hover {
  background-color: #f9fafb !important;
}

.brc-secondary-l5 {
  border-color: #f9fafb !important;
}

.bgc-info {
  background-color: #57b5da !important;
}

.brc-info {
  border-color: #57b5da !important;
}

.bgc-info-tp1 {
  background-color: rgba(87, 181, 218, 0.92) !important;
}

.brc-info-tp1 {
  border-color: rgba(87, 181, 218, 0.92) !important;
}

.bgc-info-tp2 {
  background-color: rgba(87, 181, 218, 0.84) !important;
}

.brc-info-tp2 {
  border-color: rgba(87, 181, 218, 0.84) !important;
}

.bgc-info-tp3 {
  background-color: rgba(87, 181, 218, 0.76) !important;
}

.brc-info-tp3 {
  border-color: rgba(87, 181, 218, 0.76) !important;
}

.bgc-info-tp4 {
  background-color: rgba(87, 181, 218, 0.68) !important;
}

.brc-info-tp4 {
  border-color: rgba(87, 181, 218, 0.68) !important;
}

.bgc-info-d1 {
  background-color: #48afd7 !important;
}

.bgc-info-d2 {
  background-color: #3aa8d4 !important;
}

.bgc-info-m1 {
  background-color: #70c0e0 !important;
}

.brc-info-m1, .brc-h-info-m1:hover {
  border-color: #70c0e0 !important;
}

.bgc-info-m2 {
  background-color: #8acbe5 !important;
}

.brc-info-m2, .brc-h-info-m2:hover {
  border-color: #8acbe5 !important;
}

.bgc-info-m3 {
  background-color: #a3d7eb !important;
}

.brc-info-m3, .brc-h-info-m3:hover {
  border-color: #a3d7eb !important;
}

.bgc-info-m4 {
  background-color: #bde2f0 !important;
}

.brc-info-m4, .brc-h-info-m4:hover {
  border-color: #bde2f0 !important;
}

.bgc-info-l1, .bgc-h-info-l1:hover {
  background-color: #d5ecf6 !important;
}

.brc-info-l1 {
  border-color: #d5ecf6 !important;
}

.bgc-info-l2, .bgc-h-info-l2:hover {
  background-color: #e1f2f8 !important;
}

.brc-info-l2 {
  border-color: #e1f2f8 !important;
}

.bgc-info-l3, .bgc-h-info-l3:hover {
  background-color: #ebf6fb !important;
}

.brc-info-l3 {
  border-color: #ebf6fb !important;
}

.bgc-info-l4, .bgc-h-info-l4:hover {
  background-color: #f2f9fc !important;
}

.brc-info-l4 {
  border-color: #f2f9fc !important;
}

.bgc-info-l5, .bgc-h-info-l5:hover {
  background-color: #f9fcfe !important;
}

.brc-info-l5 {
  border-color: #f9fcfe !important;
}

.bgc-success {
  background-color: #73bd73 !important;
}

.brc-success {
  border-color: #73bd73 !important;
}

.bgc-success-tp1 {
  background-color: rgba(115, 189, 115, 0.92) !important;
}

.brc-success-tp1 {
  border-color: rgba(115, 189, 115, 0.92) !important;
}

.bgc-success-tp2 {
  background-color: rgba(115, 189, 115, 0.84) !important;
}

.brc-success-tp2 {
  border-color: rgba(115, 189, 115, 0.84) !important;
}

.bgc-success-tp3 {
  background-color: rgba(115, 189, 115, 0.76) !important;
}

.brc-success-tp3 {
  border-color: rgba(115, 189, 115, 0.76) !important;
}

.bgc-success-tp4 {
  background-color: rgba(115, 189, 115, 0.68) !important;
}

.brc-success-tp4 {
  border-color: rgba(115, 189, 115, 0.68) !important;
}

.bgc-success-d1 {
  background-color: #67b767 !important;
}

.bgc-success-d2 {
  background-color: #5bb25b !important;
}

.bgc-success-m1 {
  background-color: #88c788 !important;
}

.brc-success-m1, .brc-h-success-m1:hover {
  border-color: #88c788 !important;
}

.bgc-success-m2 {
  background-color: #9dd19d !important;
}

.brc-success-m2, .brc-h-success-m2:hover {
  border-color: #9dd19d !important;
}

.bgc-success-m3 {
  background-color: #b2dbb2 !important;
}

.brc-success-m3, .brc-h-success-m3:hover {
  border-color: #b2dbb2 !important;
}

.bgc-success-m4 {
  background-color: #c7e5c7 !important;
}

.brc-success-m4, .brc-h-success-m4:hover {
  border-color: #c7e5c7 !important;
}

.bgc-success-l1, .bgc-h-success-l1:hover {
  background-color: #dbeedb !important;
}

.brc-success-l1 {
  border-color: #dbeedb !important;
}

.bgc-success-l2, .bgc-h-success-l2:hover {
  background-color: #e6f3e6 !important;
}

.brc-success-l2 {
  border-color: #e6f3e6 !important;
}

.bgc-success-l3, .bgc-h-success-l3:hover {
  background-color: #eef7ee !important;
}

.brc-success-l3 {
  border-color: #eef7ee !important;
}

.bgc-success-l4, .bgc-h-success-l4:hover {
  background-color: #f4faf4 !important;
}

.brc-success-l4 {
  border-color: #f4faf4 !important;
}

.bgc-success-l5, .bgc-h-success-l5:hover {
  background-color: #fafcfa !important;
}

.brc-success-l5 {
  border-color: #fafcfa !important;
}

.bgc-danger {
  background-color: #dd6a57 !important;
}

.brc-danger {
  border-color: #dd6a57 !important;
}

.bgc-danger-tp1 {
  background-color: rgba(221, 106, 87, 0.92) !important;
}

.brc-danger-tp1 {
  border-color: rgba(221, 106, 87, 0.92) !important;
}

.bgc-danger-tp2 {
  background-color: rgba(221, 106, 87, 0.84) !important;
}

.brc-danger-tp2 {
  border-color: rgba(221, 106, 87, 0.84) !important;
}

.bgc-danger-tp3 {
  background-color: rgba(221, 106, 87, 0.76) !important;
}

.brc-danger-tp3 {
  border-color: rgba(221, 106, 87, 0.76) !important;
}

.bgc-danger-tp4 {
  background-color: rgba(221, 106, 87, 0.68) !important;
}

.brc-danger-tp4 {
  border-color: rgba(221, 106, 87, 0.68) !important;
}

.bgc-danger-d1 {
  background-color: #da5d48 !important;
}

.bgc-danger-d2 {
  background-color: #d75039 !important;
}

.bgc-danger-m1 {
  background-color: #e28171 !important;
}

.brc-danger-m1, .brc-h-danger-m1:hover {
  border-color: #e28171 !important;
}

.bgc-danger-m2 {
  background-color: #e7988b !important;
}

.brc-danger-m2, .brc-h-danger-m2:hover {
  border-color: #e7988b !important;
}

.bgc-danger-m3 {
  background-color: #edafa4 !important;
}

.brc-danger-m3, .brc-h-danger-m3:hover {
  border-color: #edafa4 !important;
}

.bgc-danger-m4 {
  background-color: #f2c5be !important;
}

.brc-danger-m4, .brc-h-danger-m4:hover {
  border-color: #f2c5be !important;
}

.bgc-danger-l1, .bgc-h-danger-l1:hover {
  background-color: #f7dbd6 !important;
}

.brc-danger-l1 {
  border-color: #f7dbd6 !important;
}

.bgc-danger-l2, .bgc-h-danger-l2:hover {
  background-color: #f9e6e3 !important;
}

.brc-danger-l2 {
  border-color: #f9e6e3 !important;
}

.bgc-danger-l3, .bgc-h-danger-l3:hover {
  background-color: #fbefed !important;
}

.brc-danger-l3 {
  border-color: #fbefed !important;
}

.bgc-danger-l4, .bgc-h-danger-l4:hover {
  background-color: #fdf6f4 !important;
}

.brc-danger-l4 {
  border-color: #fdf6f4 !important;
}

.bgc-danger-l5, .bgc-h-danger-l5:hover {
  background-color: #fefcfc !important;
}

.brc-danger-l5 {
  border-color: #fefcfc !important;
}

.bgc-warning {
  background-color: #efae43 !important;
}

.brc-warning {
  border-color: #efae43 !important;
}

.bgc-warning-tp1 {
  background-color: rgba(239, 174, 67, 0.92) !important;
}

.brc-warning-tp1 {
  border-color: rgba(239, 174, 67, 0.92) !important;
}

.bgc-warning-tp2 {
  background-color: rgba(239, 174, 67, 0.84) !important;
}

.brc-warning-tp2 {
  border-color: rgba(239, 174, 67, 0.84) !important;
}

.bgc-warning-tp3 {
  background-color: rgba(239, 174, 67, 0.76) !important;
}

.brc-warning-tp3 {
  border-color: rgba(239, 174, 67, 0.76) !important;
}

.bgc-warning-tp4 {
  background-color: rgba(239, 174, 67, 0.68) !important;
}

.brc-warning-tp4 {
  border-color: rgba(239, 174, 67, 0.68) !important;
}

.bgc-warning-d1 {
  background-color: #eea733 !important;
}

.bgc-warning-d2 {
  background-color: #eca022 !important;
}

.bgc-warning-m1 {
  background-color: #f1ba60 !important;
}

.brc-warning-m1, .brc-h-warning-m1:hover {
  border-color: #f1ba60 !important;
}

.bgc-warning-m2 {
  background-color: #f4c77c !important;
}

.brc-warning-m2, .brc-h-warning-m2:hover {
  border-color: #f4c77c !important;
}

.bgc-warning-m3 {
  background-color: #f6d399 !important;
}

.brc-warning-m3, .brc-h-warning-m3:hover {
  border-color: #f6d399 !important;
}

.bgc-warning-m4 {
  background-color: #f9dfb5 !important;
}

.brc-warning-m4, .brc-h-warning-m4:hover {
  border-color: #f9dfb5 !important;
}

.bgc-warning-l1, .bgc-h-warning-l1:hover {
  background-color: #fbebd0 !important;
}

.brc-warning-l1 {
  border-color: #fbebd0 !important;
}

.bgc-warning-l2, .bgc-h-warning-l2:hover {
  background-color: #fcf1de !important;
}

.brc-warning-l2 {
  border-color: #fcf1de !important;
}

.bgc-warning-l3, .bgc-h-warning-l3:hover {
  background-color: #fdf6ea !important;
}

.brc-warning-l3 {
  border-color: #fdf6ea !important;
}

.bgc-warning-l4, .bgc-h-warning-l4:hover {
  background-color: #fef9f1 !important;
}

.brc-warning-l4 {
  border-color: #fef9f1 !important;
}

.bgc-warning-l5, .bgc-h-warning-l5:hover {
  background-color: #fffdf9 !important;
}

.brc-warning-l5 {
  border-color: #fffdf9 !important;
}

.bgc-dark {
  background-color: #515559 !important;
}

.brc-dark {
  border-color: #515559 !important;
}

.bgc-dark-tp1 {
  background-color: rgba(81, 85, 89, 0.92) !important;
}

.brc-dark-tp1 {
  border-color: rgba(81, 85, 89, 0.92) !important;
}

.bgc-dark-tp2 {
  background-color: rgba(81, 85, 89, 0.84) !important;
}

.brc-dark-tp2 {
  border-color: rgba(81, 85, 89, 0.84) !important;
}

.bgc-dark-tp3 {
  background-color: rgba(81, 85, 89, 0.76) !important;
}

.brc-dark-tp3 {
  border-color: rgba(81, 85, 89, 0.76) !important;
}

.bgc-dark-tp4 {
  background-color: rgba(81, 85, 89, 0.68) !important;
}

.brc-dark-tp4 {
  border-color: rgba(81, 85, 89, 0.68) !important;
}

.bgc-dark-d1 {
  background-color: #484c50 !important;
}

.bgc-dark-d2 {
  background-color: #404346 !important;
}

.bgc-dark-m1 {
  background-color: #6a6f74 !important;
}

.brc-dark-m1, .brc-h-dark-m1:hover {
  border-color: #6a6f74 !important;
}

.bgc-dark-m2 {
  background-color: #83898e !important;
}

.brc-dark-m2, .brc-h-dark-m2:hover {
  border-color: #83898e !important;
}

.bgc-dark-m3 {
  background-color: #9ea2a7 !important;
}

.brc-dark-m3, .brc-h-dark-m3:hover {
  border-color: #9ea2a7 !important;
}

.bgc-dark-m4 {
  background-color: #b9bcbf !important;
}

.brc-dark-m4, .brc-h-dark-m4:hover {
  border-color: #b9bcbf !important;
}

.bgc-dark-l1, .bgc-h-dark-l1:hover {
  background-color: #d3d5d7 !important;
}

.brc-dark-l1 {
  border-color: #d3d5d7 !important;
}

.bgc-dark-l2, .bgc-h-dark-l2:hover {
  background-color: #e0e2e3 !important;
}

.brc-dark-l2 {
  border-color: #e0e2e3 !important;
}

.bgc-dark-l3, .bgc-h-dark-l3:hover {
  background-color: #ebeced !important;
}

.brc-dark-l3 {
  border-color: #ebeced !important;
}

.bgc-dark-l4, .bgc-h-dark-l4:hover {
  background-color: #f2f3f3 !important;
}

.brc-dark-l4 {
  border-color: #f2f3f3 !important;
}

.bgc-dark-l5, .bgc-h-dark-l5:hover {
  background-color: #fafafa !important;
}

.brc-dark-l5 {
  border-color: #fafafa !important;
}

.bgc-light {
  background-color: #f5f6f9 !important;
}

.brc-light {
  border-color: #f5f6f9 !important;
}

.bgc-light-tp1 {
  background-color: rgba(245, 246, 249, 0.92) !important;
}

.brc-light-tp1 {
  border-color: rgba(245, 246, 249, 0.92) !important;
}

.bgc-light-tp2 {
  background-color: rgba(245, 246, 249, 0.84) !important;
}

.brc-light-tp2 {
  border-color: rgba(245, 246, 249, 0.84) !important;
}

.bgc-light-tp3 {
  background-color: rgba(245, 246, 249, 0.76) !important;
}

.brc-light-tp3 {
  border-color: rgba(245, 246, 249, 0.76) !important;
}

.bgc-light-tp4 {
  background-color: rgba(245, 246, 249, 0.68) !important;
}

.brc-light-tp4 {
  border-color: rgba(245, 246, 249, 0.68) !important;
}

.bgc-light-d1 {
  background-color: #eaecf2 !important;
}

.bgc-light-d2 {
  background-color: #dfe2ec !important;
}

.bgc-light-m1 {
  background-color: #f6f7fa !important;
}

.brc-light-m1, .brc-h-light-m1:hover {
  border-color: #f6f7fa !important;
}

.bgc-light-m2 {
  background-color: #f8f9fb !important;
}

.brc-light-m2, .brc-h-light-m2:hover {
  border-color: #f8f9fb !important;
}

.bgc-light-m3 {
  background-color: #f9fafc !important;
}

.brc-light-m3, .brc-h-light-m3:hover {
  border-color: #f9fafc !important;
}

.bgc-light-m4 {
  background-color: #fbfbfc !important;
}

.brc-light-m4, .brc-h-light-m4:hover {
  border-color: #fbfbfc !important;
}

.bgc-light-l1, .bgc-h-light-l1:hover {
  background-color: #fcfcfd !important;
}

.brc-light-l1 {
  border-color: #fcfcfd !important;
}

.bgc-light-l2, .bgc-h-light-l2:hover {
  background-color: #fdfdfe !important;
}

.brc-light-l2 {
  border-color: #fdfdfe !important;
}

.bgc-light-l3, .bgc-h-light-l3:hover {
  background-color: #fdfefe !important;
}

.brc-light-l3 {
  border-color: #fdfefe !important;
}

.bgc-light-l4, .bgc-h-light-l4:hover {
  background-color: #fefefe !important;
}

.brc-light-l4 {
  border-color: #fefefe !important;
}

.bgc-light-l5, .bgc-h-light-l5:hover {
  background-color: #fefefe !important;
}

.brc-light-l5 {
  border-color: #fefefe !important;
}

.bgc-white {
  background-color: #fff !important;
}

.brc-white {
  border-color: #fff !important;
}

.bgc-white-d1 {
  background-color: #f6f6f6 !important;
}

.bgc-white-d2 {
  background-color: #ededed !important;
}

.bgc-black {
  background-color: #313539 !important;
}

.brc-black {
  border-color: #313539 !important;
}

.bgc-black-d1 {
  background-color: #292c2f !important;
}

.bgc-black-d2 {
  background-color: #202326 !important;
}

.bgc-black-m1 {
  background-color: #4d535a !important;
}

.brc-black-m1, .brc-h-black-m1:hover {
  border-color: #4d535a !important;
}

.bgc-black-m2 {
  background-color: #69727a !important;
}

.brc-black-m2, .brc-h-black-m2:hover {
  border-color: #69727a !important;
}

.bgc-black-m3 {
  background-color: #889099 !important;
}

.brc-black-m3, .brc-h-black-m3:hover {
  border-color: #889099 !important;
}

.bgc-black-m4 {
  background-color: #a9afb5 !important;
}

.brc-black-m4, .brc-h-black-m4:hover {
  border-color: #a9afb5 !important;
}

.bgc-black-l1, .bgc-h-black-l1:hover {
  background-color: #c7cbcf !important;
}

.brc-black-l1 {
  border-color: #c7cbcf !important;
}

.bgc-black-l2, .bgc-h-black-l2:hover {
  background-color: #d8dbdd !important;
}

.brc-black-l2 {
  border-color: #d8dbdd !important;
}

.bgc-black-l3, .bgc-h-black-l3:hover {
  background-color: #e5e6e8 !important;
}

.brc-black-l3 {
  border-color: #e5e6e8 !important;
}

.bgc-black-l4, .bgc-h-black-l4:hover {
  background-color: #eeeff0 !important;
}

.brc-black-l4 {
  border-color: #eeeff0 !important;
}

.bgc-black-l5, .bgc-h-black-l5:hover {
  background-color: #f7f7f8 !important;
}

.brc-black-l5 {
  border-color: #f7f7f8 !important;
}

.bgc-grey {
  background-color: #8d8f95 !important;
}

.brc-grey {
  border-color: #8d8f95 !important;
}

.bgc-grey-tp1 {
  background-color: rgba(141, 143, 149, 0.92) !important;
}

.brc-grey-tp1 {
  border-color: rgba(141, 143, 149, 0.92) !important;
}

.bgc-grey-tp2 {
  background-color: rgba(141, 143, 149, 0.84) !important;
}

.brc-grey-tp2 {
  border-color: rgba(141, 143, 149, 0.84) !important;
}

.bgc-grey-tp3 {
  background-color: rgba(141, 143, 149, 0.76) !important;
}

.brc-grey-tp3 {
  border-color: rgba(141, 143, 149, 0.76) !important;
}

.bgc-grey-tp4 {
  background-color: rgba(141, 143, 149, 0.68) !important;
}

.brc-grey-tp4 {
  border-color: rgba(141, 143, 149, 0.68) !important;
}

.bgc-grey-d1 {
  background-color: #84868c !important;
}

.bgc-grey-d2 {
  background-color: #7b7d84 !important;
}

.bgc-grey-m1 {
  background-color: #9ea0a5 !important;
}

.brc-grey-m1, .brc-h-grey-m1:hover {
  border-color: #9ea0a5 !important;
}

.bgc-grey-m2 {
  background-color: #afb1b5 !important;
}

.brc-grey-m2, .brc-h-grey-m2:hover {
  border-color: #afb1b5 !important;
}

.bgc-grey-m3 {
  background-color: #c1c2c5 !important;
}

.brc-grey-m3, .brc-h-grey-m3:hover {
  border-color: #c1c2c5 !important;
}

.bgc-grey-m4 {
  background-color: #d2d3d5 !important;
}

.brc-grey-m4, .brc-h-grey-m4:hover {
  border-color: #d2d3d5 !important;
}

.bgc-grey-l1, .bgc-h-grey-l1:hover {
  background-color: #e2e3e4 !important;
}

.brc-grey-l1 {
  border-color: #e2e3e4 !important;
}

.bgc-grey-l2, .bgc-h-grey-l2:hover {
  background-color: #ebebec !important;
}

.brc-grey-l2 {
  border-color: #ebebec !important;
}

.bgc-grey-l3, .bgc-h-grey-l3:hover {
  background-color: #f2f2f3 !important;
}

.brc-grey-l3 {
  border-color: #f2f2f3 !important;
}

.bgc-grey-l4, .bgc-h-grey-l4:hover {
  background-color: #f6f7f7 !important;
}

.brc-grey-l4 {
  border-color: #f6f7f7 !important;
}

.bgc-grey-l5, .bgc-h-grey-l5:hover {
  background-color: #fbfbfb !important;
}

.brc-grey-l5 {
  border-color: #fbfbfb !important;
}

.bgc-purple {
  background-color: #8d7bbd !important;
}

.brc-purple {
  border-color: #8d7bbd !important;
}

.bgc-purple-tp1 {
  background-color: rgba(141, 123, 189, 0.92) !important;
}

.brc-purple-tp1 {
  border-color: rgba(141, 123, 189, 0.92) !important;
}

.bgc-purple-tp2 {
  background-color: rgba(141, 123, 189, 0.84) !important;
}

.brc-purple-tp2 {
  border-color: rgba(141, 123, 189, 0.84) !important;
}

.bgc-purple-tp3 {
  background-color: rgba(141, 123, 189, 0.76) !important;
}

.brc-purple-tp3 {
  border-color: rgba(141, 123, 189, 0.76) !important;
}

.bgc-purple-tp4 {
  background-color: rgba(141, 123, 189, 0.68) !important;
}

.brc-purple-tp4 {
  border-color: rgba(141, 123, 189, 0.68) !important;
}

.bgc-purple-d1 {
  background-color: #836fb7 !important;
}

.bgc-purple-d2 {
  background-color: #7863b1 !important;
}

.bgc-purple-m1 {
  background-color: #9e8fc7 !important;
}

.brc-purple-m1, .brc-h-purple-m1:hover {
  border-color: #9e8fc7 !important;
}

.bgc-purple-m2 {
  background-color: #afa3d1 !important;
}

.brc-purple-m2, .brc-h-purple-m2:hover {
  border-color: #afa3d1 !important;
}

.bgc-purple-m3 {
  background-color: #c1b7db !important;
}

.brc-purple-m3, .brc-h-purple-m3:hover {
  border-color: #c1b7db !important;
}

.bgc-purple-m4 {
  background-color: #d2cbe5 !important;
}

.brc-purple-m4, .brc-h-purple-m4:hover {
  border-color: #d2cbe5 !important;
}

.bgc-purple-l1, .bgc-h-purple-l1:hover {
  background-color: #e2ddee !important;
}

.brc-purple-l1 {
  border-color: #e2ddee !important;
}

.bgc-purple-l2, .bgc-h-purple-l2:hover {
  background-color: #ebe7f3 !important;
}

.brc-purple-l2 {
  border-color: #ebe7f3 !important;
}

.bgc-purple-l3, .bgc-h-purple-l3:hover {
  background-color: #f1eff7 !important;
}

.brc-purple-l3 {
  border-color: #f1eff7 !important;
}

.bgc-purple-l4, .bgc-h-purple-l4:hover {
  background-color: #f6f5fa !important;
}

.brc-purple-l4 {
  border-color: #f6f5fa !important;
}

.bgc-purple-l5, .bgc-h-purple-l5:hover {
  background-color: #fbfafd !important;
}

.brc-purple-l5 {
  border-color: #fbfafd !important;
}

.bgc-pink {
  background-color: #d65c8b !important;
}

.brc-pink {
  border-color: #d65c8b !important;
}

.bgc-pink-tp1 {
  background-color: rgba(214, 92, 139, 0.92) !important;
}

.brc-pink-tp1 {
  border-color: rgba(214, 92, 139, 0.92) !important;
}

.bgc-pink-tp2 {
  background-color: rgba(214, 92, 139, 0.84) !important;
}

.brc-pink-tp2 {
  border-color: rgba(214, 92, 139, 0.84) !important;
}

.bgc-pink-tp3 {
  background-color: rgba(214, 92, 139, 0.76) !important;
}

.brc-pink-tp3 {
  border-color: rgba(214, 92, 139, 0.76) !important;
}

.bgc-pink-tp4 {
  background-color: rgba(214, 92, 139, 0.68) !important;
}

.brc-pink-tp4 {
  border-color: rgba(214, 92, 139, 0.68) !important;
}

.bgc-pink-d1 {
  background-color: #d24e81 !important;
}

.bgc-pink-d2 {
  background-color: #cf3f77 !important;
}

.bgc-pink-m1 {
  background-color: #dc759d !important;
}

.brc-pink-m1, .brc-h-pink-m1:hover {
  border-color: #dc759d !important;
}

.bgc-pink-m2 {
  background-color: #e28eae !important;
}

.brc-pink-m2, .brc-h-pink-m2:hover {
  border-color: #e28eae !important;
}

.bgc-pink-m3 {
  background-color: #e9a6c0 !important;
}

.brc-pink-m3, .brc-h-pink-m3:hover {
  border-color: #e9a6c0 !important;
}

.bgc-pink-m4 {
  background-color: #efbfd1 !important;
}

.brc-pink-m4, .brc-h-pink-m4:hover {
  border-color: #efbfd1 !important;
}

.bgc-pink-l1, .bgc-h-pink-l1:hover {
  background-color: #f5d6e2 !important;
}

.brc-pink-l1 {
  border-color: #f5d6e2 !important;
}

.bgc-pink-l2, .bgc-h-pink-l2:hover {
  background-color: #f8e3eb !important;
}

.brc-pink-l2 {
  border-color: #f8e3eb !important;
}

.bgc-pink-l3, .bgc-h-pink-l3:hover {
  background-color: #faecf2 !important;
}

.brc-pink-l3 {
  border-color: #faecf2 !important;
}

.bgc-pink-l4, .bgc-h-pink-l4:hover {
  background-color: #fcf3f7 !important;
}

.brc-pink-l4 {
  border-color: #fcf3f7 !important;
}

.bgc-pink-l5, .bgc-h-pink-l5:hover {
  background-color: #fefafc !important;
}

.brc-pink-l5 {
  border-color: #fefafc !important;
}

.bgc-yellow {
  background-color: #f4e47c !important;
}

.brc-yellow {
  border-color: #f4e47c !important;
}

.bgc-yellow-tp1 {
  background-color: rgba(244, 228, 124, 0.92) !important;
}

.brc-yellow-tp1 {
  border-color: rgba(244, 228, 124, 0.92) !important;
}

.bgc-yellow-tp2 {
  background-color: rgba(244, 228, 124, 0.84) !important;
}

.brc-yellow-tp2 {
  border-color: rgba(244, 228, 124, 0.84) !important;
}

.bgc-yellow-tp3 {
  background-color: rgba(244, 228, 124, 0.76) !important;
}

.brc-yellow-tp3 {
  border-color: rgba(244, 228, 124, 0.76) !important;
}

.bgc-yellow-tp4 {
  background-color: rgba(244, 228, 124, 0.68) !important;
}

.brc-yellow-tp4 {
  border-color: rgba(244, 228, 124, 0.68) !important;
}

.bgc-yellow-d1 {
  background-color: #f3e16c !important;
}

.bgc-yellow-d2 {
  background-color: #f1dd5b !important;
}

.bgc-yellow-m1 {
  background-color: #f6e890 !important;
}

.brc-yellow-m1, .brc-h-yellow-m1:hover {
  border-color: #f6e890 !important;
}

.bgc-yellow-m2 {
  background-color: #f7eca4 !important;
}

.brc-yellow-m2, .brc-h-yellow-m2:hover {
  border-color: #f7eca4 !important;
}

.bgc-yellow-m3 {
  background-color: #f9f0b8 !important;
}

.brc-yellow-m3, .brc-h-yellow-m3:hover {
  border-color: #f9f0b8 !important;
}

.bgc-yellow-m4 {
  background-color: #fbf5cc !important;
}

.brc-yellow-m4, .brc-h-yellow-m4:hover {
  border-color: #fbf5cc !important;
}

.bgc-yellow-l1, .bgc-h-yellow-l1:hover {
  background-color: #fcf8df !important;
}

.brc-yellow-l1 {
  border-color: #fcf8df !important;
}

.bgc-yellow-l2, .bgc-h-yellow-l2:hover {
  background-color: #fdfbea !important;
}

.brc-yellow-l2 {
  border-color: #fdfbea !important;
}

.bgc-yellow-l3, .bgc-h-yellow-l3:hover {
  background-color: #fefcf1 !important;
}

.brc-yellow-l3 {
  border-color: #fefcf1 !important;
}

.bgc-yellow-l4, .bgc-h-yellow-l4:hover {
  background-color: #fefdf7 !important;
}

.brc-yellow-l4 {
  border-color: #fefdf7 !important;
}

.bgc-yellow-l5, .bgc-h-yellow-l5:hover {
  background-color: #fffefd !important;
}

.brc-yellow-l5 {
  border-color: #fffefd !important;
}

.bgc-lightgrey {
  background-color: #a9adb3 !important;
}

.brc-lightgrey {
  border-color: #a9adb3 !important;
}

.bgc-lightgrey-tp1 {
  background-color: rgba(169, 173, 179, 0.92) !important;
}

.brc-lightgrey-tp1 {
  border-color: rgba(169, 173, 179, 0.92) !important;
}

.bgc-lightgrey-tp2 {
  background-color: rgba(169, 173, 179, 0.84) !important;
}

.brc-lightgrey-tp2 {
  border-color: rgba(169, 173, 179, 0.84) !important;
}

.bgc-lightgrey-tp3 {
  background-color: rgba(169, 173, 179, 0.76) !important;
}

.brc-lightgrey-tp3 {
  border-color: rgba(169, 173, 179, 0.76) !important;
}

.bgc-lightgrey-tp4 {
  background-color: rgba(169, 173, 179, 0.68) !important;
}

.brc-lightgrey-tp4 {
  border-color: rgba(169, 173, 179, 0.68) !important;
}

.bgc-lightgrey-d1 {
  background-color: #a0a4ab !important;
}

.bgc-lightgrey-d2 {
  background-color: #969ba2 !important;
}

.bgc-lightgrey-m1 {
  background-color: #b6b9be !important;
}

.brc-lightgrey-m1, .brc-h-lightgrey-m1:hover {
  border-color: #b6b9be !important;
}

.bgc-lightgrey-m2 {
  background-color: #c3c5ca !important;
}

.brc-lightgrey-m2, .brc-h-lightgrey-m2:hover {
  border-color: #c3c5ca !important;
}

.bgc-lightgrey-m3 {
  background-color: #cfd2d5 !important;
}

.brc-lightgrey-m3, .brc-h-lightgrey-m3:hover {
  border-color: #cfd2d5 !important;
}

.bgc-lightgrey-m4 {
  background-color: #dcdee0 !important;
}

.brc-lightgrey-m4, .brc-h-lightgrey-m4:hover {
  border-color: #dcdee0 !important;
}

.bgc-lightgrey-l1, .bgc-h-lightgrey-l1:hover {
  background-color: #e8e9eb !important;
}

.brc-lightgrey-l1 {
  border-color: #e8e9eb !important;
}

.bgc-lightgrey-l2, .bgc-h-lightgrey-l2:hover {
  background-color: #efeff1 !important;
}

.brc-lightgrey-l2 {
  border-color: #efeff1 !important;
}

.bgc-lightgrey-l3, .bgc-h-lightgrey-l3:hover {
  background-color: #f4f4f5 !important;
}

.brc-lightgrey-l3 {
  border-color: #f4f4f5 !important;
}

.bgc-lightgrey-l4, .bgc-h-lightgrey-l4:hover {
  background-color: #f7f8f8 !important;
}

.brc-lightgrey-l4 {
  border-color: #f7f8f8 !important;
}

.bgc-lightgrey-l5, .bgc-h-lightgrey-l5:hover {
  background-color: #fbfbfb !important;
}

.brc-lightgrey-l5 {
  border-color: #fbfbfb !important;
}

.bgc-brown {
  background-color: #c59c6b !important;
}

.brc-brown {
  border-color: #c59c6b !important;
}

.bgc-brown-tp1 {
  background-color: rgba(197, 156, 107, 0.92) !important;
}

.brc-brown-tp1 {
  border-color: rgba(197, 156, 107, 0.92) !important;
}

.bgc-brown-tp2 {
  background-color: rgba(197, 156, 107, 0.84) !important;
}

.brc-brown-tp2 {
  border-color: rgba(197, 156, 107, 0.84) !important;
}

.bgc-brown-tp3 {
  background-color: rgba(197, 156, 107, 0.76) !important;
}

.brc-brown-tp3 {
  border-color: rgba(197, 156, 107, 0.76) !important;
}

.bgc-brown-tp4 {
  background-color: rgba(197, 156, 107, 0.68) !important;
}

.brc-brown-tp4 {
  border-color: rgba(197, 156, 107, 0.68) !important;
}

.bgc-brown-d1 {
  background-color: #c0935e !important;
}

.bgc-brown-d2 {
  background-color: #bb8b51 !important;
}

.bgc-brown-m1 {
  background-color: #ceab81 !important;
}

.brc-brown-m1, .brc-h-brown-m1:hover {
  border-color: #ceab81 !important;
}

.bgc-brown-m2 {
  background-color: #d6ba98 !important;
}

.brc-brown-m2, .brc-h-brown-m2:hover {
  border-color: #d6ba98 !important;
}

.bgc-brown-m3 {
  background-color: #dfc9ae !important;
}

.brc-brown-m3, .brc-h-brown-m3:hover {
  border-color: #dfc9ae !important;
}

.bgc-brown-m4 {
  background-color: #e8d8c4 !important;
}

.brc-brown-m4, .brc-h-brown-m4:hover {
  border-color: #e8d8c4 !important;
}

.bgc-brown-l1, .bgc-h-brown-l1:hover {
  background-color: #f0e6d9 !important;
}

.brc-brown-l1 {
  border-color: #f0e6d9 !important;
}

.bgc-brown-l2, .bgc-h-brown-l2:hover {
  background-color: #f4ede4 !important;
}

.brc-brown-l2 {
  border-color: #f4ede4 !important;
}

.bgc-brown-l3, .bgc-h-brown-l3:hover {
  background-color: #f8f3ed !important;
}

.brc-brown-l3 {
  border-color: #f8f3ed !important;
}

.bgc-brown-l4, .bgc-h-brown-l4:hover {
  background-color: #faf7f3 !important;
}

.brc-brown-l4 {
  border-color: #faf7f3 !important;
}

.bgc-brown-l5, .bgc-h-brown-l5:hover {
  background-color: #fdfbf9 !important;
}

.brc-brown-l5 {
  border-color: #fdfbf9 !important;
}

.bgc-blue {
  background-color: #53abe4 !important;
}

.brc-blue {
  border-color: #53abe4 !important;
}

.bgc-blue-tp1 {
  background-color: rgba(83, 171, 228, 0.92) !important;
}

.brc-blue-tp1 {
  border-color: rgba(83, 171, 228, 0.92) !important;
}

.bgc-blue-tp2 {
  background-color: rgba(83, 171, 228, 0.84) !important;
}

.brc-blue-tp2 {
  border-color: rgba(83, 171, 228, 0.84) !important;
}

.bgc-blue-tp3 {
  background-color: rgba(83, 171, 228, 0.76) !important;
}

.brc-blue-tp3 {
  border-color: rgba(83, 171, 228, 0.76) !important;
}

.bgc-blue-tp4 {
  background-color: rgba(83, 171, 228, 0.68) !important;
}

.brc-blue-tp4 {
  border-color: rgba(83, 171, 228, 0.68) !important;
}

.bgc-blue-d1 {
  background-color: #44a3e2 !important;
}

.bgc-blue-d2 {
  background-color: #349cdf !important;
}

.bgc-blue-m1 {
  background-color: #6db8e8 !important;
}

.brc-blue-m1, .brc-h-blue-m1:hover {
  border-color: #6db8e8 !important;
}

.bgc-blue-m2 {
  background-color: #87c4ec !important;
}

.brc-blue-m2, .brc-h-blue-m2:hover {
  border-color: #87c4ec !important;
}

.bgc-blue-m3 {
  background-color: #a0d1f0 !important;
}

.brc-blue-m3, .brc-h-blue-m3:hover {
  border-color: #a0d1f0 !important;
}

.bgc-blue-m4 {
  background-color: #baddf4 !important;
}

.brc-blue-m4, .brc-h-blue-m4:hover {
  border-color: #baddf4 !important;
}

.bgc-blue-l1, .bgc-h-blue-l1:hover {
  background-color: #d3e9f8 !important;
}

.brc-blue-l1 {
  border-color: #d3e9f8 !important;
}

.bgc-blue-l2, .bgc-h-blue-l2:hover {
  background-color: #dff0fa !important;
}

.brc-blue-l2 {
  border-color: #dff0fa !important;
}

.bgc-blue-l3, .bgc-h-blue-l3:hover {
  background-color: #e9f4fc !important;
}

.brc-blue-l3 {
  border-color: #e9f4fc !important;
}

.bgc-blue-l4, .bgc-h-blue-l4:hover {
  background-color: #f1f8fd !important;
}

.brc-blue-l4 {
  border-color: #f1f8fd !important;
}

.bgc-blue-l5, .bgc-h-blue-l5:hover {
  background-color: #f8fbfe !important;
}

.brc-blue-l5 {
  border-color: #f8fbfe !important;
}

.bgc-red {
  background-color: #ed6565 !important;
}

.brc-red {
  border-color: #ed6565 !important;
}

.bgc-red-tp1 {
  background-color: rgba(237, 101, 101, 0.92) !important;
}

.brc-red-tp1 {
  border-color: rgba(237, 101, 101, 0.92) !important;
}

.bgc-red-tp2 {
  background-color: rgba(237, 101, 101, 0.84) !important;
}

.brc-red-tp2 {
  border-color: rgba(237, 101, 101, 0.84) !important;
}

.bgc-red-tp3 {
  background-color: rgba(237, 101, 101, 0.76) !important;
}

.brc-red-tp3 {
  border-color: rgba(237, 101, 101, 0.76) !important;
}

.bgc-red-tp4 {
  background-color: rgba(237, 101, 101, 0.68) !important;
}

.brc-red-tp4 {
  border-color: rgba(237, 101, 101, 0.68) !important;
}

.bgc-red-d1 {
  background-color: #eb5555 !important;
}

.bgc-red-d2 {
  background-color: #e94545 !important;
}

.bgc-red-m1 {
  background-color: #f07d7d !important;
}

.brc-red-m1, .brc-h-red-m1:hover {
  border-color: #f07d7d !important;
}

.bgc-red-m2 {
  background-color: #f39494 !important;
}

.brc-red-m2, .brc-h-red-m2:hover {
  border-color: #f39494 !important;
}

.bgc-red-m3 {
  background-color: #f5acac !important;
}

.brc-red-m3, .brc-h-red-m3:hover {
  border-color: #f5acac !important;
}

.bgc-red-m4 {
  background-color: #f8c4c4 !important;
}

.brc-red-m4, .brc-h-red-m4:hover {
  border-color: #f8c4c4 !important;
}

.bgc-red-l1, .bgc-h-red-l1:hover {
  background-color: #fbdada !important;
}

.brc-red-l1 {
  border-color: #fbdada !important;
}

.bgc-red-l2, .bgc-h-red-l2:hover {
  background-color: #fce6e6 !important;
}

.brc-red-l2 {
  border-color: #fce6e6 !important;
}

.bgc-red-l3, .bgc-h-red-l3:hover {
  background-color: #fdefef !important;
}

.brc-red-l3 {
  border-color: #fdefef !important;
}

.bgc-red-l4, .bgc-h-red-l4:hover {
  background-color: #fef5f5 !important;
}

.brc-red-l4 {
  border-color: #fef5f5 !important;
}

.bgc-red-l5, .bgc-h-red-l5:hover {
  background-color: #fffcfc !important;
}

.brc-red-l5 {
  border-color: #fffcfc !important;
}

.bgc-green {
  background-color: #62cb9f !important;
}

.brc-green {
  border-color: #62cb9f !important;
}

.bgc-green-tp1 {
  background-color: rgba(98, 203, 159, 0.92) !important;
}

.brc-green-tp1 {
  border-color: rgba(98, 203, 159, 0.92) !important;
}

.bgc-green-tp2 {
  background-color: rgba(98, 203, 159, 0.84) !important;
}

.brc-green-tp2 {
  border-color: rgba(98, 203, 159, 0.84) !important;
}

.bgc-green-tp3 {
  background-color: rgba(98, 203, 159, 0.76) !important;
}

.brc-green-tp3 {
  border-color: rgba(98, 203, 159, 0.76) !important;
}

.bgc-green-tp4 {
  background-color: rgba(98, 203, 159, 0.68) !important;
}

.brc-green-tp4 {
  border-color: rgba(98, 203, 159, 0.68) !important;
}

.bgc-green-d1 {
  background-color: #55c797 !important;
}

.bgc-green-d2 {
  background-color: #47c28f !important;
}

.bgc-green-m1 {
  background-color: #79d3ad !important;
}

.brc-green-m1, .brc-h-green-m1:hover {
  border-color: #79d3ad !important;
}

.bgc-green-m2 {
  background-color: #91dabb !important;
}

.brc-green-m2, .brc-h-green-m2:hover {
  border-color: #91dabb !important;
}

.bgc-green-m3 {
  background-color: #a8e2ca !important;
}

.brc-green-m3, .brc-h-green-m3:hover {
  border-color: #a8e2ca !important;
}

.bgc-green-m4 {
  background-color: #bfead8 !important;
}

.brc-green-m4, .brc-h-green-m4:hover {
  border-color: #bfead8 !important;
}

.bgc-green-l1, .bgc-h-green-l1:hover {
  background-color: #d5f1e5 !important;
}

.brc-green-l1 {
  border-color: #d5f1e5 !important;
}

.bgc-green-l2, .bgc-h-green-l2:hover {
  background-color: #e1f5ec !important;
}

.brc-green-l2 {
  border-color: #e1f5ec !important;
}

.bgc-green-l3, .bgc-h-green-l3:hover {
  background-color: #eaf8f2 !important;
}

.brc-green-l3 {
  border-color: #eaf8f2 !important;
}

.bgc-green-l4, .bgc-h-green-l4:hover {
  background-color: #f0faf6 !important;
}

.brc-green-l4 {
  border-color: #f0faf6 !important;
}

.bgc-green-l5, .bgc-h-green-l5:hover {
  background-color: #f7fcfa !important;
}

.brc-green-l5 {
  border-color: #f7fcfa !important;
}

.bgc-transparent {
  background-color: transparent !important;
}

[class*="bgc-h-"] {
  transition: background-color 0.15s;
}

.brc-transparent {
  border-color: transparent !important;
}

[class*="brc-h-"] {
  transition: border-color 0.15s;
}

.brc-black {
  border-color: #000 !important;
}

.brc-white {
  border-color: #fff !important;
}

.bgc-current {
  background-color: currentColor !important;
}

.brc-current {
  border-color: currentColor !important;
}

.bgc-black-tp1, .bgc-h-black-tp1:hover {
  background-color: rgba(0, 0, 0, 0.9075) !important;
}

.bgc-white-tp1, .bgc-h-white-tp1:hover {
  background-color: rgba(255, 255, 255, 0.9075) !important;
}

.bgc-black-tp2, .bgc-h-black-tp2:hover {
  background-color: rgba(0, 0, 0, 0.815) !important;
}

.bgc-white-tp2, .bgc-h-white-tp2:hover {
  background-color: rgba(255, 255, 255, 0.815) !important;
}

.bgc-black-tp3, .bgc-h-black-tp3:hover {
  background-color: rgba(0, 0, 0, 0.7225) !important;
}

.bgc-white-tp3, .bgc-h-white-tp3:hover {
  background-color: rgba(255, 255, 255, 0.7225) !important;
}

.bgc-black-tp4, .bgc-h-black-tp4:hover {
  background-color: rgba(0, 0, 0, 0.63) !important;
}

.bgc-white-tp4, .bgc-h-white-tp4:hover {
  background-color: rgba(255, 255, 255, 0.63) !important;
}

.bgc-black-tp5, .bgc-h-black-tp5:hover {
  background-color: rgba(0, 0, 0, 0.5375) !important;
}

.bgc-white-tp5, .bgc-h-white-tp5:hover {
  background-color: rgba(255, 255, 255, 0.5375) !important;
}

.bgc-black-tp6, .bgc-h-black-tp6:hover {
  background-color: rgba(0, 0, 0, 0.445) !important;
}

.bgc-white-tp6, .bgc-h-white-tp6:hover {
  background-color: rgba(255, 255, 255, 0.445) !important;
}

.bgc-black-tp7, .bgc-h-black-tp7:hover {
  background-color: rgba(0, 0, 0, 0.3525) !important;
}

.bgc-white-tp7, .bgc-h-white-tp7:hover {
  background-color: rgba(255, 255, 255, 0.3525) !important;
}

.bgc-black-tp8, .bgc-h-black-tp8:hover {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.bgc-white-tp8, .bgc-h-white-tp8:hover {
  background-color: rgba(255, 255, 255, 0.26) !important;
}

.bgc-black-tp9, .bgc-h-black-tp9:hover {
  background-color: rgba(0, 0, 0, 0.1675) !important;
}

.bgc-white-tp9, .bgc-h-white-tp9:hover {
  background-color: rgba(255, 255, 255, 0.1675) !important;
}

.bgc-black-tp10, .bgc-h-black-tp10:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.bgc-white-tp10, .bgc-h-white-tp10:hover {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

.brc-black-tp1 {
  border-color: rgba(0, 0, 0, 0.9075) !important;
}

.brc-white-tp1 {
  border-color: rgba(255, 255, 255, 0.9075) !important;
}

.brc-black-tp2 {
  border-color: rgba(0, 0, 0, 0.815) !important;
}

.brc-white-tp2 {
  border-color: rgba(255, 255, 255, 0.815) !important;
}

.brc-black-tp3 {
  border-color: rgba(0, 0, 0, 0.7225) !important;
}

.brc-white-tp3 {
  border-color: rgba(255, 255, 255, 0.7225) !important;
}

.brc-black-tp4 {
  border-color: rgba(0, 0, 0, 0.63) !important;
}

.brc-white-tp4 {
  border-color: rgba(255, 255, 255, 0.63) !important;
}

.brc-black-tp5 {
  border-color: rgba(0, 0, 0, 0.5375) !important;
}

.brc-white-tp5 {
  border-color: rgba(255, 255, 255, 0.5375) !important;
}

.brc-black-tp6 {
  border-color: rgba(0, 0, 0, 0.445) !important;
}

.brc-white-tp6 {
  border-color: rgba(255, 255, 255, 0.445) !important;
}

.brc-black-tp7 {
  border-color: rgba(0, 0, 0, 0.3525) !important;
}

.brc-white-tp7 {
  border-color: rgba(255, 255, 255, 0.3525) !important;
}

.brc-black-tp8 {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.brc-white-tp8 {
  border-color: rgba(255, 255, 255, 0.26) !important;
}

.brc-black-tp9 {
  border-color: rgba(0, 0, 0, 0.1675) !important;
}

.brc-white-tp9 {
  border-color: rgba(255, 255, 255, 0.1675) !important;
}

.brc-black-tp10 {
  border-color: rgba(0, 0, 0, 0.075) !important;
}

.brc-white-tp10 {
  border-color: rgba(255, 255, 255, 0.075) !important;
}

.bgc-dark-grad {
  background-image: linear-gradient(rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.33) 100%);
}

.bgc-light-grad {
  background-image: linear-gradient(rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.33) 100%);
}

.bgc-white-grad {
  background-image: linear-gradient(rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.33) 50%, rgba(255, 255, 255, 0.87) 100%);
}

.bg-none {
  background-image: none !important;
}

.border-0 {
  border-width: 0px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-none {
  border-width: 0px !important;
  border-style: solid;
  border-color: transparent;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-4 {
  border-width: 4px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-t-0, .border-y-0 {
  border-top-width: 0px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-0, .border-y-0 {
  border-bottom-width: 0px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-0, .border-x-0 {
  border-right-width: 0px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-0, .border-x-0 {
  border-left-width: 0px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-1, .border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-1, .border-y-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-1, .border-x-1 {
  border-right-width: 1px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-1, .border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-2, .border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-2, .border-y-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-2, .border-x-2 {
  border-right-width: 2px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-2, .border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-3, .border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-3, .border-y-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-3, .border-x-3 {
  border-right-width: 3px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-3, .border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-4, .border-y-4 {
  border-top-width: 4px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-4, .border-y-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-4, .border-x-4 {
  border-right-width: 4px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-4, .border-x-4 {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-solid {
  border-style: solid !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-dashed {
  border-style: dashed !important;
}

[class*="b-underline-"] {
  position: relative;
}

[class*="b-underline-"]::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.25rem;
  left: 1px;
  border-bottom: 2px solid;
  border-color: currentColor;
  opacity: 0.55;
  max-width: 80%;
}

[class*="b-underline-n"]::after {
  max-width: none;
}

.b-centered::after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.b-underline-0::after {
  width: 0rem;
}

.b-underline-n0::after {
  width: calc(100% - 0rem);
}

.b-underline-1::after {
  width: 1rem;
}

.b-underline-n1::after {
  width: calc(100% - 1rem);
}

.b-underline-2::after {
  width: 2rem;
}

.b-underline-n2::after {
  width: calc(100% - 2rem);
}

.b-underline-3::after {
  width: 3rem;
}

.b-underline-n3::after {
  width: calc(100% - 3rem);
}

.b-underline-4::after {
  width: 4rem;
}

.b-underline-n4::after {
  width: calc(100% - 4rem);
}

.b-underline-5::after {
  width: 5rem;
}

.b-underline-n5::after {
  width: calc(100% - 5rem);
}

.b-hovered::after {
  transition: width 0.15s, max-width 0s 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .b-hovered::after {
    transition: none;
  }
}

.b-hovered:hover::after {
  width: 100%;
  max-width: 100%;
  transition-delay: 0s, 0s;
}

.radius-0 {
  border-radius: 0rem !important;
}

.radius-1 {
  border-radius: 0.25rem !important;
}

.radius-2 {
  border-radius: 0.5rem !important;
}

.radius-3 {
  border-radius: 0.75rem !important;
}

.radius-4 {
  border-radius: 1rem !important;
}

.radius-5 {
  border-radius: 1.25rem !important;
}

.radius-1px {
  border-radius: 1px !important;
}

.radius-2px {
  border-radius: 2px !important;
}

.radius-3px {
  border-radius: 3px !important;
}

.radius-l-0 {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.radius-t-0 {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.radius-r-0 {
  border-bottom-right-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.radius-b-0 {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.radius-l-1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.radius-t-1 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.radius-r-1 {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.radius-b-1 {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.radius-l-2 {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.radius-t-2 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.radius-r-2 {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.radius-b-2 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.radius-l-3 {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.radius-t-3 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.radius-r-3 {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.radius-b-3 {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.radius-l-4 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.radius-t-4 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.radius-r-4 {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.radius-b-4 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.radius-l-5 {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
}

.radius-t-5 {
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.radius-r-5 {
  border-bottom-right-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.radius-b-5 {
  border-bottom-left-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

.radius-round {
  border-radius: 4rem !important;
}

.radius-100 {
  border-radius: 100% !important;
}

.mw-auto {
  min-width: auto !important;
}

.mw-none {
  max-width: none !important;
}

.mh-auto {
  min-height: auto !important;
}

.mh-none {
  max-height: none !important;
}

.minh-100 {
  min-height: 100% !important;
}

.minw-100 {
  min-width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.h-90 {
  height: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.h-95 {
  height: 95% !important;
}

.w-101 {
  width: calc(100% + 1px) !important;
}

.h-101 {
  height: calc(100% + 1px) !important;
}

.w-102 {
  width: calc(100% + 2px) !important;
}

.h-102 {
  height: calc(100% + 2px) !important;
}

.w-1 {
  width: 0.5rem;
}

.w-2 {
  width: 1rem;
}

.w-3 {
  width: 1.5rem;
}

.w-4 {
  width: 2rem;
}

.w-5 {
  width: 2.5rem;
}

.w-6 {
  width: 3rem;
}

.h-1 {
  height: 0.5rem;
}

.h-2 {
  height: 1rem;
}

.h-3 {
  height: 1.5rem;
}

.h-4 {
  height: 2rem;
}

.h-5 {
  height: 2.5rem;
}

.h-6 {
  height: 3rem;
}

.m-1px {
  margin: 1px !important;
}

.m-n1px {
  margin: -1px !important;
}

.mx-1px, .ml-1px {
  margin-left: 1px !important;
}

.mx-1px, .mr-1px {
  margin-right: 1px !important;
}

.mx-n1px, .ml-n1px {
  margin-left: -1px !important;
}

.mx-n1px, .mr-n1px {
  margin-right: -1px !important;
}

.my-1px, .mt-1px {
  margin-top: 1px !important;
}

.my-1px, .mb-1px {
  margin-bottom: 1px !important;
}

.my-n1px, .mt-n1px {
  margin-top: -1px !important;
}

.my-n1px, .mb-n1px {
  margin-bottom: -1px !important;
}

.p-1px {
  padding: 1px !important;
}

.px-1px, .pl-1px {
  padding-left: 1px !important;
}

.px-1px, .pr-1px {
  padding-right: 1px !important;
}

.py-1px, .pt-1px {
  padding-top: 1px !important;
}

.py-1px, .pb-1px {
  padding-bottom: 1px !important;
}

.m-2px {
  margin: 2px !important;
}

.m-n2px {
  margin: -2px !important;
}

.mx-2px, .ml-2px {
  margin-left: 2px !important;
}

.mx-2px, .mr-2px {
  margin-right: 2px !important;
}

.mx-n2px, .ml-n2px {
  margin-left: -2px !important;
}

.mx-n2px, .mr-n2px {
  margin-right: -2px !important;
}

.my-2px, .mt-2px {
  margin-top: 2px !important;
}

.my-2px, .mb-2px {
  margin-bottom: 2px !important;
}

.my-n2px, .mt-n2px {
  margin-top: -2px !important;
}

.my-n2px, .mb-n2px {
  margin-bottom: -2px !important;
}

.p-2px {
  padding: 2px !important;
}

.px-2px, .pl-2px {
  padding-left: 2px !important;
}

.px-2px, .pr-2px {
  padding-right: 2px !important;
}

.py-2px, .pt-2px {
  padding-top: 2px !important;
}

.py-2px, .pb-2px {
  padding-bottom: 2px !important;
}

.m-3px {
  margin: 3px !important;
}

.m-n3px {
  margin: -3px !important;
}

.mx-3px, .ml-3px {
  margin-left: 3px !important;
}

.mx-3px, .mr-3px {
  margin-right: 3px !important;
}

.mx-n3px, .ml-n3px {
  margin-left: -3px !important;
}

.mx-n3px, .mr-n3px {
  margin-right: -3px !important;
}

.my-3px, .mt-3px {
  margin-top: 3px !important;
}

.my-3px, .mb-3px {
  margin-bottom: 3px !important;
}

.my-n3px, .mt-n3px {
  margin-top: -3px !important;
}

.my-n3px, .mb-n3px {
  margin-bottom: -3px !important;
}

.p-3px {
  padding: 3px !important;
}

.px-3px, .pl-3px {
  padding-left: 3px !important;
}

.px-3px, .pr-3px {
  padding-right: 3px !important;
}

.py-3px, .pt-3px {
  padding-top: 3px !important;
}

.py-3px, .pb-3px {
  padding-bottom: 3px !important;
}

.m-15 {
  margin: 0.375rem !important;
}

.mt-15,
.my-15 {
  margin-top: 0.375rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 0.375rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 0.375rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 0.375rem !important;
}

.m-25 {
  margin: 0.75rem !important;
}

.mt-25,
.my-25 {
  margin-top: 0.75rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 0.75rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 0.75rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 0.75rem !important;
}

.m-35 {
  margin: 1.25rem !important;
}

.mt-35,
.my-35 {
  margin-top: 1.25rem !important;
}

.mr-35,
.mx-35 {
  margin-right: 1.25rem !important;
}

.mb-35,
.my-35 {
  margin-bottom: 1.25rem !important;
}

.ml-35,
.mx-35 {
  margin-left: 1.25rem !important;
}

.m-425 {
  margin: 1.75rem !important;
}

.mt-425,
.my-425 {
  margin-top: 1.75rem !important;
}

.mr-425,
.mx-425 {
  margin-right: 1.75rem !important;
}

.mb-425,
.my-425 {
  margin-bottom: 1.75rem !important;
}

.ml-425,
.mx-425 {
  margin-left: 1.75rem !important;
}

.m-45 {
  margin: 2rem !important;
}

.mt-45,
.my-45 {
  margin-top: 2rem !important;
}

.mr-45,
.mx-45 {
  margin-right: 2rem !important;
}

.mb-45,
.my-45 {
  margin-bottom: 2rem !important;
}

.ml-45,
.mx-45 {
  margin-left: 2rem !important;
}

.p-15 {
  padding: 0.375rem !important;
}

.pt-15,
.py-15 {
  padding-top: 0.375rem !important;
}

.pr-15,
.px-15 {
  padding-right: 0.375rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 0.375rem !important;
}

.pl-15,
.px-15 {
  padding-left: 0.375rem !important;
}

.p-25 {
  padding: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}

.pr-25,
.px-25 {
  padding-right: 0.75rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pl-25,
.px-25 {
  padding-left: 0.75rem !important;
}

.p-35 {
  padding: 1.25rem !important;
}

.pt-35,
.py-35 {
  padding-top: 1.25rem !important;
}

.pr-35,
.px-35 {
  padding-right: 1.25rem !important;
}

.pb-35,
.py-35 {
  padding-bottom: 1.25rem !important;
}

.pl-35,
.px-35 {
  padding-left: 1.25rem !important;
}

.p-425 {
  padding: 1.75rem !important;
}

.pt-425,
.py-425 {
  padding-top: 1.75rem !important;
}

.pr-425,
.px-425 {
  padding-right: 1.75rem !important;
}

.pb-425,
.py-425 {
  padding-bottom: 1.75rem !important;
}

.pl-425,
.px-425 {
  padding-left: 1.75rem !important;
}

.p-45 {
  padding: 2rem !important;
}

.pt-45,
.py-45 {
  padding-top: 2rem !important;
}

.pr-45,
.px-45 {
  padding-right: 2rem !important;
}

.pb-45,
.py-45 {
  padding-bottom: 2rem !important;
}

.pl-45,
.px-45 {
  padding-left: 2rem !important;
}

.m-n15 {
  margin: -0.375rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -0.375rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -0.375rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -0.375rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -0.375rem !important;
}

.m-n25 {
  margin: -0.75rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -0.75rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -0.75rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -0.75rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -0.75rem !important;
}

.m-n35 {
  margin: -1.25rem !important;
}

.mt-n35,
.my-n35 {
  margin-top: -1.25rem !important;
}

.mr-n35,
.mx-n35 {
  margin-right: -1.25rem !important;
}

.mb-n35,
.my-n35 {
  margin-bottom: -1.25rem !important;
}

.ml-n35,
.mx-n35 {
  margin-left: -1.25rem !important;
}

.m-n425 {
  margin: -1.75rem !important;
}

.mt-n425,
.my-n425 {
  margin-top: -1.75rem !important;
}

.mr-n425,
.mx-n425 {
  margin-right: -1.75rem !important;
}

.mb-n425,
.my-n425 {
  margin-bottom: -1.75rem !important;
}

.ml-n425,
.mx-n425 {
  margin-left: -1.75rem !important;
}

.m-n45 {
  margin: -2rem !important;
}

.mt-n45,
.my-n45 {
  margin-top: -2rem !important;
}

.mr-n45,
.mx-n45 {
  margin-right: -2rem !important;
}

.mb-n45,
.my-n45 {
  margin-bottom: -2rem !important;
}

.ml-n45,
.mx-n45 {
  margin-left: -2rem !important;
}

.d-style:not(.active) .d-active,
.d-style.active .d-n-active,
.d-style:not(.collapsed) .d-collapsed,
.d-style.collapsed .d-n-collapsed,
.d-style:not(:hover) .d-hover,
.d-style:hover .d-n-hover,
.d-style:not(.show) .d-show,
.d-style.show .d-n-show {
  display: none !important;
}

.v-active, .v-n-active,
.v-show, .v-n-show,
.v-collapsed, .v-n-collapsed,
.v-hover, .v-n-hover {
  transition: opacity 120ms, visibility 0ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .v-active, .v-n-active,
  .v-show, .v-n-show,
  .v-collapsed, .v-n-collapsed,
  .v-hover, .v-n-hover {
    transition: none;
  }
}

.d-style:not(.active) .v-active,
.d-style.active .v-n-active,
.d-style:not(.collapsed) .v-collapsed,
.d-style.collapsed .v-n-collapsed,
.d-style:not(.show) .v-show,
.d-style.show .v-n-show,
.d-style:not(:hover) .v-hover,
.d-style:hover .v-n-hover {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.mb-hover, .mb-n-hover,
.mt-hover, .mt-n-hover,
.mr-hover, .mr-n-hover,
.ml-hover, .ml-n-hover {
  transition: transform 120ms, visibility 0ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .mb-hover, .mb-n-hover,
  .mt-hover, .mt-n-hover,
  .mr-hover, .mr-n-hover,
  .ml-hover, .ml-n-hover {
    transition: none;
  }
}

.d-style:not(:hover) .mb-hover,
.d-style:hover .mb-n-hover {
  transform: translateY(100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .mt-hover,
.d-style:hover .mt-n-hover {
  transform: translateY(-100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .mr-hover,
.d-style:hover .mr-n-hover {
  transform: translateX(100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .ml-hover,
.d-style:hover .ml-n-hover {
  transform: translateX(-100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-zoom-1, .d-zoom-2, .d-zoom-3, .dh-zoom-1, .dh-zoom-2, .dh-zoom-3 {
  transition: transform 180ms;
}

@media (prefers-reduced-motion: reduce) {
  .d-zoom-1, .d-zoom-2, .d-zoom-3, .dh-zoom-1, .dh-zoom-2, .dh-zoom-3 {
    transition: none;
  }
}

.d-style:hover .d-zoom-1, .dh-zoom-1:hover {
  transform: scale(1.06);
}

.d-style:hover .d-zoom-2, .dh-zoom-2:hover {
  transform: scale(1.12);
}

.d-style:hover .d-zoom-3, .dh-zoom-3:hover {
  transform: scale(1.18);
}

.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

.position-tr,
.position-tl,
.position-br,
.position-bl,
.position-rc,
.position-lc,
.position-tc,
.position-bc,
.position-center {
  position: absolute !important;
  display: block;
}

.position-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.position-tr {
  right: 0;
  top: 0;
}

.position-tl {
  left: 0;
  top: 0;
}

.position-br {
  right: 0;
  bottom: 0;
}

.position-bl {
  left: 0;
  bottom: 0;
}

.position-lc {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-rc {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-tc {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.position-bc {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-n45 {
  transform: rotate(-45deg);
}

.align-sub {
  vertical-align: sub !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-equal > * {
  flex: 1 1 auto;
}

.flex-fill > * {
  flex-grow: 1;
  flex-basis: 0;
}

.ace-scroll-mob {
  overflow-y: auto;
}

.ace-scroll {
  scrollbar-width: thin;
  scrollbar-color: #9dbad5 #dce3ea;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ace-scroll::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
  appearance: none;
}

.ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
}

.ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
}

@media (hover: hover) {
  .ace-scrollbar:not(.ace-scroll-mob) {
    scrollbar-width: thin;
    scrollbar-color: #9dbad5 #dce3ea;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar {
    width: 8px;
    -webkit-appearance: none;
    appearance: none;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar-thumb {
    background-color: #9dbad5;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar-track {
    background-color: #dce3ea;
  }
}

.ace-scroll {
  overflow-y: hidden;
}

.ace-scroll:hover {
  overflow-y: auto;
  overflow-y: overlay;
}

.ace-scroll-lock {
  overscroll-behavior-y: none;
  -ms-scroll-chaining: none;
}

.ace-scroll-wrap {
  overflow-x: hidden;
}

@supports (-webkit-appearance: none) and (overflow: overlay) {
  .ace-scroll-wrap {
    overflow-y: overlay;
    color: transparent;
    transition: color 0.15s 0.4s;
  }
  @media (prefers-reduced-motion: reduce) {
    .ace-scroll-wrap {
      transition: none;
    }
  }
  .ace-scroll-wrap:hover {
    color: #9dbad5;
    transition-delay: 0s;
  }
  .ace-scroll-wrap::-webkit-scrollbar-thumb {
    background-color: currentColor;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    background-clip: content-box;
  }
  .ace-scroll-wrap::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@supports (-moz-appearance: none) and (scrollbar-width: none) {
  :root {
    --moz-scrollbar-thin: 8px;
  }
  .ace-scroll-wrap {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.15s 0.4s;
  }
  @media (prefers-reduced-motion: reduce) {
    .ace-scroll-wrap {
      transition: none;
    }
  }
  .ace-scroll-wrap > .ace-scroll-inner {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .ace-scroll-wrap:hover {
    overflow-y: scroll;
    scrollbar-color: #9dbad5 transparent;
    transition-delay: 0s;
  }
  .ace-scroll:not(.ace-scroll-wrap) {
    overflow-x: hidden;
  }
  .ace-scroll:not(.ace-scroll-wrap):hover > div, .ace-scroll:not(.ace-scroll-wrap):hover > p {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .sidebar .ace-scroll:not(.ace-scroll-wrap):hover > div, .sidebar .ace-scroll:not(.ace-scroll-wrap):hover > p {
    min-width: auto;
  }
}

@supports (-moz-appearance: none) and (not (scrollbar-width: none)) {
  :root {
    --moz-scrollbar-thin: 17px;
  }
  .ace-scroll {
    overflow-x: hidden;
  }
  .ace-scroll:hover {
    overflow-y: scroll;
  }
  .ace-scroll:hover > div, .ace-scroll:hover > p {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .sidebar .ace-scroll:hover {
    overflow-y: auto;
  }
  .sidebar .ace-scroll:hover > div, .sidebar .ace-scroll:hover > p {
    min-width: auto;
  }
}

.navbar {
  padding: 0;
  flex: 0 0 auto;
  height: 4.5rem;
  height: var(--navbar-height);
  visibility: hidden;
  z-index: 1021;
}

.navbar-sm {
  height: 4rem;
  height: var(--navbar-sm-height);
}

@media (max-width: 1199.98px) {
  .navbar {
    height: 4rem;
    height: var(--navbar-mobile-height);
  }
}

.navbar-inner {
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  visibility: visible;
}

.navbar.container > .navbar-inner {
  max-width: inherit;
}

.navbar-inner.container {
  padding: 0;
  flex-wrap: nowrap;
}

.navbar-inner > .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0;
  height: 100%;
  background-color: inherit;
  position: relative;
}

.navbar-brand {
  margin-left: 0.875rem;
  font-size: 1.5rem;
}

.navbar-intro {
  display: flex;
  align-items: center;
  width: 16rem;
  width: var(--sidebar-width);
  height: 100%;
}

.navbar-content {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 0 auto;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-content-section input {
  height: 2rem;
  margin: 0.5rem 1.5rem 0.5rem 1rem;
  opacity: 0.7;
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-content-section input {
    transition: none;
  }
}

.navbar-content-section input:focus {
  outline: none;
  opacity: 1;
}

.navbar-content-section input:not(:focus):hover {
  opacity: 0.8;
}

@media (max-width: 991.98px) {
  .navbar-content {
    margin-left: auto;
    background-color: inherit;
    flex: 0 0 auto;
  }
  .navbar-content-section {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: inherit;
  }
  .navbar-content-section input:not(:focus) {
    opacity: 0.8;
  }
  .navbar-content-section .navbar-search-input {
    width: calc(100% - 2rem);
  }
}

.btn-burger {
  cursor: pointer;
  width: 2.5rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.15s;
  color: #fff;
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-burger {
    transition: none;
  }
}

.btn-burger:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.btn-burger:active {
  transform: none !important;
}

.btn-burger .bars {
  display: block;
  width: 50%;
  height: 2px;
  position: relative;
  color: inherit;
  background-color: currentColor;
  transition: transform 0.15s, top 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-burger .bars {
    transition: none;
  }
}

.btn-burger .bars::before, .btn-burger .bars::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: inherit;
  background-color: inherit;
  color: inherit;
  border-radius: inherit;
  transition: inherit;
  transform-origin: inherit;
}

.btn-burger .bars::before {
  top: -0.375rem;
}

.btn-burger .bars::after {
  top: 0.375rem;
}

.btn-burger.burger-dots {
  width: 2.25rem;
}

.btn-burger.burger-dots .bars {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.25rem;
}

.btn-burger.burger-steps .bars {
  transform-origin: left;
}

.btn-burger.burger-steps:hover .bars {
  transform: scaleX(0.75);
}

.btn-burger.burger-steps:hover .bars::before {
  transform: scaleX(1.25);
}

.btn-burger.burger-steps:hover .bars::after {
  transform: scaleX(0.75);
}

.btn-burger.burger-arrowed .bars {
  transform-origin: left;
}

.btn-burger.burger-arrowed:not(.static):hover .bars::before, .btn-burger.burger-arrowed.static:not(.collapsed) .bars::before {
  top: 0;
  transform: rotate(-45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static):hover .bars::after, .btn-burger.burger-arrowed.static:not(.collapsed) .bars::after {
  top: 0;
  transform: rotate(45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static).collapsed .bars {
  transform-origin: right;
}

.btn-burger.burger-arrowed:not(.static).collapsed:hover .bars::before {
  top: 0;
  transform: rotate(45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static).collapsed:hover .bars::after {
  top: 0;
  transform: rotate(-45deg) scaleX(0.66);
}

.btn-burger.burger-minus:not(.static):hover .bars::before, .btn-burger.burger-minus:not(.static):hover .bars::after, .btn-burger.burger-minus.static:not(.collapsed) .bars::before, .btn-burger.burger-minus.static:not(.collapsed) .bars::after {
  top: 0;
}

.btn-burger.burger-times .bars {
  transform-origin: center;
}

.btn-burger.burger-times:not(.static):hover .bars, .btn-burger.burger-times.static:not(.collapsed) .bars {
  visibility: hidden;
}

.btn-burger.burger-times:not(.static):hover .bars::before, .btn-burger.burger-times.static:not(.collapsed) .bars::before {
  top: 0;
  visibility: visible;
  transform: rotate(-45deg) scaleX(0.9);
}

.btn-burger.burger-times:not(.static):hover .bars::after, .btn-burger.burger-times.static:not(.collapsed) .bars::after {
  top: 0;
  visibility: visible;
  transform: rotate(45deg) scaleX(0.9);
}

.btn-burger.burger-compact:not(.static):hover .bars, .btn-burger.burger-compact.static:not(.collapsed) .bars {
  transform-origin: center;
  transform: scaleX(0.8);
}

.btn-burger.burger-compact:not(.static):hover .bars::before, .btn-burger.burger-compact.static:not(.collapsed) .bars::before {
  top: -0.25rem;
}

.btn-burger.burger-compact:not(.static):hover .bars::after, .btn-burger.burger-compact.static:not(.collapsed) .bars::after {
  top: 0.25rem;
}

.navbar .nav > .nav-item > .nav-link:not(.disabled) {
  border-radius: 0;
  transition: background-color 150ms, color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .navbar .nav > .nav-item > .nav-link:not(.disabled) {
    transition: none;
  }
}

.navbar .navbar-nav .nav .dropdown-toggle::after {
  display: none;
}

.navbar .nav > .nav-item > .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .navbar .navbar-menu.navbar-collapse {
    height: 100%;
    flex-grow: 0;
  }
  .navbar .navbar-nav {
    height: 100%;
    align-items: center;
  }
  .navbar .navbar-nav > .nav {
    height: 100%;
  }
  .navbar-inner.container .navbar-nav .nav > .nav-item:last-of-type, .navbar-inner.container .navbar-nav .nav > .nav-item:last-of-type::after {
    border-right-width: 0;
  }
  .navbar .navbar-nav > .nav > .nav-item {
    height: 100%;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link, .navbar .navbar-nav > .nav > .nav-item > .btn {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-nav > .nav > .nav-item > .btn > .caret {
    margin: 0 0.25rem 0 0.5rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge, .navbar .navbar-nav > .nav > .nav-item > .btn .badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge-dot, .navbar .navbar-nav > .nav > .nav-item > .btn .badge-dot {
    top: -0.125rem;
    right: -0.125rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge-tr, .navbar .navbar-nav > .nav > .nav-item > .btn .badge-tr {
    top: 0;
    right: 0;
  }
  .navbar .navbar-nav > .nav > .nav-item > .btn {
    border-width: 0;
    border-radius: 0;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled), .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled) {
    position: relative;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled)::before {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 2px;
    right: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 0;
    transition: width 150ms;
    height: 3px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.73);
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled)::before {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .nav-link:not(.disabled), .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .btn:not(.disabled) {
    position: relative;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .btn:not(.disabled)::before {
    width: calc(100% - 4px);
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link:focus {
    outline: none;
  }
  .navbar .navbar-nav > .nav.nav-compact {
    height: 80%;
    min-height: 3.5rem;
  }
  .navbar .navbar-nav > .nav.nav-compact-2 {
    height: 65%;
    min-height: 3rem;
  }
  .navbar .navbar-nav > .nav.nav-compact-3 {
    height: 55%;
    min-height: 2.5rem;
  }
  .navbar .navbar-nav > .nav.nav-compact > .nav-item, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item {
    border-width: 0 !important;
    max-height: 100%;
  }
  .navbar .navbar-nav > .nav.nav-compact > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact > .nav-item > .btn, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item > .btn, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item > .btn {
    border-radius: 0.25rem;
  }
  .navbar .navbar-nav > .nav.border-0 > .nav-item {
    border-width: 0 !important;
  }
  .navbar .navbar-nav > .nav.border-0 > .nav-item > .nav-link::after {
    display: none;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.border-0 > .nav-link::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item.border-0 > .btn::before {
    display: none !important;
  }
  .navbar .navbar-nav .dropdown-menu {
    max-height: calc(100vh - 4.5rem - 0.25rem);
    max-height: calc(100vh - var(--navbar-height) - 0.25rem);
    overflow: hidden auto;
    margin-top: 0;
  }
  .navbar .navbar-nav .dropdown-menu.dropdown-caret {
    overflow: visible;
  }
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) .dropdown-caret:not(.dropdown-menu) {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 1001;
    left: calc(50% - 0.25rem);
    bottom: -1px;
    border-width: 0 0.5rem 0.5rem;
    border-style: solid;
    border-bottom-color: #FFF;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    opacity: 0;
    background-color: transparent;
    transform-origin: bottom center;
    transform: scale(0);
    transition: all 150ms;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) .dropdown-caret:not(.dropdown-menu) {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) > .nav-item.show .dropdown-caret:not(.dropdown-menu) {
    transform: none;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .navbar .nav-user-name {
    display: block;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .navbar .dropdown-mega > .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    border-width: 0 0 2px 0;
    padding: 1.5rem;
  }
  .navbar .dropdown-mega > .dropdown-lg {
    width: 48rem;
    max-width: calc(100vw - 1rem);
    right: 0;
    left: auto;
  }
  .navbar .dropdown-mega > .dropdown-md {
    width: 36rem;
    max-width: calc(100vw - 1rem);
    right: 0;
    left: auto;
  }
  .navbar .dropdown-mega > .dropdown-sm {
    width: 28rem;
    max-width: calc(100vw - 1rem);
    right: 0;
    left: auto;
  }
  .navbar .dropdown-mega > .dropdown-xs {
    width: 20rem;
    max-width: calc(100vw - 1rem);
    right: 0;
    left: auto;
  }
  .navbar .dropdown-mega.pos-rel > .dropdown-lg.dropdown-center {
    left: calc(-24rem + 50%);
  }
  .navbar .dropdown-mega.pos-rel > .dropdown-md.dropdown-center {
    left: calc(-18rem + 50%);
  }
  .navbar .dropdown-mega.pos-rel > .dropdown-sm.dropdown-center {
    left: calc(-14rem + 50%);
  }
  .navbar .dropdown-mega.pos-rel > .dropdown-xs.dropdown-center {
    left: calc(-10rem + 50%);
  }
  .navbar .dropdown-mega.pos-rel > .dropdown-menu-right {
    left: auto;
    right: -2rem;
  }
  .navbar .dropdown.dropdown-mega {
    position: static;
  }
}

.navbar .navbar-content-section input {
  font-size: 0.95rem;
}

.navbar .navbar-content-section input::placeholder {
  font-size: 0.9025rem;
}

@media (max-width: 991.98px) {
  .navbar .dropdown-caret:not(.dropdown-menu), .navbar .dropdown-caret.dropdown-menu::before, .navbar .dropdown-caret.dropdown-menu::after {
    display: none !important;
  }
  .navbar .navbar-intro {
    width: auto;
  }
  .navbar .navbar-toggler {
    align-self: center;
  }
  .navbar .navbar-collapse {
    position: absolute;
    z-index: 1020;
    transition-duration: 250ms;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: calc(100vh - 4rem - 3rem);
    max-height: calc(100vh - var(--navbar-mobile-height) - 3rem);
    overflow: hidden auto;
    overscroll-behavior: none;
    border-radius: 0;
    background-color: inherit;
  }
  .navbar .navbar-collapse > div {
    background-color: inherit;
  }
  .navbar.navbar-fixed .navbar-collapse {
    position: fixed;
    top: 4rem;
    top: var(--navbar-mobile-height);
    max-height: calc(100% - 4rem - 0.5rem);
    max-height: calc(100% - var(--navbar-mobile-height) - 0.5rem);
  }
  .navbar .navbar-backdrop::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
  }
  .navbar .navbar-menu.navbar-hw {
    min-width: 50%;
    max-width: 100%;
  }
  .navbar .navbar-menu .navbar-nav > .nav {
    flex-direction: column;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    border-radius: 0;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .badge, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .badge {
    margin-left: auto;
    margin-right: 0.75rem;
    top: auto;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .caret {
    margin-left: auto;
    margin-right: 0.25rem;
    transition: transform 150ms;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .caret {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .badge ~ .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .badge ~ .caret {
    margin-left: 0;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret {
    transform: rotate(-180deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret[class*="-left"], .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret[class*="-left"] {
    transform: rotate(-90deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret[class*="-right"], .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret[class*="-right"] {
    transform: rotate(90deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-menu {
    transform: none !important;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0.25rem 0.5rem 0.5rem !important;
    border-width: 0;
    max-width: calc(100vw - 1rem);
    width: auto !important;
    box-shadow: none !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-menu.show {
    display: block;
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-divider {
    margin: 0.5rem 1rem;
  }
  .navbar-menu.navbar-collapse .dropdown[class*="backdrop"]::before {
    display: none;
  }
  .navbar-collapse [class*="icon-animated"] {
    animation: none !important;
  }
  body.mob-navbar-body {
    overflow-y: hidden;
  }
}

@media (max-width: 767.98px) {
  .navbar .navbar-menu.navbar-collapse .navbar-nav > .nav .dropdown-menu {
    margin: 0.25rem !important;
    max-width: calc(100vw - 0.5rem);
  }
}

.sticky-trigger {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  z-index: -1;
}

@media (max-width: 575.98px) {
  .sticky-nav-sm {
    position: sticky;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 767.98px) {
  .sticky-nav-md {
    position: sticky;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 991.98px) {
  .sticky-nav-lg {
    position: sticky;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 1199.98px) {
  .sticky-nav-xl {
    position: sticky;
    top: 0;
    z-index: 997;
  }
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 997;
}

.navbar-fixed + div .sticky-nav {
  top: 4.5rem;
  top: var(--navbar-height);
}

.navbar-fixed + div .sticky-nav .sticky-trigger {
  top: calc( -4.5rem - 1px);
  top: calc( -1 * var(--navbar-height) - 1px);
}

.navbar-sm.navbar-fixed + div .sticky-nav {
  top: 4rem;
  top: var(--navbar-sm-height);
}

.navbar-sm.navbar-fixed + div .sticky-nav .sticky-trigger {
  top: calc( -4rem - 1px);
  top: calc( -1 * var(--navbar-sm-height) - 1px);
}

.sticky-nav .is-stuck[class*="sticky-nav"] {
  z-index: 1018;
}

@media (max-width: 1199.98px) {
  .navbar-fixed + div [class*="sticky-nav"] {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
  .navbar-fixed + div [class*="sticky-nav"] .sticky-trigger {
    top: calc( -4rem - 1px);
    top: calc( -1 * var(--navbar-mobile-height) - 1px);
  }
}

.sidebar {
  flex: 0 0 auto;
  min-height: 100%;
  width: 16rem;
  width: var(--sidebar-width);
  position: relative;
  z-index: 1020;
  visibility: hidden;
}

.navbar ~ .main-container > .sidebar:not(.sidebar-fixed) {
  z-index: 999;
}

.sidebar .sidebar-inner {
  width: inherit;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: inherit;
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.sidebar .ace-scroll,
.sidebar .sidebar-section {
  width: 16rem;
  width: var(--sidebar-width);
  white-space: nowrap;
}

.sidebar .fadeable {
  transition: opacity 150ms 0s, visibility 0s 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .fadeable {
    transition: none;
  }
}

.sidebar .fadeinable {
  position: absolute;
  left: 0;
  width: 5rem;
  width: var(--sidebar-collapsed-width);
  min-height: 3rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms 0s, visibility 0s 0s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .fadeinable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar-right {
    direction: rtl;
    order: 10;
  }
  .sidebar-right .sidebar-inner {
    direction: ltr;
  }
  .sidebar {
    will-change: width;
    transition: width 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.toggling {
    overflow: hidden;
    pointer-events: none;
  }
  .sidebar.toggling [data-toggle="sidebar"] {
    pointer-events: auto;
  }
  .sidebar.toggling.collapsed .fadeable {
    transition-delay: 0s, 150ms;
  }
  .sidebar.toggling:not(.collapsed) .fadeable {
    transition-delay: 0s;
  }
  .sidebar.collapsed {
    width: 5rem;
    width: var(--sidebar-collapsed-width);
  }
  .sidebar.collapsed .fadeable {
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.collapsed .fadeinable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 150ms;
  }
}

@keyframes hideScrollbars {
  from {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.sidebar .ace-scroll {
  overflow-x: hidden;
  overscroll-behavior: none;
  -ms-scroll-chaining: none;
  flex-grow: 1;
}

.sidebar .ace-scroll:not(:hover) {
  animation: 2s hideScrollbars;
}

.sidebar .ace-scroll:hover {
  overflow-y: auto;
  overflow-y: overlay;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sidebar .ace-scroll {
    animation: none !important;
    overflow-y: auto;
    height: 50%;
  }
}

.sidebar .ace-scroll-mob {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  animation: none !important;
  overscroll-behavior: none;
  -ms-scroll-chaining: none;
}

@media (hover: none) {
  .sidebar .ace-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    animation: none !important;
    overscroll-behavior: none;
    -ms-scroll-chaining: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .ace-scroll {
    animation: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable .ace-scroll,
  .sidebar.expandable .sidebar-section,
  .sidebar.expandable .nav {
    width: inherit;
  }
  .sidebar.expandable:not(.sidebar-spaced) .nav > .nav-item {
    width: inherit;
  }
  .sidebar.expandable .nav > .nav-item > .nav-link,
  .sidebar.expandable .nav > .nav-item > .submenu,
  .sidebar.expandable .sidebar-section-item {
    width: 16rem;
    width: var(--sidebar-width);
    white-space: nowrap;
  }
  .sidebar.expandable.collapsed:not(.sidebar-fixed) .sidebar-inner {
    position: absolute;
    top: 0;
  }
  .sidebar.expandable.collapsed .sidebar-inner {
    transition: width 150ms ease-out 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner:hover, .sidebar.expandable.collapsed .sidebar-inner.has-focus {
    width: 16rem;
    width: var(--sidebar-width);
    transition-delay: 80ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .fadeable {
    transition-delay: 400ms, 550ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner:hover .fadeable, .sidebar.expandable.collapsed .sidebar-inner.has-focus .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 80ms, 80ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .fadeinable {
    transition-delay: 400ms, 550ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner:hover .fadeinable, .sidebar.expandable.collapsed .sidebar-inner.has-focus .fadeinable {
    opacity: 0;
    visibility: hidden;
    transition-delay: 80ms;
    transition-duration: 0ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .hideable {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0ms 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner .hideable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner:hover .hideable, .sidebar.expandable.collapsed .sidebar-inner.has-focus .hideable {
    max-height: 2000px;
    transition-delay: 80ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .hideable.fadeable {
    visibility: visible;
    transition: max-height 0ms 400ms, opacity 0ms 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner .hideable.fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner:hover .hideable.fadeable, .sidebar.expandable.collapsed .sidebar-inner.has-focus .hideable.fadeable {
    transition-delay: 80ms, 80ms;
    transition-duration: 0ms, 150ms;
  }
  .sidebar.expandable.collapsed.toggling .fadeable {
    transition-delay: 0ms, 150ms;
  }
  .sidebar.expandable.collapsed.toggling:not(.collapsed) .fadeable {
    transition-delay: 0ms , 0ms;
  }
  .sidebar.expandable.collapsed.toggling .fadeinable {
    transition-delay: 0ms , 0ms;
  }
  .sidebar.expandable.collapsed.toggling .sidebar-inner {
    transition: none;
  }
}

@keyframes navItemHidden {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0);
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable:not(.collapsed):not(.sidebar-hover) .submenu,
  .sidebar.hoverable:not(.collapsed):not(.sidebar-hover) .nav > .nav-item > .nav-link > .nav-text, .sidebar:not(.hoverable):not(.sidebar-hover) .submenu,
  .sidebar:not(.hoverable):not(.sidebar-hover) .nav > .nav-item > .nav-link > .nav-text, .sidebar.toggling:not(.collapsed) .submenu,
  .sidebar.toggling:not(.collapsed) .nav > .nav-item > .nav-link > .nav-text {
    transform: none !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar .submenu,
  .sidebar .nav > .nav-item > .nav-link > .nav-text {
    transform: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable:not(.collapsed):not(.toggling) .ace-scroll,
  .sidebar.hoverable:not(.collapsed):not(.toggling) .sidebar-section,
  .sidebar.hoverable:not(.collapsed):not(.toggling) .sidebar-section-item,
  .sidebar.hoverable:not(.collapsed):not(.toggling) .nav {
    width: inherit;
  }
  .sidebar.hoverable.collapsed .sidebar-inner,
  .sidebar.hoverable.collapsed .ace-scroll,
  .sidebar.hoverable.collapsed .ace-scroll-mob {
    overflow: visible;
    animation: none;
  }
  .sidebar.hoverable.collapsed .submenu-inner {
    overflow: visible;
  }
  .sidebar.hoverable.collapsed.toggling .sidebar-inner,
  .sidebar.hoverable.collapsed.toggling .submenu-inner,
  .sidebar.hoverable.collapsed.toggling .ace-scroll,
  .sidebar.hoverable.collapsed.toggling .ace-scroll-mob {
    overflow: hidden;
  }
  .sidebar.hoverable.collapsed .ace-scroll,
  .sidebar.hoverable.collapsed .sidebar-section {
    width: auto;
    white-space: nowrap;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item {
    width: 5rem;
    width: var(--sidebar-collapsed-width);
  }
  .sidebar.hoverable.collapsed.toggling .submenu,
  .sidebar.hoverable.collapsed.toggling .sub-arrow,
  .sidebar.hoverable.collapsed.toggling .nav > .nav-item > .nav-link > .nav-text {
    display: none;
  }
  .sidebar.hoverable.collapsed.toggling .sidebar-section .fadeable {
    display: none;
  }
  .sidebar.hoverable.collapsed.toggling .fadeinable {
    transition: none;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar.hoverable.collapsed .nav-item-caption .fadeable {
    animation: 1s navItemHidden;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    transition: margin 150ms, opacity 150ms, visibility 0ms;
    transition-delay: 800ms, 800ms, 950ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text,
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeable,
  .sidebar.hoverable.collapsed .nav-item-caption:hover .fadeable {
    animation-duration: 0ms;
    transition-delay: 100ms, 100ms, 0ms;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text {
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav-item:not(:hover) > .submenu,
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav-item:not(:hover) > .sub-arrow,
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav > .nav-item:not(:hover) > .nav-link > .nav-text {
    transition: none;
  }
  .sidebar.hoverable.collapsed .nav-item {
    position: relative;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    position: absolute !important;
    left: 100%;
    z-index: 2;
    margin-left: -11px;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    top: 0;
    min-width: 14rem;
    min-width: calc(var(--sidebar-width) - 2rem);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d1;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text .badge {
    margin-left: auto !important;
    float: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu {
    top: calc(100% - 3px);
    border-top-color: #e1e1e1;
  }
  .sidebar.hoverable.collapsed .nav-item .nav-item > .submenu {
    top: -0.125rem;
  }
  .sidebar.hoverable.collapsed .submenu-inner {
    border-width: 0 !important;
    margin: 0 0.125rem;
  }
  .sidebar.hoverable.collapsed .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text {
    margin-left: -2px;
    opacity: 1;
    visibility: visible;
  }
  .sidebar.hoverable.collapsed .nav-item > .submenu {
    display: block;
    height: auto !important;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu {
    border-radius: 0 0 0.375rem 0.375rem;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link.dropdown-toggle > .nav-text {
    border-radius: 0.375rem 0.375rem 0 0;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    border-radius: 0.375rem;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu .submenu {
    border-radius: 0.375rem;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable, .sidebar.sidebar-h .sidebar-section-item .fadeable {
    position: absolute;
    left: 0;
    z-index: 2;
    min-height: inherit;
    min-width: 16rem;
    min-width: var(--sidebar-width);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    transition: opacity 150ms, visibility 0ms, background-color 0ms, box-shadow 0ms;
    transition-delay: 800ms, 950ms, 950ms, 950ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable, .sidebar.sidebar-h .sidebar-section-item .fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item[class*="fadeable-"] .fadeable, .sidebar.sidebar-h .sidebar-section-item[class*="fadeable-"] .fadeable {
    margin-left: -11px;
    transition: opacity 150ms, margin 150ms, visibility 0ms, background-color 0ms, box-shadow 0ms;
    transition-delay: 800ms, 800ms, 950ms, 950ms, 950ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .sidebar-section-item[class*="fadeable-"] .fadeable, .sidebar.sidebar-h .sidebar-section-item[class*="fadeable-"] .fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeable, .sidebar.sidebar-h .sidebar-section-item:hover .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:focus-within .fadeable, .sidebar.sidebar-h .sidebar-section-item:focus-within .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left:hover .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left:hover .fadeable {
    margin-left: -2px;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left:focus-within .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left:focus-within .fadeable {
    margin-left: -2px;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeinable, .sidebar.sidebar-h .sidebar-section-item .fadeinable {
    position: relative;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:not(:hover) .fadeinable, .sidebar.sidebar-h .sidebar-section-item:not(:hover) .fadeinable {
    transition-delay: 0s, 800ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeinable, .sidebar.sidebar-h .sidebar-section-item:hover .fadeinable {
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left .fadeable {
    left: 100%;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left .fadeinable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left .fadeinable {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-top .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-top .fadeable {
    top: 0;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-bottom .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-bottom .fadeable {
    bottom: 0;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .sub-arrow, .sidebar.sidebar-h .sidebar-section-item .sub-arrow {
    left: auto;
    right: 100%;
    opacity: 1;
    visibility: visible;
    margin-left: auto !important;
    transition: none;
    animation: none;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-top .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-top .sub-arrow {
    top: 1.25rem;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-bottom .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-bottom .sub-arrow {
    top: auto;
    bottom: 2.5rem;
  }
  .sidebar.hoverable.collapsed .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeable, .sidebar.hoverable.collapsed .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeinable, .sidebar.sidebar-h .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeable, .sidebar.sidebar-h .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeinable {
    transition: none;
  }
  .sidebar.hoverable.collapsed .nav-item-caption .fadeable, .sidebar.sidebar-h .nav-item-caption .fadeable {
    width: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret, .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret {
    transform: none;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-down"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-down"] {
    transform: rotate(-90deg) !important;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-up"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-up"] {
    transform: rotate(90deg) !important;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-left"], .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-right"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-left"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-right"] {
    transform: scaleX(-1) !important;
  }
  .sidebar-hover.toggling .sidebar-inner {
    overflow: hidden !important;
  }
  .sidebar-hover:not(.collapsed) .sidebar-inner,
  .sidebar-hover:not(.collapsed) .ace-scroll,
  .sidebar-hover:not(.collapsed) .ace-scroll-mob {
    overflow: visible;
    animation: none !important;
    animation-duration: 0ms;
  }
  .sidebar-hover:not(.collapsed) .submenu-inner {
    overflow: visible;
  }
  .sidebar-hover:not(.collapsed) .nav-item {
    position: relative;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-item > .nav-link {
    padding-left: 1.5rem !important;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-item > .nav-link > .nav-text::before {
    margin-left: 2px;
  }
  .sidebar-hover:not(.collapsed) .submenu,
  .sidebar-hover:not(.collapsed) .sub-arrow {
    animation: 1s navItemHidden;
    position: absolute !important;
    left: 100%;
    z-index: 2;
    margin-left: -11px;
    opacity: 0;
    visibility: hidden;
    transition: margin 150ms, opacity 150ms, visibility 0ms;
    transition-delay: 800ms, 800ms, 950ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar-hover:not(.collapsed) .submenu,
  .sidebar-hover:not(.collapsed) .sub-arrow {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable .sub-arrow {
    transition: none;
  }
  .sidebar-hover:not(.collapsed) .submenu {
    top: 0;
    min-width: 14rem;
    min-width: calc(var(--sidebar-width) - 2rem);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d1;
    border-radius: 0.375rem;
  }
  .sidebar-hover:not(.collapsed) .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item:hover > .submenu,
  .sidebar-hover:not(.collapsed) .nav > .nav-item:hover > .sub-arrow {
    transition-delay: 0ms;
  }
  .sidebar-hover:not(.collapsed) .sidebar-inner:hover .nav-item:not(:hover) > .submenu,
  .sidebar-hover:not(.collapsed) .sidebar-inner:hover .nav-item:not(:hover) > .sub-arrow {
    transition: none;
  }
  .sidebar-hover:not(.collapsed) .nav-item:hover > .submenu,
  .sidebar-hover:not(.collapsed) .nav-item:hover > .sub-arrow {
    margin-left: -2px;
    opacity: 1;
    visibility: visible;
    animation-duration: 0ms;
    transition-delay: 100ms, 100ms, 0ms;
  }
  .sidebar-hover:not(.collapsed) .nav-item > .submenu {
    display: block;
    height: auto !important;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item .submenu {
    top: -0.125rem;
    background-color: #fff;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu {
    top: -0.5rem;
  }
  .sidebar-hover:not(.collapsed) .submenu-inner {
    border-width: 0 !important;
    margin: 0 0.125rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-link:not(.dropdown-toggle) ~ .sub-arrow {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.hideable {
    transition-duration: 150ms;
  }
  .sidebar.hideable .sidebar-inner {
    transition: width 150ms, visibility 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hideable .sidebar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hideable.collapsed {
    width: 0;
    overflow: hidden;
  }
  .sidebar.hideable.collapsed .sidebar-inner {
    visibility: hidden;
    width: 0;
    overflow: hidden;
    transition-delay: 0ms, 150ms;
    transition-duration: 150ms, 0ms;
  }
}

.sidebar .nav {
  flex-direction: column;
}

.sidebar .nav-item, .sidebar .nav-link {
  position: relative;
}

.sidebar .nav-link:focus {
  outline: none;
}

.sidebar .nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
  min-height: 3.75em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  text-shadow: none;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 150ms, color 150ms;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sidebar .nav > .nav-item > .nav-link {
    height: 3.75em;
  }
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link {
    transition: none;
  }
}

.sidebar .nav > .nav-item > .nav-link::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-width: 0 0 0 0.25rem;
  transition: border-color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link::before {
    transition: none;
  }
}

.sidebar .nav.active-on-right > .nav-item > .nav-link::before {
  left: auto !important;
  right: 1px;
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon {
  width: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon:not(.fadeinable) {
  transition: color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link > .nav-icon:not(.fadeinable) {
    transition: none;
  }
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon.nav-icon-round {
  width: 2.125rem;
  height: 2.125rem;
  margin-left: 0;
  border-radius: 2rem;
  color: #fff !important;
  font-size: 1rem;
  text-align: center;
  line-height: 2;
  padding-left: 1px;
}

.sidebar .nav-text {
  flex-grow: 1;
  white-space: normal;
  word-break: break-all;
  margin-right: 3px;
}

.sidebar .nav-text .badge {
  float: right;
}

.sidebar .nav > .nav-item > .submenu {
  position: relative;
}

.sidebar .nav > .nav-item > .submenu > .submenu-inner {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.sidebar .nav > .nav-item > .submenu > .submenu-inner > .nav-item:not(:last-child) {
  margin-bottom: 1px;
}

.sidebar .submenu-inner {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  overflow-y: hidden;
}

.sidebar .submenu.collapsing {
  transition: height 0.35s ease !important;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .submenu.collapsing {
    transition: none !important;
  }
}

.sidebar .submenu .nav-link {
  min-height: 3rem;
}

.sidebar .submenu .submenu .nav-link {
  margin-top: 1px;
  margin-bottom: 1px;
}

.sidebar .submenu .nav-link {
  padding: 0.625rem 0 0.75rem 3rem;
  display: flex;
  align-items: center;
}

.sidebar .submenu .nav-icon {
  margin-right: 0.25rem;
  vertical-align: baseline;
  opacity: 0.85;
}

.sidebar .submenu .nav-link:hover > .nav-icon,
.sidebar .submenu .nav-item.active > .nav-link > .nav-icon {
  opacity: 1;
}

.sidebar .caret {
  margin-left: auto;
  margin-right: 1rem;
}

.sidebar .nav-item > .nav-link > .caret {
  transition: transform 150ms, opacity 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav-item > .nav-link > .caret {
    transition: none;
  }
}

.sidebar .nav-item.open > .nav-link > .caret {
  transform: scaleX(-1);
}

.sidebar .nav-item.open > .nav-link > .caret.rt-90, .sidebar .nav-item.open > .nav-link > .caret[class*="-right"] {
  transform: rotate(90deg);
}

.sidebar .nav-item.open > .nav-link > .caret.rt-n90, .sidebar .nav-item.open > .nav-link > .caret[class*="-left"] {
  transform: rotate(-90deg);
}

.sidebar .nav-item:not(.open) > .nav-link > .caret.caret-hidden,
.sidebar .nav-item.open > .nav-link > .caret.caret-shown {
  transform: none;
  display: inline-block;
}

.sidebar .nav-item.open > .nav-link > .caret.caret-hidden,
.sidebar .nav-item:not(.open) > .nav-link > .caret.caret-shown {
  transform: none;
  display: none;
}

.sidebar .submenu .caret {
  margin-right: 1.5rem;
}

.sidebar .nav > .nav-item.is-toggling:not(.open):not(.active):not(:hover) > .nav-link {
  transition: none;
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item .nav-item > .nav-link {
    padding-left: 1.5rem;
  }
  .sidebar.hoverable.collapsed .nav-item .nav-item > .nav-link > .nav-text::before {
    margin-left: 2px;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .caret {
    display: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu::before,
  .sidebar.hoverable.collapsed .nav-item .nav-item::before {
    display: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    padding: 0 0.75rem;
    color: #3d78b1;
    background-color: #f8f8f8;
    font-size: 0.9125rem;
    font-weight: 600;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link.dropdown-toggle > .nav-text {
    cursor: default;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item {
    width: auto;
    overflow: visible;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item > .nav-link {
    border-radius: inherit;
    padding: 0;
    justify-content: center;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item > .nav-link > .nav-icon {
    margin: 0;
  }
  .sidebar.hoverable.collapsed .submenu {
    background-color: #fff;
  }
  .sub-arrow {
    position: absolute;
    visibility: hidden;
    top: calc(50% - 0.625rem);
    margin-top: -1px;
    box-shadow: none;
    border: none;
  }
  .sub-arrow::after, .sub-arrow::before {
    content: "";
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    border: 0.625rem solid transparent;
  }
  .sub-arrow::after {
    border-right-color: #f8f8f8;
    right: -2px;
  }
  .sub-arrow::before {
    border-right-color: #d1d1d1;
    right: -1px;
  }
  .nav-item .nav-item .sub-arrow::after {
    border-right-color: #fff;
  }
  .submenu-pullup .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar.expandable:not(.sidebar-hover) .sub-arrow, .sidebar.collapsed:not(.hoverable) .sub-arrow {
    display: none !important;
  }
}

.sidebar .nav-link.dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item-caption {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0.325rem;
  margin: 0.5rem 0rem;
}

.sidebar .nav-item + .nav-item-caption {
  margin-top: 2rem;
}

.sidebar .nav-item-divider {
  margin: 0.5rem;
}

.sidebar .submenu .nav-item-divider {
  margin: 0.25rem 0.75rem 0.25rem 3rem;
}

.sidebar .submenu .submenu .nav-item-divider {
  margin-left: 4rem;
}

@media (min-width: 1200px) {
  .sidebar.collapsed .sidebar-inner:not(.is-hover) .nav-item-divider.hideable {
    margin: 0;
    visibility: hidden;
  }
  .sidebar.collapsed .sidebar-inner:not(.is-hover) .nav-item + .nav-item-caption.hideable {
    margin-top: 0;
  }
  .sidebar.collapsed.hoverable .submenu .nav-item-divider {
    margin: 0.5rem 1rem;
  }
}

.sidebar-section-item {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}

.sidebar-shortcuts-mini .btn {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  padding: 0;
  margin: 0 1px;
}

.sidebar-search-input {
  width: 12rem;
  width: calc(var(--sidebar-width) - 4rem);
  height: 2rem;
  line-height: 2;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid transparent;
}

.sidebar-search-input:focus {
  outline: none;
}

.sidebar-search-input::placeholder {
  font-size: 0.925rem;
  line-height: 2;
}

@media (min-width: 1200px) {
  .sidebar.expandable .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
    transition: font-size 0ms 0ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed:not(:hover) .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
    font-size: 0;
    transition-delay: 400ms;
  }
  .sidebar.expandable.collapsed:hover .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
    transition-delay: 200ms;
  }
}

@media (max-width: 1199.98px) {
  .sidebar {
    max-width: 0;
    visibility: hidden;
  }
  .sidebar .sidebar-inner {
    visibility: visible;
    transform: translateX(-100%) translateX(-1px);
    transition: transform 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-inner {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar:not(.sidebar-fixed) .sidebar-inner {
    position: relative;
    top: 0;
    min-height: auto;
    height: auto;
    max-height: 0;
    transition: transform 300ms 0ms, max-height 0ms 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar:not(.sidebar-fixed) .sidebar-inner {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar .ace-scroll,
  .sidebar .sidebar-section-item {
    width: auto;
  }
  .sidebar-visible .sidebar-inner {
    transform: translateX(0);
  }
  .sidebar-visible:not(.sidebar-fixed) .sidebar-inner {
    transition-delay: 0s !important;
    max-height: 1000vh;
  }
  .sidebar-push:not(.sidebar-fixed) .sidebar-inner {
    height: 100%;
    max-height: none;
  }
  .sidebar:not(.sidebar-push) .sidebar-inner .nav-item:last-child::after {
    display: none;
  }
  .sidebar-push {
    transition: max-width 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-push {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-push.sidebar-visible {
    max-width: 16rem;
    max-width: var(--sidebar-width);
  }
  .sidebar-push.sidebar-visible ~ .main-content {
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
  .sidebar-backdrop::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    display: block;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 150ms 0ms, visibility 0ms 150ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-backdrop::before {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-visible.sidebar-backdrop::before,
  .sidebar-visible .sidebar-backdrop::before {
    visibility: visible;
    pointer-events: all;
    opacity: 0.33;
    transition-delay: 0s;
  }
  .sidebar.sidebar-spaced .nav > .nav-item {
    width: auto;
    margin-right: 0.875rem;
  }
  body.mob-sidebar-body {
    overflow-y: hidden;
  }
  .sidebar-visible [data-toggle-mobile="sidebar"] {
    pointer-events: auto !important;
  }
}

@media (max-width: 991.98px) {
  .sidebar-fixed.sidebar-push {
    max-width: 0;
    min-width: 0;
    width: 0;
    transition: width 300ms, min-width 300ms, max-width 300ms;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-fixed.sidebar-push {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .sidebar-fixed.sidebar-push.sidebar-visible {
    width: 75vw;
    max-width: 320px;
    min-width: 16rem;
    min-width: var(--sidebar-width);
  }
  .sidebar-fixed .sidebar-inner {
    width: 75vw;
    max-width: 320px;
    min-width: 16rem;
    min-width: var(--sidebar-width);
  }
  .sidebar-fixed .sidebar-inner .ace-scroll,
  .sidebar-fixed .sidebar-inner .sidebar-section,
  .sidebar-fixed .sidebar-inner .sidebar-search-input {
    width: auto;
  }
  .sidebar-fixed .sidebar-inner .sidebar-search-input {
    max-width: calc( 100% - 1rem);
  }
}

@media (min-width: 1200px) {
  .sidebar.sidebar-h {
    will-change: unset;
    transition: none;
    min-height: auto;
    height: 4.25rem;
    height: var(--sidebar-horizontal-height);
    width: 100%;
  }
  .sidebar.sidebar-h .sidebar-inner {
    top: auto !important;
    bottom: auto !important;
    flex-direction: row;
    overflow: visible;
    height: inherit !important;
  }
  .sidebar.sidebar-h .ace-scroll {
    overflow: visible !important;
    width: auto;
    height: auto;
  }
  .sidebar.sidebar-h .nav {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .sidebar.sidebar-h .nav > .nav-item {
    width: auto;
    margin-bottom: 0 !important;
    margin: 0 1px;
    position: relative;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link {
    width: auto;
    height: 100%;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: relative;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-icon {
    margin-right: 0.5em;
    margin-left: 0.125em;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-text {
    flex-grow: 0;
    margin-right: 0;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-text .badge {
    float: none;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link > .caret {
    display: none;
  }
  .sidebar.sidebar-h .nav > .nav-item.active > .nav-link {
    font-weight: 600;
  }
  .sidebar.sidebar-h .nav.nav-link-rounded > .nav-item > .nav-link {
    border-radius: 0.25em 0.25em 0 0;
  }
  .sidebar.sidebar-h .nav-fill {
    width: 100%;
  }
  .sidebar.sidebar-h .nav-fill.text-center > .nav-item > .nav-link {
    justify-content: center;
  }
  .sidebar.sidebar-h .nav-fill .nav-item .nav-item {
    text-align: left;
  }
  .sidebar.sidebar-h .nav-item-caption {
    display: none;
  }
  .sidebar.sidebar-h .nav > .nav-item > .submenu {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 15rem;
    min-width: calc(var(--sidebar-width) - 1rem);
    margin-left: 0;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .nav > .nav-item:hover > .submenu {
    margin-left: 0 !important;
    margin-top: -2px !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link::before {
    top: auto !important;
    bottom: 1px !important;
    left: 1px !important;
    right: 1px !important;
    border-width: 3px 0 0 0;
  }
  .sidebar.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 1px !important;
    left: 0 !important;
    right: 0 !important;
    border-width: 0 0 3px 0;
  }
  .sidebar.sidebar-h .nav.has-active-border > .nav-item.active::before, .sidebar.sidebar-h .nav.has-active-border > .nav-item.active > .nav-link::before {
    left: 0 !important;
    right: 0 !important;
    bottom: 1px !important;
    top: auto;
    border-width: 3px 0 0 0;
  }
  .sidebar.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item.active::before, .sidebar.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: 0 !important;
  }
  .sidebar.sidebar-h .nav.nav-active-sm > .nav-item > .nav-link::before,
  .sidebar.sidebar-h .nav.nav-active-sm > .nav-item.active > .nav-link::before {
    left: 10% !important;
    right: 10% !important;
  }
  .sidebar.sidebar-h .nav.nav-spaced > .nav-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sidebar.sidebar-h .nav.nav-spaced > .nav-item > .nav-link {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .sidebar.sidebar-h .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    display: block !important;
    top: auto;
    bottom: -1px;
    right: auto;
    left: calc(50% - 0.625rem);
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;
    border-top-width: 0;
    border-bottom: 0.625rem solid #fff;
    z-index: 1;
  }
  .sidebar.sidebar-h .nav.has-active-arrow .nav-item .nav-item.active > .nav-link::after {
    display: none !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow {
    top: calc(100% - 1.25rem + 1px);
    bottom: auto;
    left: 1rem !important;
    right: auto;
    margin-left: 0 !important;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::after, .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::before, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::before {
    border: 0.625rem solid;
    border-color: transparent;
    right: auto;
    border-right-color: transparent !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::after {
    border-bottom-color: #d1d1d1;
    top: -1px;
    z-index: 0;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::before, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::before {
    border-bottom-color: #fff;
    z-index: 1;
  }
  .sidebar.sidebar-h .nav.has-active-arrow > .nav-item > .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
  .sidebar.sidebar-h:not(.sidebar-hover) .nav > .nav-item.open > .sub-arrow {
    display: block;
  }
  .sidebar.sidebar-h.sidebar-hover .nav > .nav-item:hover > .sub-arrow {
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-below .fadeable .sub-arrow {
    top: calc(-1.25rem + 1px);
    margin-top: 0 !important;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-right .fadeable .sub-arrow {
    left: auto !important;
    right: 2rem;
  }
  .sidebar.sidebar-h .nav > .nav-item:last-of-type > .submenu, .sidebar.sidebar-h .nav > .nav-item.submenu-right > .submenu, .sidebar.sidebar-h .nav > .nav-item:last-of-type > .submenu, .sidebar.sidebar-h .nav > .nav-item.submenu-right > .submenu {
    left: auto !important;
    right: 0 !important;
  }
  .sidebar.sidebar-h .nav:not(.has-active-arrow) > .nav-item:last-of-type > .sub-arrow, .sidebar.sidebar-h .nav:not(.has-active-arrow) > .nav-item.submenu-right > .sub-arrow {
    left: auto !important;
    right: 2rem !important;
  }
  .sidebar.sidebar-h .sidebar-section {
    width: auto;
    display: inline-flex;
    flex-direction: row;
  }
  .sidebar.sidebar-h .sidebar-section-item .fadeable {
    animation: 1s navItemHidden;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-below .fadeable {
    top: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-right .fadeable {
    left: auto !important;
    right: 0;
  }
  .sidebar.sidebar-h .sidebar-section-item .fadeinable {
    opacity: 1 !important;
    visibility: visible !important;
    transition: none;
  }
  .sidebar.sidebar-h .sidebar-section-item:hover .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
    margin-left: 0 !important;
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section-item:focus-within .fadeable {
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:not(:first-child) .fadeable,
  .sidebar.sidebar-h .sidebar-section:not(:first-child) .sidebar-section-item.fadeable-center .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .sidebar.sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:not(:first-child) .fadeable .sub-arrow,
  .sidebar.sidebar-h .sidebar-section:not(:first-child) .sidebar-section-item.fadeable-center .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
  .sidebar-h.collapsed-h {
    height: 4rem;
    height: var(--sidebar-horizontal-collapsed-height);
  }
  .sidebar-h.collapsed-h .nav > .nav-item > .nav-link > .nav-text {
    display: none;
  }
  .sidebar-h.collapsed-h .nav > .nav-item > .nav-link > .nav-icon {
    margin: 0 0.625em;
  }
  .navbar:not(.navbar-fixed) ~ .sidebar-h.sidebar-fixed, .navbar:not(.navbar-fixed) ~ .main-container .sidebar-h.sidebar-fixed {
    position: sticky;
    top: 0;
  }
  .navbar:not(.navbar-fixed) ~ .sidebar-h.sidebar-fixed .sidebar-inner, .navbar:not(.navbar-fixed) ~ .main-container .sidebar-h.sidebar-fixed .sidebar-inner {
    position: relative;
  }
  .main-container .sidebar-h.sidebar-fixed {
    position: sticky;
    top: 0;
  }
  .main-container .sidebar-h.sidebar-fixed .sidebar-inner {
    position: relative;
  }
  .navbar-fixed ~ .main-container .sidebar-h.sidebar-fixed, .main-container .navbar-fixed ~ .sidebar-h.sidebar-fixed {
    top: 4.5rem;
    top: var(--navbar-height);
  }
  .navbar-fixed.navbar-sm ~ .main-container .sidebar-h.sidebar-fixed, .main-container .navbar-fixed.navbar-sm ~ .sidebar-h.sidebar-fixed {
    top: 4rem;
    top: var(--navbar-sm-height);
  }
  .sidebar-h.sidebar-fixed ~ .main-container .sidebar-fixed .sidebar-inner {
    margin-top: 4.25rem;
    margin-top: var(--sidebar-horizontal-height);
  }
  .collapsed-h.sidebar-fixed ~ .main-container .sidebar-fixed .sidebar-inner {
    margin-top: 4rem;
    margin-top: var(--sidebar-horizontal-collapsed-height);
  }
}

@media (min-width: 1600px) {
  .container .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable, .sidebar-h .container .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .container .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow, .sidebar-h .container .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
}

@media (min-width: 1750px) {
  .container-plus .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable, .sidebar-h .container-plus .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .container-plus .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow, .sidebar-h .container-plus .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-h .nav.nav-fill .nav-item {
    flex: unset;
    text-align: left;
  }
  .sidebar-h .container {
    height: 100%;
  }
}

.nav.has-active-arrow .nav-item.active > .nav-link::after {
  content: "";
  display: none;
  position: absolute;
  z-index: 0;
  width: 0;
  height: 0;
  margin: 0;
  right: 0;
  top: calc(50% - 0.625rem);
  border-right: 0.625rem solid #fff;
  border-left: none;
  border-top: 0.625rem solid transparent;
  border-bottom: 0.625rem solid transparent;
}

.nav.has-active-arrow .nav-item.active:not(.open) > .nav-link::after {
  display: block;
}

.nav.has-active-arrow .nav-item.active.open .nav-item.active:not(.open) > .nav-link::after {
  display: block;
}

.nav.has-active-arrow .nav-item.active:not(.open) .nav-item.active:not(.open) > .nav-link::after {
  display: none;
}

@media (min-width: 1200px) {
  .sidebar.expandable:not(.collapsed).toggling .nav.has-active-arrow > .nav-item.active:not(.open) > .nav-link,
  .sidebar.expandable.collapsed.toggling .nav.has-active-arrow > .nav-item > .nav-link {
    position: static;
  }
  .sidebar.expandable.sidebar-spaced:not(.collapsed) .nav.has-active-arrow .nav-item.active > .nav-link::after, .sidebar.expandable.sidebar-spaced.toggling .nav.has-active-arrow .nav-item.active > .nav-link::after {
    display: none !important;
  }
  .sidebar.expandable.collapsed:not(.toggling) .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    right: auto !important;
    left: 4.375rem;
    left: calc( var(--sidebar-collapsed-width) - 0.625rem);
  }
  .sidebar.expandable.collapsed:not(.toggling).sidebar-spaced .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    margin-left: calc( -0.4375rem - 2px);
  }
  .sidebar.expandable.collapsed .nav.has-active-arrow .nav-item .nav-item > .nav-link::after {
    display: none !important;
  }
  .sidebar.expandable.collapsed .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    transition: visibility 0s 550ms;
    display: block;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner:hover .nav.has-active-arrow > .nav-item.active > .nav-link::after, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    visibility: hidden;
    transition-delay: 80ms;
  }
  .sidebar.hoverable.collapsed .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    display: block;
    right: -1px;
  }
  .sidebar.hoverable.collapsed .nav.has-active-arrow .nav-item .nav-item.active > .nav-link::after {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar:not(.sidebar-push) .nav.has-active-arrow .nav-item.active > .nav-link::after {
    display: none !important;
  }
}

.nav.has-active-border > .nav-item.active::before {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-left-width: 0.25rem;
}

.nav.has-active-border > .nav-item.active[class*="brc-"]::before {
  border-left-color: inherit;
}

.nav.has-active-border .nav-item.active > .nav-link::before {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-left-width: 0.25rem;
}

.nav.has-active-border > .nav-item.active[class*="brc-"] .nav-link, .nav.has-active-border > .nav-item.active[class*="brc-"] .nav-link::before, .nav.has-active-border > .nav-item.active[class*="brc-"] .submenu, .nav.has-active-border > .nav-item.active[class*="brc-"] .submenu-inner, .nav.has-active-border > .nav-item.active[class*="brc-"] .nav-item {
  border-left-color: inherit;
}

.nav.has-active-border .nav-item.active.open > .nav-link::before {
  display: none;
}

.nav.has-active-border .nav-item.active:not(.open) .nav-item > .nav-link::before {
  display: none;
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: block;
  }
  .sidebar.expandable.collapsed .sidebar-inner.is-hover .nav.has-active-border > .nav-item.active.open > .nav-link::before, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-border > .nav-item.active.open > .nav-link::before {
    visibility: hidden;
  }
  .sidebar.expandable.collapsed .sidebar-inner.is-hover .nav.has-active-border > .nav-item.active:not(.open) > .nav-link::before, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-border > .nav-item.active:not(.open) > .nav-link::before {
    visibility: visible;
  }
}

.nav.has-active-border.active-on-top .nav-item.active::before {
  display: none !important;
}

.nav.has-active-border.active-on-top .nav-item.active > .nav-link::before {
  display: block !important;
  visibility: visible !important;
}

.nav.has-active-border.active-on-top .nav-item .nav-item.active > .nav-link::before {
  display: none !important;
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: block !important;
    visibility: visible !important;
  }
  .sidebar.hoverable.collapsed .nav.has-active-border .nav-item .nav-item.active > .nav-link::before {
    display: none !important;
  }
}

.nav.has-active-border.active-on-right .nav-item.active > .nav-link::before,
.nav.has-active-border.active-on-right > .nav-item.active::before {
  left: auto !important;
  right: 1px;
}

@media (min-width: 1200px) {
  .collapsed:not(.toggling) .sidebar-inner:not(.is-hover) .nav.active-on-right .nav-item.active > .nav-link::before,
  .collapsed:not(.toggling) .sidebar-inner:not(.is-hover) .nav.active-on-right > .nav-item.active::before {
    left: 5rem !important;
    left: var(--sidebar-collapsed-width) !important;
    margin-left: calc(-0.25rem - 1px);
    right: auto;
  }
}

.sidebar-spaced .nav.has-active-arrow .nav-item.active > .nav-link::after {
  display: none !important;
}

@media (min-width: 1200px) {
  .sidebar-spaced.sidebar-color.hoverable.collapsed .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .body-container:not(.container) .sidebar:not(.sidebar-spaced) .nav.has-active-border > .nav-item.active::before,
  .body-container:not(.container) .sidebar:not(.sidebar-spaced) .nav.has-active-border .nav-item > .nav-link::before {
    left: 1px;
  }
}

@media (max-width: 1199.98px) {
  .sidebar .nav.has-active-border > .nav-item.active::before,
  .sidebar .nav.has-active-border .nav-item > .nav-link::before {
    left: 1px;
  }
}

/**
.nav.has-open-indicator {
    > .nav-item > .submenu::before,
    > .nav-item > .submenu::after {
        content: "";
        display: none;

        width: 0;
        height: 0;

        position: absolute;
        top: -1px;
        left: 0;
        right: 0;

        margin-left: auto;
        margin-right: auto;
        
        border-top: 1px solid rgba(255, 255, 255, 0.4);

        @include transition(width $transition-general);
    }
    > .nav-item.open > .submenu::before,
    > .nav-item.open > .submenu::after {
        width: calc(100% - 2rem);
    }
    > .nav-item > .submenu::after {
        transition: none;
        top: auto;
        bottom: -1px;
    }
}
*/
.sidebar-spaced .nav > .nav-item {
  width: 14.25rem;
  width: calc( var(--sidebar-width) - 1.75rem);
  margin-left: 0.875rem;
  border-radius: 0.325rem;
  overflow: hidden;
  margin-bottom: 0.25rem;
  pointer-events: none;
}

.sidebar-spaced .nav > .nav-item::before, .sidebar-spaced .nav > .nav-item::after {
  display: none !important;
}

.sidebar-spaced .nav > .nav-item > .nav-link,
.sidebar-spaced .nav > .nav-item > .submenu {
  max-width: 100%;
  pointer-events: all;
}

.sidebar-spaced .nav > .nav-item > .nav-link {
  min-height: 3.25rem;
}

.sidebar-spaced .nav > .nav-item > .nav-link > .nav-icon {
  margin-left: 0;
}

.sidebar-spaced .nav > .nav-item > .submenu {
  border-width: 0;
}

.sidebar-spaced .caret {
  margin-right: 0.25rem;
}

.sidebar-spaced .submenu .caret {
  margin-right: 0.875rem;
}

.sidebar-spaced .submenu .nav-link {
  margin-left: 2px;
  margin-right: 2px;
}

.sidebar-spaced.sidebar-color .nav > .nav-item {
  transition: none;
  border-radius: 0.5rem;
}

@media (min-width: 1200px) {
  .sidebar-spaced.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    margin-left: 0.4375rem;
  }
  .sidebar-spaced.hoverable.collapsed .nav > .nav-item {
    margin-left: 0.5775rem;
    margin-right: 0.5775rem;
  }
  .sidebar-spaced.hoverable.collapsed .nav > .nav-item > .submenu {
    border-width: 1px;
  }
}

@media (min-width: 1200px) {
  .sidebar-spaced.sidebar.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item > .nav-link > .nav-icon-round {
    margin-left: -0.625rem;
  }
  .sidebar-spaced.expandable .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
  .sidebar-spaced.hoverable:not(.collapsed) .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
  .sidebar-spaced.hideable .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-spaced .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
}

/**
If you want to use a dark sidebar like "purple", you should also add/include "sidebar-color" :
$my-sidebar-themes: (
    "color": $sidebar-color,
    "purple": $sidebar-purple
);
*/
.navbar-default {
  background-color: #2788bf;
}

.navbar-default .navbar-toggler:hover, .navbar-default .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-default .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-default .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-default .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-default .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-default .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-default .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-default .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-default .navbar-nav > .nav > .nav-item.show,
  .navbar-default .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-default .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-default .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-default .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-default .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-default .navbar-content-section input {
  background-color: transparent;
  color: #FFF;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-default .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-default .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-default .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.66);
}

.navbar-default .navbar-content-section input::-moz-placeholder {
  color: #FFF;
}

.sidebar-default {
  background-color: #f7f7f7;
}

.sidebar-default .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0 #dbdfe2;
}

@media (min-width: 1200px) {
  .sidebar-default:not(.sidebar-h) .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-default:not(.sidebar-h) .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-default:not(.sidebar-h) .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-default:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav {
    left: auto;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav .nav-item > .nav-link {
    left: auto;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border > .nav-item > .nav-link::before,
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .sidebar-default.sidebar-h .nav > .nav-item.active > .nav-link {
    top: 1px;
  }
  .sidebar-default.sidebar-h .nav.active-on-right > .nav-item::before, .sidebar-default.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 0 !important;
  }
  .sidebar-default.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item::before, .sidebar-default.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: -1px !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-default.sidebar-push .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-default.sidebar-push .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-default.sidebar-push .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-default.sidebar-push .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-default:not(.sidebar-push) .sidebar-inner {
    box-shadow: 1px 1px 0 0 #d0d5d9;
  }
}

.sidebar-default .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-default .nav > .nav-item > .nav-link {
  color: #565656;
  font-size: 1rem;
}

.sidebar-default .nav > .nav-item > .nav-link > .nav-icon {
  color: #646464;
  font-size: 1.25em;
}

.sidebar-default .nav > .nav-item.open > .nav-link {
  color: #313131;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e7eaef;
}

.sidebar-default .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #4a4a4a;
}

.sidebar-default .nav > .nav-item > .nav-link:hover {
  background-color: #e7eaef;
  color: #2e2f30;
}

.sidebar-default .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #454647;
}

.sidebar-default .nav > .nav-item > .submenu {
  background-color: #fdfdfd;
}

.sidebar-default .submenu .nav-link {
  color: #616161;
  font-size: 0.95rem;
}

.sidebar-default .submenu .nav-item.open > .nav-link {
  color: #2a7ab8;
}

.sidebar-default .submenu .nav-item > .nav-link:hover {
  color: #3576a9;
  background-color: #f0f5f9;
}

.sidebar-default .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-default .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-default .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-default .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-default .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-default .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

.sidebar-default .nav > .nav-item.active {
  border: 1px solid #dbdfe2;
  border-width: 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-default.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-default .nav > .nav-item.active > .nav-link {
  color: #3c7db9;
  background-color: #fff;
}

.sidebar-default .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-default .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #427fb8;
}

.sidebar-default .submenu .nav-item.active > .nav-link {
  color: #2a7ab8;
}

.sidebar-default .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-default .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #f0f5f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-default.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #2a7ab8;
    background-color: #f0f5f9;
    font-weight: 600;
  }
  .sidebar-default.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-default .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #629cc9;
  top: -1px;
  bottom: -1px;
}

.sidebar-default .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-default .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-default .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-default .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-default .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-default .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-default .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-default .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-default.hoverable.collapsed .submenu .nav-text::before, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-default .nav-item-caption {
  color: #717580;
  font-size: 0.875rem;
}

.sidebar-default .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-default .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-default .nav > .nav-item > .submenu > .submenu-inner {
  border: 1px solid #e6e9eb;
  border-width: 1px 0;
}

.sidebar-default .nav > .nav-item.active > .submenu > .submenu-inner {
  border-bottom-width: 0;
}

.sidebar-default .nav > .nav-item.open + .nav-item.active,
.sidebar-default .nav > .nav-item.is-toggling + .nav-item.active {
  margin-top: -2px;
}

.sidebar-default .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-default .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f7f7f7;
}

.sidebar-default .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f7f7f7;
}

.sidebar-default.sidebar-spaced .nav {
  margin-left: 0;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.active:not(.open) > .nav-link {
  left: auto;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.active {
  border-width: 1px;
  border-color: #d0d5d9;
}

@media (min-width: 1200px) {
  .sidebar-default.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #565656;
    background-color: transparent;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #646464;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-default.hoverable.collapsed .nav > .nav-item.active.open {
    border-width: 1px 0;
    transition: none;
  }
}

.sidebar-default .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-default .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-default .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-default .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.footer {
  height: 4.75rem;
  width: 100%;
  margin-top: 1.25rem;
  direction: rtl;
}

.footer-sm {
  height: 3.375rem;
}

.footer-inner {
  text-align: center;
  height: inherit;
  direction: ltr;
  padding-right: 0;
  padding-left: 0;
}

.footer-fixed .footer-inner {
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
  z-index: 998;
  width: 100%;
}

@media (min-width: 1200px) {
  .container .main-container, .container .main-content, .container .footer-fixed {
    max-width: inherit;
  }
  .container .footer-fixed .footer-inner {
    max-width: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .footer-fixed .footer-inner {
    padding-left: 16rem;
    padding-left: var(--sidebar-width);
  }
  .sidebar.toggling ~ .main-content .footer-fixed .footer-inner {
    transition: padding-left 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.toggling ~ .main-content .footer-fixed .footer-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.collapsed ~ .main-content .footer-fixed .footer-inner {
    padding-left: 5rem;
    padding-left: var(--sidebar-collapsed-width);
  }
  .sidebar.hideable.collapsed ~ .main-content .footer-fixed .footer-inner {
    padding-left: 0;
  }
}

.footer-tools {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 998;
}

.footer .footer-tools {
  right: auto;
  width: 0;
}

.btn-scroll-up {
  opacity: 0.75;
  transform: translateY(150%);
  transition: transform 300ms, opacity 200ms !important;
}

@media (prefers-reduced-motion: reduce) {
  .btn-scroll-up {
    transition: none;
  }
}

.btn-scroll-up:hover {
  opacity: 0.92;
}

.btn-scroll-up.scroll-btn-visible {
  transform: none;
}

@media (prefers-reduced-motion: reduce) {
  .btn-scroll-up {
    transition: none !important;
  }
}

.scroll-btn-observe {
  position: absolute;
  top: 90vh;
  bottom: auto;
  right: 0;
  height: 0;
  width: 0;
  visibility: hidden;
  pointer-events: none;
}

@media (min-width: 600px) {
  .scroll-btn-observe {
    top: 70vh;
  }
}

@media (min-width: 850px) {
  .scroll-btn-observe {
    top: 40vh;
  }
}

.navbar-fixed > .navbar-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
  .main-content .navbar-fixed {
    box-shadow: none;
  }
  .main-content .navbar-fixed .navbar-inner {
    left: 0;
    right: 0;
    width: auto;
  }
}

.main-content .navbar {
  z-index: 1018;
}

@media (max-width: 575.98px) {
  .navbar-fixed-sm > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-fixed-md > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-fixed-lg > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .main-content .navbar-fixed .navbar-inner {
    left: 16rem;
    left: var(--sidebar-width);
    transition: left 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .main-content .navbar-fixed .navbar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .container .main-content .navbar-fixed {
    direction: rtl;
  }
  .container .main-content .navbar-fixed .navbar-inner.container {
    left: auto;
    right: auto;
    width: 100%;
    direction: ltr;
    transition: padding-left 150ms;
    padding-left: 16rem;
    padding-left: var(--sidebar-width);
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .container .main-content .navbar-fixed .navbar-inner.container {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.collapsed ~ .main-content .navbar-fixed .navbar-inner {
    left: 5rem;
    left: var(--sidebar-collapsed-width);
  }
  .container .sidebar.collapsed ~ .main-content .navbar-fixed .navbar-inner.container {
    left: auto;
    right: auto;
    padding-left: 5rem;
    padding-left: var(--sidebar-collapsed-width);
  }
  .sidebar.collapsed.hideable ~ .main-content .navbar-fixed .navbar-inner {
    left: 0;
  }
  .container .sidebar.collapsed.hideable ~ .main-content .navbar-fixed .navbar-inner.container {
    padding-left: 0;
  }
}

@media (max-width: 1199.98px) {
  .main-content .navbar-fixed .navbar-inner,
  .container .main-content .navbar-fixed .navbar-inner {
    left: auto;
    right: auto;
    width: 100%;
  }
}

.sidebar-fixed {
  z-index: 1020;
}

.sidebar-fixed .sidebar-inner {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto;
}

.main-container .sidebar-fixed {
  z-index: 1019;
}

.main-content .sidebar-fixed {
  z-index: 1017;
}

@media (min-width: 1200px) {
  .navbar ~ .main-container .sidebar-fixed > .sidebar-inner {
    top: 4.5rem;
    top: var(--navbar-height);
  }
  .navbar-sm ~ .main-container .sidebar-fixed > .sidebar-inner {
    top: 4rem;
    top: var(--navbar-sm-height);
  }
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    z-index: 1020;
  }
  .navbar ~ .main-container .sidebar-fixed > .sidebar-inner,
  .navbar ~ .sidebar-fixed > .sidebar-inner {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
  .sidebar-fixed.sidebar-top > .sidebar-inner {
    top: 0 !important;
  }
  .sidebar-fixed.sidebar-top {
    z-index: 1022 !important;
  }
}

.content-nav {
  position: relative;
  min-height: 2.5rem;
  margin: 0.75rem 1rem;
  padding: 0 0.5rem;
  border-radius: 0.325rem;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
}

.breadcrumb-item {
  font-size: 0.95rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\203A";
  font-size: 1.25rem;
  vertical-align: baseline;
  line-height: 0;
  height: 0;
  opacity: 0.85;
}

.breadcrumb-item[class*="text-"]::before {
  color: inherit !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-header.card-header-sm {
  padding: 0.375rem 0.625rem;
}

.card-header.card-header-lg {
  padding: 1.125rem 1.25rem;
}

.card-title {
  color: #508dc1;
  flex-grow: 1;
  line-height: normal;
  margin-bottom: 0;
}

@keyframes cardFadeOutIn {
  0% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
}

.card-expanding {
  position: fixed;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .card-expanding {
    transition: none;
  }
}

.card-expanding.card-expand {
  animation-name: cardFadeOutIn;
  animation-duration: 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .card-expanding.card-expand {
    animation-duration: 0s;
  }
}

.card-expand {
  z-index: 1030;
  transition-delay: .1s;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-expand > .card-body {
  max-height: none !important;
}

.card-toolbar {
  align-self: flex-end;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 0.5rem;
}

.card-toolbar .card-toolbar-btn {
  opacity: 0.85;
  transition: all 0.15s;
  margin-left: 0.625rem;
  font-size: 1.075rem;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .card-toolbar .card-toolbar-btn {
    transition: none;
  }
}

.card-toolbar .card-toolbar-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.card-toolbar:not(.no-border)::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -0.125rem;
  bottom: -0.125rem;
  width: 0;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.card-toolbar[class*="brc-"]::before {
  border-left-color: inherit;
}

.card-toolbar + .card-toolbar {
  margin-left: 0.5rem;
}

.card-toolbar .collapsed[data-action="toggle"] [class*="-up"] {
  transform-origin: center 55%;
  transform: rotate(180deg);
}

.card-toolbar .collapsed[data-action="toggle"] [class*="-down"] {
  transform-origin: center;
  transform: rotate(180deg);
}

.card-header-sm .card-toolbar-btn {
  font-size: 1rem;
}

.card-header-lg .card-toolbar-btn {
  font-size: 1.125rem;
}

.card-body:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.bs-card-loading-overlay {
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: -1px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  border-radius: inherit;
}

.bs-card-loading-overlay .bs-card-loading-icon {
  position: relative;
  top: 25%;
}

.card-body.collapse:not(.show) ~ .bs-card-loading-overlay .bs-card-loading-icon {
  top: 10%;
}

.btn {
  cursor: pointer;
  border-radius: 0.175rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s, opacity 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn.btn-md {
  padding: 0.325rem 1rem 0.425rem;
  line-height: 1.75;
  font-size: 1.0625rem;
}

.btn.btn-md.btn-wide {
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn.btn-smd {
  padding: 0.325rem 0.75rem 0.425rem;
  line-height: 1.75;
  font-size: 0.95rem;
}

.btn.btn-smd.btn-wide {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.btn.btn-xs {
  padding: 0.25rem 0.5rem 0.275rem;
  line-height: 1.25;
  font-size: 0.9rem;
}

.btn.btn-xs.btn-wide {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn.btn-xlg {
  padding: 0.5rem 1rem 0.525rem;
  line-height: 1.875;
  font-size: 1.25rem;
}

.btn.btn-xlg.btn-wide {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.btn.btn-vlg {
  padding: 0.425rem 0.875rem 0.475rem;
  line-height: 1.625;
  font-size: 1.125rem;
}

.btn.btn-vlg.btn-wide {
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn.btn-wide {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn.btn-sm.btn-wide {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.btn.btn-lg.btn-wide {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

@media (hover: hover) {
  button.btn:active:not(.disabled):not(.static) {
    transform: translateY(2px);
  }
}

.btn:focus, .btn.focus,
.show > .btn.dropdown-toggle:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn[class*="btn-h-light"].fs--shadow:hover:focus,
.btn[class*="btn-h-light"]:not(:disabled):not(.disabled):active,
.btn[class*="btn-h-light"]:not(:disabled):not(.disabled).active,
.show > .btn[class*="btn-h-light"].dropdown-toggle {
  box-shadow: none;
}

.btn-default {
  color: #fff;
  background-color: #8bb4cd;
  border-color: #8bb4cd;
}

.btn-default:hover {
  color: #fff;
  background-color: #7ba8c3;
  border-color: #6ba0bf;
}

.btn-default:focus, .btn-default.focus {
  background-color: #7dabc7;
  border-color: #6ea2c0;
}

.btn-default:focus.fs--shadow, .btn-default.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.show > .btn.btn-default.dropdown-toggle, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active {
  background-color: #6f9fbb;
  border-color: #6099ba;
  color: #fff;
}

.btn-default.disabled, .btn-default:disabled {
  background-color: #8bb4cd;
  border-color: #8bb4cd;
}

.btn-light-default {
  color: #5491b5;
  background-color: #f1f6f9;
  border-color: #c9dce8;
}

.btn-light-default:hover {
  color: #4588af;
  background-color: #eaf2f7;
  border-color: #c0d8e6;
}

.btn-light-default:focus, .btn-light-default.focus {
  color: #4385ac;
  background-color: #e6f0f5;
}

.show > .btn.btn-light-default.dropdown-toggle, .btn-light-default:not(:disabled):not(.disabled):active, .btn-light-default:not(:disabled):not(.disabled).active {
  color: #3e7a9d;
  background-color: #d7e7ef;
  border-color: #aecddf;
}

.btn-lighter-default {
  color: #5491b5;
  background-color: #f8fbfc;
  border-color: #d0e1eb;
}

.btn-lighter-default:hover {
  color: #4588af;
  background-color: #f1f6f9;
  border-color: #c8dde9;
}

.btn-lighter-default:focus, .btn-lighter-default.focus {
  color: #4385ac;
  background-color: #edf4f8;
}

.show > .btn.btn-lighter-default.dropdown-toggle, .btn-lighter-default:not(:disabled):not(.disabled):active, .btn-lighter-default:not(:disabled):not(.disabled).active {
  color: #3e7a9d;
  background-color: #dfebf2;
  border-color: #b5d2e2;
}

.btn-outline-default {
  color: #588aa7;
  border-color: #a9bdc9;
  background-color: transparent;
}

.btn-outline-default:hover {
  color: #fff;
  background-color: #8bb4cd;
  border-color: #8bb4cd;
}

.btn-outline-default:focus.fs--shadow, .btn-outline-default.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.show > .btn.btn-outline-default.dropdown-toggle, .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #82aac0;
  border-color: #82aac0;
}

.show > .btn.btn-outline-default.dropdown-toggle.fs--shadow:focus, .btn-outline-default:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-default:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.btn-outline-default.disabled, .btn-outline-default:disabled {
  border-color: #a9bdc9;
}

.btn-primary {
  color: #fff;
  background-color: #5899ca;
  border-color: #5899ca;
}

.btn-primary:hover {
  color: #fff;
  background-color: #478dc1;
  border-color: #3b83b9;
}

.btn-primary:focus, .btn-primary.focus {
  background-color: #4990c5;
  border-color: #3c86bd;
}

.btn-primary:focus.fs--shadow, .btn-primary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.show > .btn.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #4082b4;
  border-color: #377bae;
  color: #fff;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #5899ca;
  border-color: #5899ca;
}

.btn-light-primary {
  color: #397fb3;
  background-color: #eff5fa;
  border-color: #bdd7ea;
}

.btn-light-primary:hover {
  color: #3074a8;
  background-color: #e7f1f8;
  border-color: #b3d2ea;
}

.btn-light-primary:focus, .btn-light-primary.focus {
  color: #2f72a4;
  background-color: #e3eef7;
}

.show > .btn.btn-light-primary.dropdown-toggle, .btn-light-primary:not(:disabled):not(.disabled):active, .btn-light-primary:not(:disabled):not(.disabled).active {
  color: #2a6794;
  background-color: #d3e5f3;
  border-color: #9fc7e4;
}

.btn-lighter-primary {
  color: #397fb3;
  background-color: #f7fafc;
  border-color: #c5dbed;
}

.btn-lighter-primary:hover {
  color: #3074a8;
  background-color: #eff5fa;
  border-color: #bbd7ec;
}

.btn-lighter-primary:focus, .btn-lighter-primary.focus {
  color: #2f72a4;
  background-color: #ebf3f9;
}

.show > .btn.btn-lighter-primary.dropdown-toggle, .btn-lighter-primary:not(:disabled):not(.disabled):active, .btn-lighter-primary:not(:disabled):not(.disabled).active {
  color: #2a6794;
  background-color: #dbeaf5;
  border-color: #a7cbe6;
}

.btn-outline-primary {
  color: #4887b7;
  border-color: #71a8d2;
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5899ca;
  border-color: #5899ca;
}

.btn-outline-primary:focus.fs--shadow, .btn-outline-primary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.show > .btn.btn-outline-primary.dropdown-toggle, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #5390be;
  border-color: #5390be;
}

.show > .btn.btn-outline-primary.dropdown-toggle.fs--shadow:focus, .btn-outline-primary:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-primary:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  border-color: #71a8d2;
}

.btn-secondary {
  color: #fff;
  background-color: #9aaab3;
  border-color: #9aaab3;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #8d9da6;
  border-color: #80949f;
}

.btn-secondary:focus, .btn-secondary.focus {
  background-color: #8ea1aa;
  border-color: #8297a1;
}

.btn-secondary:focus.fs--shadow, .btn-secondary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.show > .btn.btn-secondary.dropdown-toggle, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #84939a;
  border-color: #778d98;
  color: #fff;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #9aaab3;
  border-color: #9aaab3;
}

.btn-light-secondary {
  color: #728995;
  background-color: #f3f5f6;
  border-color: #d2dadd;
}

.btn-light-secondary:hover {
  color: #64818f;
  background-color: #edf1f2;
  border-color: #cbd5db;
}

.btn-light-secondary:focus, .btn-light-secondary.focus {
  color: #627e8c;
  background-color: #eaeef0;
}

.show > .btn.btn-light-secondary.dropdown-toggle, .btn-light-secondary:not(:disabled):not(.disabled):active, .btn-light-secondary:not(:disabled):not(.disabled).active {
  color: #597380;
  background-color: #dee5e8;
  border-color: #bcc9d0;
}

.btn-lighter-secondary {
  color: #728995;
  background-color: #f9fafb;
  border-color: #d8dfe2;
}

.btn-lighter-secondary:hover {
  color: #64818f;
  background-color: #f3f5f7;
  border-color: #d1dadf;
}

.btn-lighter-secondary:focus, .btn-lighter-secondary.focus {
  color: #627e8c;
  background-color: #f0f3f4;
}

.show > .btn.btn-lighter-secondary.dropdown-toggle, .btn-lighter-secondary:not(:disabled):not(.disabled):active, .btn-lighter-secondary:not(:disabled):not(.disabled).active {
  color: #597380;
  background-color: #e4e9ec;
  border-color: #c2ced4;
}

.btn-outline-secondary {
  color: #71858e;
  border-color: #a9b7be;
  background-color: transparent;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #9aaab3;
  border-color: #9aaab3;
}

.btn-outline-secondary:focus.fs--shadow, .btn-outline-secondary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.show > .btn.btn-outline-secondary.dropdown-toggle, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #90a0a8;
  border-color: #90a0a8;
}

.show > .btn.btn-outline-secondary.dropdown-toggle.fs--shadow:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  border-color: #a9b7be;
}

.btn-info {
  color: #fff;
  background-color: #57b5da;
  border-color: #57b5da;
}

.btn-info:hover {
  color: #fff;
  background-color: #44abd3;
  border-color: #31a4d2;
}

.btn-info:focus, .btn-info.focus {
  background-color: #46aed6;
  border-color: #36a6d3;
}

.btn-info:focus.fs--shadow, .btn-info.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.show > .btn.btn-info.dropdown-toggle, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
  background-color: #36a2cd;
  border-color: #2c9cc8;
  color: #fff;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #57b5da;
  border-color: #57b5da;
}

.btn-light-info {
  color: #2da1ce;
  background-color: #eff8fc;
  border-color: #bde2f0;
}

.btn-light-info:hover {
  color: #2596c2;
  background-color: #e6f5fa;
  border-color: #b2dff0;
}

.btn-light-info:focus, .btn-light-info.focus {
  color: #2492be;
  background-color: #e2f3f9;
}

.show > .btn.btn-light-info.dropdown-toggle, .btn-light-info:not(:disabled):not(.disabled):active, .btn-light-info:not(:disabled):not(.disabled).active {
  color: #2185ad;
  background-color: #d1ecf6;
  border-color: #9dd6ec;
}

.btn-lighter-info {
  color: #2da1ce;
  background-color: #f8fcfd;
  border-color: #c5e5f2;
}

.btn-lighter-info:hover {
  color: #2596c2;
  background-color: #eff8fc;
  border-color: #bbe2f2;
}

.btn-lighter-info:focus, .btn-lighter-info.focus {
  color: #2492be;
  background-color: #ebf6fb;
}

.show > .btn.btn-lighter-info.dropdown-toggle, .btn-lighter-info:not(:disabled):not(.disabled):active, .btn-lighter-info:not(:disabled):not(.disabled).active {
  color: #2185ad;
  background-color: #daeff8;
  border-color: #a6d9ee;
}

.btn-outline-info {
  color: #3c9dc3;
  border-color: #70c0e0;
  background-color: transparent;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #57b5da;
  border-color: #57b5da;
}

.btn-outline-info:focus.fs--shadow, .btn-outline-info.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.show > .btn.btn-outline-info.dropdown-toggle, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #52aacd;
  border-color: #52aacd;
}

.show > .btn.btn-outline-info.dropdown-toggle.fs--shadow:focus, .btn-outline-info:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-info:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  border-color: #70c0e0;
}

.btn-success {
  color: #fff;
  background-color: #73bd73;
  border-color: #73bd73;
}

.btn-success:hover {
  color: #fff;
  background-color: #64b364;
  border-color: #54ae54;
}

.btn-success:focus, .btn-success.focus {
  background-color: #65b665;
  border-color: #57b057;
}

.btn-success:focus.fs--shadow, .btn-success.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.show > .btn.btn-success.dropdown-toggle, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
  background-color: #59a959;
  border-color: #4ea54e;
  color: #fff;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #73bd73;
  border-color: #73bd73;
}

.btn-light-success {
  color: #50aa50;
  background-color: #f1f9f1;
  border-color: #c7e5c7;
}

.btn-light-success:hover {
  color: #45a145;
  background-color: #eaf6ea;
  border-color: #bee3be;
}

.btn-light-success:focus, .btn-light-success.focus {
  color: #449d44;
  background-color: #e6f4e6;
}

.show > .btn.btn-light-success.dropdown-toggle, .btn-light-success:not(:disabled):not(.disabled):active, .btn-light-success:not(:disabled):not(.disabled).active {
  color: #3d8f3d;
  background-color: #d8eed8;
  border-color: #addcad;
}

.btn-lighter-success {
  color: #50aa50;
  background-color: #f8fcf8;
  border-color: #cee8ce;
}

.btn-lighter-success:hover {
  color: #45a145;
  background-color: #f1f9f1;
  border-color: #c6e6c6;
}

.btn-lighter-success:focus, .btn-lighter-success.focus {
  color: #449d44;
  background-color: #edf7ed;
}

.show > .btn.btn-lighter-success.dropdown-toggle, .btn-lighter-success:not(:disabled):not(.disabled):active, .btn-lighter-success:not(:disabled):not(.disabled).active {
  color: #3d8f3d;
  background-color: #dff1df;
  border-color: #b4dfb4;
}

.btn-outline-success {
  color: #5aa55a;
  border-color: #88c788;
  background-color: transparent;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #73bd73;
  border-color: #73bd73;
}

.btn-outline-success:focus.fs--shadow, .btn-outline-success.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.show > .btn.btn-outline-success.dropdown-toggle, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #6cb26c;
  border-color: #6cb26c;
}

.show > .btn.btn-outline-success.dropdown-toggle.fs--shadow:focus, .btn-outline-success:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-success:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  border-color: #88c788;
}

.btn-danger {
  color: #fff;
  background-color: #dd6a57;
  border-color: #dd6a57;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d65944;
  border-color: #d54831;
}

.btn-danger:focus, .btn-danger.focus {
  background-color: #da5b46;
  border-color: #d64c35;
}

.btn-danger:focus.fs--shadow, .btn-danger.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.show > .btn.btn-danger.dropdown-toggle, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active {
  background-color: #d04c36;
  border-color: #cd412a;
  color: #fff;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dd6a57;
  border-color: #dd6a57;
}

.btn-light-danger {
  color: #d4432b;
  background-color: #fcf3f2;
  border-color: #f2c5be;
}

.btn-light-danger:hover {
  color: #c73a23;
  background-color: #fbebe9;
  border-color: #f2bdb4;
}

.btn-light-danger:focus, .btn-light-danger.focus {
  color: #c33922;
  background-color: #fae7e4;
}

.show > .btn.btn-light-danger.dropdown-toggle, .btn-light-danger:not(:disabled):not(.disabled):active, .btn-light-danger:not(:disabled):not(.disabled).active {
  color: #b2341f;
  background-color: #f7d8d3;
  border-color: #eea99e;
}

.btn-lighter-danger {
  color: #d4432b;
  background-color: #fefbfa;
  border-color: #f4cdc7;
}

.btn-lighter-danger:hover {
  color: #c73a23;
  background-color: #fdf3f1;
  border-color: #f3c4bd;
}

.btn-lighter-danger:focus, .btn-lighter-danger.focus {
  color: #c33922;
  background-color: #fcefed;
}

.show > .btn.btn-lighter-danger.dropdown-toggle, .btn-lighter-danger:not(:disabled):not(.disabled):active, .btn-lighter-danger:not(:disabled):not(.disabled).active {
  color: #b2341f;
  background-color: #f9e0dc;
  border-color: #f0b1a7;
}

.btn-outline-danger {
  color: #c54f3a;
  border-color: #e28070;
  background-color: transparent;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dd6a57;
  border-color: #dd6a57;
}

.btn-outline-danger:focus.fs--shadow, .btn-outline-danger.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.show > .btn.btn-outline-danger.dropdown-toggle, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #d06452;
  border-color: #d06452;
}

.show > .btn.btn-outline-danger.dropdown-toggle.fs--shadow:focus, .btn-outline-danger:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-danger:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  border-color: #e28070;
}

.btn-warning {
  color: #fff;
  background-color: #efae43;
  border-color: #efae43;
}

.btn-warning:hover {
  color: #fff;
  background-color: #eba32e;
  border-color: #eb9c19;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #eda630;
  border-color: #ec9e1d;
}

.btn-warning:focus.fs--shadow, .btn-warning.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.show > .btn.btn-warning.dropdown-toggle, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active {
  background-color: #e69b1e;
  border-color: #e29413;
  color: #fff;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #efae43;
  border-color: #efae43;
}

.btn-light-warning {
  color: #e99814;
  background-color: #fef8ee;
  border-color: #f9dfb5;
}

.btn-light-warning:hover {
  color: #da8d0e;
  background-color: #fdf4e4;
  border-color: #fadcaa;
}

.btn-light-warning:focus, .btn-light-warning.focus {
  color: #d68a0d;
  background-color: #fdf2df;
}

.show > .btn.btn-light-warning.dropdown-toggle, .btn-light-warning:not(:disabled):not(.disabled):active, .btn-light-warning:not(:disabled):not(.disabled).active {
  color: #c27e0c;
  background-color: #fceacc;
  border-color: #f8d292;
}

.btn-lighter-warning {
  color: #e99814;
  background-color: #fefcf8;
  border-color: #fae3bf;
}

.btn-lighter-warning:hover {
  color: #da8d0e;
  background-color: #fef8ee;
  border-color: #fae0b4;
}

.btn-lighter-warning:focus, .btn-lighter-warning.focus {
  color: #d68a0d;
  background-color: #fef6e9;
}

.show > .btn.btn-lighter-warning.dropdown-toggle, .btn-lighter-warning:not(:disabled):not(.disabled):active, .btn-lighter-warning:not(:disabled):not(.disabled).active {
  color: #c27e0c;
  background-color: #fceed6;
  border-color: #f9d69c;
}

.btn-outline-warning {
  color: #d89527;
  border-color: #f1ba5f;
  background-color: transparent;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #efae43;
  border-color: #efae43;
}

.btn-outline-warning:focus.fs--shadow, .btn-outline-warning.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.show > .btn.btn-outline-warning.dropdown-toggle, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #e1a43f;
  border-color: #e1a43f;
}

.show > .btn.btn-outline-warning.dropdown-toggle.fs--shadow:focus, .btn-outline-warning:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-warning:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  border-color: #f1ba5f;
}

.btn-dark {
  color: #fff;
  background-color: #515559;
  border-color: #515559;
}

.btn-dark:hover {
  color: #fff;
  background-color: #46484a;
  border-color: #3b3e41;
}

.btn-dark:focus, .btn-dark.focus {
  background-color: #474b4e;
  border-color: #3e4144;
}

.btn-dark:focus.fs--shadow, .btn-dark.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.show > .btn.btn-dark.dropdown-toggle, .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active {
  background-color: #3e3e3e;
  border-color: #343639;
  color: #fff;
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #515559;
  border-color: #515559;
}

.btn-light-dark {
  color: #373a3d;
  background-color: #edeeef;
  border-color: #b9bcbf;
}

.btn-light-dark:hover {
  color: #2c3034;
  background-color: #e7e9eb;
  border-color: #b1b7bd;
}

.btn-light-dark:focus, .btn-light-dark.focus {
  color: #2a2d31;
  background-color: #e4e7e9;
}

.show > .btn.btn-light-dark.dropdown-toggle, .btn-light-dark:not(:disabled):not(.disabled):active, .btn-light-dark:not(:disabled):not(.disabled).active {
  color: #202326;
  background-color: #d9dcdf;
  border-color: #a3aab2;
}

.btn-lighter-dark {
  color: #373a3d;
  background-color: #f3f3f4;
  border-color: #bec1c4;
}

.btn-lighter-dark:hover {
  color: #2c3034;
  background-color: #edeef0;
  border-color: #b6bcc2;
}

.btn-lighter-dark:focus, .btn-lighter-dark.focus {
  color: #2a2d31;
  background-color: #eaeced;
}

.show > .btn.btn-lighter-dark.dropdown-toggle, .btn-lighter-dark:not(:disabled):not(.disabled):active, .btn-lighter-dark:not(:disabled):not(.disabled).active {
  color: #202326;
  background-color: #dfe1e4;
  border-color: #a9b0b6;
}

.btn-outline-dark {
  color: #4b4e52;
  border-color: #6b6f72;
  background-color: transparent;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #515559;
  border-color: #515559;
}

.btn-outline-dark:focus.fs--shadow, .btn-outline-dark.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.show > .btn.btn-outline-dark.dropdown-toggle, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4c5054;
  border-color: #4c5054;
}

.show > .btn.btn-outline-dark.dropdown-toggle.fs--shadow:focus, .btn-outline-dark:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-dark:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  border-color: #6b6f72;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.btn-light:hover {
  color: #757984;
  background-color: #eaecf2;
  border-color: #c3c7d0;
}

.btn-light:focus, .btn-light.focus {
  background-color: #e8ebf1;
  border-color: #c6cad2;
}

.btn-light:focus.fs--shadow, .btn-light.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.show > .btn.btn-light.dropdown-toggle, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
  background-color: #dee1ea;
  border-color: #bbbfc9;
  color: #6c717f;
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.btn-light-light {
  color: #d4d8e5;
  background-color: white;
  border-color: #fbfbfc;
}

.btn-light-light:hover {
  color: #c5ccdf;
  background-color: #fafafa;
  border-color: #f4f5f9;
}

.btn-light-light:focus, .btn-light-light.focus {
  color: #c2c9dd;
  background-color: #f8f7f7;
}

.show > .btn.btn-light-light.dropdown-toggle, .btn-light-light:not(:disabled):not(.disabled):active, .btn-light-light:not(:disabled):not(.disabled).active {
  color: #b5bdd6;
  background-color: #eeecec;
  border-color: #e4e7f0;
}

.btn-lighter-light {
  color: #d4d8e5;
  background-color: white;
  border-color: white;
}

.btn-lighter-light:hover {
  color: #c5ccdf;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-lighter-light:focus, .btn-lighter-light.focus {
  color: #c2c9dd;
  background-color: #f8f7f7;
}

.show > .btn.btn-lighter-light.dropdown-toggle, .btn-lighter-light:not(:disabled):not(.disabled):active, .btn-lighter-light:not(:disabled):not(.disabled).active {
  color: #b5bdd6;
  background-color: #eeecec;
  border-color: #eeecec;
}

.btn-outline-light {
  color: #555;
  border-color: #e2e4e8;
  background-color: transparent;
}

.btn-outline-light:hover {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.btn-outline-light:focus.fs--shadow, .btn-outline-light.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.show > .btn.btn-outline-light.dropdown-toggle, .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active {
  color: #757984;
  background-color: #e6e7ea;
  border-color: #d0d2d6;
}

.show > .btn.btn-outline-light.dropdown-toggle.fs--shadow:focus, .btn-outline-light:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-light:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  border-color: #e2e4e8;
}

.btn-white {
  color: #606060;
  background-color: #fff;
  border-color: #eee;
}

.btn-white:hover {
  color: #606060;
  background-color: #fff;
  border-color: #d7d7d7;
}

.btn-white:focus, .btn-white.focus {
  background-color: whitesmoke;
  border-color: #dadada;
}

.btn-white:focus.fs--shadow, .btn-white.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.show > .btn.btn-white.dropdown-toggle, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active {
  background-color: #fff;
  border-color: #cfcfcf;
  color: #606060;
}

.btn-white.disabled, .btn-white:disabled {
  background-color: #fff;
  border-color: #eee;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-outline-white:hover {
  color: #606060;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus.fs--shadow, .btn-outline-white.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.show > .btn.btn-outline-white.dropdown-toggle, .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active {
  color: #606060;
  background-color: #f0f0f0;
  border-color: #fff;
}

.show > .btn.btn-outline-white.dropdown-toggle.fs--shadow:focus, .btn-outline-white:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-white:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  border-color: #fff;
}

.btn-black {
  color: #fff;
  background-color: #313539;
  border-color: #313539;
}

.btn-black:hover {
  color: #fff;
  background-color: #26282a;
  border-color: #1c1e20;
}

.btn-black:focus, .btn-black.focus {
  background-color: #282b2e;
  border-color: #1e2123;
}

.btn-black:focus.fs--shadow, .btn-black.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.show > .btn.btn-black.dropdown-toggle, .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active {
  background-color: #1d1e1f;
  border-color: #151618;
  color: #fff;
}

.btn-black.disabled, .btn-black:disabled {
  background-color: #313539;
  border-color: #313539;
}

.btn-light-black {
  color: #181a1c;
  background-color: #e7e9eb;
  border-color: #a9afb5;
}

.btn-light-black:hover {
  color: #0e1012;
  background-color: #e1e4e7;
  border-color: #a0aab3;
}

.btn-light-black:focus, .btn-light-black.focus {
  color: #0c0d0f;
  background-color: #dee1e5;
}

.show > .btn.btn-light-black.dropdown-toggle, .btn-light-black:not(:disabled):not(.disabled):active, .btn-light-black:not(:disabled):not(.disabled).active {
  color: #030304;
  background-color: #d2d7dc;
  border-color: #919da8;
}

.btn-lighter-black {
  color: #181a1c;
  background-color: #edeeef;
  border-color: #aeb4b9;
}

.btn-lighter-black:hover {
  color: #0e1012;
  background-color: #e6e9eb;
  border-color: #a5afb8;
}

.btn-lighter-black:focus, .btn-lighter-black.focus {
  color: #0c0d0f;
  background-color: #e4e6e9;
}

.show > .btn.btn-lighter-black.dropdown-toggle, .btn-lighter-black:not(:disabled):not(.disabled):active, .btn-lighter-black:not(:disabled):not(.disabled).active {
  color: #030304;
  background-color: #d8dce0;
  border-color: #97a2ad;
}

.btn-outline-black {
  color: #2d3134;
  border-color: #414549;
  background-color: transparent;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #313539;
  border-color: #313539;
}

.btn-outline-black:focus.fs--shadow, .btn-outline-black.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.show > .btn.btn-outline-black.dropdown-toggle, .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2e3236;
  border-color: #2e3236;
}

.show > .btn.btn-outline-black.dropdown-toggle.fs--shadow:focus, .btn-outline-black:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-black:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  border-color: #414549;
}

.btn-grey {
  color: #fff;
  background-color: #8d8f95;
  border-color: #8d8f95;
}

.btn-grey:hover {
  color: #fff;
  background-color: #828386;
  border-color: #76787e;
}

.btn-grey:focus, .btn-grey.focus {
  background-color: #82858b;
  border-color: #787a81;
}

.btn-grey:focus.fs--shadow, .btn-grey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.show > .btn.btn-grey.dropdown-toggle, .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active {
  background-color: #7a7a7a;
  border-color: #6e7077;
  color: #fff;
}

.btn-grey.disabled, .btn-grey:disabled {
  background-color: #8d8f95;
  border-color: #8d8f95;
}

.btn-light-grey {
  color: #72747b;
  background-color: #f4f4f5;
  border-color: #d2d3d5;
}

.btn-light-grey:hover {
  color: #646874;
  background-color: #eeeff1;
  border-color: #cbcdd2;
}

.btn-light-grey:focus, .btn-light-grey.focus {
  color: #616572;
  background-color: #ececee;
}

.show > .btn.btn-light-grey.dropdown-toggle, .btn-light-grey:not(:disabled):not(.disabled):active, .btn-light-grey:not(:disabled):not(.disabled).active {
  color: #585c67;
  background-color: #e1e2e5;
  border-color: #bdbfc6;
}

.btn-lighter-grey {
  color: #72747b;
  background-color: #fafafa;
  border-color: #d7d8da;
}

.btn-lighter-grey:hover {
  color: #646874;
  background-color: #f4f4f5;
  border-color: #d0d2d7;
}

.btn-lighter-grey:focus, .btn-lighter-grey.focus {
  color: #616572;
  background-color: #f1f2f3;
}

.show > .btn.btn-lighter-grey.dropdown-toggle, .btn-lighter-grey:not(:disabled):not(.disabled):active, .btn-lighter-grey:not(:disabled):not(.disabled).active {
  color: #585c67;
  background-color: #e6e7ea;
  border-color: #c3c5cb;
}

.btn-outline-grey {
  color: #666;
  border-color: #9ea0a5;
  background-color: transparent;
}

.btn-outline-grey:hover {
  color: #fff;
  background-color: #8d8f95;
  border-color: #8d8f95;
}

.btn-outline-grey:focus.fs--shadow, .btn-outline-grey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.show > .btn.btn-outline-grey.dropdown-toggle, .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #85868c;
  border-color: #85868c;
}

.show > .btn.btn-outline-grey.dropdown-toggle.fs--shadow:focus, .btn-outline-grey:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-grey:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.btn-outline-grey.disabled, .btn-outline-grey:disabled {
  border-color: #9ea0a5;
}

.btn-purple {
  color: #fff;
  background-color: #9382c0;
  border-color: #9382c0;
}

.btn-purple:hover {
  color: #fff;
  background-color: #8573b6;
  border-color: #7863b1;
}

.btn-purple:focus, .btn-purple.focus {
  background-color: #8774ba;
  border-color: #7b67b3;
}

.btn-purple:focus.fs--shadow, .btn-purple.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.show > .btn.btn-purple.dropdown-toggle, .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active {
  background-color: #7b68ac;
  border-color: #7059ac;
  color: #fff;
}

.btn-purple.disabled, .btn-purple:disabled {
  background-color: #9382c0;
  border-color: #9382c0;
}

.btn-light-purple {
  color: #6e57ab;
  background-color: #f4f2f9;
  border-color: #d2cbe5;
}

.btn-light-purple:hover {
  color: #634ba3;
  background-color: #eeebf6;
  border-color: #cbc2e3;
}

.btn-light-purple:focus, .btn-light-purple.focus {
  color: #6149a0;
  background-color: #ebe8f4;
}

.show > .btn.btn-light-purple.dropdown-toggle, .btn-light-purple:not(:disabled):not(.disabled):active, .btn-light-purple:not(:disabled):not(.disabled).active {
  color: #584392;
  background-color: #dfdaee;
  border-color: #bcb0db;
}

.btn-lighter-purple {
  color: #6e57ab;
  background-color: #faf9fc;
  border-color: #d8d1e8;
}

.btn-lighter-purple:hover {
  color: #634ba3;
  background-color: #f4f2f9;
  border-color: #d1c9e6;
}

.btn-lighter-purple:focus, .btn-lighter-purple.focus {
  color: #6149a0;
  background-color: #f1eff7;
}

.show > .btn.btn-lighter-purple.dropdown-toggle, .btn-lighter-purple:not(:disabled):not(.disabled):active, .btn-lighter-purple:not(:disabled):not(.disabled).active {
  color: #584392;
  background-color: #e5e1f1;
  border-color: #c2b7de;
}

.btn-outline-purple {
  color: #705da2;
  border-color: #a395ca;
  background-color: transparent;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #9382c0;
  border-color: #9382c0;
}

.btn-outline-purple:focus.fs--shadow, .btn-outline-purple.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.show > .btn.btn-outline-purple.dropdown-toggle, .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #8a7ab5;
  border-color: #8a7ab5;
}

.show > .btn.btn-outline-purple.dropdown-toggle.fs--shadow:focus, .btn-outline-purple:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-purple:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  border-color: #a395ca;
}

.btn-pink {
  color: #fff;
  background-color: #d65c8b;
  border-color: #d65c8b;
}

.btn-pink:hover {
  color: #fff;
  background-color: #cf4a7d;
  border-color: #cd3771;
}

.btn-pink:focus, .btn-pink.focus {
  background-color: #d24c7f;
  border-color: #ce3b74;
}

.btn-pink:focus.fs--shadow, .btn-pink.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.show > .btn.btn-pink.dropdown-toggle, .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active {
  background-color: #c83c72;
  border-color: #c4316a;
  color: #fff;
}

.btn-pink.disabled, .btn-pink:disabled {
  background-color: #d65c8b;
  border-color: #d65c8b;
}

.btn-light-pink {
  color: #ca336d;
  background-color: #fbf0f5;
  border-color: #efbfd1;
}

.btn-light-pink:hover {
  color: #be2a63;
  background-color: #fae8ef;
  border-color: #efb5cb;
}

.btn-light-pink:focus, .btn-light-pink.focus {
  color: #ba2961;
  background-color: #f9e4ec;
}

.show > .btn.btn-light-pink.dropdown-toggle, .btn-light-pink:not(:disabled):not(.disabled):active, .btn-light-pink:not(:disabled):not(.disabled).active {
  color: #a92558;
  background-color: #f5d3e0;
  border-color: #eaa0bd;
}

.btn-lighter-pink {
  color: #ca336d;
  background-color: #fdf9fa;
  border-color: #f1c7d7;
}

.btn-lighter-pink:hover {
  color: #be2a63;
  background-color: #fcf0f5;
  border-color: #f1bdd1;
}

.btn-lighter-pink:focus, .btn-lighter-pink.focus {
  color: #ba2961;
  background-color: #fbecf2;
}

.show > .btn.btn-lighter-pink.dropdown-toggle, .btn-lighter-pink:not(:disabled):not(.disabled):active, .btn-lighter-pink:not(:disabled):not(.disabled).active {
  color: #a92558;
  background-color: #f7dbe6;
  border-color: #eca9c2;
}

.btn-outline-pink {
  color: #be4171;
  border-color: #dc749c;
  background-color: transparent;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #d65c8b;
  border-color: #d65c8b;
}

.btn-outline-pink:focus.fs--shadow, .btn-outline-pink.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.show > .btn.btn-outline-pink.dropdown-toggle, .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #c95683;
  border-color: #c95683;
}

.show > .btn.btn-outline-pink.dropdown-toggle.fs--shadow:focus, .btn-outline-pink:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-pink:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  border-color: #dc749c;
}

.btn-yellow {
  color: #996633;
  background-color: #f4e47c;
  border-color: #f4d859;
}

.btn-yellow:hover {
  color: #996633;
  background-color: #f0de66;
  border-color: #f1ce2e;
}

.btn-yellow:focus, .btn-yellow.focus {
  background-color: #f2e069;
  border-color: #f1cf33;
}

.btn-yellow:focus.fs--shadow, .btn-yellow.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.show > .btn.btn-yellow.dropdown-toggle, .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active {
  background-color: #edd955;
  border-color: #f0cb20;
  color: #996633;
}

.btn-yellow.disabled, .btn-yellow:disabled {
  background-color: #f4e47c;
  border-color: #f4d859;
}

.btn-light-yellow {
  color: #f0da4b;
  background-color: #fefdf6;
  border-color: #fbf5cc;
}

.btn-light-yellow:hover {
  color: #f3d933;
  background-color: #fefcec;
  border-color: #fbf4c2;
}

.btn-light-yellow:focus, .btn-light-yellow.focus {
  color: #f2d82f;
  background-color: #fefbe7;
}

.show > .btn.btn-light-yellow.dropdown-toggle, .btn-light-yellow:not(:disabled):not(.disabled):active, .btn-light-yellow:not(:disabled):not(.disabled).active {
  color: #f1d51b;
  background-color: #fcf7d4;
  border-color: #faefaa;
}

.btn-lighter-yellow {
  color: #f0da4b;
  background-color: white;
  border-color: #fcf7d6;
}

.btn-lighter-yellow:hover {
  color: #f3d933;
  background-color: #fafafa;
  border-color: #fcf5cb;
}

.btn-lighter-yellow:focus, .btn-lighter-yellow.focus {
  color: #f2d82f;
  background-color: #f8f7f7;
}

.show > .btn.btn-lighter-yellow.dropdown-toggle, .btn-lighter-yellow:not(:disabled):not(.disabled):active, .btn-lighter-yellow:not(:disabled):not(.disabled).active {
  color: #f1d51b;
  background-color: #eeecec;
  border-color: #faf1b3;
}

.btn-outline-yellow {
  color: #b17131;
  border-color: #f6de72;
  background-color: transparent;
}

.btn-outline-yellow:hover {
  color: #996633;
  background-color: #f4e47c;
  border-color: #f4d859;
}

.btn-outline-yellow:focus.fs--shadow, .btn-outline-yellow.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.show > .btn.btn-outline-yellow.dropdown-toggle, .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active {
  color: #996633;
  background-color: #e5d675;
  border-color: #e5cb54;
}

.show > .btn.btn-outline-yellow.dropdown-toggle.fs--shadow:focus, .btn-outline-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  border-color: #f6de72;
}

.btn-lightgrey {
  color: #fff;
  background-color: #a9adb3;
  border-color: #a9adb3;
}

.btn-lightgrey:hover {
  color: #fff;
  background-color: #9da0a5;
  border-color: #91969d;
}

.btn-lightgrey:focus, .btn-lightgrey.focus {
  background-color: #9ea3a9;
  border-color: #9398a0;
}

.btn-lightgrey:focus.fs--shadow, .btn-lightgrey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.show > .btn.btn-lightgrey.dropdown-toggle, .btn-lightgrey:not(:disabled):not(.disabled):active, .btn-lightgrey:not(:disabled):not(.disabled).active {
  background-color: #959799;
  border-color: #898e96;
  color: #fff;
}

.btn-lightgrey.disabled, .btn-lightgrey:disabled {
  background-color: #a9adb3;
  border-color: #a9adb3;
}

.btn-light-lightgrey {
  color: #8d929a;
  background-color: #f6f7f7;
  border-color: #dcdee0;
}

.btn-light-lightgrey:hover {
  color: #7d8795;
  background-color: #f0f1f3;
  border-color: #d5d8dd;
}

.btn-light-lightgrey:focus, .btn-light-lightgrey.focus {
  color: #7a8493;
  background-color: #eeeff1;
}

.show > .btn.btn-light-lightgrey.dropdown-toggle, .btn-light-lightgrey:not(:disabled):not(.disabled):active, .btn-light-lightgrey:not(:disabled):not(.disabled).active {
  color: #707a89;
  background-color: #e2e4e8;
  border-color: #c7cbd1;
}

.btn-lighter-lightgrey {
  color: #8d929a;
  background-color: #fcfcfc;
  border-color: #e2e3e5;
}

.btn-lighter-lightgrey:hover {
  color: #7d8795;
  background-color: #f6f7f8;
  border-color: #dbdee2;
}

.btn-lighter-lightgrey:focus, .btn-lighter-lightgrey.focus {
  color: #7a8493;
  background-color: #f3f4f5;
}

.show > .btn.btn-lighter-lightgrey.dropdown-toggle, .btn-lighter-lightgrey:not(:disabled):not(.disabled):active, .btn-lighter-lightgrey:not(:disabled):not(.disabled).active {
  color: #707a89;
  background-color: #e8eaec;
  border-color: #cdd1d6;
}

.btn-outline-lightgrey {
  color: #7a8085;
  border-color: #d0d5da;
  background-color: transparent;
}

.btn-outline-lightgrey:hover {
  color: #fff;
  background-color: #a9adb3;
  border-color: #a9adb3;
}

.btn-outline-lightgrey:focus.fs--shadow, .btn-outline-lightgrey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.show > .btn.btn-outline-lightgrey.dropdown-toggle, .btn-outline-lightgrey:not(:disabled):not(.disabled):active, .btn-outline-lightgrey:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #9fa3a8;
  border-color: #9fa3a8;
}

.show > .btn.btn-outline-lightgrey.dropdown-toggle.fs--shadow:focus, .btn-outline-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.btn-outline-lightgrey.disabled, .btn-outline-lightgrey:disabled {
  border-color: #d0d5da;
}

.btn-brown {
  color: #fff;
  background-color: #c59c6b;
  border-color: #c59c6b;
}

.btn-brown:hover {
  color: #fff;
  background-color: #bc905b;
  border-color: #b8864a;
}

.btn-brown:focus, .btn-brown.focus {
  background-color: #bf925c;
  border-color: #ba884e;
}

.btn-brown:focus.fs--shadow, .btn-brown.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.show > .btn.btn-brown.dropdown-toggle, .btn-brown:not(:disabled):not(.disabled):active, .btn-brown:not(:disabled):not(.disabled).active {
  background-color: #b3854f;
  border-color: #ae7e44;
  color: #fff;
}

.btn-brown.disabled, .btn-brown:disabled {
  background-color: #c59c6b;
  border-color: #c59c6b;
}

.btn-light-brown {
  color: #b48247;
  background-color: #f9f5f1;
  border-color: #e8d8c4;
}

.btn-light-brown:hover {
  color: #aa783c;
  background-color: #f7f0e9;
  border-color: #e7d3bb;
}

.btn-light-brown:focus, .btn-light-brown.focus {
  color: #a6753b;
  background-color: #f6eee5;
}

.show > .btn.btn-light-brown.dropdown-toggle, .btn-light-brown:not(:disabled):not(.disabled):active, .btn-light-brown:not(:disabled):not(.disabled).active {
  color: #976b36;
  background-color: #f0e4d6;
  border-color: #e0c7a8;
}

.btn-lighter-brown {
  color: #b48247;
  background-color: #fcfaf8;
  border-color: #ebdccb;
}

.btn-lighter-brown:hover {
  color: #aa783c;
  background-color: #faf5f0;
  border-color: #ead8c2;
}

.btn-lighter-brown:focus, .btn-lighter-brown.focus {
  color: #a6753b;
  background-color: #f8f3ec;
}

.show > .btn.btn-lighter-brown.dropdown-toggle, .btn-lighter-brown:not(:disabled):not(.disabled):active, .btn-lighter-brown:not(:disabled):not(.disabled).active {
  color: #976b36;
  background-color: #f3e9dd;
  border-color: #e3cbb0;
}

.btn-outline-brown {
  color: #ad8452;
  border-color: #ceab81;
  background-color: transparent;
}

.btn-outline-brown:hover {
  color: #fff;
  background-color: #c59c6b;
  border-color: #c59c6b;
}

.btn-outline-brown:focus.fs--shadow, .btn-outline-brown.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.show > .btn.btn-outline-brown.dropdown-toggle, .btn-outline-brown:not(:disabled):not(.disabled):active, .btn-outline-brown:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #b99365;
  border-color: #b99365;
}

.show > .btn.btn-outline-brown.dropdown-toggle.fs--shadow:focus, .btn-outline-brown:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-brown:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.btn-outline-brown.disabled, .btn-outline-brown:disabled {
  border-color: #ceab81;
}

.btn-blue {
  color: #fff;
  background-color: #5cafe5;
  border-color: #5cafe5;
}

.btn-blue:hover {
  color: #fff;
  background-color: #48a4e0;
  border-color: #349cdf;
}

.btn-blue:focus, .btn-blue.focus {
  background-color: #4aa7e3;
  border-color: #399ee0;
}

.btn-blue:focus.fs--shadow, .btn-blue.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.show > .btn.btn-blue.dropdown-toggle, .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active {
  background-color: #399bda;
  border-color: #2795dd;
  color: #fff;
}

.btn-blue.disabled, .btn-blue:disabled {
  background-color: #5cafe5;
  border-color: #5cafe5;
}

.btn-light-blue {
  color: #2594dd;
  background-color: #eef7fc;
  border-color: #baddf4;
}

.btn-light-blue:hover {
  color: #1b8ad2;
  background-color: #e4f2fc;
  border-color: #b0d9f5;
}

.btn-light-blue:focus, .btn-light-blue.focus {
  color: #1b87cd;
  background-color: #e0f0fb;
}

.show > .btn.btn-light-blue.dropdown-toggle, .btn-light-blue:not(:disabled):not(.disabled):active, .btn-light-blue:not(:disabled):not(.disabled).active {
  color: #187bbb;
  background-color: #cee8f9;
  border-color: #99cff2;
}

.btn-lighter-blue {
  color: #2594dd;
  background-color: #f7fbfe;
  border-color: #c3e2f6;
}

.btn-lighter-blue:hover {
  color: #1b8ad2;
  background-color: #edf7fd;
  border-color: #b9def6;
}

.btn-lighter-blue:focus, .btn-lighter-blue.focus {
  color: #1b87cd;
  background-color: #e9f5fc;
}

.show > .btn.btn-lighter-blue.dropdown-toggle, .btn-lighter-blue:not(:disabled):not(.disabled):active, .btn-lighter-blue:not(:disabled):not(.disabled).active {
  color: #187bbb;
  background-color: #d7ecfa;
  border-color: #a2d3f3;
}

.btn-outline-blue {
  color: #3490cb;
  border-color: #74bbe9;
  background-color: transparent;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #5cafe5;
  border-color: #5cafe5;
}

.btn-outline-blue:focus.fs--shadow, .btn-outline-blue.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.show > .btn.btn-outline-blue.dropdown-toggle, .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #56a5d8;
  border-color: #56a5d8;
}

.show > .btn.btn-outline-blue.dropdown-toggle.fs--shadow:focus, .btn-outline-blue:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-blue:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  border-color: #74bbe9;
}

.btn-red {
  color: #fff;
  background-color: #ed6565;
  border-color: #ed6565;
}

.btn-red:hover {
  color: #fff;
  background-color: #e85050;
  border-color: #e83c3c;
}

.btn-red:focus, .btn-red.focus {
  background-color: #eb5353;
  border-color: #e94040;
}

.btn-red:focus.fs--shadow, .btn-red.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.show > .btn.btn-red.dropdown-toggle, .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active {
  background-color: #e44040;
  border-color: #e72e2e;
  color: #fff;
}

.btn-red.disabled, .btn-red:disabled {
  background-color: #ed6565;
  border-color: #ed6565;
}

.btn-light-red {
  color: #e73535;
  background-color: #fef3f3;
  border-color: #f8c4c4;
}

.btn-light-red:hover {
  color: #ea1e1e;
  background-color: #fdeaea;
  border-color: #f9b9b9;
}

.btn-light-red:focus, .btn-light-red.focus {
  color: #ea1919;
  background-color: #fde5e5;
}

.show > .btn.btn-light-red.dropdown-toggle, .btn-light-red:not(:disabled):not(.disabled):active, .btn-light-red:not(:disabled):not(.disabled).active {
  color: #da1414;
  background-color: #fbd2d2;
  border-color: #f6a2a2;
}

.btn-lighter-red {
  color: #e73535;
  background-color: #fffdfd;
  border-color: #f9cdcd;
}

.btn-lighter-red:hover {
  color: #ea1e1e;
  background-color: #fef3f3;
  border-color: #f9c2c2;
}

.btn-lighter-red:focus, .btn-lighter-red.focus {
  color: #ea1919;
  background-color: #fdeeee;
}

.show > .btn.btn-lighter-red.dropdown-toggle, .btn-lighter-red:not(:disabled):not(.disabled):active, .btn-lighter-red:not(:disabled):not(.disabled).active {
  color: #da1414;
  background-color: #fcdcdc;
  border-color: #f7abab;
}

.btn-outline-red {
  color: #d02f2f;
  border-color: #f07c7c;
  background-color: transparent;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #ed6565;
  border-color: #ed6565;
}

.btn-outline-red:focus.fs--shadow, .btn-outline-red.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.show > .btn.btn-outline-red.dropdown-toggle, .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #df5f5f;
  border-color: #df5f5f;
}

.show > .btn.btn-outline-red.dropdown-toggle.fs--shadow:focus, .btn-outline-red:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-red:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.btn-outline-red.disabled, .btn-outline-red:disabled {
  border-color: #f07c7c;
}

.btn-green {
  color: #fff;
  background-color: #62cb9f;
  border-color: #62cb9f;
}

.btn-green:hover {
  color: #fff;
  background-color: #51c293;
  border-color: #40c08a;
}

.btn-green:focus, .btn-green.focus {
  background-color: #53c696;
  border-color: #43c18c;
}

.btn-green:focus.fs--shadow, .btn-green.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.show > .btn.btn-green.dropdown-toggle, .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active {
  background-color: #45ba89;
  border-color: #3cb482;
  color: #fff;
}

.btn-green.disabled, .btn-green:disabled {
  background-color: #62cb9f;
  border-color: #62cb9f;
}

.btn-light-green {
  color: #3eba86;
  background-color: #eef9f4;
  border-color: #bfead8;
}

.btn-light-green:hover {
  color: #34af7b;
  background-color: #e5f7f0;
  border-color: #b6e9d4;
}

.btn-light-green:focus, .btn-light-green.focus {
  color: #33ab79;
  background-color: #e1f6ed;
}

.show > .btn.btn-light-green.dropdown-toggle, .btn-light-green:not(:disabled):not(.disabled):active, .btn-light-green:not(:disabled):not(.disabled).active {
  color: #2e9b6e;
  background-color: #d2f2e4;
  border-color: #a2e3c8;
}

.btn-lighter-green {
  color: #3eba86;
  background-color: #f5fcf9;
  border-color: #c7ecdd;
}

.btn-lighter-green:hover {
  color: #34af7b;
  background-color: #edfaf4;
  border-color: #bdecd8;
}

.btn-lighter-green:focus, .btn-lighter-green.focus {
  color: #33ab79;
  background-color: #e9f9f2;
}

.show > .btn.btn-lighter-green.dropdown-toggle, .btn-lighter-green:not(:disabled):not(.disabled):active, .btn-lighter-green:not(:disabled):not(.disabled).active {
  color: #2e9b6e;
  background-color: #d9f4e9;
  border-color: #aae6cd;
}

.btn-outline-green {
  color: #4ab588;
  border-color: #7ad3ad;
  background-color: transparent;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #62cb9f;
  border-color: #62cb9f;
}

.btn-outline-green:focus.fs--shadow, .btn-outline-green.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.show > .btn.btn-outline-green.dropdown-toggle, .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #5cbf95;
  border-color: #5cbf95;
}

.show > .btn.btn-outline-green.dropdown-toggle.fs--shadow:focus, .btn-outline-green:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-green:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  border-color: #7ad3ad;
}

.btn-h-default:hover {
  background-color: #7ba8c3;
  border-color: #6ba0bf;
  color: #fff;
}

.btn-h-default:hover.fs--shadow.focus, .btn-h-default:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.btn-h-outline-default:hover {
  color: #588aa7;
  border-color: #a9bdc9;
  background-color: transparent;
}

.btn-h-outline-default:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.btn-h-outline-default[class*="btn-outline-"]:hover {
  background-color: rgba(139, 180, 205, 0.05);
}

.btn-h-light-default:hover {
  color: #4588af;
  background-color: #eaf2f7;
  border-color: #c0d8e6;
}

.btn-h-lighter-default:hover {
  color: #4588af;
  background-color: #f1f6f9;
  border-color: #c8dde9;
}

.btn-h-primary:hover {
  background-color: #478dc1;
  border-color: #3b83b9;
  color: #fff;
}

.btn-h-primary:hover.fs--shadow.focus, .btn-h-primary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.btn-h-outline-primary:hover {
  color: #4887b7;
  border-color: #5899ca;
  background-color: transparent;
}

.btn-h-outline-primary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.btn-h-outline-primary[class*="btn-outline-"]:hover {
  background-color: rgba(88, 153, 202, 0.05);
}

.btn-h-light-primary:hover {
  color: #3074a8;
  background-color: #e7f1f8;
  border-color: #b3d2ea;
}

.btn-h-lighter-primary:hover {
  color: #3074a8;
  background-color: #eff5fa;
  border-color: #bbd7ec;
}

.btn-h-secondary:hover {
  background-color: #8d9da6;
  border-color: #80949f;
  color: #fff;
}

.btn-h-secondary:hover.fs--shadow.focus, .btn-h-secondary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.btn-h-outline-secondary:hover {
  color: #71858e;
  border-color: #9aaab3;
  background-color: transparent;
}

.btn-h-outline-secondary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.btn-h-outline-secondary[class*="btn-outline-"]:hover {
  background-color: rgba(154, 170, 179, 0.05);
}

.btn-h-light-secondary:hover {
  color: #64818f;
  background-color: #edf1f2;
  border-color: #cbd5db;
}

.btn-h-lighter-secondary:hover {
  color: #64818f;
  background-color: #f3f5f7;
  border-color: #d1dadf;
}

.btn-h-info:hover {
  background-color: #44abd3;
  border-color: #31a4d2;
  color: #fff;
}

.btn-h-info:hover.fs--shadow.focus, .btn-h-info:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.btn-h-outline-info:hover {
  color: #3c9dc3;
  border-color: #57b5da;
  background-color: transparent;
}

.btn-h-outline-info:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.btn-h-outline-info[class*="btn-outline-"]:hover {
  background-color: rgba(87, 181, 218, 0.05);
}

.btn-h-light-info:hover {
  color: #2596c2;
  background-color: #e6f5fa;
  border-color: #b2dff0;
}

.btn-h-lighter-info:hover {
  color: #2596c2;
  background-color: #eff8fc;
  border-color: #bbe2f2;
}

.btn-h-success:hover {
  background-color: #64b364;
  border-color: #54ae54;
  color: #fff;
}

.btn-h-success:hover.fs--shadow.focus, .btn-h-success:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.btn-h-outline-success:hover {
  color: #5aa55a;
  border-color: #73bd73;
  background-color: transparent;
}

.btn-h-outline-success:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.btn-h-outline-success[class*="btn-outline-"]:hover {
  background-color: rgba(115, 189, 115, 0.05);
}

.btn-h-light-success:hover {
  color: #45a145;
  background-color: #eaf6ea;
  border-color: #bee3be;
}

.btn-h-lighter-success:hover {
  color: #45a145;
  background-color: #f1f9f1;
  border-color: #c6e6c6;
}

.btn-h-danger:hover {
  background-color: #d65944;
  border-color: #d54831;
  color: #fff;
}

.btn-h-danger:hover.fs--shadow.focus, .btn-h-danger:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.btn-h-outline-danger:hover {
  color: #c54f3a;
  border-color: #dd6a57;
  background-color: transparent;
}

.btn-h-outline-danger:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.btn-h-outline-danger[class*="btn-outline-"]:hover {
  background-color: rgba(221, 106, 87, 0.05);
}

.btn-h-light-danger:hover {
  color: #c73a23;
  background-color: #fbebe9;
  border-color: #f2bdb4;
}

.btn-h-lighter-danger:hover {
  color: #c73a23;
  background-color: #fdf3f1;
  border-color: #f3c4bd;
}

.btn-h-warning:hover {
  background-color: #eba32e;
  border-color: #eb9c19;
  color: #fff;
}

.btn-h-warning:hover.fs--shadow.focus, .btn-h-warning:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.btn-h-outline-warning:hover {
  color: #d89527;
  border-color: #efae43;
  background-color: transparent;
}

.btn-h-outline-warning:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.btn-h-outline-warning[class*="btn-outline-"]:hover {
  background-color: rgba(239, 174, 67, 0.05);
}

.btn-h-light-warning:hover {
  color: #da8d0e;
  background-color: #fdf4e4;
  border-color: #fadcaa;
}

.btn-h-lighter-warning:hover {
  color: #da8d0e;
  background-color: #fef8ee;
  border-color: #fae0b4;
}

.btn-h-dark:hover {
  background-color: #46484a;
  border-color: #3b3e41;
  color: #fff;
}

.btn-h-dark:hover.fs--shadow.focus, .btn-h-dark:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.btn-h-outline-dark:hover {
  color: #4b4e52;
  border-color: #515559;
  background-color: transparent;
}

.btn-h-outline-dark:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.btn-h-outline-dark[class*="btn-outline-"]:hover {
  background-color: rgba(81, 85, 89, 0.05);
}

.btn-h-light-dark:hover {
  color: #2c3034;
  background-color: #e7e9eb;
  border-color: #b1b7bd;
}

.btn-h-lighter-dark:hover {
  color: #2c3034;
  background-color: #edeef0;
  border-color: #b6bcc2;
}

.btn-h-light:hover {
  background-color: #eaecf2;
  border-color: #c3c7d0;
  color: #757984;
}

.btn-h-light:hover.fs--shadow.focus, .btn-h-light:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.btn-h-outline-light:hover {
  color: #555;
  border-color: #dddfe4;
  background-color: transparent;
}

.btn-h-outline-light:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.btn-h-outline-light[class*="btn-outline-"]:hover {
  background-color: rgba(245, 246, 249, 0.05);
}

.btn-h-light-light:hover {
  color: #c5ccdf;
  background-color: #fafafa;
  border-color: #f4f5f9;
}

.btn-h-lighter-light:hover {
  color: #c5ccdf;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-h-white:hover {
  background-color: #fff;
  border-color: #d7d7d7;
  color: #606060;
}

.btn-h-white:hover.fs--shadow.focus, .btn-h-white:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.btn-h-outline-white:hover {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-h-outline-white:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.btn-h-outline-white[class*="btn-outline-"]:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.btn-h-black:hover {
  background-color: #26282a;
  border-color: #1c1e20;
  color: #fff;
}

.btn-h-black:hover.fs--shadow.focus, .btn-h-black:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.btn-h-outline-black:hover {
  color: #2d3134;
  border-color: #414549;
  background-color: transparent;
}

.btn-h-outline-black:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.btn-h-outline-black[class*="btn-outline-"]:hover {
  background-color: rgba(49, 53, 57, 0.05);
}

.btn-h-light-black:hover {
  color: #0e1012;
  background-color: #e1e4e7;
  border-color: #a0aab3;
}

.btn-h-lighter-black:hover {
  color: #0e1012;
  background-color: #e6e9eb;
  border-color: #a5afb8;
}

.btn-h-grey:hover {
  background-color: #828386;
  border-color: #76787e;
  color: #fff;
}

.btn-h-grey:hover.fs--shadow.focus, .btn-h-grey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.btn-h-outline-grey:hover {
  color: #666;
  border-color: #8d8f95;
  background-color: transparent;
}

.btn-h-outline-grey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.btn-h-outline-grey[class*="btn-outline-"]:hover {
  background-color: rgba(141, 143, 149, 0.05);
}

.btn-h-light-grey:hover {
  color: #646874;
  background-color: #eeeff1;
  border-color: #cbcdd2;
}

.btn-h-lighter-grey:hover {
  color: #646874;
  background-color: #f4f4f5;
  border-color: #d0d2d7;
}

.btn-h-purple:hover {
  background-color: #8573b6;
  border-color: #7863b1;
  color: #fff;
}

.btn-h-purple:hover.fs--shadow.focus, .btn-h-purple:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.btn-h-outline-purple:hover {
  color: #705da2;
  border-color: #9382c0;
  background-color: transparent;
}

.btn-h-outline-purple:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.btn-h-outline-purple[class*="btn-outline-"]:hover {
  background-color: rgba(147, 130, 192, 0.05);
}

.btn-h-light-purple:hover {
  color: #634ba3;
  background-color: #eeebf6;
  border-color: #cbc2e3;
}

.btn-h-lighter-purple:hover {
  color: #634ba3;
  background-color: #f4f2f9;
  border-color: #d1c9e6;
}

.btn-h-pink:hover {
  background-color: #cf4a7d;
  border-color: #cd3771;
  color: #fff;
}

.btn-h-pink:hover.fs--shadow.focus, .btn-h-pink:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.btn-h-outline-pink:hover {
  color: #be4171;
  border-color: #d65c8b;
  background-color: transparent;
}

.btn-h-outline-pink:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.btn-h-outline-pink[class*="btn-outline-"]:hover {
  background-color: rgba(214, 92, 139, 0.05);
}

.btn-h-light-pink:hover {
  color: #be2a63;
  background-color: #fae8ef;
  border-color: #efb5cb;
}

.btn-h-lighter-pink:hover {
  color: #be2a63;
  background-color: #fcf0f5;
  border-color: #f1bdd1;
}

.btn-h-yellow:hover {
  background-color: #f0de66;
  border-color: #f1ce2e;
  color: #996633;
}

.btn-h-yellow:hover.fs--shadow.focus, .btn-h-yellow:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.btn-h-outline-yellow:hover {
  color: #b17131;
  border-color: #f4d859;
  background-color: transparent;
}

.btn-h-outline-yellow:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.btn-h-outline-yellow[class*="btn-outline-"]:hover {
  background-color: rgba(244, 228, 124, 0.05);
}

.btn-h-light-yellow:hover {
  color: #f3d933;
  background-color: #fefcec;
  border-color: #fbf4c2;
}

.btn-h-lighter-yellow:hover {
  color: #f3d933;
  background-color: #fafafa;
  border-color: #fcf5cb;
}

.btn-h-lightgrey:hover {
  background-color: #9da0a5;
  border-color: #91969d;
  color: #fff;
}

.btn-h-lightgrey:hover.fs--shadow.focus, .btn-h-lightgrey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.btn-h-outline-lightgrey:hover {
  color: #7a8085;
  border-color: #d0d5da;
  background-color: transparent;
}

.btn-h-outline-lightgrey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.btn-h-outline-lightgrey[class*="btn-outline-"]:hover {
  background-color: rgba(169, 173, 179, 0.05);
}

.btn-h-light-lightgrey:hover {
  color: #7d8795;
  background-color: #f0f1f3;
  border-color: #d5d8dd;
}

.btn-h-lighter-lightgrey:hover {
  color: #7d8795;
  background-color: #f6f7f8;
  border-color: #dbdee2;
}

.btn-h-brown:hover {
  background-color: #bc905b;
  border-color: #b8864a;
  color: #fff;
}

.btn-h-brown:hover.fs--shadow.focus, .btn-h-brown:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.btn-h-outline-brown:hover {
  color: #ad8452;
  border-color: #c59c6b;
  background-color: transparent;
}

.btn-h-outline-brown:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.btn-h-outline-brown[class*="btn-outline-"]:hover {
  background-color: rgba(197, 156, 107, 0.05);
}

.btn-h-light-brown:hover {
  color: #aa783c;
  background-color: #f7f0e9;
  border-color: #e7d3bb;
}

.btn-h-lighter-brown:hover {
  color: #aa783c;
  background-color: #faf5f0;
  border-color: #ead8c2;
}

.btn-h-blue:hover {
  background-color: #48a4e0;
  border-color: #349cdf;
  color: #fff;
}

.btn-h-blue:hover.fs--shadow.focus, .btn-h-blue:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.btn-h-outline-blue:hover {
  color: #3490cb;
  border-color: #5cafe5;
  background-color: transparent;
}

.btn-h-outline-blue:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.btn-h-outline-blue[class*="btn-outline-"]:hover {
  background-color: rgba(92, 175, 229, 0.05);
}

.btn-h-light-blue:hover {
  color: #1b8ad2;
  background-color: #e4f2fc;
  border-color: #b0d9f5;
}

.btn-h-lighter-blue:hover {
  color: #1b8ad2;
  background-color: #edf7fd;
  border-color: #b9def6;
}

.btn-h-red:hover {
  background-color: #e85050;
  border-color: #e83c3c;
  color: #fff;
}

.btn-h-red:hover.fs--shadow.focus, .btn-h-red:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.btn-h-outline-red:hover {
  color: #d02f2f;
  border-color: #ed6565;
  background-color: transparent;
}

.btn-h-outline-red:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.btn-h-outline-red[class*="btn-outline-"]:hover {
  background-color: rgba(237, 101, 101, 0.05);
}

.btn-h-light-red:hover {
  color: #ea1e1e;
  background-color: #fdeaea;
  border-color: #f9b9b9;
}

.btn-h-lighter-red:hover {
  color: #ea1e1e;
  background-color: #fef3f3;
  border-color: #f9c2c2;
}

.btn-h-green:hover {
  background-color: #51c293;
  border-color: #40c08a;
  color: #fff;
}

.btn-h-green:hover.fs--shadow.focus, .btn-h-green:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.btn-h-outline-green:hover {
  color: #4ab588;
  border-color: #62cb9f;
  background-color: transparent;
}

.btn-h-outline-green:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.btn-h-outline-green[class*="btn-outline-"]:hover {
  background-color: rgba(98, 203, 159, 0.05);
}

.btn-h-light-green:hover {
  color: #34af7b;
  background-color: #e5f7f0;
  border-color: #b6e9d4;
}

.btn-h-lighter-green:hover {
  color: #34af7b;
  background-color: #edfaf4;
  border-color: #bdecd8;
}

.btn-a-default:not(:disabled):not(.disabled):active,
.btn-a-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-default.dropdown-toggle {
  color: #fff;
  background-color: #7ba8c3;
  border-color: #6ba0bf;
}

.btn-a-default:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-default:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-default.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.btn-a-outline-default:not(:disabled):not(.disabled):active,
.btn-a-outline-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-default.dropdown-toggle {
  color: #54839f;
  border-color: #a9bdc9;
  background-color: rgba(139, 180, 205, 0.05);
}

.btn-a-outline-default:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-default:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-default.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 180, 205, 0.45);
}

.show > .btn.btn-a-light-default.dropdown-toggle, .btn-a-light-default:not(:disabled):not(.disabled):active, .btn-a-light-default:not(:disabled):not(.disabled).active {
  color: #3e7a9d;
  background-color: #d7e7ef;
  border-color: #aecddf;
}

.show > .btn.btn-a-lighter-default.dropdown-toggle, .btn-a-lighter-default:not(:disabled):not(.disabled):active, .btn-a-lighter-default:not(:disabled):not(.disabled).active {
  color: #3e7a9d;
  background-color: #dfebf2;
  border-color: #b5d2e2;
}

.btn-a-primary:not(:disabled):not(.disabled):active,
.btn-a-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-primary.dropdown-toggle {
  color: #fff;
  background-color: #478dc1;
  border-color: #3b83b9;
}

.btn-a-primary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-primary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-primary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active,
.btn-a-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-primary.dropdown-toggle {
  color: #4581ad;
  border-color: #5899ca;
  background-color: rgba(88, 153, 202, 0.05);
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-primary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-primary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(88, 153, 202, 0.45);
}

.show > .btn.btn-a-light-primary.dropdown-toggle, .btn-a-light-primary:not(:disabled):not(.disabled):active, .btn-a-light-primary:not(:disabled):not(.disabled).active {
  color: #2a6794;
  background-color: #d3e5f3;
  border-color: #9fc7e4;
}

.show > .btn.btn-a-lighter-primary.dropdown-toggle, .btn-a-lighter-primary:not(:disabled):not(.disabled):active, .btn-a-lighter-primary:not(:disabled):not(.disabled).active {
  color: #2a6794;
  background-color: #dbeaf5;
  border-color: #a7cbe6;
}

.btn-a-secondary:not(:disabled):not(.disabled):active,
.btn-a-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-secondary.dropdown-toggle {
  color: #fff;
  background-color: #8d9da6;
  border-color: #80949f;
}

.btn-a-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-secondary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.btn-a-outline-secondary:not(:disabled):not(.disabled):active,
.btn-a-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-secondary.dropdown-toggle {
  color: #6c7e87;
  border-color: #9aaab3;
  background-color: rgba(154, 170, 179, 0.05);
}

.btn-a-outline-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-secondary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(154, 170, 179, 0.45);
}

.show > .btn.btn-a-light-secondary.dropdown-toggle, .btn-a-light-secondary:not(:disabled):not(.disabled):active, .btn-a-light-secondary:not(:disabled):not(.disabled).active {
  color: #597380;
  background-color: #dee5e8;
  border-color: #bcc9d0;
}

.show > .btn.btn-a-lighter-secondary.dropdown-toggle, .btn-a-lighter-secondary:not(:disabled):not(.disabled):active, .btn-a-lighter-secondary:not(:disabled):not(.disabled).active {
  color: #597380;
  background-color: #e4e9ec;
  border-color: #c2ced4;
}

.btn-a-info:not(:disabled):not(.disabled):active,
.btn-a-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-info.dropdown-toggle {
  color: #fff;
  background-color: #44abd3;
  border-color: #31a4d2;
}

.btn-a-info:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-info:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-info.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.btn-a-outline-info:not(:disabled):not(.disabled):active,
.btn-a-outline-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-info.dropdown-toggle {
  color: #3995b9;
  border-color: #57b5da;
  background-color: rgba(87, 181, 218, 0.05);
}

.btn-a-outline-info:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-info:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-info.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(87, 181, 218, 0.45);
}

.show > .btn.btn-a-light-info.dropdown-toggle, .btn-a-light-info:not(:disabled):not(.disabled):active, .btn-a-light-info:not(:disabled):not(.disabled).active {
  color: #2185ad;
  background-color: #d1ecf6;
  border-color: #9dd6ec;
}

.show > .btn.btn-a-lighter-info.dropdown-toggle, .btn-a-lighter-info:not(:disabled):not(.disabled):active, .btn-a-lighter-info:not(:disabled):not(.disabled).active {
  color: #2185ad;
  background-color: #daeff8;
  border-color: #a6d9ee;
}

.btn-a-success:not(:disabled):not(.disabled):active,
.btn-a-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-success.dropdown-toggle {
  color: #fff;
  background-color: #64b364;
  border-color: #54ae54;
}

.btn-a-success:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-success:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-success.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.btn-a-outline-success:not(:disabled):not(.disabled):active,
.btn-a-outline-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-success.dropdown-toggle {
  color: #559d55;
  border-color: #73bd73;
  background-color: rgba(115, 189, 115, 0.05);
}

.btn-a-outline-success:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-success:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-success.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(115, 189, 115, 0.45);
}

.show > .btn.btn-a-light-success.dropdown-toggle, .btn-a-light-success:not(:disabled):not(.disabled):active, .btn-a-light-success:not(:disabled):not(.disabled).active {
  color: #3d8f3d;
  background-color: #d8eed8;
  border-color: #addcad;
}

.show > .btn.btn-a-lighter-success.dropdown-toggle, .btn-a-lighter-success:not(:disabled):not(.disabled):active, .btn-a-lighter-success:not(:disabled):not(.disabled).active {
  color: #3d8f3d;
  background-color: #dff1df;
  border-color: #b4dfb4;
}

.btn-a-danger:not(:disabled):not(.disabled):active,
.btn-a-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-danger.dropdown-toggle {
  color: #fff;
  background-color: #d65944;
  border-color: #d54831;
}

.btn-a-danger:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-danger:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-danger.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.btn-a-outline-danger:not(:disabled):not(.disabled):active,
.btn-a-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-danger.dropdown-toggle {
  color: #bb4b37;
  border-color: #dd6a57;
  background-color: rgba(221, 106, 87, 0.05);
}

.btn-a-outline-danger:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-danger:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-danger.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 106, 87, 0.45);
}

.show > .btn.btn-a-light-danger.dropdown-toggle, .btn-a-light-danger:not(:disabled):not(.disabled):active, .btn-a-light-danger:not(:disabled):not(.disabled).active {
  color: #b2341f;
  background-color: #f7d8d3;
  border-color: #eea99e;
}

.show > .btn.btn-a-lighter-danger.dropdown-toggle, .btn-a-lighter-danger:not(:disabled):not(.disabled):active, .btn-a-lighter-danger:not(:disabled):not(.disabled).active {
  color: #b2341f;
  background-color: #f9e0dc;
  border-color: #f0b1a7;
}

.btn-a-warning:not(:disabled):not(.disabled):active,
.btn-a-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-warning.dropdown-toggle {
  color: #fff;
  background-color: #eba32e;
  border-color: #eb9c19;
}

.btn-a-warning:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-warning:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-warning.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.btn-a-outline-warning:not(:disabled):not(.disabled):active,
.btn-a-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-warning.dropdown-toggle {
  color: #cd8d25;
  border-color: #efae43;
  background-color: rgba(239, 174, 67, 0.05);
}

.btn-a-outline-warning:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-warning:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-warning.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(239, 174, 67, 0.45);
}

.show > .btn.btn-a-light-warning.dropdown-toggle, .btn-a-light-warning:not(:disabled):not(.disabled):active, .btn-a-light-warning:not(:disabled):not(.disabled).active {
  color: #c27e0c;
  background-color: #fceacc;
  border-color: #f8d292;
}

.show > .btn.btn-a-lighter-warning.dropdown-toggle, .btn-a-lighter-warning:not(:disabled):not(.disabled):active, .btn-a-lighter-warning:not(:disabled):not(.disabled).active {
  color: #c27e0c;
  background-color: #fceed6;
  border-color: #f9d69c;
}

.btn-a-dark:not(:disabled):not(.disabled):active,
.btn-a-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-dark.dropdown-toggle {
  color: #fff;
  background-color: #46484a;
  border-color: #3b3e41;
}

.btn-a-dark:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-dark:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-dark.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.btn-a-outline-dark:not(:disabled):not(.disabled):active,
.btn-a-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-dark.dropdown-toggle {
  color: #474a4e;
  border-color: #515559;
  background-color: rgba(81, 85, 89, 0.05);
}

.btn-a-outline-dark:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-dark:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-dark.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 85, 89, 0.45);
}

.show > .btn.btn-a-light-dark.dropdown-toggle, .btn-a-light-dark:not(:disabled):not(.disabled):active, .btn-a-light-dark:not(:disabled):not(.disabled).active {
  color: #202326;
  background-color: #d9dcdf;
  border-color: #a3aab2;
}

.show > .btn.btn-a-lighter-dark.dropdown-toggle, .btn-a-lighter-dark:not(:disabled):not(.disabled):active, .btn-a-lighter-dark:not(:disabled):not(.disabled).active {
  color: #202326;
  background-color: #dfe1e4;
  border-color: #a9b0b6;
}

.btn-a-light:not(:disabled):not(.disabled):active,
.btn-a-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-light.dropdown-toggle {
  color: #757984;
  background-color: #eaecf2;
  border-color: #c3c7d0;
}

.btn-a-light:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-light:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-light.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.btn-a-outline-light:not(:disabled):not(.disabled):active,
.btn-a-outline-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-light.dropdown-toggle {
  color: #515151;
  border-color: #dddfe4;
  background-color: rgba(245, 246, 249, 0.05);
}

.btn-a-outline-light:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-light:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-light.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 223, 228, 0.45);
}

.show > .btn.btn-a-light-light.dropdown-toggle, .btn-a-light-light:not(:disabled):not(.disabled):active, .btn-a-light-light:not(:disabled):not(.disabled).active {
  color: #b5bdd6;
  background-color: #eeecec;
  border-color: #e4e7f0;
}

.show > .btn.btn-a-lighter-light.dropdown-toggle, .btn-a-lighter-light:not(:disabled):not(.disabled):active, .btn-a-lighter-light:not(:disabled):not(.disabled).active {
  color: #b5bdd6;
  background-color: #eeecec;
  border-color: #eeecec;
}

.btn-a-white:not(:disabled):not(.disabled):active,
.btn-a-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-white.dropdown-toggle {
  color: #606060;
  background-color: #fff;
  border-color: #d7d7d7;
}

.btn-a-white:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-white:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-white.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.btn-a-outline-white:not(:disabled):not(.disabled):active,
.btn-a-outline-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-white.dropdown-toggle {
  color: #f2f2f2;
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
}

.btn-a-outline-white:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-white:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-white.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 238, 238, 0.45);
}

.btn-a-black:not(:disabled):not(.disabled):active,
.btn-a-black:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-black.dropdown-toggle {
  color: #fff;
  background-color: #26282a;
  border-color: #1c1e20;
}

.btn-a-black:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-black:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-black.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.btn-a-outline-black:not(:disabled):not(.disabled):active,
.btn-a-outline-black:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-black.dropdown-toggle {
  color: #2b2f31;
  border-color: #414549;
  background-color: rgba(49, 53, 57, 0.05);
}

.btn-a-outline-black:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-black:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-black.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(49, 53, 57, 0.45);
}

.show > .btn.btn-a-light-black.dropdown-toggle, .btn-a-light-black:not(:disabled):not(.disabled):active, .btn-a-light-black:not(:disabled):not(.disabled).active {
  color: #030304;
  background-color: #d2d7dc;
  border-color: #919da8;
}

.show > .btn.btn-a-lighter-black.dropdown-toggle, .btn-a-lighter-black:not(:disabled):not(.disabled):active, .btn-a-lighter-black:not(:disabled):not(.disabled).active {
  color: #030304;
  background-color: #d8dce0;
  border-color: #97a2ad;
}

.btn-a-grey:not(:disabled):not(.disabled):active,
.btn-a-grey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-grey.dropdown-toggle {
  color: #fff;
  background-color: #828386;
  border-color: #76787e;
}

.btn-a-grey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-grey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-grey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.btn-a-outline-grey:not(:disabled):not(.disabled):active,
.btn-a-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-grey.dropdown-toggle {
  color: #616161;
  border-color: #8d8f95;
  background-color: rgba(141, 143, 149, 0.05);
}

.btn-a-outline-grey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-grey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-grey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(141, 143, 149, 0.45);
}

.show > .btn.btn-a-light-grey.dropdown-toggle, .btn-a-light-grey:not(:disabled):not(.disabled):active, .btn-a-light-grey:not(:disabled):not(.disabled).active {
  color: #585c67;
  background-color: #e1e2e5;
  border-color: #bdbfc6;
}

.show > .btn.btn-a-lighter-grey.dropdown-toggle, .btn-a-lighter-grey:not(:disabled):not(.disabled):active, .btn-a-lighter-grey:not(:disabled):not(.disabled).active {
  color: #585c67;
  background-color: #e6e7ea;
  border-color: #c3c5cb;
}

.btn-a-purple:not(:disabled):not(.disabled):active,
.btn-a-purple:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-purple.dropdown-toggle {
  color: #fff;
  background-color: #8573b6;
  border-color: #7863b1;
}

.btn-a-purple:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-purple:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-purple.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.btn-a-outline-purple:not(:disabled):not(.disabled):active,
.btn-a-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-purple.dropdown-toggle {
  color: #6a589a;
  border-color: #9382c0;
  background-color: rgba(147, 130, 192, 0.05);
}

.btn-a-outline-purple:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-purple:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-purple.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(147, 130, 192, 0.45);
}

.show > .btn.btn-a-light-purple.dropdown-toggle, .btn-a-light-purple:not(:disabled):not(.disabled):active, .btn-a-light-purple:not(:disabled):not(.disabled).active {
  color: #584392;
  background-color: #dfdaee;
  border-color: #bcb0db;
}

.show > .btn.btn-a-lighter-purple.dropdown-toggle, .btn-a-lighter-purple:not(:disabled):not(.disabled):active, .btn-a-lighter-purple:not(:disabled):not(.disabled).active {
  color: #584392;
  background-color: #e5e1f1;
  border-color: #c2b7de;
}

.btn-a-pink:not(:disabled):not(.disabled):active,
.btn-a-pink:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-pink.dropdown-toggle {
  color: #fff;
  background-color: #cf4a7d;
  border-color: #cd3771;
}

.btn-a-pink:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-pink:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-pink.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.btn-a-outline-pink:not(:disabled):not(.disabled):active,
.btn-a-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-pink.dropdown-toggle {
  color: #b53e6b;
  border-color: #d65c8b;
  background-color: rgba(214, 92, 139, 0.05);
}

.btn-a-outline-pink:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-pink:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-pink.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(214, 92, 139, 0.45);
}

.show > .btn.btn-a-light-pink.dropdown-toggle, .btn-a-light-pink:not(:disabled):not(.disabled):active, .btn-a-light-pink:not(:disabled):not(.disabled).active {
  color: #a92558;
  background-color: #f5d3e0;
  border-color: #eaa0bd;
}

.show > .btn.btn-a-lighter-pink.dropdown-toggle, .btn-a-lighter-pink:not(:disabled):not(.disabled):active, .btn-a-lighter-pink:not(:disabled):not(.disabled).active {
  color: #a92558;
  background-color: #f7dbe6;
  border-color: #eca9c2;
}

.btn-a-yellow:not(:disabled):not(.disabled):active,
.btn-a-yellow:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-yellow.dropdown-toggle {
  color: #996633;
  background-color: #f0de66;
  border-color: #f1ce2e;
}

.btn-a-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-yellow.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.btn-a-outline-yellow:not(:disabled):not(.disabled):active,
.btn-a-outline-yellow:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-yellow.dropdown-toggle {
  color: #a86b2f;
  border-color: #f4d859;
  background-color: rgba(244, 228, 124, 0.05);
}

.btn-a-outline-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-yellow.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(244, 216, 89, 0.45);
}

.show > .btn.btn-a-light-yellow.dropdown-toggle, .btn-a-light-yellow:not(:disabled):not(.disabled):active, .btn-a-light-yellow:not(:disabled):not(.disabled).active {
  color: #f1d51b;
  background-color: #fcf7d4;
  border-color: #faefaa;
}

.show > .btn.btn-a-lighter-yellow.dropdown-toggle, .btn-a-lighter-yellow:not(:disabled):not(.disabled):active, .btn-a-lighter-yellow:not(:disabled):not(.disabled).active {
  color: #f1d51b;
  background-color: #eeecec;
  border-color: #faf1b3;
}

.btn-a-lightgrey:not(:disabled):not(.disabled):active,
.btn-a-lightgrey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-lightgrey.dropdown-toggle {
  color: #fff;
  background-color: #9da0a5;
  border-color: #91969d;
}

.btn-a-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-lightgrey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.btn-a-outline-lightgrey:not(:disabled):not(.disabled):active,
.btn-a-outline-lightgrey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-lightgrey.dropdown-toggle {
  color: #74797e;
  border-color: #d0d5da;
  background-color: rgba(169, 173, 179, 0.05);
}

.btn-a-outline-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-lightgrey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 173, 179, 0.45);
}

.show > .btn.btn-a-light-lightgrey.dropdown-toggle, .btn-a-light-lightgrey:not(:disabled):not(.disabled):active, .btn-a-light-lightgrey:not(:disabled):not(.disabled).active {
  color: #707a89;
  background-color: #e2e4e8;
  border-color: #c7cbd1;
}

.show > .btn.btn-a-lighter-lightgrey.dropdown-toggle, .btn-a-lighter-lightgrey:not(:disabled):not(.disabled):active, .btn-a-lighter-lightgrey:not(:disabled):not(.disabled).active {
  color: #707a89;
  background-color: #e8eaec;
  border-color: #cdd1d6;
}

.btn-a-brown:not(:disabled):not(.disabled):active,
.btn-a-brown:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-brown.dropdown-toggle {
  color: #fff;
  background-color: #bc905b;
  border-color: #b8864a;
}

.btn-a-brown:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-brown:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-brown.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.btn-a-outline-brown:not(:disabled):not(.disabled):active,
.btn-a-outline-brown:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-brown.dropdown-toggle {
  color: #a57e4e;
  border-color: #c59c6b;
  background-color: rgba(197, 156, 107, 0.05);
}

.btn-a-outline-brown:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-brown:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-brown.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 156, 107, 0.45);
}

.show > .btn.btn-a-light-brown.dropdown-toggle, .btn-a-light-brown:not(:disabled):not(.disabled):active, .btn-a-light-brown:not(:disabled):not(.disabled).active {
  color: #976b36;
  background-color: #f0e4d6;
  border-color: #e0c7a8;
}

.show > .btn.btn-a-lighter-brown.dropdown-toggle, .btn-a-lighter-brown:not(:disabled):not(.disabled):active, .btn-a-lighter-brown:not(:disabled):not(.disabled).active {
  color: #976b36;
  background-color: #f3e9dd;
  border-color: #e3cbb0;
}

.btn-a-blue:not(:disabled):not(.disabled):active,
.btn-a-blue:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-blue.dropdown-toggle {
  color: #fff;
  background-color: #48a4e0;
  border-color: #349cdf;
}

.btn-a-blue:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-blue:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-blue.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.btn-a-outline-blue:not(:disabled):not(.disabled):active,
.btn-a-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-blue.dropdown-toggle {
  color: #3288c1;
  border-color: #5cafe5;
  background-color: rgba(92, 175, 229, 0.05);
}

.btn-a-outline-blue:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-blue:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-blue.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(92, 175, 229, 0.45);
}

.show > .btn.btn-a-light-blue.dropdown-toggle, .btn-a-light-blue:not(:disabled):not(.disabled):active, .btn-a-light-blue:not(:disabled):not(.disabled).active {
  color: #187bbb;
  background-color: #cee8f9;
  border-color: #99cff2;
}

.show > .btn.btn-a-lighter-blue.dropdown-toggle, .btn-a-lighter-blue:not(:disabled):not(.disabled):active, .btn-a-lighter-blue:not(:disabled):not(.disabled).active {
  color: #187bbb;
  background-color: #d7ecfa;
  border-color: #a2d3f3;
}

.btn-a-red:not(:disabled):not(.disabled):active,
.btn-a-red:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-red.dropdown-toggle {
  color: #fff;
  background-color: #e85050;
  border-color: #e83c3c;
}

.btn-a-red:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-red:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-red.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.btn-a-outline-red:not(:disabled):not(.disabled):active,
.btn-a-outline-red:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-red.dropdown-toggle {
  color: #c52d2d;
  border-color: #ed6565;
  background-color: rgba(237, 101, 101, 0.05);
}

.btn-a-outline-red:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-red:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-red.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(237, 101, 101, 0.45);
}

.show > .btn.btn-a-light-red.dropdown-toggle, .btn-a-light-red:not(:disabled):not(.disabled):active, .btn-a-light-red:not(:disabled):not(.disabled).active {
  color: #da1414;
  background-color: #fbd2d2;
  border-color: #f6a2a2;
}

.show > .btn.btn-a-lighter-red.dropdown-toggle, .btn-a-lighter-red:not(:disabled):not(.disabled):active, .btn-a-lighter-red:not(:disabled):not(.disabled).active {
  color: #da1414;
  background-color: #fcdcdc;
  border-color: #f7abab;
}

.btn-a-green:not(:disabled):not(.disabled):active,
.btn-a-green:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-green.dropdown-toggle {
  color: #fff;
  background-color: #51c293;
  border-color: #40c08a;
}

.btn-a-green:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-green:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-green.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.btn-a-outline-green:not(:disabled):not(.disabled):active,
.btn-a-outline-green:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-green.dropdown-toggle {
  color: #47ac81;
  border-color: #62cb9f;
  background-color: rgba(98, 203, 159, 0.05);
}

.btn-a-outline-green:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-green:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-green.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(98, 203, 159, 0.45);
}

.show > .btn.btn-a-light-green.dropdown-toggle, .btn-a-light-green:not(:disabled):not(.disabled):active, .btn-a-light-green:not(:disabled):not(.disabled).active {
  color: #2e9b6e;
  background-color: #d2f2e4;
  border-color: #a2e3c8;
}

.show > .btn.btn-a-lighter-green.dropdown-toggle, .btn-a-lighter-green:not(:disabled):not(.disabled):active, .btn-a-lighter-green:not(:disabled):not(.disabled).active {
  color: #2e9b6e;
  background-color: #d9f4e9;
  border-color: #aae6cd;
}

.btn-text-default,
.btn-h-text-default:hover,
.btn-a-text-default:not(.disabled):not(:disabled):active,
.btn-a-text-default:not(.disabled):not(:disabled).active {
  color: #588aa7;
}

.btn-text-primary,
.btn-h-text-primary:hover,
.btn-a-text-primary:not(.disabled):not(:disabled):active,
.btn-a-text-primary:not(.disabled):not(:disabled).active {
  color: #4887b7;
}

.btn-text-secondary,
.btn-h-text-secondary:hover,
.btn-a-text-secondary:not(.disabled):not(:disabled):active,
.btn-a-text-secondary:not(.disabled):not(:disabled).active {
  color: #71858e;
}

.btn-text-info,
.btn-h-text-info:hover,
.btn-a-text-info:not(.disabled):not(:disabled):active,
.btn-a-text-info:not(.disabled):not(:disabled).active {
  color: #3c9dc3;
}

.btn-text-success,
.btn-h-text-success:hover,
.btn-a-text-success:not(.disabled):not(:disabled):active,
.btn-a-text-success:not(.disabled):not(:disabled).active {
  color: #5aa55a;
}

.btn-text-danger,
.btn-h-text-danger:hover,
.btn-a-text-danger:not(.disabled):not(:disabled):active,
.btn-a-text-danger:not(.disabled):not(:disabled).active {
  color: #c54f3a;
}

.btn-text-warning,
.btn-h-text-warning:hover,
.btn-a-text-warning:not(.disabled):not(:disabled):active,
.btn-a-text-warning:not(.disabled):not(:disabled).active {
  color: #d89527;
}

.btn-text-dark,
.btn-h-text-dark:hover,
.btn-a-text-dark:not(.disabled):not(:disabled):active,
.btn-a-text-dark:not(.disabled):not(:disabled).active {
  color: #4b4e52;
}

.btn-text-light,
.btn-h-text-light:hover,
.btn-a-text-light:not(.disabled):not(:disabled):active,
.btn-a-text-light:not(.disabled):not(:disabled).active {
  color: #555;
}

.btn-text-white,
.btn-h-text-white:hover,
.btn-a-text-white:not(.disabled):not(:disabled):active,
.btn-a-text-white:not(.disabled):not(:disabled).active {
  color: #fff;
}

.btn-text-black,
.btn-h-text-black:hover,
.btn-a-text-black:not(.disabled):not(:disabled):active,
.btn-a-text-black:not(.disabled):not(:disabled).active {
  color: #2d3134;
}

.btn-text-grey,
.btn-h-text-grey:hover,
.btn-a-text-grey:not(.disabled):not(:disabled):active,
.btn-a-text-grey:not(.disabled):not(:disabled).active {
  color: #666;
}

.btn-text-purple,
.btn-h-text-purple:hover,
.btn-a-text-purple:not(.disabled):not(:disabled):active,
.btn-a-text-purple:not(.disabled):not(:disabled).active {
  color: #705da2;
}

.btn-text-pink,
.btn-h-text-pink:hover,
.btn-a-text-pink:not(.disabled):not(:disabled):active,
.btn-a-text-pink:not(.disabled):not(:disabled).active {
  color: #be4171;
}

.btn-text-yellow,
.btn-h-text-yellow:hover,
.btn-a-text-yellow:not(.disabled):not(:disabled):active,
.btn-a-text-yellow:not(.disabled):not(:disabled).active {
  color: #b17131;
}

.btn-text-lightgrey,
.btn-h-text-lightgrey:hover,
.btn-a-text-lightgrey:not(.disabled):not(:disabled):active,
.btn-a-text-lightgrey:not(.disabled):not(:disabled).active {
  color: #7a8085;
}

.btn-text-brown,
.btn-h-text-brown:hover,
.btn-a-text-brown:not(.disabled):not(:disabled):active,
.btn-a-text-brown:not(.disabled):not(:disabled).active {
  color: #ad8452;
}

.btn-text-blue,
.btn-h-text-blue:hover,
.btn-a-text-blue:not(.disabled):not(:disabled):active,
.btn-a-text-blue:not(.disabled):not(:disabled).active {
  color: #3490cb;
}

.btn-text-red,
.btn-h-text-red:hover,
.btn-a-text-red:not(.disabled):not(:disabled):active,
.btn-a-text-red:not(.disabled):not(:disabled).active {
  color: #d02f2f;
}

.btn-text-green,
.btn-h-text-green:hover,
.btn-a-text-green:not(.disabled):not(:disabled):active,
.btn-a-text-green:not(.disabled):not(:disabled).active {
  color: #4ab588;
}

.btn-brc-tp,
.btn-h-brc-tp:hover,
.btn-a-brc-tp:not(.disabled):not(:disabled):active,
.btn-a-brc-tp:not(.disabled):not(:disabled).active {
  border-color: transparent;
}

.btn-brc-white,
.btn-h-brc-white:hover,
.btn-a-brc-white:not(.disabled):not(:disabled):active,
.btn-a-brc-white:not(.disabled):not(:disabled).active {
  border-color: #fff;
}

.btn-bgc-white,
.btn-h-bgc-white:hover,
.btn-h-bgc-white[class*="btn-h-outline-"]:hover,
.btn-a-bgc-white:not(.disabled):not(:disabled):active,
.btn-a-bgc-white:not(.disabled):not(:disabled).active {
  background-color: #fff;
}

.btn-bgc-tp,
.btn-h-bgc-tp:hover,
.btn-a-bgc-tp:not(.disabled):not(:disabled):active,
.btn-a-bgc-tp:not(.disabled):not(:disabled).active {
  background-color: transparent;
}

.btn.btn-bold {
  border-width: 2px;
  font-weight: 600;
  font-size: 90%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.btn-h-bold:hover,
.btn-a-bold.active:not(.disabled):not(:disabled) {
  font-weight: 600;
}

.btn.fs--outline:focus {
  outline-color: rgba(255, 255, 255, 0.73);
  outline-width: 1px;
  outline-style: dotted;
  outline-offset: -3px;
}

.btn.fs--outline[class*="btn-outline-"]:focus, .btn.fs--outline[class*="btn-light"]:focus {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--outline[class*="btn-h-"]:not([class*="btn-h-outline"]):not([class*="btn-h-light"]):hover:focus {
  outline-color: rgba(255, 255, 255, 0.73);
}

.btn.fs--outline[class*="btn-h-outline-"]:focus:hover, .btn.fs--outline[class*="btn-h-light"]:focus:hover {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--outline[class*="btn-a-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(255, 255, 255, 0.73);
}

.btn.fs--outline[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active:focus, .btn.fs--outline[class*="btn-a-light"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-light"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--solid:focus {
  outline-color: rgba(255, 255, 255, 0.6);
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -3px;
}

.btn.fs--solid[class*="btn-outline-"]:focus, .btn.fs--solid[class*="btn-light"]:focus {
  outline-color: rgba(0, 0, 0, 0.16);
}

.btn.fs--solid[class*="btn-h-"]:not([class*="btn-h-outline"]):not([class*="btn-h-light"]):hover:focus {
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--solid[class*="btn-h-outline-"]:focus:hover, .btn.fs--solid[class*="btn-h-light"]:focus:hover {
  outline-color: rgba(0, 0, 0, 0.16);
}

.btn.fs--solid[class*="btn-a-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--solid[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active:focus, .btn.fs--solid[class*="btn-a-light"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-light"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(0, 0, 0, 0.16);
}

.btn.fs--dashed:focus {
  outline-width: 1px;
  outline-style: dashed;
  outline-offset: -1px;
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--raised:focus, .btn.fs--raised.focus {
  box-shadow: inset 0px -2px 1px 0px rgba(0, 0, 0, 0.1) !important;
}

.btn {
  position: relative;
  z-index: auto;
}

.btn.disabled, .btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn.disabled:after, .btn:disabled:after {
  content: "";
  position: absolute;
  z-index: 2;
  border-radius: inherit;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  background-color: rgba(0, 0, 0, 0.05);
}

.btn-link {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.btn.btn-raised,
.btn.btn-h-raised:hover,
.btn.btn-a-raised:not(.disabled):not(:disabled):active,
.btn.btn-a-raised:not(.disabled):not(:disabled).active {
  box-shadow: inset 0px -2px 1px 0px rgba(0, 0, 0, 0.1);
}

.btn.btn-app {
  display: inline-block;
  min-width: 7.5rem;
  line-height: 2rem;
  font-size: 1.325rem;
  font-weight: 400;
  text-align: center;
  border-radius: 0.825rem;
  padding: 1rem 0 0.75rem;
  position: relative;
}

.btn.btn-app.btn-sm {
  line-height: 1.675rem;
  min-width: 6.25rem;
  font-size: 1.175rem;
  padding: 0.875rem 0 0.675rem;
}

.btn.btn-app.btn-xs {
  line-height: 1.25rem;
  min-width: 5.25rem;
  font-size: 1rem;
  padding: 0.75rem 0 0.625rem;
}

.btn-app.btn-default {
  background-image: linear-gradient(to bottom, #9dc3d9 0%, #71a8c7 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-default:hover {
  background-image: linear-gradient(to bottom, #8bb8d1 0%, #5f9cc0 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-primary {
  background-image: linear-gradient(to bottom, #6ba8d5 0%, #3c8cc8 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-primary:hover {
  background-image: linear-gradient(to bottom, #589cd0 0%, #347eb6 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-secondary {
  background-image: linear-gradient(to bottom, #a8bac3 0%, #849daa 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-secondary:hover {
  background-image: linear-gradient(to bottom, #99aeb8 0%, #75919f 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-info {
  background-image: linear-gradient(to bottom, #6dc2e3 0%, #39acd9 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-info:hover {
  background-image: linear-gradient(to bottom, #57b9df 0%, #28a1d1 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-success {
  background-image: linear-gradient(to bottom, #84ca84 0%, #59b859 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-success:hover {
  background-image: linear-gradient(to bottom, #72c372 0%, #4aad4a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-danger {
  background-image: linear-gradient(to bottom, #e67e6d 0%, #dd5039 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-danger:hover {
  background-image: linear-gradient(to bottom, #e26b57 0%, #d73e25 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-warning {
  background-image: linear-gradient(to bottom, #f5bb5c 0%, #f1a322 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-warning:hover {
  background-image: linear-gradient(to bottom, #f3b144 0%, #eb980f 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-dark {
  background-image: linear-gradient(to bottom, #5c646d 0%, #40464c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-dark:hover {
  background-image: linear-gradient(to bottom, #50585f 0%, #34393e 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-light {
  background-image: linear-gradient(to bottom, white 0%, #e1e4ee 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-light:hover {
  background-image: linear-gradient(to bottom, #f8f9fb 0%, #d0d6e5 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-white {
  background-image: linear-gradient(to bottom, white 0%, #f0efef 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-white:hover {
  background-image: linear-gradient(to bottom, white 0%, #e4e2e2 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-black {
  background-image: linear-gradient(to bottom, #3c444c 0%, #21262a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-black:hover {
  background-image: linear-gradient(to bottom, #31383e 0%, #16191c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-grey {
  background-image: linear-gradient(to bottom, #999da8 0%, #787d8b 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-grey:hover {
  background-image: linear-gradient(to bottom, #8b8f9c 0%, #6c707e 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-purple {
  background-image: linear-gradient(to bottom, #a393ce 0%, #7f69bb 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-purple:hover {
  background-image: linear-gradient(to bottom, #9482c6 0%, #7058b3 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-pink {
  background-image: linear-gradient(to bottom, #e0719c 0%, #d53f79 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-pink:hover {
  background-image: linear-gradient(to bottom, #db5c8d 0%, #cd2d6b 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-yellow {
  background-image: linear-gradient(to bottom, #f9eb96 0%, #f5e15c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-yellow:hover {
  background-image: linear-gradient(to bottom, #f7e77e 0%, #f4dc44 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-lightgrey {
  background-image: linear-gradient(to bottom, #b7bcc4 0%, #959da8 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-lightgrey:hover {
  background-image: linear-gradient(to bottom, #a9afb9 0%, #87909d 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-brown {
  background-image: linear-gradient(to bottom, #d1ab7d 0%, #c18e50 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-brown:hover {
  background-image: linear-gradient(to bottom, #ca9f6b 0%, #b78141 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-blue {
  background-image: linear-gradient(to bottom, #73bded 0%, #3da3e6 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-blue:hover {
  background-image: linear-gradient(to bottom, #5db2ea 0%, #2699e3 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-red {
  background-image: linear-gradient(to bottom, #f37e7e 0%, #ee4646 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-red:hover {
  background-image: linear-gradient(to bottom, #f16666 0%, #ec2e2e 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-green {
  background-image: linear-gradient(to bottom, #75d6ae 0%, #46c892 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-green:hover {
  background-image: linear-gradient(to bottom, #62d0a2 0%, #38bd85 100%);
  background-repeat: repeat-x;
}

.btn-group > .btn-app + .btn-app.dropdown-toggle {
  min-width: auto;
}

.btn-group:not(.btn-group-vertical) > .btn:not(:last-child) {
  margin-right: 2px;
}

.btn-group.btn-group-vertical > .btn {
  margin-top: 1px;
}

.btn-group > .btn-app + .btn-app.dropdown-toggle {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.btn-group-vertical > .btn:first-child:not(.dropdown-toggle), .btn-group-vertical > .btn-group:first-child {
  margin-left: -1px;
}

.action-buttons a, .action-btn {
  display: inline-block;
  opacity: 0.88;
  margin: 0 0.125rem;
  transition: all 0.12s;
}

@media (prefers-reduced-motion: reduce) {
  .action-buttons a, .action-btn {
    transition: none;
  }
}

.action-buttons a:hover, .action-btn:hover {
  text-decoration: none;
  opacity: 1;
  transform: scale(1.15);
}

.btn-text-slide {
  position: relative;
  overflow: hidden;
}

.btn-text-slide .btn-text-1, .btn-text-slide .btn-text-2 {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.12s, opacity 0.12s;
  transition-delay: .1s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide .btn-text-1, .btn-text-slide .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide .btn-text-2 {
  transition-delay: 0s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: inherit;
  padding-top: inherit;
  padding-bottom: inherit;
}

.btn-text-slide:hover .btn-text-1 {
  transition-delay: 0s;
  transform: translateY(100%);
  opacity: 0;
}

.btn-text-slide:hover .btn-text-1.move-up {
  transform: translateY(-100%);
}

.btn-text-slide:hover .btn-text-1.move-down {
  transform: translateY(100%);
}

.btn-text-slide:hover .btn-text-1.move-right {
  transform: translateX(120%);
}

.btn-text-slide:hover .btn-text-1.move-left {
  transform: translateX(-120%);
}

.btn-text-slide .btn-text-2 {
  transform: translateY(-100%);
}

.btn-text-slide .btn-text-2.move-down {
  transform: translateY(-100%);
}

.btn-text-slide .btn-text-2.move-up {
  transform: translateY(100%);
}

.btn-text-slide .btn-text-2.move-left {
  transform: translateX(100%);
}

.btn-text-slide .btn-text-2.move-right {
  transform: translateX(-100%);
}

.btn-text-slide:hover .btn-text-2 {
  transform: none;
  transition-delay: 0.1s;
}

.btn-text-slide-x {
  position: relative;
  overflow: hidden;
}

.btn-text-slide-x .btn-text-2 {
  transition: all 0.25s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide-x .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide-x .btn-text-2 {
  opacity: 0;
  letter-spacing: -0.5rem;
  max-width: 0%;
  white-space: nowrap;
  word-break: normal;
  display: inline-block;
}

.btn-text-slide-x .btn-text-2.move-right {
  transform: translateX(-100%);
}

.btn-text-slide-x .btn-text-2.move-left {
  transform: translateX(100%);
}

.btn-text-slide-x:not(:hover) .btn-text-2 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.btn-text-slide-x:hover .btn-text-2 {
  opacity: 1;
  letter-spacing: 0;
  max-width: 100%;
  transform: none;
}

.btn-text-slide-y {
  position: relative;
  overflow: hidden;
}

.btn-text-slide-y .btn-text-1, .btn-text-slide-y .btn-text-2 {
  display: block;
  transition: all 0.12s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide-y .btn-text-1, .btn-text-slide-y .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide-y .btn-text-2 {
  transform: translateY(100%);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.btn-text-slide-y:hover .btn-text-1 {
  transform: translateY(-30%) scale(0.8);
}

.btn-text-slide-y:hover .btn-text-2 {
  transform: none;
}

.btn-text-slide-y.direction-reverse .btn-text-2 {
  transform: translateY(-100%);
  top: 0;
  bottom: auto;
}

.btn-text-slide-y.direction-reverse:hover .btn-text-1 {
  transform: translateY(30%) scale(0.8);
}

.btn-text-slide-y.direction-reverse:hover .btn-text-2 {
  transform: none;
}

.btn-text-slide-y .btn-text-fade {
  transform: none !important;
  opacity: 0 !important;
  transition-delay: 0s;
  transition-duration: 50ms;
}

.btn-text-slide-y:hover .btn-text-fade {
  transition-delay: 0.1s;
  transform: none !important;
  opacity: 1 !important;
}

.form-control {
  color: #696b6f;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 0.125rem;
  font-family: inherit;
  transition-duration: 120ms;
}

.form-control:hover {
  border-color: #b5b5b5;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(245, 153, 66, 0.2);
  color: #696969;
  border-color: #F59942;
  background-color: #FFF;
  outline: none;
}

.form-control[disabled] {
  color: #848484;
  background-color: #eee;
}

.form-control[disabled]:hover {
  border-color: #d5d5d5;
}

.form-control[readonly] {
  color: #939192;
  background: #f5f5f5;
  cursor: default;
}

.form-control[readonly]:hover {
  border-color: #c4c4c4;
}

.form-control[readonly]:focus {
  box-shadow: none;
  border-color: #aaa;
  background-color: #f9f9f9;
}

.form-control::placeholder {
  color: #999;
}

.form-control[class*="brc-success"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(115, 189, 115, 0.2);
}

.form-control[class*="brc-danger"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(221, 106, 87, 0.2);
}

.form-control[class*="brc-warning"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(239, 174, 67, 0.2);
}

.form-control[class*="brc-primary"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(88, 153, 202, 0.2);
}

.form-control[class*="brc-info"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(87, 181, 218, 0.2);
}

.form-control[class*="brc-blue"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(83, 171, 228, 0.2);
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #73bd73;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  box-shadow: 0 0 0 2px rgba(115, 189, 115, 0.2);
}

.valid-feedback {
  color: #6bae47;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dd6a57;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 2px rgba(221, 106, 87, 0.2);
}

.invalid-feedback {
  color: #da3737;
}

label {
  cursor: pointer;
}

label.disabled {
  cursor: default;
}

input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
  cursor: default;
}

.input-group-fade .input-group-append > .btn {
  opacity: 0.55;
}

.input-group-fade .input-group-append > .btn:hover {
  opacity: 1;
}

.input-group-fade .form-control:focus + .input-group-append > .btn {
  opacity: 1;
}

.input-floating-label {
  position: relative;
}

.input-floating-label .floating-label {
  pointer-events: none;
  background-color: #fff;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .input-floating-label .floating-label {
    transition: none;
  }
}

.input-floating-label input:focus ~ .floating-label, .input-floating-label input.has-content ~ .floating-label, .input-floating-label .floating-label.active {
  top: 0;
  font-size: 80%;
  opacity: 0.85;
}

.input-floating-label[class*="text-"] input:focus ~ .floating-label {
  color: inherit !important;
  opacity: 0.95;
}

.input-floating-label[class*="brc-"] input:focus {
  border-color: inherit;
}

select.form-control {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

select.form-control option {
  padding: 0.3125rem 0.3125rem 0.375rem;
}

select.ace-select {
  position: relative;
  cursor: pointer;
  appearance: none;
  width: 100%;
  height: 2.5rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  border: 2px solid #eee;
  border-radius: 3px;
  background-color: #FFf;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: calc(100% - 1.125rem - 0.5px) center, calc(100% - 0.75rem - 0.5px) center, calc(100% - 2.25rem) center;
  background-size: 0.375rem 0.375rem, 0.375rem 0.375rem, 1px 1.5rem;
  background-repeat: no-repeat;
}

select.ace-select.double-caret {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(135deg, transparent 50%, currentColor 50%), linear-gradient(45deg, currentColor 50%, transparent 50%), linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: calc(100% - 1.0625rem - 0.5px) calc(50% + 0.25rem), calc(100% - 0.75rem - 0.5px) calc(50% + 0.25rem), calc(100% - 1.0625rem - 0.5px) calc(50% - 0.25rem), calc(100% - 0.75rem - 0.5px) calc(50% - 0.25rem), calc(100% - 2.125rem) center;
  background-size: 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 1px 1.5rem;
}

select.ace-select option {
  background-color: #fff;
  color: #484b51;
}

select.ace-select::-ms-expand {
  display: none;
}

select.ace-select:focus {
  outline: none;
}

select.ace-select.no-border {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
}

select.ace-select.double-caret.no-border {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(135deg, transparent 50%, currentColor 50%), linear-gradient(45deg, currentColor 50%, transparent 50%);
}

.form-text.popover {
  left: auto;
  margin-top: 0;
  z-index: auto;
}

.form-text.bs-popover-right > .arrow, .form-text.bs-popover-auto[x-placement^="right"] > .arrow {
  top: 50%;
  margin-top: -0.5rem;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none) {
  .custom-control-input {
    position: absolute !important;
  }
  input[type=checkbox], input[type=radio] {
    position: relative;
    appearance: none;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;
    border: 1px solid #adb8c0;
    background-color: #fff;
    vertical-align: text-bottom;
    margin-right: 0.25rem;
  }
  input[type=checkbox].input-lg, input[type=radio].input-lg {
    width: 1.5rem;
    height: 1.5rem;
  }
  input[type=checkbox].input-xlg, input[type=radio].input-xlg {
    width: 1.625rem;
    height: 1.625rem;
  }
  input[type=checkbox].input-sm, input[type=radio].input-sm {
    width: 1rem;
    height: 1rem;
  }
  input[type=checkbox]:hover, input[type=radio]:hover {
    border-color: #ff893c;
  }
  input[type=checkbox]:focus, input[type=radio]:focus {
    outline: none;
    border-color: #f79a5e;
  }
  input[type=checkbox]:active, input[type=radio]:active {
    border-color: #ff893c;
  }
  input[type=checkbox] {
    border-radius: 0.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
  }
  input[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%2332a3ce' d='M 7.564,1.75 l -3.59,3.612 -1.538,-1.55 L 1,5.26 3.974,8.25 9,3.193 z'/%3e%3c/svg%3e");
  }
  input[type=radio] {
    border-radius: 100%;
  }
  input[type=radio]::after {
    content: "";
    border-radius: inherit;
    position: absolute;
    right: 0.25rem;
    left: 0.25rem;
    top: 0.25rem;
    bottom: 0.25rem;
    background-color: transparent;
    transition: background-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=radio]::after {
      transition: none;
    }
  }
  input[type=radio]:checked::after {
    background-color: #32a3ce;
  }
  input[type=radio].border-2:not(.input-lg):not(.input-xlg)::after {
    right: 0.1875rem;
    left: 0.1875rem;
    top: 0.1875rem;
    bottom: 0.1875rem;
  }
  input[type=radio].input-lg:not(.border-2)::after, input[type=radio].input-xlg::after {
    right: 0.3125rem;
    left: 0.3125rem;
    top: 0.3125rem;
    bottom: 0.3125rem;
  }
  input[type=radio].input-sm::after {
    right: 0.1875rem;
    left: 0.1875rem;
    top: 0.1875rem;
    bottom: 0.1875rem;
  }
  input[type=checkbox][class*="bgc-"] {
    transition: background-color 80ms, border-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=checkbox][class*="bgc-"] {
      transition: none;
    }
  }
  input[type=checkbox][class*="bgc-"]:not(:checked) {
    background-color: #fff !important;
  }
  input[type=checkbox][class*="bgc-"]:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23fff' d='M 7.564,1.75 l -3.59,3.612 -1.538,-1.55 L 1,5.26 3.974,8.25 9,3.193 z'/%3e%3c/svg%3e");
    border-color: transparent !important;
  }
  input[type=checkbox][class*="bgc-"].input-lg, input[type=checkbox][class*="bgc-"].input-xlg {
    background-size: 85%;
    background-position: center;
  }
  input[type=checkbox][class*="text-"] {
    transition: background-color 80ms;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cmask id='mask'%3E%3Crect fill='white' width='100%25' height='100%25' fill-opacity='1' stroke-width='0' /%3E%3Cpath d='M 7.564,1.75 l -3.59,3.612 -1.538,-1.55 L 1,5.26 3.974,8.25 9,3.193 z' /%3E%3C/mask%3E%3Crect mask='url(%23mask)' fill='white' width='100%25' height='100%25' fill-opacity='1'/%3E%3C/svg%3E");
    background-size: 90%;
    background-position-x: 1px;
    background-clip: padding-box;
    box-shadow: inset 0 0 0 2px #fff;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=checkbox][class*="text-"] {
      transition: none;
    }
  }
  input[type=checkbox][class*="text-"]:checked {
    background-color: currentColor;
  }
  input[type=checkbox][class*="text-"].input-lg, input[type=checkbox][class*="text-"].input-xlg {
    background-size: 85%;
    box-shadow: inset 0 0 0 3px #fff;
    background-position-x: 2px;
  }
  input[type=checkbox][class*="text-"].input-lg.border-2, input[type=checkbox][class*="text-"].input-xlg.border-2 {
    background-size: 90%;
    background-position-x: 1px;
  }
  input[type=radio][class*="text-"]:checked::after {
    color: inherit;
    background-color: currentColor;
  }
  input[type=radio][class*="bgc-"] {
    transition: background-color 80ms, border-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=radio][class*="bgc-"] {
      transition: none;
    }
  }
  input[type=radio][class*="bgc-"]:not(:checked) {
    background-color: #fff !important;
  }
  input[type=radio][class*="bgc-"]:checked {
    border-color: transparent;
  }
  input[type=radio][class*="bgc-"]:checked::after {
    box-shadow: none;
    background-color: #fff;
  }
  .brc-on-checked:not(:checked):not(:hover):not(:focus) {
    border-color: #adb8c0 !important;
  }
  input[type=checkbox]:disabled, input[type=radio]:disabled {
    cursor: default;
    border-color: #ccc !important;
    background-color: #eee !important;
    background-image: none;
    box-shadow: none !important;
  }
  input[type=checkbox]:disabled:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23aaa' d='M 7.564,1.75 l -3.59,3.612 -1.538,-1.55 L 1,5.26 3.974,8.25 9,3.193 z'/%3e%3c/svg%3e");
  }
  input[type=radio]:disabled:checked::after {
    background-color: #aaa;
    box-shadow: none;
  }
  input.ace-switch[class*="text-"] {
    box-shadow: none !important;
    background-clip: border-box;
  }
}

@supports (-moz-appearance: none) {
  input[type=checkbox] {
    background-position-x: 1px;
  }
}

/* exclude edge */
@supports ((-webkit-appearance: none) and ((-webkit-box-reflect: above) or (text-orientation: mixed))) or (-moz-appearance: none) {
  input.ace-switch {
    background-color: #9ea7ae;
    border: none;
    margin-right: 0;
  }
  input.ace-switch:focus {
    box-shadow: none;
  }
  input.ace-switch::after {
    content: "";
    position: absolute;
    background-color: #fff;
    border-radius: inherit;
    transition: transform 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input.ace-switch::after {
      transition: none;
    }
  }
  input.ace-switch:checked {
    background-image: none;
    background-color: #468fcc;
  }
  input.ace-switch {
    border-radius: 1.5rem;
    width: 2.75rem;
    height: 1.5rem;
  }
  input.ace-switch::after {
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
  }
  input.ace-switch[class*="brc-"]::after {
    border-width: 0.4375rem;
  }
  input.ace-switch:checked::after {
    transform: translateX(1.25rem);
  }
  input.ace-switch.input-lg {
    border-radius: 2rem;
    width: 4.25rem;
    height: 2rem;
  }
  input.ace-switch.input-lg::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  input.ace-switch.input-lg[class*="brc-"]::after {
    border-width: 0.625rem;
  }
  input.ace-switch.input-lg:checked::after {
    transform: translateX(2.25rem);
  }
  input.ace-switch.input-sm {
    border-radius: 1.25rem;
    width: 2.25rem;
    height: 1.25rem;
  }
  input.ace-switch.input-sm::after {
    top: 0.1875rem;
    left: 0.1875rem;
    width: 0.875rem;
    height: 0.875rem;
  }
  input.ace-switch.input-sm[class*="brc-"]::after {
    border-width: 0.3125rem;
  }
  input.ace-switch.input-sm:checked::after {
    transform: translateX(1rem);
  }
  input.ace-switch[class*="bgc-"]:not(:checked):not([class*="text-"]) {
    background-color: #9ea7ae !important;
  }
  input.ace-switch[class*="bgc-"]:checked {
    background-image: none;
  }
  input.ace-switch[class*="text-"]:not(:checked) {
    background-image: none;
    background-color: currentColor !important;
  }
  input.ace-switch[class*="text-"]:checked:not([class*="bgc-"]) {
    background-color: #468fcc;
  }
  input.ace-switch[class*="brc-"] {
    background-color: transparent !important;
    border-width: 0.125rem;
    border-style: solid;
  }
  input.ace-switch[class*="brc-"]:not(:checked):not([class*="text-"]) {
    background-color: transparent !important;
    border-color: #9ea7ae !important;
  }
  input.ace-switch[class*="brc-"][class*="text-"] {
    background-color: transparent !important;
  }
  input.ace-switch[class*="brc-"][class*="text-"]:not(:checked) {
    border-color: currentColor !important;
  }
  input.ace-switch[class*="brc-"]::after {
    border-style: solid;
    border-color: inherit;
    background-color: transparent;
    width: auto;
    height: auto;
  }
  input.ace-switch::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.3125rem;
    line-height: normal;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFF;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0.625rem;
    text-align: right;
  }
  input.ace-switch:checked::before {
    text-align: left;
  }
  input.ace-switch.ace-switch-yesno::before {
    content: "NO";
  }
  input.ace-switch.ace-switch-yesno:checked::before {
    content: "YES";
  }
  input.ace-switch.ace-switch-onoff::before {
    padding-right: 0.5rem;
    content: "OFF";
  }
  input.ace-switch.ace-switch-onoff:checked::before {
    content: "ON";
  }
  input.ace-switch.ace-switch-times:not(:checked) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='none' stroke='%23fff' stroke-width='1.75px' d='M 2.5,2.5 L 7.5,7.5  M 7.5,2.5 L 2.5,7.5'/%3e%3c/svg%3e");
    background-size: 33%;
    background-position: 88% center;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-check:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23fff' d='M 7.564,1.75 l -3.59,3.612 -1.538,-1.55 L 1,5.26 3.974,8.25 9,3.193 z'/%3e%3c/svg%3e");
    background-size: 33%;
    background-position: 15% center;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars::after {
    color: #999;
    content: "";
    background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
    background-position: calc(50% - 3px) center, center, calc(50% + 3px) center;
    background-size: 1px 45%;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars:disabled::after {
    color: #bbb;
  }
  input.ace-switch.ace-switch-bars-h::after {
    color: #999;
    content: "";
    background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
    background-position: center calc(50% - 3px), center, center calc(50% + 3px);
    background-size: 45% 1px;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars-h:disabled::after {
    color: #bbb;
  }
  input.ace-switch.ace-switch-thin {
    visibility: hidden;
    overflow: visible;
    width: 3.09375rem;
    height: 1.6875rem;
  }
  input.ace-switch.ace-switch-thin::before {
    visibility: visible;
    content: "";
    position: absolute;
    width: 80%;
    height: 40%;
    top: 2px;
    bottom: 0;
    margin: auto;
    border-radius: 1rem;
    background-color: inherit;
    color: inherit;
    opacity: 0.45;
  }
  input.ace-switch.ace-switch-thin::after {
    height: 1.5rem;
    width: 1.5rem;
    visibility: visible;
    background-color: inherit;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }
  input.ace-switch.ace-switch-thin:checked::after {
    transform: translateX(1.4rem);
  }
  input[type=checkbox].ace-switch:disabled:not(:checked) {
    color: #ddd !important;
    background-color: #d0d0d0 !important;
  }
  input[type=checkbox].ace-switch:disabled:checked {
    background-color: #c6c6c6 !important;
  }
  input.ace-switch:not(.ace-switch-check):not(.ace-switch-times):checked:disabled {
    background-image: none;
  }
  input[type=checkbox].ace-switch[class*="brc-"]:disabled {
    background-color: transparent !important;
  }
}

*::-ms-backdrop,
input::-ms-check {
  width: 1.125rem;
  height: 1.125rem;
  color: #32a3ce;
  border-color: #adb8c0;
}

*::-ms-backdrop,
input:not([type=radio]):checked[class*="bgc-"]::-ms-check {
  color: white;
  background-color: inherit;
}

*::-ms-backdrop,
input[type=radio][class*="bgc-"] {
  background-color: transparent !important;
}

*::-ms-backdrop,
input[class*="brc-"]::-ms-check {
  border-color: inherit;
}

*::-ms-backdrop,
input[class*="border-2"]::-ms-check {
  border-width: 2px;
}

*::-ms-backdrop,
input[class*="text-"]::-ms-check {
  color: inherit;
}

*::-ms-backdrop,
input[type=checkbox]::-ms-check {
  border-radius: 0.1875rem;
}

*::-ms-backdrop,
input[type=radio]::-ms-check {
  border-radius: 100%;
}

*::-ms-backdrop,
input:disabled::-ms-check {
  color: #aaa !important;
}

input.ace-file-input[type=file], .ace-file-input [type=file] {
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}

input.ace-file-input[type=file]:focus, .ace-file-input [type=file]:focus {
  outline: none;
}

.ace-file-input {
  display: block;
  font-size: inherit;
  position: relative;
  height: 2.5rem;
  user-select: none;
  cursor: pointer;
}

.ace-file-input .ace-file-placeholder {
  display: flex;
}

.ace-file-input .ace-file-container {
  display: block;
  height: 2.5rem;
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .ace-file-input .ace-file-container {
    transition: none;
  }
}

.ace-file-input .ace-file-container .ace-file-name {
  max-width: 100%;
  height: 2.5rem;
  line-height: 2.25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888;
}

.ace-file-input .ace-file-container.selected {
  margin-right: 1rem;
}

.ace-file-input .ace-file-container.selected .ace-file-name {
  color: #666;
}

.ace-file-input .remove {
  display: none;
  text-decoration: none;
}

.ace-file-input .ace-file-container.selected + .remove {
  display: block;
}

.ace-file-input input[type=file].disabled + .ace-file-container, .ace-file-input input[type=file][disabled] + .ace-file-container, .ace-file-input input[type=file][readonly] + .ace-file-container {
  cursor: default;
  background-color: #eee;
  border-color: #d5d5d5 !important;
}

.ace-file-input .ace-file-overlay {
  position: absolute;
  top: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.ace-file-input .ace-file-overlay > .overlay-content {
  position: relative;
  top: calc(50% - 1rem);
}

.ace-file-multiple {
  height: auto;
}

.ace-file-multiple .ace-file-placeholder {
  flex-direction: column-reverse;
}

.ace-file-multiple .ace-file-container {
  height: auto;
  border-radius: 0.25rem;
}

.ace-file-multiple .ace-file-container .ace-file-name {
  height: auto;
  margin: 0 0.25rem;
}

.ace-file-multiple .ace-file-container.selected {
  margin-right: 0;
}

.ace-file-multiple .ace-file-placeholder .ace-file-name {
  margin: 0 1rem;
  text-align: center;
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
  line-height: normal;
}

.ace-file-multiple .ace-file-item {
  border-bottom: 1px solid #ebebec;
}

.ace-file-multiple .ace-file-item:last-child {
  border-bottom-width: 0;
}

.ace-file-multiple .ace-file-icon.thumbnail-img {
  width: 3.25rem;
  text-align: center;
}

.badge {
  font-size: 75%;
  font-weight: 600;
  padding: 0.25rem 0.5rem 0.275rem;
  line-height: 1.175;
  border-radius: 0.125rem;
  position: relative;
}

.badge-pill {
  border-radius: 2rem;
}

@supports (-moz-appearance: none) {
  .badge {
    line-height: 1.1333;
  }
}

.badge-sm {
  padding: 0.1875rem 0.425rem 0.275rem;
  font-size: 70%;
  line-height: 1;
}

.badge-lg {
  padding: 0.275rem 0.5rem 0.425rem;
  font-size: 90%;
  line-height: 1.25;
  height: 1.75rem;
}

.badge-xlg {
  padding: 0.325rem 0.625rem 0.475rem;
  font-size: 105%;
  line-height: 1.625;
  height: 2.5rem;
}

.badge.arrowed::before, .badge.arrowed-in::before, .badge.arrowed-right::after, .badge.arrowed-in-right::after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  border: 1px solid transparent;
  border-width: 0.6875rem 0.375rem;
}

.badge.arrowed {
  margin-left: 0.325rem;
}

.badge.arrowed::before {
  left: -0.7125rem;
  border-right-color: inherit;
}

.badge.arrowed-right {
  margin-right: 0.325rem;
}

.badge.arrowed-right::after {
  right: -0.7125rem;
  border-left-color: inherit;
}

.badge.arrowed-in {
  margin-left: 0.375rem;
}

.badge.arrowed-in::before {
  left: -0.375rem;
  border-color: inherit;
  border-left-color: transparent;
}

.badge.arrowed-in-right {
  margin-right: 0.375rem;
}

.badge.arrowed-in-right::after {
  right: -0.375rem;
  border-color: inherit;
  border-right-color: transparent;
}

.badge.badge-sm.arrowed {
  margin-left: 0.3rem;
}

.badge.badge-sm.arrowed::before {
  border-width: 0.575rem 0.325rem;
  left: -0.6125rem;
}

.badge.badge-sm.arrowed-in {
  margin-left: 0.3rem;
}

.badge.badge-sm.arrowed-in::before {
  border-width: 0.575rem 0.325rem;
  left: -0.325rem;
}

.badge.badge-sm.arrowed-right {
  margin-right: 0.3rem;
}

.badge.badge-sm.arrowed-right::after {
  border-width: 0.575rem 0.325rem;
  right: -0.6125rem;
}

.badge.badge-sm.arrowed-in-right {
  margin-right: 0.3rem;
}

.badge.badge-sm.arrowed-in-right::after {
  border-width: 0.575rem 0.325rem;
  right: -0.325rem;
}

.badge.badge-lg.arrowed {
  margin-left: 0.5rem;
}

.badge.badge-lg.arrowed::before {
  border-width: 0.875rem 0.5rem;
  left: -0.975rem;
}

.badge.badge-lg.arrowed-in {
  margin-left: 0.5rem;
}

.badge.badge-lg.arrowed-in::before {
  border-width: 0.875rem 0.5rem;
  left: -0.5rem;
}

.badge.badge-lg.arrowed-right {
  margin-right: 0.5rem;
}

.badge.badge-lg.arrowed-right::after {
  border-width: 0.875rem 0.5rem;
  right: -0.975rem;
}

.badge.badge-lg.arrowed-in-right {
  margin-right: 0.5rem;
}

.badge.badge-lg.arrowed-in-right::after {
  border-width: 0.875rem 0.5rem;
  right: -0.5rem;
}

.badge.badge-xlg.arrowed {
  padding-left: 0.5rem;
  margin-left: 0.675rem;
}

.badge.badge-xlg.arrowed-in {
  padding-left: 0;
  margin-left: 1.325rem;
}

.badge.badge-xlg.arrowed::before, .badge.badge-xlg.arrowed-in::before {
  border-width: 1.25rem 0.625rem;
  left: -1.25rem;
}

.badge.badge-xlg.arrowed-right {
  padding-right: 0.5rem;
  margin-right: 0.725rem;
}

.badge.badge-xlg.arrowed-in-right {
  padding-right: 0;
  margin-right: 1.375rem;
}

.badge.badge-xlg.arrowed-right::after, .badge.badge-xlg.arrowed-in-right::after {
  border-width: 1.25rem 0.625rem;
  right: -0.625rem;
}

.badge-default {
  color: #fff;
  background-color: #8bb4cd;
  border-color: #8bb4cd;
}

.badge-primary {
  color: #fff;
  background-color: #5899ca;
  border-color: #5899ca;
}

.badge-secondary {
  color: #fff;
  background-color: #9aaab3;
  border-color: #9aaab3;
}

.badge-info {
  color: #fff;
  background-color: #57b5da;
  border-color: #57b5da;
}

.badge-success {
  color: #fff;
  background-color: #73bd73;
  border-color: #73bd73;
}

.badge-danger {
  color: #fff;
  background-color: #dd6a57;
  border-color: #dd6a57;
}

.badge-warning {
  color: #fff;
  background-color: #efae43;
  border-color: #efae43;
}

.badge-dark {
  color: #fff;
  background-color: #515559;
  border-color: #515559;
}

.badge-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #f5f6f9;
}

.badge-dot {
  font-size: 0px !important;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  padding: 0;
}

.badge-dot:empty {
  display: inline-block;
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropleft .dropdown-toggle::before,
.dropright .dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  line-height: 2rem;
}

.dropdown-item:not(.btn) {
  color: rgba(67, 72, 77, 0.93);
}

.dropdown-item:not(.btn):hover, .dropdown-item:not(.btn):focus {
  background-color: #f0f2f4;
  color: rgba(67, 72, 77, 0.93);
}

.dropdown-item:not(.btn):active, .dropdown-item:not(.btn).active {
  background-color: #e0ecf5;
  color: rgba(67, 72, 77, 0.96);
}

.dropdown-item.btn {
  padding: 0.25rem 1.5rem;
  width: auto;
  margin: 1px;
  font-size: 0.9375rem;
}

.dropdown-menu[class*="bgc-"]:not([class*="-m"]):not([class*="-l"]):not([class*="bgc-yellow"]) .dropdown-item:not(:hover) {
  color: #FFF;
}

.dropdown-item > a {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
}

li.dropdown-item {
  padding: 0 !important;
}

.page-content .dropdown-hover {
  position: relative;
}

.page-content .dropdown-hover > .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-0.5rem);
  transition: opacity 150ms 350ms, transform 150ms 350ms, visibility 0ms 500ms;
}

@media (prefers-reduced-motion: reduce) {
  .page-content .dropdown-hover > .dropdown-menu {
    transition: none;
  }
}

.page-content .dropdown-hover > .dropdown-menu {
  top: 100%;
  left: 0;
}

.page-content .dropdown-hover.dropright > .dropdown-menu {
  left: 100%;
  top: 0;
}

.page-content .dropdown-hover.dropup > .dropdown-menu {
  bottom: 100%;
  top: auto;
}

.page-content .dropdown-hover.dropleft > .dropdown-menu {
  left: auto;
  right: 100%;
  transform: translateX(0.5rem);
}

.page-content .dropdown-hover:hover > .dropdown-menu, .page-content .dropdown-hover .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  transform: none;
  transition-delay: 0ms;
}

.page-content .dropdown-menu:hover > .dropdown-hover:not(:hover) > .dropdown-menu {
  transition-delay: 50ms, 50ms, 200ms;
}

@media (max-width: 575.98px) {
  .dropdown-item.dropdown-hover > .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    top: 100% !important;
    bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-hover {
    position: relative;
  }
  .navbar .dropdown-hover > .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-0.5rem);
    transition: opacity 150ms 350ms, transform 150ms 350ms, visibility 0ms 500ms;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .dropdown-hover > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-hover.dropup > .dropdown-menu {
    bottom: 100%;
    top: auto;
  }
  .navbar .dropdown-hover:hover > .dropdown-menu, .navbar .dropdown-hover .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition-delay: 0ms;
  }
}

.dropdown-menu {
  border-radius: 0;
}

.dropdown-menu.dropdown-caret::before, .dropdown-menu.dropdown-caret::after {
  display: block;
  content: "";
  position: absolute;
  top: -0.5em;
  left: 10px;
  width: 0;
  height: 0;
  border-bottom: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-right: 0.5rem solid transparent;
  border-top: 0;
  border-left: 0.5rem solid transparent;
}

.dropdown-menu.dropdown-caret::after {
  border-bottom-color: #FFF;
}

.dropdown-menu.dropdown-caret::before {
  margin-top: -1px;
  border-bottom-color: inherit;
}

.dropdown-menu.dropdown-caret.dropdown-menu-right::before, .dropdown-menu.dropdown-caret.dropdown-menu-right::after {
  left: auto;
  right: 10px;
}

.dropdown-menu[x-placement^="top"].dropdown-caret::before, .dropdown-menu[x-placement^="top"].dropdown-caret::after {
  top: auto;
  bottom: -0.5em;
  border-top: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-bottom: 0;
}

.dropdown-menu[x-placement^="top"].dropdown-caret::after {
  border-top-color: #FFF;
}

.dropdown-menu[x-placement^="top"].dropdown-caret::before {
  margin-bottom: -1px;
  margin-top: 0;
  border-top-color: inherit;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::before, .dropdown-menu[x-placement^="right"].dropdown-caret::after {
  top: 12px;
  left: -0.5em;
  border-right: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-top: 0.5rem solid transparent;
  border-left: 0;
  border-bottom: 0.5rem solid transparent;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::after {
  border-right-color: #FFF;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::before {
  margin-left: -1px;
  margin-top: 0;
  border-right-color: inherit;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::before, .dropdown-menu[x-placement^="left"].dropdown-caret::after {
  top: 12px;
  right: -0.5em;
  left: auto;
  border-left: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-top: 0.5rem solid transparent;
  border-right: 0;
  border-bottom: 0.5rem solid transparent;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::after {
  border-left-color: #FFF;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::before {
  margin-right: -1px;
  margin-top: 0;
  border-left-color: inherit;
}

.dropdown-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  will-change: transform;
  transform-origin: top center;
  transition: opacity 150ms, transform 150ms, visibility 0s;
  transition-delay: 0s, 0s, 150ms !important;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-animated {
    transition: none;
  }
}

.dropdown-animated[x-placement^="bottom"] {
  top: 100% !important;
  bottom: auto !important;
}

.dropdown-animated[x-placement^="top"] {
  bottom: 100% !important;
  top: auto !important;
  transform-origin: bottom center;
}

.dropdown-animated.dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
}

.dropdown-animated:not(.show) {
  transform: scaleY(0) !important;
  pointer-events: none;
}

.dropdown-animated.animated-1 {
  transform: translateY(0) scale(0.6);
}

.dropdown-animated.animated-2 {
  transition: opacity 150ms, margin-top 150ms, visibility 0s;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-animated.animated-2 {
    transition: none;
  }
}

.dropdown-animated.animated-2:not(.show) {
  margin-top: -1rem !important;
}

.dropdown-animated.show {
  opacity: 1;
  visibility: visible;
  transform: none !important;
  transition-delay: 0ms, 0ms, 0ms !important;
}

@media (min-width: 1200px) {
  .dropdown.dropdown-hover:hover > .dropdown-menu.dropdown-animated {
    opacity: 1;
    visibility: visible;
    transform: none !important;
    transition-delay: 0ms, 0ms, 0ms !important;
    pointer-events: all;
  }
}

@keyframes dropdownSlideUp {
  from {
    transform: translate(0, 100%);
    display: none;
  }
  to {
    transform: translate(0, 0);
    display: block;
  }
}

@keyframes dropdownSlideCenter {
  from {
    transform: translate(0, 200%);
    display: none;
  }
  to {
    transform: translate(0, -50%);
    display: block;
  }
}

.dropdown-inner {
  background-color: inherit;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  padding: inherit;
  max-height: calc(100% - 2rem);
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
}

.dropdown-menu.dd-slide-up > .dropdown-inner {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: hidden;
}

.dropdown-menu.dd-slide-up.show > .dropdown-inner {
  animation-name: dropdownSlideUp;
  animation-duration: 150ms;
  animation-fill-mode: forwards;
  visibility: visible;
}

.dropdown-menu.dd-slide-center > .dropdown-inner {
  position: fixed;
  top: 50%;
  bottom: auto;
  left: 0;
  right: 0;
  max-width: calc(100% - 1rem);
  margin: 0 auto;
  width: 240px;
  visibility: hidden;
}

.dropdown-menu.dd-slide-center.show > .dropdown-inner {
  animation-name: dropdownSlideCenter;
  animation-duration: 150ms;
  animation-fill-mode: forwards;
  visibility: visible;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-menu.dd-slide-up.show > .dropdown-inner,
  .dropdown-menu.dd-slide-center.show > .dropdown-inner {
    animation-duration: 0ms;
  }
}

.dd-backdrop::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1040;
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms, visibility 0s;
  transition-delay: 0s, 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .dd-backdrop::before {
    transition: none;
  }
}

.dd-backdrop.show::before {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.dd-backdrop.show > .dropdown-toggle {
  position: relative;
}

.dd-backdrop.show .dropdown-menu {
  z-index: 1050;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]) {
  transform: none !important;
  will-change: auto !important;
  visibility: hidden;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).show > .dropdown-inner {
  visibility: visible;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).dropdown-caret::before, .dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).dropdown-caret::after {
  display: none !important;
}

.dropdown-menu.dd-slide-none.show {
  z-index: 1000;
}

.dropdown-menu.dd-slide-none > .dropdown-inner {
  position: static;
  animation: none !important;
  width: auto;
  max-width: none;
  max-height: none;
  margin: auto;
  overflow: visible;
  background-color: transparent;
  border: none;
  padding: 0;
}

.dd-backdrop-none::before {
  display: none;
}

@media (min-width: 576px) {
  .dropdown-menu.dd-slide-none-sm.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-sm > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .dd-backdrop-none-sm::before {
    display: none;
  }
}

@media (min-width: 768px) {
  .dropdown-menu.dd-slide-none-md.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-md > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .dd-backdrop-none-md::before {
    display: none;
  }
}

@media (min-width: 992px) {
  .dropdown-menu.dd-slide-none-lg.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-lg > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .dd-backdrop-none-lg::before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu.dd-slide-none-xl.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-xl > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .dd-backdrop-none-xl::before {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .dropdown-menu.dd-slide-none-sm {
    transform: none !important;
    will-change: auto !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-sm.dropdown-caret::before, .dropdown-menu.dd-slide-none-sm.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .dropdown-menu.dd-slide-none-md {
    transform: none !important;
    will-change: auto !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-md.dropdown-caret::before, .dropdown-menu.dd-slide-none-md.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .dropdown-menu.dd-slide-none-lg {
    transform: none !important;
    will-change: auto !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-lg.dropdown-caret::before, .dropdown-menu.dd-slide-none-lg.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-menu.dd-slide-none-xl {
    transform: none !important;
    will-change: auto !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-xl.dropdown-caret::before, .dropdown-menu.dd-slide-none-xl.dropdown-caret::after {
    display: none !important;
  }
}

.dropdown-menu.dd-slide-none {
  transform: none !important;
  will-change: auto !important;
  visibility: hidden !important;
}

.dropdown-menu.dd-slide-none.dropdown-caret::before, .dropdown-menu.dd-slide-none.dropdown-caret::after {
  display: none !important;
}

.page-link {
  color: #4491c0;
  background-color: #fdfeff;
  border-color: #cde7f2;
  border-radius: 0;
}

.page-link:hover {
  color: #5894bc;
  background-color: #f0f8fe;
  border-color: #a5ccda;
}

.page-link.active {
  z-index: 3;
}

.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4da4cb;
  border-color: #4da4cb;
}

.page-item.disabled .page-link {
  color: #798087;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-compact .page-link {
  margin-left: 0.425rem;
  border-width: 0;
}

.pagination .btn.page-link {
  border-radius: 0;
}

.tab-content {
  border: 1px solid #c5d0dc;
  padding: 1rem;
}

.tab-sliding {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.tab-sliding .tab-pane {
  max-width: 100%;
  flex: 1 0 auto;
  display: block !important;
  transform: none;
  visibility: hidden;
  max-height: 0;
  transition: margin-left, margin-right, transform, max-height, visibility;
  transition-duration: 350ms, 350ms, 350ms, 350ms, 0s;
  transition-delay: 0s, 0s, 0s, 0s, 340ms;
}

@media (prefers-reduced-motion: reduce) {
  .tab-sliding .tab-pane {
    transition: none;
  }
}

.tab-sliding .tab-pane:not(.active) {
  pointer-events: none;
}

.tab-sliding .tab-pane {
  margin-left: -100%;
}

.tab-sliding .tab-pane.active {
  transform: translateX(100%);
  visibility: visible;
  max-height: 100%;
  transition-delay: 0s;
}

.tab-sliding .tab-pane.mh-none:not(.active) {
  max-height: 0 !important;
}

.tab-sliding .tab-pane.active ~ .tab-pane {
  margin-left: 100%;
  margin-right: -200%;
}

.tab-pane.tab-swiping.active {
  overflow: hidden;
  transition: none;
  touch-action: none;
}

.tab-pane.tab-swiping:not(.active) {
  transition: none;
  visibility: visible;
  max-height: none;
}

.dropdown-menu:not(.show) .tab-sliding .tab-pane.active {
  visibility: hidden;
}

.dropdown-hover:hover .tab-sliding .tab-pane.active {
  visibility: visible;
}

.nav-tabs-scroll {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}

.nav-tabs-scroll:not([class*="sticky-nav"]) {
  position: relative;
}

@supports (overflow: overlay) or (scrollbar-width: none) {
  .nav-tabs-scroll.is-scrollable {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .nav-tabs-scroll.is-scrollable::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}

.nav-tabs {
  border-width: 0;
  border-color: #e2e7e9;
}

.nav-tabs .nav-item {
  display: flex;
}

.nav-tabs .nav-link {
  width: 100%;
  height: calc(100% - 1px);
  box-shadow: none !important;
  border: 1px solid #e5eaef;
  font-size: 0.95rem;
}

.nav-tabs .nav-link:not([class*="btn-"]) {
  color: #7c8087;
  background-color: #f8f8f8;
}

.nav-tabs .nav-link:not([class*="btn-"]):hover {
  background-color: #fff;
  border-color: #d1d9e3;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  height: calc(100% + 1px);
}

.nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .nav-tabs .nav-link.active:not([class*="btn-"]) {
  color: #6494b8;
  background-color: #fff;
  border-color: #c5d0dc;
  font-weight: 600;
}

.tabs-above > .nav-tabs:not(.simple-tabs) {
  border-width: 0;
}

.tabs-above > .nav-tabs:not(.simple-tabs) .nav-link {
  border-width: 1px 1px 0;
}

.nav-tabs .nav-item.show .nav-link, .tabs-above > .nav-tabs:not(.simple-tabs) .nav-link.active, .tabs-above > .nav-tabs:not(.simple-tabs) .nav-link.btn:active {
  margin-top: -1px;
  border-width: 2px 1px 1px;
}

.nav-tabs .nav-item.show .nav-link, .tabs-above > .nav-tabs:not(.simple-tabs) .nav-link.active {
  border-bottom-color: transparent;
}

.nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-above > .nav-tabs:not(.simple-tabs) .nav-link.active:not([class*="btn-"]) {
  border-top-color: #85afe2;
}

.nav-tabs.nav-tabs-simple .nav-link {
  border-width: 0;
  margin-right: 0.5rem;
  padding-left: 0.25rem;
  background-color: transparent;
  color: #7c8087;
  height: calc(100% - 1px);
}

.nav-tabs.nav-tabs-simple .nav-link:not(.active):hover {
  color: #586670;
  background-color: transparent;
}

.nav-tabs-simple .nav-item.show .nav-link, .nav-tabs.nav-tabs-simple .nav-link.active {
  border-width: 0 0 2px 0;
  color: #4881ac;
  font-weight: 600;
  background-color: transparent;
}

.nav-tabs-simple .nav-item.show .nav-link:not([class*="btn-"]), .nav-tabs.nav-tabs-simple .nav-link.active:not([class*="btn-"]) {
  border-bottom-color: #8eb0df;
}

.nav-tabs.nav-tabs-simple + .tab-content {
  border-width: 0;
  padding: 0.75rem;
}

.nav-tabs.nav-tabs-simple.nav-tabs-double {
  background-color: #f4f5f7;
  border-width: 1px 0;
  border-style: solid;
}

.nav-tabs.nav-tabs-simple.nav-tabs-double .nav-link.active,
.nav-tabs.nav-tabs-simple.nav-tabs-double .nav-item.show .nav-link {
  background-color: rgba(255, 255, 255, 0.87);
}

.tabs-below {
  display: flex;
  flex-direction: column-reverse;
}

.tabs-below > .nav-tabs .nav-item {
  margin-bottom: 0;
}

.tabs-below > .nav-tabs .nav-link {
  border-width: 0 1px 1px;
  border-radius: 0 0 0.25rem 0.25rem;
}

.tabs-below .nav-tabs .nav-item.show .nav-link, .tabs-below > .nav-tabs .nav-link.active, .tabs-below > .nav-tabs .nav-link.btn:active {
  margin-top: -1px;
  border-width: 1px 1px 2px;
}

.tabs-below .nav-tabs .nav-item.show .nav-link, .tabs-below > .nav-tabs .nav-link.active {
  border-top-color: transparent;
  position: relative;
}

.tabs-below .nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-below > .nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-bottom-color: #85afe2;
}

.tabs-left {
  display: flex;
  flex-direction: row;
}

.tabs-left > .tab-content {
  flex-grow: 1;
}

.tabs-left > .nav-tabs {
  flex-direction: column;
}

.tabs-left > .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-top: 0;
  display: list-item;
}

.tabs-left > .nav-tabs .nav-link {
  border-width: 1px 0 1px 1px;
  border-radius: 0.25rem 0 0 0.25rem;
  margin: 0 0 -1px 0;
  width: auto;
  height: auto;
}

.tabs-left .nav-tabs .nav-item.show .nav-link, .tabs-left > .nav-tabs .nav-link.active, .tabs-left > .nav-tabs .nav-link.btn:active {
  font-weight: 400;
  margin: 0 -1px -1px -1px;
  border-width: 1px 1px 1px 2px;
}

.tabs-left .nav-tabs .nav-item.show .nav-link, .tabs-left > .nav-tabs .nav-link.active {
  border-right-color: transparent;
  position: relative;
}

.tabs-left .nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-left > .nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-left-color: #85afe2;
}

.tabs-right {
  display: flex;
  flex-direction: row-reverse;
}

.tabs-right > .tab-content {
  flex-grow: 1;
}

.tabs-right > .nav-tabs {
  flex-direction: column;
}

.tabs-right > .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-top: 0;
  display: list-item;
}

.tabs-right > .nav-tabs .nav-link {
  border-width: 1px 1px 1px 0;
  border-radius: 0 0.25rem 0.25rem 0;
  margin: 0 0 -1px 0;
  width: auto;
  height: auto;
}

.tabs-right .nav-tabs .nav-item.show .nav-link, .tabs-right > .nav-tabs .nav-link.active, .tabs-right > .nav-tabs .nav-link.btn:active {
  font-weight: 400;
  margin: 0 -1px -1px -1px;
  border-width: 1px 2px 1px 1px;
}

.tabs-right .nav-tabs .nav-item.show .nav-link, .tabs-right > .nav-tabs .nav-link.active {
  border-left-color: transparent;
  position: relative;
}

.tabs-right .nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-right > .nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-right-color: #85afe2;
}

.nav-tabs-lg .nav-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.nav-tabs-lg .nav-item .nav-link {
  width: 100%;
}

.nav-tabs-lg .nav-link {
  border-radius: 0.25rem 0.25rem 0 0;
  font-size: 1rem;
  padding: 0.75rem 1rem;
}

.nav-tabs-lg.nav-tabs-compact.nav-tabs .nav-item {
  margin: 0 -1px -1px 0;
}

.nav-tabs-lg.nav-tabs-compact.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-tabs-lg.nav-tabs-compact.nav-tabs .nav-item:first-child .nav-link {
  border-top-left-radius: 0.25rem;
}

.nav-tabs-lg.nav-tabs-compact.nav-tabs .nav-item:last-child .nav-link {
  border-top-right-radius: 0.25rem;
}

.nav-tabs.page-nav-tabs {
  margin: 0 -1rem;
  padding: 0 1rem;
}

.nav-tabs.page-nav-tabs + .tab-content {
  border-width: 1px 0 0;
}

.nav-tabs.page-nav-tabs .nav-item .nav-link {
  width: 100%;
  transition: background-color 120ms, border-color 120ms, color 120ms;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: reduce) {
  .nav-tabs.page-nav-tabs .nav-item .nav-link {
    transition: none;
  }
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-item {
  margin: 0 1px -1px 0;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-link {
  font-weight: 600;
  border-bottom: 2px solid;
  padding: 0.75rem 0.5rem;
  margin-right: 2px;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-link:hover {
  background-color: #f6f8f9;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-link:not(.active) {
  border-bottom-color: transparent !important;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-link:not(.active):hover {
  border-bottom: 2px solid #a9c1d7;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-link.active,
.nav-tabs.page-nav-tabs.nav-tabs-simple .nav-item.show .nav-link {
  border-bottom-width: 3px;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple + .tab-content {
  border-top-width: 0;
}

.nav-tabs.page-nav-tabs.nav-tabs-simple.nav-tabs-double .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.85);
}

.nav-tabs.page-nav-tabs.nav-tabs-simple.nav-tabs-double .nav-link.active,
.nav-tabs.page-nav-tabs.nav-tabs-simple.nav-tabs-double .nav-item.show .nav-link {
  border-bottom-width: 2px;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed {
  background-color: #f7f8fa;
  border-width: 0;
  margin: 0;
  padding: 0;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item {
  margin: 0 -1px 0 0;
  display: flex;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item:last-child {
  margin-right: 0;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link {
  border-radius: 0;
  padding: 0.875rem 0.75rem;
  height: 100%;
  font-size: 0.925rem;
  border: 1px solid;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link:not([class*="btn-"]) {
  background-color: transparent;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link:not(.active) {
  border-color: rgba(0, 0, 0, 0.075) !important;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link:not([class*="btn-h-"]):not(.active):hover {
  background-color: #f4f4f5;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link[class*="btn-h-"]:hover {
  z-index: 1;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item:first-child .nav-link {
  border-radius: 0.25rem 0 0 0.25rem;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item:last-child .nav-link {
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link.active,
.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item.show .nav-link {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom-width: 3px;
  font-weight: 0.95rem;
  z-index: 2;
  background-color: #fff;
}

.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-link.active:not([class*="btn-a-"]),
.nav-tabs.page-nav-tabs.nav-tabs-boxed .nav-item.show .nav-link:not([class*="btn-a-"]) {
  color: #6097c0;
  border-color: #bad0ea #bad0ea #85afe2;
}

.nav-tabs[class*="nav-tabs-color-"] {
  padding: 0.325rem 0.75rem 0;
  border-style: solid;
  border-width: 1px 1px 0;
}

.nav-tabs[class*="nav-tabs-color-"] .nav-link {
  color: #FFF;
  border-color: transparent;
  margin-right: 3px;
}

.nav-tabs[class*="nav-tabs-color-"] .nav-link:last-child .nav-link {
  margin-right: 0;
}

.nav-tabs[class*="nav-tabs-color-"] .nav-link.active, .nav-tabs[class*="nav-tabs-color-"] .nav-item.show .nav-link {
  background-color: #FFF;
  font-weight: 400;
  color: #6494b8;
}

.tabs-below .nav-tabs[class*="nav-tabs-color-"] {
  padding: 0 0.75rem 0.325rem;
  border-width: 0 1px 1px;
}

.tabs-below .nav-tabs[class*="nav-tabs-color-"] .nav-link.active, .tabs-below .nav-tabs[class*="nav-tabs-color-"] .nav-item.show .nav-link {
  border-color: transparent #7db4d8 #7db4d8;
}

.tabs-left .nav-tabs[class*="nav-tabs-color-"] {
  padding: 0.125rem 0 0.325rem 0.325rem;
  border-width: 1px 0 1px 1px;
}

.tabs-left .nav-tabs[class*="nav-tabs-color-"] .nav-link {
  margin-right: 0;
  margin-top: 3px;
}

.tabs-left .nav-tabs[class*="nav-tabs-color-"] .nav-link.active, .tabs-left .nav-tabs[class*="nav-tabs-color-"] .nav-item.show .nav-link {
  margin-right: -1px;
  border-color: #7db4d8 transparent #7db4d8 #7db4d8;
}

.tabs-right .nav-tabs[class*="nav-tabs-color-"] {
  padding: 0.125rem 0.325rem 0.325rem 0;
  border-width: 1px 1px 1px 0;
}

.tabs-right .nav-tabs[class*="nav-tabs-color-"] .nav-link {
  margin-right: 0;
  margin-top: 3px;
}

.tabs-right .nav-tabs[class*="nav-tabs-color-"] .nav-link.active, .tabs-right .nav-tabs[class*="nav-tabs-color-"] .nav-item.show .nav-link {
  margin-left: -1px;
  margin-right: -1px;
  border-color: #7db4d8 #7db4d8 #7db4d8 transparent;
}

.nav-tabs.nav-tabs-color-blue {
  background-color: #eff3f8;
  border-color: #c5d0dc;
}

.nav-tabs.nav-tabs-color-blue .nav-link, .nav-tabs.nav-tabs-color-blue .nav-link:not(.active):hover {
  background-color: #7db4d8;
  border-color: transparent;
}

.nav-tabs.nav-tabs-color-blue .nav-link.active,
.nav-tabs.nav-tabs-color-blue .nav-item.show .nav-link {
  color: #6494b8;
  border-color: #7db4d8 #7db4d8 transparent;
}

.accordion .card {
  border: 1px solid #c9dce8;
}

.accordion .card:first-of-type, .accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #c9dce8;
}

.accordion .card[class*="brc-"] .card-header {
  border-bottom-width: 0;
}

.accordion .card + .card {
  margin-top: 0.125rem;
}

.accordion .card-header {
  border-bottom-color: #c9dce8;
  padding: 0;
}

.accordion .card-header .card-title {
  font-size: 1rem;
}

.accordion .card-header .accordion-toggle {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem 0.875rem;
  font-weight: 600;
  color: #508dc1;
  background-color: #f2f7fb;
  position: relative;
  overflow: hidden;
}

.accordion .card-header .accordion-toggle.collapsed {
  font-weight: 400;
  color: #6e94a8;
  background-color: #fbfbfb;
}

.accordion .card-header .accordion-toggle:hover {
  color: #508dc1;
  background-color: #f1f8fd;
  text-decoration: none;
}

a[data-toggle="collapse"].collapsed .toggle-icon {
  transform: none;
}

a[data-toggle="collapse"] .toggle-icon {
  transition: transform 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  a[data-toggle="collapse"] .toggle-icon {
    transition: none;
  }
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-down"] {
  transform: rotate(-180deg);
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-left"] {
  transform: rotate(-90deg);
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-right"] {
  transform: rotate(90deg);
}

.tooltip {
  top: 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement="top"] .arrow {
  left: calc(50% - 0.4rem) !important;
}

.bs-tooltip-top .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="top"] .arrow[class*="brc-"]::before {
  border-top-color: inherit;
}

.bs-tooltip-top .arrow + .arrow, .bs-tooltip-auto[x-placement="top"] .arrow + .arrow {
  margin-bottom: 2px;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement="bottom"] .arrow {
  left: calc(50% - 0.4rem) !important;
}

.bs-tooltip-bottom .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="bottom"] .arrow[class*="brc-"]::before {
  border-bottom-color: inherit;
}

.bs-tooltip-bottom .arrow + .arrow, .bs-tooltip-auto[x-placement="bottom"] .arrow + .arrow {
  margin-top: 2px;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement="left"] .arrow {
  top: calc(50% - 0.4rem) !important;
}

.bs-tooltip-left .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="left"] .arrow[class*="brc-"]::before {
  border-left-color: inherit;
}

.bs-tooltip-left .arrow + .arrow, .bs-tooltip-auto[x-placement="left"] .arrow + .arrow {
  margin-right: 2px;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement="right"] .arrow {
  top: calc(50% - 0.4rem) !important;
}

.bs-tooltip-right .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="right"] .arrow[class*="brc-"]::before {
  border-right-color: inherit;
}

.bs-tooltip-right .arrow + .arrow, .bs-tooltip-auto[x-placement="right"] .arrow + .arrow {
  margin-left: 2px;
}

.popover .arrow2 {
  z-index: 1;
}

.popover .arrow2::after, .popover .arrow2 + .arrow::after {
  display: none;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement="top"] .arrow {
  left: calc(50% - 0.5rem) !important;
  margin-left: 0;
  margin-right: 0;
}

.bs-popover-top .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="top"] .arrow[class*="brc-"]::before {
  border-top-color: inherit;
}

.bs-popover-top .arrow2, .bs-popover-auto[x-placement="top"] .arrow2 {
  margin-bottom: 1px;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement="bottom"] .arrow {
  left: calc(50% - 0.5rem) !important;
  margin-left: 0;
  margin-right: 0;
}

.bs-popover-bottom .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="bottom"] .arrow[class*="brc-"]::before {
  border-bottom-color: inherit;
}

.bs-popover-bottom .arrow2, .bs-popover-auto[x-placement="bottom"] .arrow2 {
  margin-top: 1px;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement="left"] .arrow {
  top: calc(50% - 0.5rem) !important;
  margin-top: 0;
  margin-bottom: 0;
}

.bs-popover-left .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="left"] .arrow[class*="brc-"]::before {
  border-left-color: inherit;
}

.bs-popover-left .arrow2, .bs-popover-auto[x-placement="left"] .arrow2 {
  margin-right: 1px;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement="right"] .arrow {
  top: calc(50% - 0.5rem) !important;
  margin-top: 0;
  margin-bottom: 0;
}

.bs-popover-right .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="right"] .arrow[class*="brc-"]::before {
  border-right-color: inherit;
}

.bs-popover-right .arrow2, .bs-popover-auto[x-placement="right"] .arrow2 {
  margin-left: 1px;
}

.popover .arrow[class*="brc-"] ~ .popover-header::before {
  display: none !important;
}

.alert {
  border-radius: 3px;
  color: #57585b;
}

.alert-collapse.collapsing {
  transition-duration: 150ms;
}

.ace-toaster-container {
  position: fixed !important;
  z-index: 1021;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ace-toaster-container.position-tr, .ace-toaster-container.position-br, .ace-toaster-container.position-rc {
  right: 1rem;
  align-items: end;
}

.ace-toaster-container.position-tr {
  top: 1rem;
}

.ace-toaster-container.position-br {
  bottom: 1rem;
}

.ace-toaster-container.position-tl, .ace-toaster-container.position-bl, .ace-toaster-container.position-lc {
  left: 1rem;
  align-items: start;
}

.ace-toaster-container.position-tl {
  top: 1rem;
}

.ace-toaster-container.position-bl {
  bottom: 1rem;
}

.ace-toaster-container.position-bc {
  top: auto;
  bottom: 1rem;
}

.ace-toaster-container.position-tc {
  bottom: auto;
  top: 1rem;
}

.ace-toaster-container.toaster-below-nav {
  margin-top: 4.5rem;
  margin-top: var(--navbar-height);
  z-index: 1019;
}

@media (max-width: 1199.98px) {
  .ace-toaster-container.toaster-below-nav {
    margin-top: 4rem;
    margin-top: var(--navbar-mobile-height);
  }
}

.ace-toaster-container .toast {
  pointer-events: all;
  position: relative;
  z-index: inherit;
  width: 24rem;
  max-width: calc(100vw - 2rem);
}

.ace-toaster-container .toast .toast-header {
  position: relative;
}

.ace-toaster-container .toast .toast-header .close {
  align-self: flex-start;
}

.ace-toaster-container .toast .toast-image {
  flex-shrink: 0;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  overflow: hidden;
  align-self: flex-start;
  padding: 1px;
}

.ace-toaster-container .toast .toast-image img {
  border-radius: 0.1875rem;
}

.ace-toaster-container .toast .toast-main {
  flex-grow: 1;
  width: 100%;
}

.ace-toaster-container .toast.collapsing {
  transition-duration: 250ms;
  margin-bottom: 0;
}

.ace-toaster-container .toast .toast-title {
  margin-right: auto;
  font-weight: 600;
}

.modal-header {
  border-bottom-color: #e4e9ee;
}

.modal-footer {
  border-top-color: #e4e9ee;
  background-color: #eff3f8;
}

.ace-aside {
  transition: z-index 0s 250ms;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside {
    transition: none;
  }
}

.ace-aside.aside-hover {
  transition-delay: 750ms;
}

.ace-aside.show, .ace-aside.aside-hover:hover {
  transition-delay: 0s;
}

.ace-aside.modal-nb {
  pointer-events: none;
  z-index: 1018;
}

.ace-aside.modal-nb.show, .ace-aside.modal-nb.aside-hover:hover {
  z-index: 1021;
}

.ace-aside.modal-nb.aside-offset:not(.aside-below-nav) {
  z-index: 1020;
}

.ace-aside.modal-nb.aside-offset:not(.aside-below-nav).show, .ace-aside.modal-nb.aside-offset:not(.aside-below-nav).aside-hover:hover {
  z-index: 1021;
}

.ace-aside.aside-below-nav.show, .ace-aside.aside-below-nav.aside-hover:hover {
  z-index: 1019;
}

.ace-aside:not(.modal-nb) {
  z-index: 1018;
}

.ace-aside:not(.modal-nb).show, .ace-aside:not(.modal-nb).aside-hover:hover {
  z-index: 1050;
}

.ace-aside:not(.modal-nb) {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.ace-aside:not(.modal-nb).aside-bottom {
  display: flex !important;
  align-items: flex-end;
}

.ace-aside:not(.modal-nb).aside-bottom:not(.aside-r):not(.aside-l):not(.aside-c) .modal-dialog {
  width: 100%;
}

.ace-aside {
  display: block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: auto;
  height: auto;
  visibility: hidden;
}

.ace-aside.modal-nb {
  overflow: visible !important;
}

.ace-aside.show:not(.modal-nb) {
  visibility: visible;
}

.ace-aside.aside-hover:not(.modal-nb):hover {
  visibility: visible;
}

.ace-aside.fade {
  opacity: 1;
}

.ace-aside.fade .modal-dialog {
  transition-duration: 250ms;
}

.ace-aside .modal-dialog {
  margin: 0 auto;
  display: flex;
  transition: transform 250ms;
  max-width: none;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside .modal-dialog {
    transition: none;
  }
}

.ace-aside .modal-dialog .aside-header {
  pointer-events: all;
  order: -1;
  position: relative;
  z-index: 1;
  visibility: visible;
}

.ace-aside .modal-dialog .modal-content {
  z-index: 2;
}

.ace-aside.show .modal-dialog {
  visibility: visible;
}

.ace-aside.aside-top, .ace-aside.aside-bottom {
  left: 0;
  right: 0;
  width: 100%;
}

.ace-aside.aside-top .modal-dialog, .ace-aside.aside-bottom .modal-dialog {
  align-items: center;
  transform: translateY(0);
}

.ace-aside.aside-top .aside-header, .ace-aside.aside-bottom .aside-header {
  height: 3rem;
}

.ace-aside.aside-top {
  top: 0;
  bottom: auto;
}

.ace-aside.aside-top .modal-dialog {
  transform: translateY(-100%);
  flex-direction: column-reverse;
  margin-top: 0;
}

.ace-aside.aside-top .modal-content {
  border-width: 0 0 1px 0;
}

.ace-aside.aside-top.aside-offset .modal-dialog {
  transform: translateY(-100%) translateY(3rem);
}

.ace-aside.aside-top.show .modal-dialog, .ace-aside.aside-top.aside-hover:hover .modal-dialog {
  transform: translateY(0);
}

.ace-aside.aside-top.show.margin-1 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateY(0.25rem);
}

.ace-aside.aside-top.show.margin-2 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateY(0.5rem);
}

.ace-aside.aside-top.show.margin-3 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateY(1rem);
}

.ace-aside.aside-top.show.margin-4 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateY(1.5rem);
}

.ace-aside.aside-top.show.margin-5 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateY(3rem);
}

.ace-aside.aside-bottom {
  bottom: 0;
  top: auto;
}

.ace-aside.aside-bottom .modal-dialog {
  transform: translateY(100%);
  flex-direction: column;
  margin-bottom: 0;
}

.ace-aside.aside-bottom .modal-content {
  border-width: 1px 0 0 0;
}

.ace-aside.aside-bottom.aside-offset .modal-dialog {
  transform: translateY(100%) translateY(-3rem);
}

.ace-aside.aside-bottom.show .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover .modal-dialog {
  transform: translateY(0);
}

.ace-aside.aside-bottom.show.margin-1 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateY(-0.25rem);
}

.ace-aside.aside-bottom.show.margin-2 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateY(-0.5rem);
}

.ace-aside.aside-bottom.show.margin-3 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateY(-1rem);
}

.ace-aside.aside-bottom.show.margin-4 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateY(-1.5rem);
}

.ace-aside.aside-bottom.show.margin-5 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateY(-3rem);
}

.ace-aside.aside-l .modal-dialog, .ace-aside.aside-r .modal-dialog, .ace-aside.aside-c .modal-dialog {
  width: 500px;
  max-width: calc(100vw - 1rem);
}

.ace-aside.aside-l .modal-dialog, .ace-aside.aside-l .modal-dialog {
  margin-left: 0;
}

.ace-aside.aside-r .modal-dialog, .ace-aside.aside-r .modal-dialog {
  margin-right: 0;
}

.ace-aside.aside-right, .ace-aside.aside-left {
  top: 0;
  bottom: 0;
}

.ace-aside.aside-right .modal-dialog, .ace-aside.aside-left .modal-dialog {
  align-items: center;
  width: 500px;
  max-width: calc(100vw - 1rem);
  height: 100%;
  max-height: 100vh;
}

.ace-aside.aside-right .modal-dialog .modal-content, .ace-aside.aside-left .modal-dialog .modal-content {
  height: 100%;
}

.ace-aside.aside-right .modal-dialog .aside-header, .ace-aside.aside-left .modal-dialog .aside-header {
  width: 3rem;
  flex: 0 0 auto;
  word-break: break-all;
}

.ace-aside.aside-right {
  right: 0;
  left: auto;
}

.ace-aside.aside-right .modal-dialog {
  transform: translateX(100%);
  flex-direction: row;
  margin-right: 0;
}

.ace-aside.aside-right .aside-header {
  order: -1;
}

.ace-aside.aside-right .modal-content {
  border-width: 0 0 0 1px;
}

.ace-aside.aside-right.aside-offset .modal-dialog {
  transform: translateX(100%) translateX(-3rem);
}

.ace-aside.aside-right.show .modal-dialog, .ace-aside.aside-right.aside-hover:hover .modal-dialog {
  transform: translateX(0);
}

.ace-aside.aside-right.show.margin-1 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateX(-0.25rem);
}

.ace-aside.aside-right.show.margin-2 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateX(-0.5rem);
}

.ace-aside.aside-right.show.margin-3 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateX(-1rem);
}

.ace-aside.aside-right.show.margin-4 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateX(-1.5rem);
}

.ace-aside.aside-right.show.margin-5 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateX(-3rem);
}

.ace-aside.aside-left {
  left: 0;
  right: auto;
}

.ace-aside.aside-left .modal-dialog {
  transform: translateX(-100%);
  flex-direction: row-reverse;
  margin-left: 0;
}

.ace-aside.aside-left .modal-content {
  border-width: 0 1px 0 0;
}

.ace-aside.aside-left.aside-offset .modal-dialog {
  transform: translateX(-100%) translateX(3rem);
}

.ace-aside.aside-left.show .modal-dialog, .ace-aside.aside-left.aside-hover:hover .modal-dialog {
  transform: translateX(0);
}

.ace-aside.aside-left.show.margin-1 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateX(0.25rem);
}

.ace-aside.aside-left.show.margin-2 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateX(0.5rem);
}

.ace-aside.aside-left.show.margin-3 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateX(1rem);
}

.ace-aside.aside-left.show.margin-4 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateX(1.5rem);
}

.ace-aside.aside-left.show.margin-5 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateX(3rem);
}

.ace-aside.aside-m {
  margin-top: auto;
  margin-bottom: auto;
}

.ace-aside.aside-m .modal-dialog {
  max-height: calc(100vh - 2rem);
  height: 400px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.ace-aside.aside-center {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

.ace-aside.aside-center .modal-dialog {
  width: 500px;
  max-width: calc(100vw - 1rem);
}

.ace-aside.aside-below-nav:not(.aside-bottom) {
  top: 4.5rem;
  top: var(--navbar-height);
}

@media (min-width: 1200px) {
  .navbar-sm ~ .main-container .ace-side.aside-below-nav:not(.aside-bottom) {
    top: 4rem;
    top: var(--navbar-sm-height);
  }
}

@media (max-width: 1199.98px) {
  .ace-aside.aside-below-nav:not(.aside-bottom) {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
}

.ace-aside .modal-content, .ace-aside:not(.aside-offset) .aside-header {
  visibility: hidden;
  transition: visibility 0s 250ms;
}

.ace-aside.show .modal-content, .ace-aside.show:not(.aside-offset) .aside-header, .ace-aside.aside-hover:hover .modal-content, .ace-aside.aside-hover:hover:not(.aside-offset) .aside-header {
  visibility: visible;
  transition-delay: 0s;
}

.ace-aside {
  /**
    .modal-content.scroll-body {
        max-height: 100%;
        overflow: hidden;

        .modal-body {
            overflow: hidden auto;
            flex: 2 1 auto;
        }
    }
    */
}

.ace-aside .modal-content.scroll-content {
  max-height: 100%;
  overflow: hidden auto;
}

@media (min-width: 1200px) {
  .container .aside-right.container, .container .aside-left.container {
    transform: none;
    right: 0;
    left: 0;
    width: 100%;
  }
  .container .aside-right.container, .container .aside-left.container {
    clip-path: inset(-30px 0px -30px 0px);
  }
  .container .aside-right.container .modal-dialog {
    margin-right: 0;
  }
  .container .aside-left.container .modal-dialog {
    margin-left: 0;
  }
  .container .container:not(.modal-nb).aside-left, .container .container:not(.modal-nb).aside-right {
    min-width: 100%;
    max-height: none;
  }
  .container .container:not(.modal-nb).aside-left .modal-dialog, .container .container:not(.modal-nb).aside-right .modal-dialog {
    max-width: inherit;
    width: auto;
    margin: auto;
    justify-content: flex-end;
    align-items: center;
  }
  .container .container:not(.modal-nb).aside-left .modal-dialog .modal-content, .container .container:not(.modal-nb).aside-right .modal-dialog .modal-content {
    width: 500px;
    max-width: calc(100vw - 1rem);
  }
  .container .container:not(.modal-nb).aside-top, .container .container:not(.modal-nb).aside-bottom {
    min-width: 100%;
  }
  .container .container:not(.modal-nb).aside-top .modal-dialog, .container .container:not(.modal-nb).aside-bottom .modal-dialog {
    max-width: inherit;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .container .container:not(.modal-nb).aside-r .modal-content, .container .container:not(.modal-nb).aside-l .modal-content, .container .container:not(.modal-nb).aside-c .modal-content {
    width: 500px;
    max-width: calc(100vw - 1rem);
  }
  .container .container:not(.modal-nb).aside-l .modal-dialog {
    align-items: flex-start;
  }
  .container .container:not(.modal-nb).aside-r .modal-dialog {
    align-items: flex-end;
  }
  .container .aside-m.container:not(.modal-nb) .modal-dialog {
    max-height: calc(100vh - 2rem);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.ace-aside.aside-fade.aside-offset {
  opacity: 1;
}

.ace-aside.aside-fade .modal-dialog {
  transform: none !important;
  visibility: hidden;
}

.ace-aside.aside-fade .modal-content {
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  transition: transform 250ms 0s, opacity 250ms 0s, visibility 0s 250ms;
  z-index: 2;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside.aside-fade .modal-content {
    transition: none;
  }
}

.ace-aside.aside-fade .aside-header {
  z-index: 1;
  visibility: visible;
}

.ace-aside.aside-fade .aside-header.fade {
  opacity: 1;
  transition: all 250ms;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside.aside-fade .aside-header.fade {
    transition: none;
  }
}

.ace-aside.aside-fade.show .modal-content, .ace-aside.aside-fade.aside-hover:hover .modal-content {
  transform: none;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.ace-aside.aside-fade.show .aside-header.fade, .ace-aside.aside-fade.aside-hover:hover .aside-header.fade {
  transform: scale(0.75);
  opacity: 0;
}

.ace-aside.aside-fade.aside-right .modal-content, .ace-aside.aside-fade.aside-right .aside-header {
  transform-origin: right center;
}

.ace-aside.aside-fade.aside-left .modal-content, .ace-aside.aside-fade.aside-left .aside-header {
  transform-origin: left center;
}

.ace-aside.aside-fade.aside-top .modal-content, .ace-aside.aside-fade.aside-top .aside-header {
  transform-origin: top center;
}

.ace-aside.aside-fade.aside-top.aside-r .modal-content, .ace-aside.aside-fade.aside-top.aside-r .aside-header {
  transform-origin: top right;
}

.ace-aside.aside-fade.aside-top.aside-l .modal-content, .ace-aside.aside-fade.aside-top.aside-l .aside-header {
  transform-origin: top left;
}

.ace-aside.aside-fade.aside-bottom .modal-content, .ace-aside.aside-fade.aside-bottom .aside-header {
  transform-origin: bottom center;
}

.ace-aside.aside-fade.aside-bottom.aside-r .modal-content, .ace-aside.aside-fade.aside-bottom.aside-r .aside-header {
  transform-origin: bottom right;
}

.ace-aside.aside-fade.aside-bottom.aside-l .modal-content, .ace-aside.aside-fade.aside-bottom.aside-l .aside-header {
  transform-origin: bottom left;
}

.aside-hover .modal-dialog {
  transition-delay: 500ms;
}

.aside-hover:hover .modal-dialog {
  transition-delay: 100ms;
}

.modal:not(.show) .d-show {
  display: none;
}

.modal.show .d-n-show {
  display: none;
}

body.modal-open:not(.scrollbar-w0).modal-nb {
  overflow-y: auto;
  padding-right: 0 !important;
}

@media (min-width: 1200px) {
  body.modal-open:not(.scrollbar-w0):not(.modal-nb) .navbar-fixed:not(.container) .navbar-inner {
    padding-right: var(--modal-padding);
  }
  body.modal-open:not(.scrollbar-w0):not(.modal-nb) .container .footer-fixed .footer-inner {
    left: calc(-1 * var(--modal-padding));
  }
}

body.modal-open:not(.modal-nb):not(.scrollbar-w0) .aside-top, body.modal-open:not(.modal-nb):not(.scrollbar-w0) .aside-bottom {
  padding-right: var(--modal-padding) !important;
}

body.modal-open:not(.modal-nb):not(.scrollbar-w0) .aside-center .modal-dialog {
  right: var(--modal-padding);
}

body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0) .aside-right {
  padding-right: var(--modal-padding) !important;
}

body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0) .aside-right.aside-m {
  right: var(--modal-padding) !important;
}

@media (max-width: 1199.98px) {
  body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0) .navbar-fixed .navbar-inner {
    padding-right: var(--modal-padding);
  }
}

@media (min-width: 1200px) {
  body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0) .navbar-fixed .navbar-inner.container {
    right: var(--modal-padding);
    padding-right: 0;
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.scrollbar-w0) .container .ace-aside.container.aside-bottom {
    left: calc(-1 * var(--modal-padding));
    padding-right: 0 !important;
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0) .container .ace-aside.container.aside-right {
    padding-right: calc( var(--modal-padding) / 2) !important;
    clip-path: inset(-30px calc( var(--modal-padding) / 2) -30px 0px);
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0) .container .ace-aside.container.aside-left {
    left: calc(-1 * var(--modal-padding));
  }
}

@supports ((overscroll-behavior: none) and (-webkit-appearance: none) and (-webkit-box-reflect: above)) or ((overscroll-behavior: none) and (scrollbar-width: none)) {
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .aside-bottom {
    padding-right: 0 !important;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .aside-center .modal-dialog {
    right: 0;
  }
  @media (min-width: 1200px) {
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .navbar-fixed:not(.container) .navbar-inner {
      padding-right: 0;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .footer-fixed .footer-inner {
      left: 0;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-bottom {
      left: 0;
      padding-right: 0 !important;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-right {
      padding-right: 0 !important;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-left {
      left: 0;
    }
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) {
    padding: 0 !important;
    overflow-y: auto;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .modal[aria-modal=true] {
    overscroll-behavior: none;
    padding: 0 !important;
    overflow-y: auto;
    scrollbar-width: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .modal[aria-modal=true]::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .modal[aria-modal=true]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: calc(100% + 0.5rem);
    pointer-events: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .ace-aside[aria-modal=true]::before {
    height: calc(100% + 1px);
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .modal-dialog-scrollable .modal-body {
    overscroll-behavior: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0) .container .ace-aside.container.aside-bottom {
    left: 0;
    margin-left: auto;
  }
}

.modal-fs {
  padding-right: 0 !important;
}

.modal-fs .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 1rem);
  max-height: calc(100vh - 1rem);
}

.modal-fs .modal-dialog .modal-content {
  min-height: 100%;
}

@media (min-width: 576px) {
  .modal-fs .modal-dialog {
    max-width: calc(100vw - 3.5rem);
    max-height: calc(100vh - 3.5rem);
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: none;
  }
  .modal-lg .modal-dialog,
  .modal-xl .modal-dialog {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: none;
  }
  .modal-xl .modal-dialog {
    max-width: 1140px;
  }
}

.modal-dialog-static {
  transform: none !important;
}

.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  transform: none !important;
  height: calc(100% - 0.5rem);
}

@media (min-width: 576px) {
  .modal-dialog-bottom {
    height: calc(100% - 1.75rem);
  }
}

th {
  font-weight: 600;
}

.table td, .table th {
  border-color: rgba(0, 0, 0, 0.075);
}

.table thead {
  color: #717276;
}

.table thead th {
  border-bottom-width: 1px;
}

.table-hover tbody tr:hover {
  color: #444549;
  background-color: rgba(0, 0, 0, 0.05);
}

.table-bordered th {
  border-bottom-width: 1px;
}

.table-bordered-x th, .table-bordered-x td {
  border-width: 1px 0;
  border-style: solid;
}

.table-bordered-y th, .table-bordered-y td {
  border-width: 0 1px;
  border-style: solid;
}

.table[class*="brc-"] > thead, .table[class*="brc-"] > tbody, .table[class*="brc-"] > tr,
.table[class*="brc-"] > thead > tr, .table[class*="brc-"] > tbody > tr,
.table[class*="brc-"] > thead > tr > th, .table[class*="brc-"] > tr > th,
.table[class*="brc-"] > tbody > tr > td, .table[class*="brc-"] > tr > td {
  border-color: inherit;
}

.table thead[class*="brc-"] > tr, .table thead[class*="brc-"] > tr > th, .table thead[class*="brc-"] > tr > td, .table tbody[class*="brc-"] > tr, .table tbody[class*="brc-"] > tr > th, .table tbody[class*="brc-"] > tr > td {
  border-color: inherit;
}

.table tr[class*="brc-"] > th, .table tr[class*="brc-"] > td {
  border-color: inherit;
}

.table-spaced {
  border-spacing: 0 0.75rem;
  border-collapse: separate;
}

.table-spaced td, .table-spaced th {
  border-width: 0;
}

.table-lg td, .table-lg th {
  padding: 0.875rem;
}

table.bt tbody tr td {
  padding: 0;
}

table.bt tbody tr td .bt-content {
  padding: 0.375rem;
  padding-left: 0.875rem;
}

table.bt tbody tr td:first-child::before, table.bt tbody tr td:first-child .bt-content {
  padding-top: 0.875rem;
}

table.bt tbody tr td:last-child .bt-content {
  padding-bottom: 0.875rem;
}

table.bt tbody tr {
  border-bottom: 1px solid #fff;
}

table.bt tbody td::before {
  background-color: #71a8d2;
  color: #fff;
  margin-right: 0.625rem;
  padding: 0.125rem 0.625rem;
  padding-top: 0.125rem;
  font-weight: 600;
}

table.bt tbody[class*="bgc-"] td::before {
  background-color: inherit;
}

table.bt tbody[class*="bgc-"] td .bt-content {
  flex: 2 1 0;
  background-color: #fff;
}

.table[class*="table-striped"]:not(.bt) tbody tr:nth-of-type(2n) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f6f7f7;
}

.table-striped.bt tbody tr:nth-of-type(2n+1) {
  background-color: transparent;
}

.table-striped.bt tbody tr:nth-of-type(2n+1) > td .bt-content {
  background-color: #f6f7f7;
}

.table-striped-danger tbody tr:nth-of-type(2n+1) {
  background-color: #fdf6f4;
}

.table-striped-danger.bt tbody tr:nth-of-type(2n+1) {
  background-color: transparent;
}

.table-striped-danger.bt tbody tr:nth-of-type(2n+1) > td .bt-content {
  background-color: #fdf6f4;
}

.table-striped-success tbody tr:nth-of-type(2n+1) {
  background-color: #f4faf4;
}

.table-striped-success.bt tbody tr:nth-of-type(2n+1) {
  background-color: transparent;
}

.table-striped-success.bt tbody tr:nth-of-type(2n+1) > td .bt-content {
  background-color: #f4faf4;
}

.table-striped-warning tbody tr:nth-of-type(2n+1) {
  background-color: #fdf6ea;
}

.table-striped-warning.bt tbody tr:nth-of-type(2n+1) {
  background-color: transparent;
}

.table-striped-warning.bt tbody tr:nth-of-type(2n+1) > td .bt-content {
  background-color: #fdf6ea;
}

.table-striped-info tbody tr:nth-of-type(2n+1) {
  background-color: #f2f9fc;
}

.table-striped-info.bt tbody tr:nth-of-type(2n+1) {
  background-color: transparent;
}

.table-striped-info.bt tbody tr:nth-of-type(2n+1) > td .bt-content {
  background-color: #f2f9fc;
}

.icon-glossy {
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: normal;
}

.icon-glossy::before {
  content: "";
  display: block;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  left: 1px;
  right: 2px;
  top: 0;
  bottom: 5px;
  z-index: 0;
}

.progress {
  border-radius: 0.125rem;
}

.shadow-md {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.c-pointer {
  cursor: pointer;
}

.icon-animated-bell {
  display: inline-block;
  animation: ringing 2.0s 5 ease 1.0s;
  transform-origin: 50% 0%;
}

@keyframes ringing {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}

.icon-animated-vertical {
  display: inline-block;
  animation: vertical 2.0s 5 ease 2.0s;
}

@keyframes vertical {
  0% {
    transform: translate(0, -3px);
  }
  4% {
    transform: translate(0, 3px);
  }
  8% {
    transform: translate(0, -3px);
  }
  12% {
    transform: translate(0, 3px);
  }
  16% {
    transform: translate(0, -3px);
  }
  20% {
    transform: translate(0, 3px);
  }
  22% {
    transform: translate(0, 0);
  }
}

.icon-animated-hand-pointer {
  display: inline-block;
  animation: hand-pointer 2.0s 4 ease 2.0s;
}

@keyframes hand-pointer {
  0% {
    -moz-transform: translate(0, 0);
  }
  6% {
    -moz-transform: translate(5px, 0);
  }
  12% {
    -moz-transform: translate(0, 0);
  }
  18% {
    -moz-transform: translate(5px, 0);
  }
  24% {
    -moz-transform: translate(0, 0);
  }
  30% {
    -moz-transform: translate(5px, 0);
  }
  36% {
    -moz-transform: translate(0, 0);
  }
}

.icon-animated-wrench {
  display: inline-block;
  animation: wrenching 2.5s 4 ease;
  transform-origin: 90% 35%;
}

@keyframes wrenching {
  0% {
    transform: rotate(-12deg);
  }
  8% {
    transform: rotate(12deg);
  }
  10% {
    transform: rotate(24deg);
  }
  18% {
    transform: rotate(-24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  28% {
    transform: rotate(24deg);
  }
  30% {
    transform: rotate(24deg);
  }
  38% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  48% {
    transform: rotate(24deg);
  }
  50% {
    transform: rotate(24deg);
  }
  58% {
    transform: rotate(-24deg);
  }
  60% {
    transform: rotate(-24deg);
  }
  68% {
    transform: rotate(24deg);
  }
  75% {
    transform: rotate(0deg);
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes pulsating {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(0.75);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.25);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes blinkAndpulse {
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
}

.typing-dots {
  color: #aaa;
  visibility: hidden;
  display: inline-block;
  line-height: 0;
  letter-spacing: -0.1875rem;
  white-space: nowrap;
}

.typing-dots .typing-dot {
  visibility: visible;
  position: relative;
  display: inline-block;
  color: inherit;
  opacity: 0.4;
  min-height: 0.625rem;
  vertical-align: middle;
  animation: 1.5s blinkAndpulse infinite 0.5s;
}

.typing-dots .typing-dot:nth-child(2) {
  animation-delay: 1s;
}

.typing-dots .typing-dot:nth-child(3) {
  animation-delay: 1.5s;
}

@media (prefers-reduced-motion: reduce) {
  [class*="icon-animated"], .typing-dot {
    animation: none !important;
  }
}

.jqtree-toggler + .jqtree-title .opened-icon {
  display: inline-block;
}

.jqtree-toggler + .jqtree-title .closed-icon {
  display: none;
}

.jqtree-closed + .jqtree-title .opened-icon {
  display: none;
}

.jqtree-closed + .jqtree-title .closed-icon {
  display: inline-block;
}

ul.jqtree-tree {
  margin: auto;
  padding: 0 0 0 1rem;
  position: relative;
}

ul.jqtree_common ul.jqtree_common {
  margin-left: 0.5rem;
  padding-left: 1rem;
}

ul.jqtree-tree li.jqtree_common {
  margin: 0.5rem 0;
}

.jqtree_common {
  position: relative;
}

ul.jqtree_common::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: -1.125rem;
  bottom: 0;
  left: 0;
  width: 0;
  border-left: 1px dotted #67b2dd;
  z-index: auto;
}

ul.jqtree-tree::before {
  left: 0;
  bottom: 0.5rem;
}

ul.jqtree-tree > .jqtree_common:last-child::after, li.jqtree_common:last-child::after {
  display: inline-block;
  content: "";
  position: absolute;
  top: calc(1rem - 1px);
  bottom: -0.5rem;
  left: -1rem;
  width: 0px;
  border-left: 1px solid #fff;
  z-index: 1;
}

li.jqtree_common:last-child::after {
  left: -1rem;
}

li.jqtree_common {
  position: relative;
}

li.jqtree_common::before {
  display: block;
  content: "";
  position: absolute;
  top: 0.925rem;
  left: calc(-1rem + 2px);
  border-top: 1px dotted #67b2dd;
  height: 1px;
  width: 1.125rem;
  z-index: auto;
}

ul.jqtree-tree .jqtree-title {
  margin-left: 0.325rem;
}

.tree-dotted ul.jqtree-tree .jqtree-title:not(.jqtree-title-folder) {
  margin-left: 0.925rem;
}

ul.jqtree-tree li.jqtree-selected > .jqtree-element, ul.jqtree-tree li.jqtree-selected > .jqtree-element:hover {
  background: #e6f1f8;
}

.jqtree-tree .jqtree-element {
  padding: 0.125rem 0.125rem 0.25rem;
  align-items: center;
}

.jqtree-folder > .jqtree-element {
  margin-left: 1rem;
}

.jqtree-folder > .jqtree-element > .jqtree-toggler {
  margin-left: -1.125rem;
}

.node-icon {
  margin-right: 0.5rem;
  margin-left: 0.125rem;
}

ul.jqtree-tree .jqtree-moving > .jqtree-element .jqtree-title {
  outline: none;
}

ul.jqtree-tree span.jqtree-border {
  border-color: #57b5da;
}

ul.jqtree-tree li.jqtree-ghost span.jqtree-circle {
  border-color: #57b5da;
}

ul.jqtree-tree li.jqtree-ghost span.jqtree-line {
  background-color: #57b5da;
}

span.jqtree-dragging {
  z-index: 4;
}

.jqtree.tree-dotted .jqtree_common:not(.jqtree-folder) > .jqtree-element {
  position: relative;
}

.jqtree.tree-dotted .jqtree_common:not(.jqtree-folder) > .jqtree-element::before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;
  background-color: #91a3ac;
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.fixed-table-pagination, .fixed-table-toolbar {
  border-style: solid;
  border-width: 1px 0;
  padding: 0.25rem 1.25rem;
}

.fixed-table-toolbar {
  background-color: #f2f7fb;
  margin-bottom: -1px;
  border-color: #ebebec;
}

.fixed-table-pagination {
  background-color: #f2f7fb;
  margin-top: -1px;
  border-color: #ebebec;
}

.bootstrap-table .fixed-table-container.fixed-height:not(.has-footer) {
  border-bottom-width: 0;
}

.bootstrap-table .fixed-table-container {
  position: static;
}

.bootstrap-table .fixed-table-container .table tbody tr.selected td {
  background-color: #eef7ee;
}

.bootstrap-table th .sortable {
  background-image: none !important;
  position: relative;
}

.bootstrap-table th .sortable::before, .bootstrap-table th .sortable::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 0.46875rem;
  top: 50%;
  bottom: auto;
  opacity: 0.3;
  color: inherit;
  border-right: 0.3125rem solid transparent;
  border-left: 0.3125rem solid transparent;
}

.bootstrap-table th .sortable::before {
  border-bottom: 0.3125rem solid currentColor;
  border-top: none;
  margin-top: -0.3125rem;
}

.bootstrap-table th .sortable::after {
  border-top: 0.3125rem solid currentColor;
  border-bottom: none;
  margin-top: 0.125rem;
}

.bootstrap-table th .sortable.asc::before, .bootstrap-table th .sortable.desc::after {
  opacity: 1;
}

.table.dataTable {
  margin: auto !important;
}

.dataTables_wrapper > .row:first-of-type {
  padding: 1.25rem 0.5rem 0.75rem;
  margin: auto;
  background-color: #f2f7fb;
}

.dataTables_wrapper > .row:last-of-type {
  padding: 0.75rem 0.5rem 1.25rem;
  margin: auto;
  background-color: #f2f7fb;
  border-bottom: 1px solid #ebebec;
}

.dataTables_wrapper div.dataTables_length select.form-control {
  width: 4rem;
  margin: 0 0.125rem;
}

.dataTable tr.selected {
  background-color: #eef7ee;
}

.dataTables_info .select-item {
  font-size: 90%;
  opacity: 0.9;
  margin-left: 0.5rem;
}

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: transparent;
  z-index: 202;
}

div.DTCR_pointer {
  width: 0.125rem;
  background-color: #5899ca;
  z-index: 201;
}

.dataTable th::after, .dataTable th::before {
  content: "" !important;
  right: 0.46875rem !important;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::before, .dataTable th[class*=sorting]:not(.sorting_disabled)::after {
  width: 0;
  height: 0;
  color: inherit;
  border-right: 0.3125rem solid transparent;
  border-left: 0.3125rem solid transparent;
  top: 50% !important;
  bottom: auto !important;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::before {
  border-bottom: 0.3125rem solid currentColor;
  border-top: none;
  margin-top: -0.3125rem;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::after {
  border-top: 0.3125rem solid currentColor;
  border-bottom: none;
  margin-top: 0.125rem;
}

.ui-jqgrid table {
  border-collapse: collapse;
}

.ui-jqgrid .ui-jqgrid-view,
.ui-jqgrid .ui-paging-info,
.ui-jqgrid .ui-pg-table,
.ui-jqgrid .ui-pg-selbox {
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-view .btn,
.ui-jqgrid .ui-jqgrid-pager .btn {
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-title, .ui-jqgrid .ui-jqgrid-title-rtl {
  margin: 0 0.75rem;
}

.ui-jqgrid .disabled {
  opacity: 0.35;
}

.ui-jqgrid .jqgfirstrow td {
  padding: 0 !important;
}

.ui-jqgrid .ui-jqgrid-bdiv tr > td:last-child {
  border-right-width: 0;
}

@media (max-width: 575.98px) {
  .ui-jqgrid .ui-pg-selbox {
    display: block;
  }
}

.ui-jqgrid .ui-jqgrid-titlebar,
.ui-jqgrid .ui-jqgrid-errorbar {
  font-size: 1.25rem;
}

.ui-jqgrid-btable input:not([type=checkbox]), .ui-jqgrid-btable textarea, .ui-jqgrid-btable select {
  width: auto;
  max-width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 0.125rem;
}

.ui-jqgrid-btable input:not([type=checkbox]):hover, .ui-jqgrid-btable textarea:hover, .ui-jqgrid-btable select:hover {
  border-color: #b5b5b5;
}

.ui-jqgrid-btable input:not([type=checkbox]):focus, .ui-jqgrid-btable textarea:focus, .ui-jqgrid-btable select:focus {
  box-shadow: 0 0 0 2px rgba(245, 153, 66, 0.2);
  color: #696969;
  border-color: #F59942;
  background-color: #FFF;
}

.ui-jqgrid tr.ui-row-ltr td,
.ui-jqgrid tr.ui-row-rtl td {
  padding: 0.75rem;
}

.ui-jqgrid .ui-pg-button .ui-separator {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-color: #a1b1b8;
}

.ui-jqgrid .ui-jqgrid-sortable {
  padding: 0.75rem 0;
}

.ui-jqgrid th[aria-selected=true] {
  background-color: #f9f0b8 !important;
  color: rgba(0, 0, 0, 0.73) !important;
}

.ui-jqgrid .s-ico {
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  right: 0.125rem;
}

.ui-jqgrid .s-ico > .ui-grid-ico-sort {
  height: 0.45rem;
  line-height: 0.5;
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-bdiv {
  overflow: auto;
  overflow: overlay;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.075), -1px 0 0 0 rgba(0, 0, 0, 0.075);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
}

@media (hover: hover) {
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar {
    width: 7px;
  }
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
  }
}

.ui-jqdialog .ui-jqdialog-titlebar {
  font-size: 1.25rem;
}

.ui-jqdialog .FormData {
  border-bottom: 1px dotted #e2e7e9;
}

.ui-jqdialog .FormData:nth-last-child(2) {
  border-bottom: none;
}

.ui-jqdialog pre {
  font-size: 1rem;
}

.ui-jqdialog[id*="alertcnt_"] {
  padding: 0.5rem 0.75rem;
}

.ui-jqdialog .ui-resizable-handle {
  bottom: 1px;
  right: 3px;
}

.ui-jqdialog .searchFilter td {
  padding: 0.375rem 0;
}

.ui-jqdialog .searchFilter .queryresult {
  margin-bottom: 0.75rem;
}

.ui-jqdialog .searchFilter .queryresult td.query {
  padding: 0.5rem 0.75rem;
  border-bottom: 2px solid #e2e3e4;
  background-color: #f3f3f3;
}

.ui-jqdialog .searchFilter .queryresult td.query:empty {
  display: none;
}

.ui-pg-table.navtable {
  float: none !important;
}

.ui-pager-control > .ui-pg-table > tbody > tr > td {
  width: auto !important;
}

@media (max-width: 991.98px) {
  .ui-pager-control > .ui-pg-table > tbody > tr > td {
    display: block;
    text-align: center;
    margin: 0.5rem;
    min-height: 1.75rem;
    scrollbar-width: none;
    overflow-y: auto;
  }
}

.ui-subgrid .ui-jqgrid-htable .ui-jqgrid-labels {
  background-color: transparent;
}

.ui-subgrid .ui-jqgrid-bdiv {
  max-height: 10rem;
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar {
  border-top: 1px solid #e2e3e4;
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar .ui-th-column {
  border-top-width: 0;
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar .ui-search-input .form-control:not([size]):not([multiple]) {
  min-height: 1.75rem;
}

.fm-button.btn-default .fm-button-text, .fm-button.btn-secondary .fm-button-text {
  color: #fff;
}

.fm-button:only-of-type {
  margin: 0 0.25rem;
}

.ui-jqgrid .ui-jqgrid-bdiv .jqgroup {
  background-color: #fff !important;
}

.ui-jqgrid .ui-jqgrid-bdiv .jqgroup > td {
  color: #508dc1;
  border-left: 3px solid #87c4ec !important;
}

*::-ms-backdrop,
.ui-jqgrid .jqgrow > td > .cbox {
  width: auto;
  height: auto;
}

.chosen-container {
  font-size: 1rem;
}

.chosen-container > .chosen-single, [class*="chosen-container"] > .chosen-single {
  font-size: 1rem;
  line-height: 2.25rem;
  height: 2.5rem;
  background: #fafafa;
  box-shadow: none;
}

.chosen-choices {
  box-shadow: none !important;
}

.chosen-container-single .chosen-single .search-choice-close {
  background: none;
  height: 2.5rem;
  top: 0;
}

.chosen-container-single .chosen-single .search-choice-close::after {
  content: "\00D7";
  font-size: 1.25rem;
}

.chosen-container-single .chosen-single .search-choice-close:hover::after {
  color: #da3737;
}

.chosen-container-single .chosen-single div b {
  background: none;
}

.chosen-container-single .chosen-single div b::after {
  content: "";
  position: absolute;
  top: calc(50% - 0.25em);
  right: 0.5em;
  width: 0;
  height: 0;
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: none;
}

.chosen-container-active.chosen-with-drop .chosen-single div b::after {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-single .chosen-search {
  position: relative;
}

.chosen-container-single .chosen-search input[type="text"] {
  background: none;
  border-radius: 0;
  line-height: 2.25rem;
  height: 2.5rem;
  font-size: 1rem;
}

.chosen-container-single .chosen-search::after {
  content: "\1F50D";
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 1.25em;
}

.chosen-container .chosen-results li {
  line-height: 1.125rem;
  padding: 0.5rem;
}

.chosen-container-multi .chosen-choices {
  padding: 0.25rem 0.5rem;
  background: none;
  border-radius: 2px;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 1.5rem;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #eee;
  line-height: 1.5rem;
  font-size: 1rem;
  padding-bottom: 3px;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: none;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close::after {
  content: "\00D7";
  font-size: 1.125rem;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover::after {
  color: #da3737;
}

.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
  background: none;
}

.chosen-container .chosen-results-scroll-down span:before,
.chosen-container .chosen-results-scroll-up span:before {
  content: "";
  position: relative;
  top: -1px;
  left: 1px;
  width: 0;
  height: 0;
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
  content: "\f0d7";
  display: inline-block;
}

.chosen-container .chosen-results-scroll-up span:before {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-active .chosen-single-with-drop div b:before {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-single .chosen-single {
  border-radius: 2px;
}

.chosen-container .chosen-results li.highlighted {
  background: #4f99c6;
  color: #fff;
}

.chosen-container .chosen-drop {
  border-radius: 0;
  border-color: #4492c9;
  border-bottom-width: 2px;
}

.chosen-single.chosen-single-with-drop, .chosen-container-active .chosen-single {
  border-color: #4492c9;
}

.chosen-container-multi .chosen-drop {
  border-top-width: 0;
}

.chosen-container-active .chosen-choices {
  border-color: #4492c9;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: #4492c9;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: auto;
  right: auto;
  display: block;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice {
  background: none;
  background-color: #6da1c0;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 0.375rem 1.375rem 0.375rem 0.5rem;
  position: relative;
  white-space: nowrap;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1.125rem;
  height: auto;
  line-height: 1.5;
  text-align: center;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close::after {
  color: #FFF;
  position: static;
  font-size: 0.9rem;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover::after {
  color: #FFF;
}

/**
 .chosen-single , .chosen-choices {
    .has-error & {
        border-color: $btn-danger !important;
    }
    .has-info & {
      border-color: $btn-info !important;
    }
    .has-warning & {
      border-color: $btn-warning !important;
    }
    .has-success & {
      border-color: $btn-success !important;
    }
 }
*/
.select2-container .select2-selection {
  border-radius: 0.125rem;
  border-color: #aaa;
}

.select2-container .select2-selection .select2-selection__rendered {
  line-height: inherit;
  height: 100%;
}

.select2-container.select2-container--focus .select2-selection {
  border-color: #4492c9;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  position: relative;
  overflow: visible;
}

.select2-container .select2-selection__clear {
  position: absolute;
  left: auto;
  top: 0;
  height: 100%;
  font-size: 1.25rem;
  color: #6e7074;
  width: 1.5rem;
  text-align: center;
}

.select2-container .select2-selection__clear:hover {
  color: #da3737;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  right: -2.5rem;
  top: auto;
  height: auto;
}

.select2-container .select2-selection--multiple .select2-selection__clear:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.select2-container--open .select2-dropdown {
  border-radius: 0;
  border-color: #4492c9;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.125);
}

.select2-results__option {
  padding: 0.5rem;
  margin-bottom: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0.25rem 0.375rem;
  border-radius: 0.125rem;
}

.select2-container .select2-selection--single {
  height: 2.5rem;
  line-height: 2.25rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
  margin-top: 0.14167rem;
  margin-left: -0.6375rem;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 0.425rem;
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
}

.select2-container--default .select2-selection .select2-selection__choice__remove {
  font-size: 1rem;
  margin-right: 3px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #eceff2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4f99c6;
}

.select2-container .select2-search--inline {
  max-width: calc(100% - 1.5rem);
}

.select2-container .select2-search--inline .select2-search__field {
  max-width: 100%;
  margin: 0.25rem 0;
  padding-left: 0.25rem;
}

.select2-search {
  background: none;
}

.select2-search:before {
  content: "\1F50D";
  font-size: 1rem;
  color: #888;
  position: absolute;
  right: 0.5rem;
  margin-top: 2px;
}

.select2-selection--multiple .select2-search:before {
  display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  margin-top: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 1px;
}

.tag-input-style .select2-selection .select2-selection__choice {
  position: relative;
  color: #fff;
  background-color: #6da1c0;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  margin: 2px;
  padding: 0.3125rem 1.5rem 0.3125rem 0.5rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}

.tag-input-style .select2-selection .select2-selection__choice .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2px;
  left: auto;
  width: 1.125rem;
  height: auto;
  line-height: 2rem;
  text-align: center;
  color: #FFF;
}

.tag-input-style .select2-selection .select2-selection__choice .select2-selection__choice__remove:hover {
  background-color: rgba(0, 0, 0, 0.15);
  color: #FFF;
}

/**
.select2-container--default .select2-selection {
    .has-error & {
        border-color: $btn-danger !important;
    }
    .has-info & {
        border-color: $btn-info !important;
    }
    .has-warning & {
        border-color: $btn-warning !important;
    }
    .has-success & {
        border-color: $btn-success !important;
    }
}
*/
.sw-main > ul.step-anchor > li.wizard-progressbar {
  position: absolute;
  z-index: 2;
  top: calc(50% - 0.125rem);
  bottom: 0;
  height: 0.25rem;
  max-width: 0;
  background-color: #5293c4;
  transition: all 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sw-main > ul.step-anchor > li.wizard-progressbar {
    transition: none;
  }
}

.sw-main > ul.step-anchor > li.wizard-progressbar::before, .sw-main > ul.step-anchor > li.wizard-progressbar::after {
  display: none !important;
}

.sw-theme-circles > ul.step-anchor {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  width: 75%;
}

@media (max-width: 767.98px) {
  .sw-theme-circles > ul.step-anchor {
    width: 100%;
    margin-bottom: 3.5rem;
  }
}

.sw-theme-circles > ul.step-anchor::before {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li {
  display: inline-block;
  margin-left: 0;
  flex-grow: 1;
  position: relative;
  z-index: auto;
  border: 0px solid;
}

.sw-theme-circles > ul.step-anchor > li, .sw-theme-circles > ul.step-anchor > li.active ~ li.done {
  border-color: #ced1d6;
}

.sw-theme-circles > ul.step-anchor > li.active, .sw-theme-circles > ul.step-anchor > li.done {
  border-color: #5293c4;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done), .sw-theme-circles > ul.step-anchor > li.active ~ li.done {
  border-color: #ced1d6 !important;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done) .step-title, .sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title {
  color: #546474 !important;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done) .step-description, .sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-description {
  color: #5f7183 !important;
}

.sw-theme-circles > ul.step-anchor > li::before {
  display: block;
  content: "";
  width: 100%;
  height: 0;
  overflow: hidden;
  border-top: 0.25rem solid;
  border-color: inherit;
  position: absolute;
  top: calc(50% - 0.125rem);
  z-index: 0;
}

.sw-theme-circles > ul.step-anchor > li:last-child::before {
  left: 0;
  width: 50%;
}

.sw-theme-circles > ul.step-anchor > li:first-child::before, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar + li::before {
  left: 50%;
  width: 50%;
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li::before {
  border-color: #ced1d6;
}

.sw-theme-circles > ul.step-anchor > li.nav-item > a,
.sw-theme-circles > ul.step-anchor > li.nav-item.active ~ li.done > a {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  padding: 0;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #546474;
  font-size: 1.125rem;
  background-color: #fff;
  border: 5px solid;
  border-color: inherit !important;
  box-shadow: none !important;
}

@media (max-width: 575.98px) {
  .sw-theme-circles > ul.step-anchor > li.nav-item > a,
  .sw-theme-circles > ul.step-anchor > li.nav-item.active ~ li.done > a {
    width: 3.25rem;
    height: 3.25rem;
    border-width: 4px;
  }
}

.sw-theme-circles > ul.step-anchor > li.nav-item.active > a, .sw-theme-circles > ul.step-anchor > li.nav-item.done > a {
  background-color: #fff;
  color: #546474;
  border-color: inherit !important;
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar.active ~ li.done > a {
  transition: border-color 75ms, border-width 150ms, font-size 150ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar.active ~ li.done > a {
    transition: none;
  }
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li.active > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li.done > a {
  transition-delay: 75ms;
  transition-duration: 150ms;
}

.sw-theme-circles > ul.step-anchor > li:not(.done) > a:hover {
  cursor: default;
}

.sw-theme-circles > ul.step-anchor > li > a:hover, .sw-theme-circles > ul.step-anchor > li > a:focus {
  border-width: 5px;
  background-color: #fff;
  color: #546474;
  border-color: inherit;
}

.sw-theme-circles > ul.step-anchor > li.done > a:hover, .sw-theme-circles > ul.step-anchor > li.active ~ li.done > a:hover {
  border-width: 4px;
  font-size: 1.325rem;
}

.sw-theme-circles > ul.step-anchor > li .step-title-done {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li.done .step-title {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li.done .step-title-done {
  display: inline-block;
}

.sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title {
  display: inline-block;
}

.sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title-done {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li .step-description {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child > a, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li > a {
  margin: 0 !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child::before, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li::before {
  left: 0;
  width: 100%;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child::after, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li::after {
  display: none !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child > a {
  margin: 0 0 0 auto !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child::before {
  left: 0;
  width: 100%;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child::after {
  display: none !important;
}

.sw-theme-circles .sw-toolbar {
  padding-left: 0;
  padding-right: 0;
}

@supports (-moz-appearance: none) {
  .sw-theme-circles > ul.step-anchor > li.wizard-progressbar {
    margin-top: 1px;
  }
}

.sw-theme-circles > .sw-container {
  padding: 1rem;
  border-width: 1px 0;
  border-style: dotted;
}

.sw-btn-finish, .sw-btn-next.sw-btn-hide.disabled {
  display: none;
}

.sw-btn-next.disabled ~ .sw-btn-finish {
  display: inline-block;
}

.fc-bootstrap th, .fc-bootstrap td, .fc-bootstrap hr, .fc-bootstrap thead, .fc-bootstrap tbody, .fc-bootstrap .fc-row, .fc-bootstrap .fc-popover {
  border-color: #d5e5f2;
}

.fc-bootstrap .fc-event {
  border-width: 0;
  border-radius: 0.125rem;
}

.fc-bootstrap .fc-day-number {
  color: #64758d;
}

.fc-bootstrap .fc-axis {
  background: #eef4f8;
  color: #60889e;
}

.fc-bootstrap .fc-highlight {
  background: #baddf4;
}

.fc-bootstrap a {
  text-decoration: none;
}

.fc-bootstrap a[data-goto]:hover {
  text-decoration: underline;
}

.fc-bootstrap hr.fc-divider {
  border-color: inherit;
}

.fc-bootstrap .fc-today.alert {
  border-radius: 0;
}

.fc-bootstrap a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}

.fc-bootstrap .fc-popover.card {
  position: absolute;
}

.fc-bootstrap .fc-popover .card-body {
  padding: 0;
}

.fc-bootstrap .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}

.fc-scroller {
  scrollbar-width: thin;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
  appearance: none;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
  }
  .fc-toolbar > .fc-center {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .fc-toolbar > .fc-left {
    align-self: flex-end;
    order: 10;
  }
  .fc-toolbar > .fc-right {
    align-self: flex-start;
    order: -1;
  }
}

.dp-modal {
  z-index: 1050;
}

.dp {
  padding-top: 0;
  border-top: 0.25rem solid #619fcd;
  border-radius: 3px;
  font-size: 1rem;
}

.dp::before {
  display: none;
}

.dp .dp-col-header {
  font-size: 0.875em;
  color: #999;
}

.dp .dp-day {
  font-size: 0.925em;
}

.dp-cal-month, .dp-cal-year, .dp-day, .dp-month, .dp-year {
  border-radius: 0;
  font-size: 0.925em;
}

.dp-day:hover, .dp-month:hover, .dp-year:hover {
  background: #EEE;
  color: #3B404D;
}

.dp-selected:hover, .dp-selected:focus, .dp-selected,
.dp-current:focus, .dp-current, .dp-day:focus, .dp-month:focus, .dp-year:focus {
  background: rgba(86, 152, 201, 0.93);
  color: #FFF;
}

.dp-close:hover, .dp-close:focus,
.dp-clear:hover,
.dp-clear:focus,
.dp-today:hover,
.dp-today:focus {
  background: rgba(72, 175, 214, 0.93);
  color: #fff;
}

.dp-next,
.dp-prev {
  top: 0.75rem;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 2px solid #d1dce7;
  color: #7a868d;
}

.dp-next:hover, .dp-next:focus,
.dp-prev:hover,
.dp-prev:focus {
  background-color: #FFF;
  color: #5698C9;
  border-color: rgba(86, 152, 201, 0.67);
}

.dp-cal-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dp-cal-month,
.dp-cal-year {
  vertical-align: middle;
  padding: 0.425rem 0.75rem 0.5rem;
}

.dp-cal-month:hover, .dp-cal-month:focus,
.dp-cal-year:hover,
.dp-cal-year:focus {
  color: #3B404D;
  background-color: #e7e7e7;
}

.dp-day-today::after {
  border-right-color: #F2D11C;
  border-top-color: #F2D11C;
}

.dr-cals {
  display: flex;
  position: relative;
  z-index: 11;
  overflow: visible;
  box-shadow: none;
  padding-top: 0;
  border-top: 0.25rem solid #619fcd;
  border-radius: 3px;
  font-size: 1rem;
}

.dr-cals::before {
  display: none;
}

.dr-cals .dr-in-range, .dr-cals .dr-in-range:hover, .dr-cals .dr-in-range:focus {
  color: #3B404D;
  background-color: #e9f3fd;
}

.dr-cals .dp-current:hover {
  color: #3B404D;
  background-color: #e9f3fd;
  transform: scale(1.1);
}

.dr-cals .dr-selected, .dr-cals .dr-selected:hover, .dr-cals .dr-selected:focus {
  color: #fff;
  background: #619fcd;
  border-radius: 0;
}

.dp-daterange-picker {
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: opacity 0.15s, visibility 0s 0.15s, z-index 0s 0.15s;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
}

@media (prefers-reduced-motion: reduce) {
  .dp-daterange-picker {
    transition: none;
  }
}

.dp-daterange-picker.visible {
  opacity: 1;
  z-index: 100;
  visibility: visible;
  transition: opacity 0.15s, visibility 0s 0s, z-index 0s 0s;
}

@media (prefers-reduced-motion: reduce) {
  .dp-daterange-picker.visible {
    transition: none;
  }
}

.dp-daterange-above {
  top: auto;
  bottom: 100%;
}

.dr-cal-end .dp {
  border-left-width: 0.25rem;
}

@media (max-width: 616px), (max-height: 480px) {
  .dr-cal-end {
    display: none;
  }
  .dr-cal-start .dp-next {
    visibility: visible;
  }
}

.bootstrap-touchspin .btn-group-vertical, .bootstrap-touchspin .input-group-btn-vertical {
  display: flex;
  flex-direction: column;
}

.bootstrap-touchspin .btn-group-vertical > .btn, .bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
  height: 50%;
  border-radius: 0 3px 0 0;
}

.bootstrap-touchspin .btn-group-vertical > .btn:last-child, .bootstrap-touchspin .input-group-btn-vertical > .btn:last-child {
  margin-top: 1px;
  border-radius: 0 0 3px 0;
}

.bootstrap-touchspin .btn-group-inside-spinner {
  padding-right: 2.25rem;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2rem;
  text-align: center;
  line-height: 1;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 2px 0 0;
  top: 0;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 2px 0;
  bottom: 0;
}

.noUi-target {
  box-shadow: none;
  border-width: 0;
  border-radius: 0.125rem;
  background-color: #DDD;
}

.noUi-target[disabled] {
  opacity: 0.75;
  background-color: #E5E5E5;
}

.noUi-target:not([disabled]):not(.disabled) .noUi-handle {
  cursor: pointer;
}

.noUi-target .noUi-handle {
  border-radius: 2px;
  border-color: #bababa;
  box-shadow: 0 3px 6px -3px #BBB;
}

.noUi-target .noUi-handle.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 6px #D7D7D7, 0 3px 6px -3px #BBB;
}

.noUi-target .noUi-handle::before {
  display: none;
}

.noUi-target .noUi-handle::after {
  background-color: transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: 45%;
  margin: auto;
  color: #999;
  background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
  background-position: calc(50% - 3px) center, center, calc(50% + 3px) center;
  background-size: 1px 100%;
  background-repeat: no-repeat;
}

.noUi-target .noUi-handle.slider-bars-h::after {
  height: auto;
  width: 45%;
  background-position: center calc(50% - 3px), center, center calc(50% + 3px);
  background-size: 100% 1px;
}

.noUi-target .noUi-handle.slider-bars-none::after {
  display: none;
}

.noUi-target.noUi-horizontal {
  height: 0.75rem;
}

.noUi-target.noUi-horizontal .noUi-handle {
  width: 2rem;
  height: 2rem;
  top: -0.625rem;
  right: -1rem;
}

.noUi-target.noUi-vertical {
  width: 0.75rem;
}

.noUi-target.noUi-vertical .noUi-handle {
  width: 2rem;
  height: 2rem;
  right: -0.625rem;
  top: -1rem;
}

.noUi-target.slider-sm.noUi-horizontal {
  height: 0.5rem;
}

.noUi-target.slider-sm.noUi-horizontal .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  top: -0.625rem;
  right: -0.875rem;
}

.noUi-target.slider-sm.noUi-vertical {
  width: 0.5rem;
}

.noUi-target.slider-sm.noUi-vertical .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.625rem;
  top: -0.875rem;
}

.noUi-target.slider-thin.noUi-horizontal {
  height: 0.3125rem;
}

.noUi-target.slider-thin.noUi-horizontal .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  top: -0.71875rem;
  right: -0.875rem;
}

.noUi-target.slider-thin.noUi-vertical {
  width: 0.3125rem;
}

.noUi-target.slider-thin.noUi-vertical .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.71875rem;
  top: -0.875rem;
}

.noUi-target.slider-sm .noUi-handle::after, .noUi-target.slider-thin .noUi-handle::after {
  padding-top: 0.125rem;
}

.noUi-target.noUi-toggle-tooltip .noUi-tooltip {
  display: none;
}

.noUi-target.noUi-toggle-tooltip .noUi-active .noUi-tooltip {
  display: block;
}

.noUi-tooltip {
  padding: 0.25rem 0.325rem;
}

.slider-tooltip-right .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  bottom: auto;
  left: 120%;
  right: auto;
}

.slider-tooltip-left .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  bottom: auto;
  right: 120%;
  left: auto;
}

.slider-tooltip-top .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  right: auto;
  bottom: 120%;
  top: auto;
}

.slider-tooltip-bottom .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  right: auto;
  top: 120%;
  bottom: auto;
}

.slider-tooltip-caret .noUi-tooltip::before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.slider-tooltip-caret.slider-tooltip-bottom .noUi-tooltip {
  margin-top: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-bottom .noUi-tooltip::before {
  top: -0.5em;
  left: calc(50% - 0.5em);
  border-bottom: 0.5em solid;
  border-bottom-color: inherit;
  border-top: 0;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-top .noUi-tooltip {
  margin-bottom: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-top .noUi-tooltip::before {
  bottom: -0.5em;
  left: calc(50% - 0.5em);
  border-top: 0.5em solid;
  border-top-color: inherit;
  border-bottom: 0;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-left .noUi-tooltip {
  margin-right: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-left .noUi-tooltip::before {
  right: -0.5em;
  top: calc(50% - 0.5em);
  border-left: 0.5em solid;
  border-left-color: inherit;
  border-right: 0;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-right .noUi-tooltip {
  margin-left: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-right .noUi-tooltip::before {
  left: -0.5em;
  top: calc(50% - 0.5em);
  border-right: 0.5em solid;
  border-right-color: inherit;
  border-left: 0;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}

.irs--flat .irs-line {
  height: 1rem;
  border-radius: 2px;
}

.irs--flat .irs-bar {
  height: 1rem;
  border-radius: 2px 0 0 2px;
}

.irs--flat .irs-handle {
  height: 1.325rem;
  z-index: auto;
}

.irs-from, .irs-to, .irs-single {
  top: -0.25rem;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  font-size: 0.875rem;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  font-size: 0.75rem;
}

.dropzone .dz-preview .dz-progress.progress {
  border-radius: 0.1875rem;
}

.dropzone .dz-preview .dz-progress.progress .dz-upload {
  background: none;
}

.dropzone .dz-preview.dz-image-preview {
  background-color: transparent;
}

.note-btn {
  font-size: 1rem;
  color: #7b7d81;
  padding: 0.375rem 0.75rem;
  border-width: 0 !important;
}

.note-btn:hover, .note-btn:focus, .note-btn.focus {
  color: #64758d;
  background-color: #d3e9f8;
}

.note-btn > .fa {
  color: inherit;
  font-size: 1rem;
}

.note-btn > .note-current-fontname {
  color: inherit;
}

.note-toolbar {
  background-color: #f2f2f3;
  border-color: #e2e3e4;
}

.md-editor > textarea {
  background-color: #f6f7f7;
}

.card-body > .md-editor {
  border-width: 0;
}

.bootstrap-wysiwyg-editor {
  min-height: 200px;
  max-height: calc(100vh - 2rem);
  overflow: auto;
}

.bootstrap-wysiwyg-editor > img {
  margin: 0.25rem;
}

.card-body .bootstrap-wysiwyg-editor {
  border-radius: 0 0 0.25rem 0.25rem;
}

.bootstrap-wysiwyg-toolbar {
  background-color: #f2f2f3;
  padding: 0.25rem 0.3125rem;
}

.bootstrap-wysiwyg-toolbar .btn[data-edit], .bootstrap-wysiwyg-toolbar a[data-toggle] {
  background-color: #fff;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 {
  border: 1px solid #e2e3e4;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 .btn[data-edit], .bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 a[data-toggle] {
  border-radius: 0;
  border-width: 0;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 .btn-group + .btn-group {
  margin-left: 2px;
}

.card-body .bsw-toolbar-style-2 {
  border-width: 0 0 1px 0;
}

.file-input-invisible {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.popover-wysiwyg-image {
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  width: 300px;
  max-width: calc(100vw - 2rem);
}

.popover-wysiwyg-image .arrow {
  display: none;
}

.rating-container .badge {
  font-size: 0.8125rem;
  font-weight: 600;
}

.rating-container .filled-stars {
  text-shadow: none;
  color: inherit;
  -webkit-text-stroke: 1px currentColor;
}

input.typeahead ~ .dropdown-menu {
  width: 100%;
}

input.typeahead.scrollable ~ .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.bootstrap-tagsinput {
  color: #6e7074;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  display: block;
  padding: 0.5rem 0.375rem 0.25rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

.bootstrap-tagsinput:hover {
  border-color: #b5b5b5;
}

.bootstrap-tagsinput.tagsinput-focus, .bootstrap-tagsinput.tagsinput-focus:hover {
  border-color: #F59942;
  outline: 0;
}

.bootstrap-tagsinput input {
  display: inline;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  color: inherit;
}

.bootstrap-tagsinput .tag {
  display: inline-block;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 0.25rem;
  margin-right: 1px;
  padding-right: 1.75rem !important;
  transition: all 0.15s;
}

.bootstrap-tagsinput .tag:not(.badge) {
  background-color: #6da1c0;
  color: #fff;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-tagsinput .tag {
    transition: none;
  }
}

.bootstrap-tagsinput .tag:empty {
  display: none;
}

.bootstrap-tagsinput .tag span[data-role="remove"] {
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  width: 1.25rem;
  text-align: center;
  line-height: 2;
  cursor: pointer;
}

.bootstrap-tagsinput .tag span[data-role="remove"]:hover {
  background-color: rgba(0, 0, 0, 0.125);
}

.bootstrap-tagsinput .tag span[data-role="remove"]::after {
  content: "\00D7";
  font-size: 110%;
}

/*# sourceMappingURL=ace.css.map */