.navbar {
	padding: 0;
	flex: 0 0 auto;
	
	height: map-get($navbar-common, 'height');
	height: var(--navbar-height);

	visibility: hidden;

	z-index: $zindex-navbar + 1;
}
.navbar-expanded {
	//z-index: $zindex-navbar + 1;//to appear above some modals, etc
}
@if map-get($navbar-common, 'sm-height') != null {
 .navbar-sm {
	height: map-get($navbar-common, 'sm-height');
	height: var(--navbar-sm-height);
 }
}

//navbar mobile height is applied when sidebar becomes mobile view (i.e. hidden)
@if map-get($navbar-common, 'mobile-height') != null {
	@include media-breakpoint-down($sidebar-mobile-max-width) { 
		.navbar {
			height: map-get($navbar-common, 'mobile-height');
			height: var(--navbar-mobile-height);
		}
	}
}


.navbar-inner {
	height: inherit;
	width: 100%;

	display: flex;
	justify-content: space-between;

	background-color: inherit;

	visibility: visible;
}
.navbar.container > .navbar-inner {
	max-width: inherit;

	//margin-left: auto;
	//margin-right: auto;
}
.navbar-inner.container {
	padding: 0;
	flex-wrap: nowrap;
}
.navbar-inner > .container {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	align-items: stretch;

	padding: 0;	
	height: 100%;

	background-color: inherit;

	position: relative;
}


.navbar-brand {
	margin-left: 0.875rem;
	font-size: map-get($navbar-common, 'brand-font-size');
}

.navbar-intro {
	display: flex;
	align-items: center;

	width: map-get($sidebar-common, 'width');
    width: var(--sidebar-width);

	height: 100%;
}

.navbar-content {
	display: flex;
	align-items: center;
	height: 100%;

	flex: 1 0 auto;//flex grow

	//.btn-burger {
	//	height: 100%;
	//	width: 4rem;
	//	border-radius: 0;
	//}
}

.navbar-toggler {
	&:focus {
		outline: none;
	}
}



.navbar-content-section {
	input {
		height: 2rem;
		margin: 0.5rem 1.5rem 0.5rem 1rem;

		opacity: 0.7;		
		@include transition(all $transition-general);

		&:focus {
			outline: none;
			opacity: 1;
		}
		&:not(:focus):hover {
			opacity: 0.8;
		}
	}
}


//mobile view
@include media-breakpoint-down($navbar-mobile-max-width) {
	.navbar-content {
		//justify-content: end;
		margin-left: auto;
		background-color: inherit;//takes the color of navbar

		flex: 0 0 auto;
	}

	.navbar-content-section {
	   position: absolute;
	   top: 100%;
	   left: 0;

	   width: 100%;
   
	   background-color: inherit;//takes the color of navbar

	   input:not(:focus) {
			opacity: 0.8;
	    }
	   .navbar-search-input {
			width: calc(100% - 2rem);
		}
	}
}

