$datepicker-top-border: #619fcd;
$datepicker-top-border-width: 0.25rem;

$datepicker-font-size: 1rem;

$datepicker-item-hover-bg: #EEE;
$datepicker-item-hover-color: #3B404D;

$datepicker-item-selected-bg: rgba(86, 152, 201, 0.93);//bg-primary
$datepicker-item-selected-color: #FFF;

$datepicker-btn-hover-bg: rgba(72, 175, 214, 0.93);//bg-info
$datepicker-btn-hover-color: #fff;

$datepicker-nav-btn-border: #d1dce7;
$datepicker-nav-btn-color: #7a868d;
$datepicker-nav-btn-bg: #fff;

$datepicker-nav-btn-hover-bg: #FFF;
$datepicker-nav-btn-hover-color: #5698C9;
$datepicker-nav-btn-hover-border: rgba(86, 152, 201, 0.67);

$datepicker-month-hover-color: #3B404D;
$datepicker-month-hover-bg: #e7e7e7; //btn-light

$datepicker-today-highlight: #F2D11C;

//daterange
$datepicker-range-bg: #e9f3fd;
$datepicker-range-color: #3B404D;
$datepicker-range-selected-bg: #619fcd;
$datepicker-range-selected-color: #fff;



@if $plugin-datepicker == true {

//date-time picker
.dp-modal {
  z-index: $zindex-modal;
}

.dp {
  padding-top: 0;
  border-top: $datepicker-top-border-width solid $datepicker-top-border;
  border-radius: 3px;

  font-size: $datepicker-font-size;

  &::before {
    display: none;
  }

  .dp-col-header {
    font-size: 0.875em;
    color: #999;
  }
  .dp-day {
    font-size: 0.925em;
  }
}

.dp-cal-month, .dp-cal-year, .dp-day, .dp-month, .dp-year {
  border-radius: 0;
  font-size: 0.925em;
}



.dp-day, .dp-month, .dp-year {
  &:hover {
    background: $datepicker-item-hover-bg;
    color: $datepicker-item-hover-color;
  }
}

.dp-selected:hover, .dp-selected:focus, .dp-selected,
.dp-current:focus, .dp-current, .dp-day:focus, .dp-month:focus, .dp-year:focus {
  background: $datepicker-item-selected-bg;
  color: $datepicker-item-selected-color;
}

.dp-close,
.dp-clear,
.dp-today {
  &:hover, &:focus {
    background: $datepicker-btn-hover-bg;
    color: $datepicker-btn-hover-color;
  }
}
.dp-next, 
.dp-prev {
  top: 0.75rem;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;

  background-color: $datepicker-nav-btn-bg;
  border: 2px solid $datepicker-nav-btn-border;
  color: $datepicker-nav-btn-color;
  
  &:hover, &:focus {
    background-color: $datepicker-nav-btn-hover-bg;
    color: $datepicker-nav-btn-hover-color;
    border-color: $datepicker-nav-btn-hover-border;
  }
}


.dp-cal-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.dp-cal-month,
.dp-cal-year {
  vertical-align: middle;
  padding: 0.425rem 0.75rem 0.5rem;

  &:hover, &:focus {
    color: $datepicker-month-hover-color;
    background-color: $datepicker-month-hover-bg;
  }
}

.dp-day-today::after {
  border-right-color: $datepicker-today-highlight;
  border-top-color: $datepicker-today-highlight;
}

//daterange picker
.dr-cals {
  display: flex;
  position: relative;
  z-index: 11;

  overflow: visible;

  box-shadow: none;

  padding-top: 0;
  border-top: $datepicker-top-border-width solid $datepicker-top-border;
  border-radius: 3px;

  font-size: $datepicker-font-size;

  &::before {
    display: none;
  }


  .dr-in-range {
    &, &:hover, &:focus {
      color: $datepicker-range-color;
      background-color: $datepicker-range-bg;
    }
  }
  .dp-current:hover {
    color: $datepicker-range-color;
    background-color: $datepicker-range-bg;
    transform: scale(1.1);
  }

  .dr-selected {
    &, &:hover, &:focus {
      color: $datepicker-range-selected-color;
      background: $datepicker-range-selected-bg;      
      border-radius: 0;
    }
  }

}



.dp-daterange-picker {
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  @include transition(opacity $transition-general, visibility 0s $transition-general, z-index 0s $transition-general);

  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);

  &.visible {
    opacity: 1;
    z-index: 100;
    visibility: visible;
    @include transition(opacity $transition-general, visibility 0s 0s, z-index 0s 0s);
  }
}
.dp-daterange-above {
  top: auto;
  bottom: 100%;
}

.dr-cal-end .dp {
  border-left-width: 0.25rem;
}

@media (max-width: 616px), (max-height: 480px) {
  .dr-cal-end {
    display: none;
  }

  .dr-cal-start .dp-next {
    visibility: visible;
  }
}



}//@if $plugin-datepicker