.badge {
    //color: #fff;
    font-size: 75%;
    font-weight: $font-semibold;

    padding: 0.25rem 0.5rem 0.275rem;
    line-height: 1.175;
    
    border-radius: 0.125rem;

    position: relative;
}
.badge-pill {
    border-radius: 2rem;
}

//firefox
@supports (-moz-appearance:none) {
    .badge {
        line-height: 1.1333;
    }
}

.badge-sm {
    padding: 0.1875rem 0.425rem 0.275rem;

    font-size: 70%;
    line-height: 1;
}
.badge-lg {
    padding: 0.275rem 0.5rem 0.425rem;
    font-size: 90%;
    line-height: 1.25;
    height: 1.75rem;
}
.badge-xlg {
    padding: 0.325rem 0.625rem 0.475rem;
    font-size: 105%;
    line-height: 1.625;
    height: 2.5rem;
}


@if $badge-arrow == true {
.badge {
    &.arrowed::before, &.arrowed-in::before,
    &.arrowed-right::after, &.arrowed-in-right::after {
        display: inline-block;
        content: "";
        position: absolute;
        top: 0;
        border: 1px solid transparent;

        border-width: 0.6875rem 0.375rem;
    }

    &.arrowed {
        margin-left: 0.325rem;
        &::before {
            left: -0.7125rem;
            border-right-color: inherit;
        }
    }
    &.arrowed-right {
        margin-right: 0.325rem;        
        &::after {
            right: -0.7125rem;
            border-left-color: inherit;
        }
    }
    &.arrowed-in {
        margin-left: 0.375rem;        
        &::before {
            left: -0.375rem;
            border-color: inherit;
            border-left-color: transparent;
        }
    }
    &.arrowed-in-right {
        margin-right: 0.375rem;
        &::after {
            right: -0.375rem;
            border-color: inherit;
            border-right-color: transparent;
        }
    }



    &.badge-sm {
        &.arrowed {
            margin-left: 0.3rem;            
            &::before {
                border-width: 0.575rem 0.325rem;
                left: -0.6125rem;
            }
        }
        &.arrowed-in {
            margin-left: 0.3rem;
            &::before {                
                border-width: 0.575rem 0.325rem;
                left: -0.325rem;
            }
        }

        &.arrowed-right {
            margin-right: 0.3rem;
            &::after {
                border-width: 0.575rem 0.325rem;
                right: -0.6125rem;
            }
        }
        &.arrowed-in-right {
            margin-right: 0.3rem;
            &::after {
                border-width: 0.575rem 0.325rem;
                right: -0.325rem;
            }
        }
    }


    &.badge-lg {
        &.arrowed {
            margin-left: 0.5rem;            
            &::before {
                border-width: 0.875rem 0.5rem;
                left: -0.975rem;
            }
        }
        &.arrowed-in {
            margin-left: 0.5rem;
            &::before {                
                border-width: 0.875rem 0.5rem;
                left: -0.5rem;
            }
        }

        &.arrowed-right {
            margin-right: 0.5rem;
            &::after {
                border-width: 0.875rem 0.5rem;
                right: -0.975rem;
            }
        }
        &.arrowed-in-right {
            margin-right: 0.5rem;
            &::after {
                border-width: 0.875rem 0.5rem;
                right: -0.5rem;
            }
        }
    }



    &.badge-xlg {
        &.arrowed {            
            padding-left: 0.5rem;
            margin-left: 0.675rem;
        }
        &.arrowed-in {
            padding-left: 0;
            margin-left: 1.325rem;
        }
        &.arrowed, &.arrowed-in {            
            &::before {
                border-width: 1.25rem 0.625rem;
                left: -1.25rem;
            }
        }

        &.arrowed-right {
            padding-right: 0.5rem;
            margin-right: 0.725rem;
        }
        &.arrowed-in-right {
            padding-right: 0;
            margin-right: 1.375rem;
        }

        &.arrowed-right, &.arrowed-in-right {
            &::after {
                border-width: 1.25rem 0.625rem;
                right: -0.625rem;
            }
        }
    }
}

}//@if $badge-arrow

@mixin badge-colors($name, $color, $text:null) {
    @if $text == null {
        $text: #fff;
    }
    .badge-#{$name} {
        color: $text;
        background-color: $color;
        border-color: $color;
    }
}

@each $color, $value in $badge-colors {
    $text: map-get($badge-text-colors, $color);
    @include badge-colors($color, $value, $text);
}



//round circle
.badge-dot {
    font-size: 0px !important;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    padding: 0;

    &:empty {
        display: inline-block;
    }
}