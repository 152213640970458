//compile and include only the theme you want

@import "navbar-theme-maker";
@import "sidebar-theme-maker";

$my-navbar-themes: (
    "default": $navbar-default
);
$my-sidebar-themes: (
    "default": $sidebar-default
);
/**
If you want to use a dark sidebar like "purple", you should also add/include "sidebar-color" :
$my-sidebar-themes: (
    "color": $sidebar-color,
    "purple": $sidebar-purple
);
*/

@each $theme-name, $theme-values in $my-navbar-themes {
    @include navbar-colors($theme-name, $theme-values);
}

@each $theme-name, $theme-values in $my-sidebar-themes {
    @include sidebar-colors($theme-name, $theme-values);
}

