@if $hideable == true {

@include media-breakpoint-up($sidebar-desktop-min-width) {
    //hideable sidebar ... sidebar is hidden instead of minimized
    .sidebar.hideable {
        transition-duration: $hideable-transition-duration;

        .sidebar-inner {
            @include transition(width $hideable-transition-duration, visibility $hideable-transition-duration);
        }
        
        &.collapsed { 
            width: 0;    
            overflow: hidden;

            .sidebar-inner {
                visibility: hidden;
                width: 0;
                overflow: hidden;

                transition-delay: 0ms, $hideable-transition-duration;
                transition-duration: $hideable-transition-duration, 0ms; 
            }
        }
    }
   
}//media query


}//@if $hideable == true